import {CommonFormData} from "./commonFormData";

export const accidentInsuranceFormData = {
  "beginn": null,
  "versicherungsdauer": "1",
  "tarif": "",
  "zahlungsweise": "",
  "anzahl_kinder": "",
  "haushalt": "",
  "gesundheitsfragen": "",
  "zusatzbausteinMitwirkung": false,
  "zusaetzliche_partnerdaten_einwilligung_see": false,
  "zusaetzliche_partnerdaten_gesund": false,

  "mich": {
    "geburtsdatum": "",
    "beruf": null,
    "berufsgruppe": "",
    "invaliditaet": "",
    "progression": "",
    "unfallrente": "",
    "krankenhaustagegeld": "",
    "todesfallleistung": "",
    "gipsgeld": "",
    "notfall_assistent": "",
    "uebergangsleistung": "",
    "unfall_tagegeld": "",
    "beginn_unfall_tagegeld": "",
    "dynamik": "",
  },

  "partner": {
    "vorname": "",
    "nachname": "",
    "geburtsdatum": "",
    "beruf": null,
    "berufsgruppe": "",
    "invaliditaet": "",
    "progression": "",
    "unfallrente": "",
    "krankenhaustagegeld": "",
    "todesfallleistung": "",
    "gipsgeld": "",
    "notfall_assistent": "",
    "uebergangsleistung": "",
    "unfall_tagegeld": "",
    "beginn_unfall_tagegeld": "",
    "dynamik": "",
  },

  "kind1": {
    "vorname": "",
    "nachname": "",
    "geburtsdatum": "",
    "beruf": null,
    "berufsgruppe": "",
    "invaliditaet": "",
    "progression": "",
    "unfallrente": "",
    "krankenhaustagegeld": "",
    "todesfallleistung": "",
    "gipsgeld": "",
    "uebergangsleistung": "",
    "dynamik": ""
  },

  "kind2": {
    "vorname": "",
    "nachname": "",
    "geburtsdatum": "",
    "beruf": null,
    "berufsgruppe": "",
    "invaliditaet": "",
    "progression": "",
    "unfallrente": "",
    "krankenhaustagegeld": "",
    "todesfallleistung": "",
    "gipsgeld": "",
    "uebergangsleistung": "",
    "dynamik": ""
  },

  "kind3": {
    "vorname": "",
    "nachname": "",
    "geburtsdatum": "",
    "beruf": null,
    "berufsgruppe": "",
    "invaliditaet": "",
    "progression": "",
    "unfallrente": "",
    "krankenhaustagegeld": "",
    "todesfallleistung": "",
    "gipsgeld": "",
    "uebergangsleistung": "",
    "dynamik": ""
  },

  ...CommonFormData
}
