import React from "react";
import validationSchemaHousehold from "../utils/validationschemas/validationSchemaHousehold";
import {labels} from "../../_params/form_labels";
import {setOrderHousehold} from "../utils/setOrders/setOrderHousehold";
import {householdFormdata} from "../utils/initialvalues/householdFormdata";
import {insuranceTypes} from "../../_params/variables";
import DateInput from "../components/formitems/DateInput";
import SelectInput from "../components/formitems/SelectInput";
import {
	berufsgruppeRadioOptions, gebaeudeartOptions_Hausrat,
	JaNeinSwitcher,
	selbstbehaltOptions,
	vorschaedenOptions,
	zahlweiseOptions
} from "../utils/optionsSelection";
import TextInput from "../components/formitems/TextInput";
import RadioGroupInput from "../components/formitems/RadioGroupInput";
import {getQuoteHousehold} from "../utils/getquotes/getQuoteHousehold";
import {errors} from "../../_params/form_errors";
import FormWrapper from "../components/forms/FormWrapper";

const OFFER_URL = "HouseholdInsurance";
const labels_HouseholdInsurance = labels.HouseholdInsurance;

const HouseholdPage = () => {

	const CalculationFormRender = (formik) => {
		const {values, setFieldValue, handleChange} = formik;
		return (
				<>
					<DateInput item={"beginn"} label={labels_HouseholdInsurance.beginn}/>
					<TextInput item={"wohnflaeche"} label={labels_HouseholdInsurance.wohnflaeche}
								  handleChange={e => {
									  handleChange(e);
									  setFieldValue('versicherungssumme', e.target.value * 650)
								  }}
					/>
					<TextInput item={"versicherungssumme"} label={labels_HouseholdInsurance.versicherungssumme}
								  extraText={values.versicherungssumme && values.versicherungssumme < (650 * values.wohnflaeche)
										  ? errors.HouseholdInsurance.versicherungssummeMin : ""}
					/>
					<RadioGroupInput item={"berufsgruppe"} label={labels_HouseholdInsurance.berufsgruppe}
										  options={berufsgruppeRadioOptions}/>
					<DateInput item={"geburtsdatum_VN"} label={labels.COMMON.KUNDENDATEN.geburtsdatum_VN}/>
					<SelectInput item={"vorschaeden"} label={labels_HouseholdInsurance.vorschaeden}
									 options={vorschaedenOptions}/>
					<SelectInput item={"selbstbehalt"} label={labels_HouseholdInsurance.selbstbehalt}
									 options={selbstbehaltOptions}/>
					<SelectInput item={"zahlungsweise"} label={labels_HouseholdInsurance.zahlungsweise}
									 options={zahlweiseOptions}/>
					<RadioGroupInput item={"fahrrad"} label={labels_HouseholdInsurance.fahrrad}
										  options={JaNeinSwitcher} muiJaNeinSwitcher/>
					{values.fahrrad &&
					 <TextInput item={"fahrrad_versicherungssumme"}
									label={labels_HouseholdInsurance.fahrrad_versicherungssumme}/>
					}
					<RadioGroupInput item={"glas"} label={labels_HouseholdInsurance.glas}
										  options={JaNeinSwitcher} muiJaNeinSwitcher/>
					{values.glas &&
					 <SelectInput item={"gebaeudeart"} label={labels_HouseholdInsurance.gebaeudeart}
									  options={gebaeudeartOptions_Hausrat}/>
					}
					<RadioGroupInput item={"elementar"} label={labels_HouseholdInsurance.elementar}
										  options={JaNeinSwitcher} muiJaNeinSwitcher/>
					{values.elementar &&
					 <SelectInput item={"vorschaeden_elementar"}
									  label={labels_HouseholdInsurance.vorschaeden_elementar}
									  options={vorschaedenOptions}/>
					}
					<hr style={{backgroundColor: '#fff'}}/>
					<h3>{labels_HouseholdInsurance.versichertes_objekt}</h3>
					<TextInput item={"versichertes_objekt.strasse_vo"} label={labels_HouseholdInsurance.strasse_vo}/>
					<TextInput item={"versichertes_objekt.hausnr_vo"} label={labels_HouseholdInsurance.hausnr_vo}/>
					<TextInput item={"versichertes_objekt.postleitzahl_vo"}
								  label={labels_HouseholdInsurance.postleitzahl_vo}/>
					<TextInput item={"versichertes_objekt.ort_vo"} label={labels_HouseholdInsurance.ort_vo}/>

				</>
		)
	}

	return (<>
				<FormWrapper
						validationSchemaCalculation={validationSchemaHousehold}
						OFFER_URL={OFFER_URL}
						CalculationForm={CalculationFormRender}
						calcList={['L','XL', 'XXL']}
						getQuoteHandler={getQuoteHousehold}
						setOrder={setOrderHousehold}
						initValuesForm={householdFormdata}
						tariffName={insuranceTypes.HOUSE_HOLD}/>
			</>
	)
}

export default HouseholdPage;
