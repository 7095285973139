export const labels = {

	LiabilityInsurance: {

		beginn: 'Versicherungsbeginn',
		versicherungsdauer: 'Versicherungsdauer',
		versicherungssumme: 'Versicherungssumme',
		familienstand: 'Familienstand',
		vorschaeden: 'Vorschäden',
		selbstbehalt: 'Selbstbehalt',
		zahlungsweise: 'Zahlungsweise',

	},

	PetLiabilityInsurance: {

		beginn: 'Beginn',
		versicherungsdauer: 'Versicherungsdauer',
		versicherungssumme: 'Versicherungssumme',
		hundeliste: 'Hundeliste',
		hundename: 'Hundename',
		hunderasse: 'Hunderasse',
		berufsgruppe: 'Berufsgruppe Versicherungsnehmer',
		postleitzahl: 'PLZ',
		vorschaeden: 'Vorschäden',
		selbstbehalt: 'Selbstbehalt',
		zahlungsweise: 'Zahlungsweise',

	},

	HouseholdInsurance: {

		beginn: 'Beginn',
		versicherungsdauer: 'Versicherungsdauer',
		wohnflaeche: 'Wohnfläche',
		versicherungssumme: 'Versicherungssumme',
		berufsgruppe: 'Berufsgruppe',
		vorschaeden: 'Vorschäden',
		selbstbehalt: 'Selbstbehalt',
		zahlungsweise: 'Zahlungsweise',

		fahrrad: 'Fahrrad',
		fahrrad_versicherungssumme: 'Fahrrad Versicherungssumme',
		glas: 'Glas',
		elementar: 'Elementar',
		vorschaeden_elementar: 'Vorschäden Elementar',
		gebaeudeart: 'Gebäudeart',

		versichertes_objekt: "Versichertes Objekt",
		strasse_vo: 'Straße',
		hausnr_vo: 'Hausnr.',
		postleitzahl_vo: 'PLZ',
		ort_vo: 'Ort',

	},

	LegalCostsInsurance: {

		beginn: 'Beginn',
		versicherungsdauer: 'Versicherungsdauer',
		familienstand: 'Familienstand',
		berufsgruppeVN: 'Berufsgruppe VN',
		berufsgruppe_partner: 'Berufsgruppe Partner',
		produkt: 'Produkt',
		anzahl_wohnungen: 'Anzahl der Wohnungen',
		vorschaeden: 'Vorschäden',
		schadenfreieJahre: 'Anzahl der schadenfreien Jahre',
		selbstbehalt: 'Selbstbehalt',
		zahlungsweise: 'Zahlungsweise',
		zusatzbausteinErweiterterStraftrechtsschutz: 'Erweiterter Strafrechtsschutz',
		zusatzbausteinPflegeberatung: 'Pflegeberatung',

		versicherte_objekte: "Adresse Wohneinheit",
		strasse_vo: 'Straße',
		hausnr_vo: 'Hausnummer',
		postleitzahl_vo: 'Postleitzahl',
		ort_vo: 'Ort',
	},
	BicycleInsurance: {
		beginn: 'Versicherungsbeginn',
		typ: 'Was wird versichert?',
		fahrradtyp: 'Fahrradtyp',
		fahrradhersteller: 'Fahrradhersteller',
		nutzung: 'Nutzung',
		condition: 'Zustand',
		originalrechnung: 'Originalrechnung vorhanden?',
		kaufdatum: 'Datum der Originalrechnung',
		kaufsumme: 'Kaufpreis',
		carbon: 'Karbonrahmen?',
		leasing: 'Leasing?',
		rahmenummer: 'Rahmennummer',
		geburtsdatum_VN: 'Geburtsdatum Versicherungsnehmer',
		postleitzahl_VN: 'Postleitzahl Versicherungsnehmer',
		vorschaeden: 'Vorschäden',
		zahlungsweise: 'Zahlungsweise',
		zusatzbausteinDiebstahl: 'Diebstahl',
		zusatzbausteinReparatur: 'Reparatur',
		zusatzbausteinPannenhilfe: 'Pannenhilfe'
	},

	AccidentInsurance: {

		beginn: 'Versicherungsbeginn',
		versicherungsdauer: 'Versicherungsdauer',
		zahlungsweise: 'Zahlungsweise',
		geburtsdatum: 'Geburtsdatum',
		geburtsdatum_Mich: 'Geburtsdatum Mich (Versicherungsnehmer)',
		beruf: 'Beruf',
		berufsgruppe: 'Berufsgruppe',

		zusaetzliche_partnerdaten_einwilligung_see: 'Zusätzliche Partnerdaten Einwilligung SEE',
		zusaetzliche_partnerdaten_gesund: 'Zusätzliche Partnerdaten Gesund',

		haushalt: 'Haushalt',
		anzahl_kinder: 'Anzahl Kinder',

		gesundheitsfragen: 'Gesundheitsfragen',

		zusatzbausteinMitwirkung: 'Mitwirkungsbaustein',

		invaliditaet: "Invalidität",
		progression: "Progression",
		unfallrente: "Unfallrente",
		krankenhaustagegeld: "Krankenhaustagegeld",
		todesfallleistung: "Todesfallleistung",
		gipsgeld: "Gipsgeld",
		notfall_assistent: "Notfall-Assistent",

		uebergangsleistung: "Übergangsleistung",
		unfall_tagegeld: "Unfall-Tagegeld",
		beginn_unfall_tagegeld: "Beginn Unfall-Tagegeld",

		// vorschaeden: 'Vorschäden',
		// selbstbehalt: 'Selbstbehalt',

		dynamik: "Dynamik",

		versicherte_person_daten: {
			anrede: 'Anrede',
			vorname: 'Vorname',
			nachname: 'Nachanme',
			zivildienst: 'Zivildienst',
			titel: 'Titel',
			geschlecht: 'Geschlecht',
			geburtsdatum: 'Geburtsdatum'
		},

		mich: 'Versicherungsnehmer',
		partner: 'Partner',
		kind1: 'Kind 1',
		kind2: 'Kind 2',
		kind3: 'Kind 3',
	},

	ResidentialBuildingInsurance: {
		beginn: 'Versicherungsbeginn',
		versicherungsdauer: 'Versicherungsdauer',
		zahlungsweise: 'Zahlungsweise',
		vorschaeden: 'Vorschäden pro Gefahr',
		feuerschutz: 'Feuerschutz',
		anzahl_vorschaeden_feuer: 'Anzahl Vorschäden Feuer',
		leitungswasser: 'Leitungswasser',
		anzahl_vorschaeden_leitungswasser: 'Anzahl Vorschäden Leitungswasser',
		sturm_oder_hagel: 'Sturm / Hagel',
		anzahl_vorschaeden_sturm_hagel: 'Anzahl Vorschäden Sturm/Hagel',
		elementar: 'Elementar',
		anzahl_vorschaeden_elementar: 'Anzahl Vorschäden Elementar',
		selbstbehalt: 'Selbstbehalt',

		gebaeudetyp: 'Gebäudetyp',
		bauartklasse: 'Bauartklasse',
		gebaeudenutzung: 'Gebäudenutzung',
		prozentualer_anteil_gewerblich_genutzt: 'Anteil gewerblich genutzt in %',
		baujahr: 'Baujahr',
		kernsanierungsjahr: 'Kernsanierungsjahr',
		nutzungsart: 'Nutzungsart',
		garage_oder_carport: 'Garage/Carport',
		anzahl_garage_oder_carport: 'Anzahl Garage/Carport',
		gebaeude_staendig_bewohnt: 'Gebäude ständig bewohnt',
		denkmalschutz: 'Steht Ihr Gebäude unter Denkmalschutz?',
		wohnflaeche_einschliesslich_gewerbeflaeche: 'Wohnfläche einschließlich Gewerbefläche (inkl. Dachgeschoss, ohne Keller)',
		anzahl_stockwerke: 'Anzahl Stockwerke',
		unterkellerung: 'Ist das Gebäude unterkellert?',
		wohnflaeche_keller: 'Wohnfläche Keller',
		dachausbau: 'Dachausbau',
		wohnflaeche_dachgeschoss: 'Wohnfläche Dachgeschoss (in qm)',
		fussboden_oder_dachheizung: 'Fußboden- oder Dachheizung',
		waermepumpe_oder_solaranlage: 'Wärmepumpe oder Solaranlage',
		anzahl_geschaefte: 'Anzahl Geschäfte',
		solaranlage: 'Solaranlage',
		waermepumpe: 'Wärmepumpe',
		fussbodenheizung: 'Fußbodenheizung',
		dachheizung: 'Dachheizung',

		glas: 'Glas',
		unbenannte_gefahren: 'Unbenannte Gefahren',
		haustechnik: 'Haustechnik',
		ableitungsrohre: 'Ableitungsrohre',
		photovoltaikanlage: 'Photovoltaikanlage',
		//		feuerschale: 'Feuerschale',
		assistance: 'Schutzbrief',

		versichertes_objekt: "Versichertes Objekt",
		strasse_vo: 'Straße',
		hausnr_vo: 'Hausnr.',
		postleitzahl_vo: 'PLZ',
		ort_vo: 'Ort',
	},

	COMMON: {

		TARIF_SELEKTION: {
			tarif: 'Tarif',
			berechneter_preis: "Berechneter Preis",
			berechneter_preis_produkte: "Berechneter Preis pro Produkt"
		},

		KUNDENDATEN: {
			anrede: 'Anrede',
			vorname: 'Vorname',
			nachname: 'Nachname',
			geburtsdatum_VN: 'Geburtsdatum Versicherungsnehmer',
			strasse: 'Straße',
			hausnummer: 'Hausnummer',
			postleitzahl: 'Postleitzahl',
			postleitzahl_VN: 'Postleitzahl Versicherungsnehmer',
			ort: 'Ort',
			telefon: 'Telefonnummer',
			email: 'E-Mail',
		},

		KONTODATEN: {
			kontoinhaber: 'Kontoinhaber',
			iban: 'IBAN',
			// bic: 'BIC',
		},

		VORVERSICHERUNGSDATEN: {
			vorversicherung: 'Vorversicherung vorhanden?',
			gesellschaftsname: 'Name der Gesellschaft',
			vorversicherungsnummer: 'Nummer Vorversicherung',
			gekuendigt_durch: 'Gekündigt durch',
		},

		bedingungen: ''
	}

};
