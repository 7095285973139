import {CommonFormData} from "./commonFormData";

export const petLiabilityFormdata = {
  "beginn": null,
  "versicherungsdauer": "1",
  "tarif": "",
  // "versicherungssumme": "",
  "hundeliste": [],
  "berufsgruppe": "freie_wirtschaft",
  "vorschaeden": "",
  "selbstbehalt": "",
  "zahlungsweise": "",

  ...CommonFormData
}
