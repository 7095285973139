export default [
	"Unbekannt",
	"Affenpinscher",
	"Pinscher (Affenpinscher)",
	"Afghan Hound",
	"Afghane",
	"Afghanischer Windhund",
	"Balutschi-Windhund",
	"Windhund (Afghanischer Windhund )",
	"Aidi",
	"Aïdi",
	"Aiidi",
	"Atlas Sheepdog",
	"Atlas-Berghund",
	"Atlas-Schäferhund",
	"Ayidi",
	"Chien de l'Atlas",
	"Airedale Terrier",
	"Bingley Terrier",
	"Terrier (Airedale Terrier)",
	"Waterside Terrier",
	"Akbas",
	"Akbaş",
	"Akbas Coban Köpegi",
	"Akbaş Çoban Köpeği",
	"Akbasch",
	"Akbash",
	"Akbash Turkish Shepherd",
	"Türk Coban Köpegi Akbas",
	"Türk Çoban Köpegi Akbas",
	"Türk Çoban Köpeği Akbas",
	"Türkischer Hirtenhund Akbasch",
	"Akita",
	"Akita Inu",
	"Akita Ken",
	"Akita-Inu",
	"Japanischer Akita",
	"Alangu Mastiff",
	"Bully Dog",
	"Bully Kutta",
	"Pakistani Mastiff",
	"Sindhi Mastiff",
	"Alano",
	"Alano Espanol",
	"Alano Español",
	"Alapaha Blue Blood Bulldog",
	"Alaska Husky",
	"Alaskan Husky",
	"Alaskan Malamute",
	"Malamut",
	"Alpenländische Dachsbracke",
	"Alpenländische-Erzgebirgler Dachsbracke",
	"Bracke (Alpenländische Dachsbracke)",
	"Österreichische Dachsbracke",
	"Altdänischer Hühnerhund",
	"Altdänischer Vorstehhund",
	"Gammel Dansk Honsehund",
	"Gammel Dansk Hønsehund",
	"Hühnerhund (Altdänischer Hühnerhund)",
	"Altdeutscher Hütehund",
	"Fuchs",
	"Fuchs (Altdeutscher Hütehund)",
	"Gelbbacke",
	"Gelbbacke (Altdeutscher Hütehund)",
	"Harzer Fuchs",
	"Harzer Fuchs (Altdeutscher Hütehund)",
	"Kuhhund",
	"Kuhhund (Altdeutscher Hütehund)",
	"Schafpudel",
	"Schafpudel (Altdeutscher Hütehund)",
	"Siegerländer Kuhhund",
	"Tiger",
	"Tiger (Altdeutscher Hütehund)",
	"Westerwälder Kuhhund",
	"Altdeutscher Schäferhund",
	"Langstockhaariger Deutscher Schäferhund",
	"American Blue Gascon Hound",
	"Big'n Blue",
	"American Bulldog",
	"Amerikanische Bulldogge",
	"Bulldog (American Bulldog)",
	"Old Country Bulldog",
	"American Bully",
	"American Cocker Spaniel",
	"Amerikanischer Cocker Spaniel",
	"Spaniel (American Cocker Spaniel)",
	"American Deutscher Spitz",
	"American Eskimo Dog",
	"American Spitz",
	"Eskimo Spitz",
	"German Spitz",
	"American Foxhound",
	"Amerikanischer Foxhound",
	"Foxhound (Amerikanischer Foxhound)",
	"American Leopard Hound",
	"Leopard Cur",
	"American Pit Bull Terrier",
	"APBT",
	"Pit Bull",
	"Pitbull",
	"Pitbullterrier",
	"Terrier (American Pit Bull Terrier)",
	"Terrier (Pitbullterrier)",
	"American Stafford Terrier",
	"American Staffordshire Terrier",
	"Amstaff",
	"Terrier (American Stafford Terrier)",
	"Terrier (American Staffordshire Terrier)",
	"American Staghound",
	"American Toy Terrier",
	"Terrier (American Toy Terrier)",
	"Toy Fox Terrier",
	"American Water Spaniel",
	"Amerikanischer Wasserspaniel",
	"Chien d'eau americain",
	"Chien d'eau américain",
	"Perro de agua Americano",
	"Spaniel (American Water Spaniel )",
	"Spaniel (Amerikanischer Wasserspaniel)",
	"American Akita",
	"Amerikanischer Akita",
	"Great Japanese Dog",
	"Großer Japanischer Hund",
	"American Collie",
	"Amerikanischer Collie",
	"American Hairless Terrier",
	"Amerikanischer Nackthund",
	"Anglo Francais de Petite Venerie",
	"Anglo-Francais de petite venerie",
	"Anglo-Français de petite vénerie",
	"Appenzeller Sennenhund",
	"Appenzellerhund",
	"Appezöller Bläss",
	"Sennenhund (Appenzeller Sennenhund)",
	"Ardennen-Treibhund",
	"Bouvier des Ardennes",
	"Ariegeois",
	"Ariégeois",
	"Armant",
	"Erminti",
	"Australian Cattle Dog",
	"Australian Heeler",
	"Australischer Treibhund",
	"Blue Heeler",
	"Hall's Heeler",
	"Queensland Heeler",
	"Australian Kelpie",
	"Australian Sheepdog",
	"Australien Sheepdog",
	"Australischer Schäferhund",
	"Barb",
	"Kelpie",
	"Working Kelpie",
	"Australian Shepherd",
	"Australian Silky",
	"Australian Silky Terrier",
	"Silky Terrier",
	"Sydney Silky",
	"Terrier (Australian Silky Terrier)",
	"Terrier (Australian Silky)",
	"Terrier (Silky Terrier)",
	"Terrier (Sydney Silky)",
	"Australian Stumpy Tail Cattle Dog",
	"Australian Terrier",
	"Terrier (Australian Terrier)",
	"Azawakh",
	"Berber-Windhund",
	"Tuareg-Windhund",
	"Bakarwal",
	"Bakharwal",
	"Bakharwal Mastiff",
	"Gujjar Watchdog",
	"Kashmir Mastiff",
	"Kashmiri Bakharwal Dog",
	"Kashmiri Mastiff",
	"Barbet",
	"Französischer Wasserhund",
	"Barsoi",
	"Barzaia",
	"Barzoi",
	"Russischer Windhund",
	"Russischer Wolfshund",
	"Windhund (Russischer Windhund )",
	"Basenji",
	"Kongo-Terrier",
	"Basset artesien normand",
	"Basset artésien normand",
	"Basset Artesien-Normand",
	"Basset fauve de Bretagne",
	"Basset",
	"Basset Hound",
	"Hound (Basset Hound)",
	"Beagle",
	"Beagle Harrier",
	"Beagle-Harrier",
	"Bearded Collie",
	"Collie (Bearded Collie)",
	"Hairy Mou'ed Collie",
	"Highland Collie",
	"Mountain Collie",
	"Bas-Rouge",
	"Beauceron",
	"Berger de Beauce",
	"Chien (Chien de Berger de Beauce )",
	"Chien de Beauce",
	"Chien de Berger de Beauce",
	"Bedlington Terrier",
	"Rothbury Terrier",
	"Terrier (Bedlington Terrier)",
	"Belgischer Schäferhund",
	"Belgischer Schäferhund Groenendael",
	"Belgischer Schäferhund Laekenois",
	"Belgischer Schäferhund Malinois",
	"Belgischer Schäferhund Tervueren",
	"Berger de Laeken",
	"Chien de Berger Belge",
	"Groenendael",
	"Laekenois",
	"Malinois",
	"Schäferhund (Belgischer Schäferhund)",
	"Tervueren",
	"Bergamasco Shepherd Dog",
	"Bergamasker",
	"Bergamasker Hirtenhund",
	"Berger de bergame",
	"Cane da Pastore Bergamasco",
	"Hirtenhund der Abruzzen",
	"Perro de pastor Bergamasco",
	"AC Weisser Schäferhund",
	"AC Weißer Schäferhund",
	"Amerikanisch Canadisch (AC) Weißer Schäferhund",
	"Amerikanisch Canadisch Weißer Schäferhund",
	"Berger Blanc Suisse",
	"Kanadischer Schäferhund",
	"Schäferhund (AC Weisser Schäferhund)",
	"Schäferhund (Weisser Schäferhund )",
	"Weißer Schäferhund",
	"Weisser Schweizer Schäferhund",
	"Berger de Picardie",
	"Berger Picard",
	"Picard",
	"Picardischer Schäferhund",
	"Berger de Savoie",
	"Berger de Savoie (Berger des Alpes)",
	"Berger des Alpes",
	"Berger de la Crau",
	"Berger des Cevennes",
	"Berger des Cévennes",
	"Berger du Languedoc",
	"Berner Sennenhund",
	"Bernese Mountain Dog",
	"Bouvier bernois",
	"Dürrbächler",
	"Perro Boyero de montana Bernes",
	"Sennenhund (Berner Sennenhund)",
	"Bernhardiner",
	"Chien du Saint-Bernard",
	"Perro San Bernardo",
	"Saint Bernard Dog",
	"Sankt-Bernhards-Hund",
	"Schweizer",
	"St. Bernhardshund",
	"St.-Bernhards-Hund",
	"Bichon a poil frise",
	"Bichon à poil frisé",
	"Bichon Frise",
	"Bichon Frisé",
	"Gelockter Bichon",
	"Teneriffe",
	"Ténériffe",
	"Biewer Terrier",
	"Biewer Yorkshire a la Pom-Pon",
	"Biewer Yorkshire à la Pom-Pon",
	"Biewer Yorkshire Terrier",
	"Terrier (Biewer Terrier)",
	"Billy",
	"Bisben",
	"Bisben (Himalaja Schäferhund)",
	"Himalaja Schäferhund",
	"Himalayan Bisben Sheepdog",
	"American Black and Tan Coonhound",
	"Black and Tan Coonhound",
	"Hound (Black and tan Coonhound)",
	"Schwarz-roter Waschbärenhund",
	"Black Mouth Cur",
	"Southern Cur",
	"Yellow Black Cur",
	"Basset bleu de Gascogne",
	"Blauer Basset der Gascogne",
	"Blue Lacy",
	"Lacy Dog",
	"Lacy Game Dog",
	"Lacy Hog Dog",
	"Texas Blue Lacy",
	"Bluetick Coonhound",
	"Altenglischer Schäferhund",
	"Bobtail",
	"Old English Sheepdog",
	"Sheepdog (Old English Sheepdog)",
	"Boerboel",
	"Bologneser",
	"Boulogneser",
	"Bolonka Franzuska",
	"Franzuskaya Bolonka",
	"Bolonka Zwetna",
	"Tsvetnaya Bolonka",
	"Border Collie",
	"Collie (Border Collie)",
	"Border Terrier",
	"Terrier (Border Terrier)",
	"Boston Terrier",
	"Terrier (Boston Terrier)",
	"Bourbonnaiser Vorstehhund",
	"Bourbonnais-Vorstehhund",
	"Braque (Braque du Bourbonnais)",
	"Braque du Bourbonnais",
	"Bouvier des Flandres",
	"Flandrischer Treibhund",
	"Boykin Spaniel",
	"Bracco Italiano",
	"Italienische Bracke",
	"Italienischer Vorstehhund",
	"Vorstehhund (Italienischer Vorstehhund)",
	"Bracke (Brandlbracke)",
	"Brandlbracke",
	"Kärntner Bracke",
	"Vieräugel",
	"Braque (Braque d`Auverne)",
	"Braque (Braque d'Auverne)",
	"Braque d’Auvergne",
	"Ariege-Vorstehhund",
	"Ariège-Vorstehhund",
	"Braque (Braque de l`Ariège)",
	"Braque (Braque de l'Ariège)",
	"Braque de l’Ariege",
	"Braque de l’Ariège",
	"Braque (Braque Saint-Germain)",
	"Braque Charles",
	"Braque Compiegne",
	"Braque Saint-Germain",
	"Brasilianische Dogge",
	"Brasilianischer Mastiff",
	"Dogge (Brasilianische Dogge)",
	"Fila Brasileiro",
	"Brasilianischer Terrier",
	"Terrier (Brasilianischer Terrier)",
	"Terrier Brasileiro",
	"Berger de Brie",
	"Briard",
	"Briquet de Provence",
	"Briquet Griffon Vendeen",
	"Briquet Griffon Vendéen",
	"Griffon (Briquet Griffon Vendeen)",
	"Broholmer",
	"Brüsseler Griffon",
	"Griffon (Brüsseler Griffon )",
	"Griffon (Griffon Bruxellois)",
	"Griffon Bruxellois",
	"Bulldog",
	"Bulldogge",
	"Bullmastiff",
	"Bull Terrier",
	"Bullterrier",
	"English Bull Terrier",
	"Terrier (Bull Terrier)",
	"Terrier (Bullterrier)",
	"Ca (Ca De Bestiar)",
	"Ca de Bestiar",
	"Mallorca-Schäferhund",
	"Perro de Pastor Mallorquín",
	"Azoren-Bulldogge",
	"Bulldogge (Azoren-Bulldogge)",
	"Ca (Ca de Bou)",
	"Ca de Bou",
	"Dogo (Dogo Mallorquin)",
	"Dogo Mallorquin",
	"Mallorca Dogge",
	"Mallorca Mastiff",
	"Perro (Perro de Presa Mallorquin)",
	"Perro de Presa Mallorquin",
	"Perro de Presa Mallorquín",
	"Perro Dogo Mallorquin",
	"Perro Dogo Mallorquín",
	"Cairn Terrier",
	"Terrier (Cairn Terrier)",
	"Cane (Cane Corso)",
	"Cane Corso",
	"Cane Corso Italiano",
	"Cane Di Macellaio",
	"Corso-Hund",
	"Italienischer Corso-Hund",
	"Bielleser Schäferhund",
	"Cane da Pastore Biellese",
	"Cane Pastore di Oropa",
	"Cao (Cao Da Serra Da Estrela)",
	"Cao da Serra da Estrela",
	"Cão da Serra da Estrela",
	"Estrela Berghund",
	"Cao (Cao Da Serra De Aires)",
	"Cao da Serra de Aires",
	"Cão da Serra de Aires",
	"Portugiesischer Schäferhund",
	"Cao (Cao De Agua Portugues )",
	"Cao de Agua Portugues",
	"Cão de Água Português",
	"Portugiesischer Wasserhund",
	"Wasserhund (Portugiesischer Wasserhund)",
	"Cao (Cao de Castro Laboreiro)",
	"Cao de Castro Laboreiro",
	"Cão de Castro Laboreiro",
	"Castro Laboreiro-Hund",
	"Cao Fila de Sao Miguel",
	"Cão Fila de São Miguel",
	"American Dingo",
	"Carolina Dog",
	"Carolina Hund",
	"Dixie Dingo",
	"Indian's Dog",
	"North American Native Dog",
	"Yaller Dog",
	"Yellow Dog",
	"Catahoula Bulldog",
	"Catahoula Bulldogge",
	"Çatal Burun Hunting",
	"Catalburun",
	"Çatalburun",
	"Blenheim",
	"Cavalier King Charles Spaniel",
	"Cavalier King Charles Spaniel Ruby",
	"Ruby",
	"Spaniel (Cavalier King Charles Spaniel)",
	"Böhmisch Rauhbart",
	"Böhmischer Rauhbart",
	"Cesky fousek",
	"Ceský Fousek",
	"Český Fousek",
	"Chart Polski",
	"Polnischer Windhund",
	"Chesapeake Bay Retriever",
	"Retriever (Chesapeake Bay Retriever)",
	"Artois Hound",
	"Basset d Artois",
	"Chien d’Artois",
	"Chihuahua",
	"Chihuahueno",
	"Chihuahueño",
	"Techichi",
	"Chin",
	"Japan Chin",
	"Chinese Chongqing Dog",
	"Chinesischer Chongqing Hund",
	"Chongqing Dog",
	"Chinese Crested Dog",
	"Chinese Crested Hairless",
	"Chinese Crested Powderpuff",
	"Chinesischer Haarloser Schopfhund",
	"Chinesischer Schopfhund",
	"Powder Puff",
	"Chinook",
	"Chippiparai",
	"Thanjavurhund",
	"Chodenhund",
	"Chodenländer Hund",
	"Chodsky pes",
	"Chodský pes",
	"Chortaj",
	"Chortaj (Hortaya Borzay)",
	"Chortaja Borzaja",
	"Hort",
	"Hortaya Borzaya",
	"Horty",
	"Chow Chow",
	"Chow-Chow",
	"Cimarron Uruguayo",
	"Cimarrón Uruguayo",
	"Perro Criollo",
	"Perro Gaucho",
	"Carpatin",
	"Ciobanesc Romanesc",
	"Ciobanesc Românesc",
	"Ciobanesc Romănesc",
	"Ciobanesc Romanesc Carpatin",
	"Ciobanesc Românesc Carpatin",
	"Ciobanesc Romănesc Carpatin",
	"Ciobanesc Romanesc Corb",
	"Ciobanesc Românesc Corb",
	"Ciobanesc Romănesc Corb",
	"Ciobanesc Romanesc de Bucovina",
	"Ciobanesc Românesc de Bucovina",
	"Ciobanesc Romănesc de Bucovina",
	"Ciobanesc Romanesc Mioritic",
	"Ciobanesc Românesc Mioritic",
	"Ciobanesc Romănesc Mioritic",
	"Mioritic",
	"Mocano",
	"Rumänischer Hirtenhund",
	"Rumänischer Rabenhirtenhund",
	"Rumänischer Schäferhund",
	"Südosteuropäischer Schäferhund",
	"Zavod",
	"Clumber Spaniel",
	"Spaniel (Clumber Spaniel)",
	"Cockapoo",
	"Cocker Poo",
	"Cockerpoo",
	"Collie",
	"Schottischer Schäferhund",
	"Combai",
	"Indian Beardog",
	"Continental Bulldog",
	"Coonhound",
	"Corgi",
	"Baumwollhund",
	"Coton de Tulear",
	"Coton de Tuléar",
	"Curly Coated Retriever",
	"Retriever (Curly Coated Retriever)",
	"Chien Corse",
	"Cursinu",
	"Da C´apo Dog",
	"Da Capo Dog",
	"Dachshund",
	"Dackel",
	"Kurzhaar Dackel",
	"Langhaar Dackel",
	"Rauhaar Dackel",
	"Teckel",
	"Zwergdackel",
	"Zwergteckel",
	"Dalmatinac",
	"Dalmatiner",
	"Dandie Dinmont Terrier",
	"Terrier (Dandie Dinmont Terrier)",
	"Dänisch-Schwedischer Farmhund",
	"Dansk-Svensk Gardshund",
	"Dansk-Svensk Gårdshund",
	"Sällskapshund",
	"Deerhound",
	"Highland Deerhound",
	"Schottischer Hirschhund",
	"Scottish Deerhound",
	"Deutsch Drahthaar",
	"Deutsch-Drahthaar",
	"Deutscher drahthaariger Vorstehhund",
	"Deutscher Vorstehhund (drahthaar)",
	"Vorstehhund (Deutscher drahthaariger Vorstehhund)",
	"Deutsch Kurzhaar",
	"Deutscher kurzhaariger Vorstehhund",
	"Deutscher Vorstehhund (kurzhaar)",
	"Deutsch-Kurzhaar",
	"Vorstehhund (Deutscher kurzhaariger Vorstehhund)",
	"Deutsch Langhaar",
	"Deutscher langhaariger Vorstehhund",
	"Deutscher Vorstehhund (langhaar)",
	"Deutsch-Langhaar",
	"Vorstehhund (Deutscher langhaariger Vorstehhund)",
	"Deutsch Stichelhaar",
	"Deutscher stichelhaariger Vorstehhund",
	"Deutscher Vorstehhund (stichelhaarig)",
	"Deutsch-Stichelhaar",
	"Vorstehhund (Deutscher stichelhaariger Vorstehhund)",
	"Bracke (Deutsche Bracke)",
	"Deutsche Bracke",
	"Olper Bracke",
	"Sauerländer Bracke",
	"Steinbracke",
	"Westfälische Bracke",
	"Dänische Dogge",
	"Deutsche Dogge",
	"Dogge (Dänische Dogge )",
	"Dogge (Deutsche Dogge)",
	"Dogge (Englische Dogge)",
	"Englische Dogge",
	"Große Dogge",
	"Hatzrüde",
	"Saupacker",
	"Ulmer Dogge",
	"Boxer",
	"Deutscher Boxer",
	"Deutscher Jagdterrier",
	"Terrier (Deutscher Jagdterrier)",
	"Deutscher Pinscher",
	"Pinscher (Deutscher Pinscher (Mittelschlag))",
	"Deutscher Schäferhund",
	"DSH",
	"Schäferhund (Deutscher Schäferhund)",
	"Schäferhund Deutscher",
	"Deutscher Wachtel",
	"Deutscher Wachtelhund",
	"Wachtel",
	"Dingo",
	"Do Khyi",
	"Dogge (Tibet Dogge)",
	"Do-Khyi",
	"Tibet Mastiff",
	"Tibetanische Dogge",
	"Tibetanischer Mastiff",
	"Tibetdogge",
	"Tibetmastiff",
	"Dobermann",
	"Dobermannpinscher",
	"Argentinische Dogge",
	"Argentinischer Mastiff",
	"Dogo (Dogo Argentino)",
	"Dogo Argentino",
	"Canary Dog",
	"Dogo (Dogo Canario)",
	"Dogo Canario",
	"Kanarische Dogge",
	"Perro (Perro de Presa Canario)",
	"Perro de Presa Canario",
	"Bordeaux Dogge",
	"Bordeaux Mastiff",
	"Bordeauxdogge",
	"Dogge (Bordeaux Dogge)",
	"Dogue de Bordeaux",
	"Bracke (Jugoslawische Dreifarbige Bracke)",
	"Dreifarbiger Serbischer Laufhund",
	"Gonic (Jugoslavenski Trobojni Gonic )",
	"Jugoslavenski Planinski Gonic",
	"Jugoslavenski Trobojni Gonic",
	"Jugoslawische Dreifarbige",
	"Jugoslawische Dreifarbige Bracke",
	"Jugoslawischer Dreifarbiger Laufhund",
	"Jugoslovenski Trobojni Gonic",
	"Srpski Trobojni Gonic",
	"Drentsche Patijshond",
	"Drentscher Hühnerhund",
	"Drentse Patrijshond",
	"Rebhuhnhund",
	"Bracke (Schwedische Dachsbracke)",
	"Drever",
	"Schwedische Dachsbracke",
	"Dunker",
	"Dunkerbracke",
	"Dunkerlaufhund",
	"Elo",
	"Groß-Elo",
	"Klein-Elo",
	"Bulldogge (Englische Bulldogge)",
	"Englische Bulldogge",
	"English Bulldog",
	"Cocker Spaniel",
	"Englischer Cocker Spaniel",
	"English Cocker Spaniel",
	"Spaniel (Cocker Spaniel)",
	"Spaniel (Englischer Cocker Spaniel )",
	"American English Coonhound",
	"English Coonhound",
	"Redtick Coonhound",
	"English Foxhound",
	"Foxhound",
	"Hound",
	"Englischer Pointer",
	"English Pointer",
	"Pointer",
	"English Setter",
	"Laverack Setter",
	"Setter (English Setter)",
	"Englischer Schäferhund",
	"English Shepherd",
	"Englischer Springer Spaniel",
	"English Springer Spaniel",
	"Norfolk Spaniel",
	"Spaniel (Englischer Springer Spaniel)",
	"Englischer Toy Spaniel",
	"English Toy Spaniel",
	"King Charles Spaniel",
	"King-Charles-Spaniel",
	"King-Charles-Spaniel",
	"King-Charles-Spaniel Ruby",
	"Prince Charles",
	"Spaniel (King Charles Spaniel)",
	"English Toy Terrier",
	"Terrier (English Toy Terrier)",
	"Toy Terrier",
	"Entlebucher Sennenhund",
	"Sennenhund (Entlebucher Sennenhund)",
	"Blauer Picardie-Spaniel",
	"Epagneul Bleu de Picardie",
	"Bretonischer Spaniel",
	"Bretonischer Vorstehhund",
	"Brittany",
	"Brittany Spaniel",
	"Brittany Wiegref",
	"Epagneul Breton",
	"French Brittany",
	"Epagneul de Pont Audemer",
	"Epagneul de Pont-Audemer",
	"Pont-Audemer-Spaniel",
	"Epagneul de Saint-Usuge",
	"Epagneul Francais",
	"Epagneul Français",
	"Französischer Spaniel",
	"Epagneul Picard",
	"Picardie-Spaniel",
	"Bracke (Siebenbürger Bracke)",
	"Erdelyi Kopo",
	"Erdélyi Kopó",
	"Siebenbürger Bracke",
	"Transsylvanischer Laufhund",
	"Ungarische Bracke",
	"Eesti hagijas",
	"Estnische Bracke",
	"Estnischer Laufhund",
	"Eurasier",
	"Eurohound",
	"Europäischer Schlittenhund",
	"German Trail Hound",
	"German Trailhound",
	"Scandinavian Hound",
	"Field Spaniel",
	"Spaniel (Field Spaniel)",
	"Bracke (Finnenbracke)",
	"Finnenbracke",
	"Finnischer Laufhund",
	"Finsk Stövare",
	"Suomenajokoira",
	"Finnenspitz",
	"Finnischer Spitz",
	"Finsk Spets",
	"Karelo-Finnische Laika",
	"Karelo-Finnish Laika",
	"Spitz (Finnenspitz)",
	"Suomenpystykorva",
	"Flat Coated Retriever",
	"Flat-coated Retriever",
	"Retriever (Flat Coated Retriever)",
	"Fox Terrier",
	"Foxterrier",
	"Foxterrier (Drahthaarig)",
	"Foxterrier (Glatthaarig)",
	"Foxterrier (Smooth)",
	"Foxterrier (Smooth) (Glatthaarig)",
	"Foxterrier (Wire)",
	"Foxterrier (Wire) (Drahthaarig)",
	"Foxterrier Drahthaar",
	"Foxterrier Glatthaar",
	"Foxterrier Kurzhaar",
	"Foxterrier Rauhhaar",
	"Glatthaar-Foxterrier",
	"Kurzhaar-Foxterrier",
	"Rauhhaar Foxterrier",
	"Terrier (Fox Terrier)",
	"Terrier (Foxerrier Rauhhaar)",
	"Terrier (Foxterrier Drahthaar)",
	"Terrier (Foxterrier Glatthaar)",
	"Terrier (Foxterrier Kurzhaar)",
	"Terrier (Foxterrier)",
	"Francais blanc et noir",
	"Français blanc et noir",
	"Französischer Weiss-Schwarzer Laufhund",
	"Francais blanc et orange",
	"Français blanc et orange",
	"Französischer Weiss-Oranger Laufhund",
	"Francais tricolor",
	"Francais tricolore",
	"Français tricolore",
	"Französischer Dreifarbiger Laufhund",
	"Bouledogue francais",
	"Bouledogue français",
	"Bulldogge (Französische Bulldogge)",
	"Chincha Bull",
	"Französische Bulldogge",
	"Französischer Laufhund",
	"Braque (Braque Francais)",
	"Braque Francais",
	"Braque Français",
	"Braque Francais, Type Gascogne grande taille",
	"Braque Français, Type Gascogne grande taille",
	"Braque Francais, Type Pyrenees petite taille",
	"Braque Français, Type Pyrénées petite taille",
	"Französischer Vorstehhund",
	"Französischer Vorstehhund Typ Gascogne",
	"Französischer Vorstehhund Typ Pyrenäen",
	"Französischer-Vorstehhund",
	"Galgo",
	"Galgo Espanol",
	"Galgo Español",
	"Spanischer Windhund",
	"Windhund (Spanischer Windhund)",
	"Germanischer Bärenhund",
	"Glen of Imaal Terrier",
	"Irish Glen of Imaal Terrier",
	"Terrier (Glen of Imaal Terrier)",
	"Golden Retriever",
	"Retriever (Golden Retriever)",
	"Goldendoodle",
	"Gonczy Polski",
	"Gończy Polski",
	"Polish Hunting Dog",
	"Polish Scenthound",
	"Polnischer Laufhund",
	"Gordon Setter",
	"Setter (Gordon Setter)",
	"Gos D’Atura",
	"Gos d’Atura Catala",
	"Gos d’Atura Català",
	"Katalanischer Schäferhund",
	"Katalonischer Schäferhund",
	"Perro (Perro de Pastor Catalan)",
	"Perro de pastor catalan",
	"Perro de pastor catalán",
	"Schäferhund (Katalonischer Schäferhund)",
	"Grand Anglo Francais",
	"Grand Anglo-Francais",
	"Grand Anglo-Français",
	"Grand Anglo-Francais blanc et noir",
	"Grand Anglo-Français blanc et noir",
	"Grand Anglo-Francais blanc et orange",
	"Grand Anglo-Français blanc et orange",
	"Grand Anglo-Francais tricolore",
	"Grand Anglo-Français tricolore",
	"Großer Anglo-Französischer Dreifarbiger Laufhund",
	"Großer Anglo-Französischer Laufhund",
	"Großer Anglo-Französischer Weiß-Oranger Laufhund",
	"Großer Anglo-Französischer Weiß-Schwarzer Laufhund",
	"Grand Basset Griffon Vendeen",
	"Grand Basset Griffon Vendéen",
	"Griffon (Grand Basset Griffon Vendéen)",
	"Großer Basset Griffon Vendeen",
	"Großer Basset Griffon Vendéen",
	"Grand Bleu de Gascogne",
	"Großer Blauer Gascogner Laufhund",
	"Grand Gascon Saintongeois",
	"Grand Griffon Vendeen",
	"Grand Griffon Vendéen",
	"Griffon (Grand Griffon Vendeen)",
	"Greyhound",
	"Großer Englischer Windhund",
	"Ellenikos Poimenikos",
	"Ellenikós Poimenikós",
	"Griechischer Schäferhund",
	"Belgischer Griffon",
	"Griffon (Belgischer Griffon )",
	"Griffon (Griffon Belge )",
	"Griffon Belge",
	"Blauer Gascogner Griffon",
	"Griffon bleu de Gascogne",
	"Boulet Griffon",
	"Griffon (Griffon a poil laineux)",
	"Griffon a Poil Laineux",
	"Griffon à Poil Laineux",
	"Griffon Boulet",
	"Griffon d’arret a Poil Laineux",
	"Griffon d’arrêt à Poil Laineux",
	"Wollhaariger Vorstehhund",
	"Griffon (Griffon d Arret à poil dur )",
	"Griffon (Korthals Griffon)",
	"Griffon a Poil Dur",
	"Griffon à Poil Dur",
	"Griffon d Arret à poil dur",
	"Griffon d’arret a poil dur",
	"Griffon d’arrêt à poil dur",
	"Griffon Korthals",
	"Korthals Griffon",
	"Griffon (Griffon Fauve de Bretagne)",
	"Griffon Fauve de Bretagne",
	"Chien de Pays",
	"Grand Griffon Nivernais",
	"Griffon (Griffon nivernais)",
	"Griffon Nivernais",
	"Grönlandhund",
	"Gronlandshund",
	"Grönlandshund",
	"Grønlandshund",
	"Großer Münsterländer",
	"Großer Münsterländer Vorstehhund",
	"Münsterländer",
	"Vorstehhund (Großer Münsterländer Vorstehhund)",
	"Greater Swiss Mountain Dog",
	"Großer Schweizer Sennenhund",
	"Sennenhund (Großer Schweizer Sennenhund)",
	"Großpudel",
	"Königspudel",
	"Pudel",
	"Deutscher Spitz",
	"Großspitz",
	"Spitz",
	"Spitz (Deutscher Spitz )",
	"Haldenbracke",
	"Haldenlaufhund",
	"Haldenstoever",
	"Halden-Stövare",
	"Haldenstover",
	"Haldenstøver",
	"Hamilton Stövare",
	"Hamilton-Bracke",
	"Hamilton-Laufhund",
	"Hamiltonstövare",
	"Hare-Hound",
	"Harrier",
	"Bichon Habanero",
	"Bichón Habanero",
	"Bichon Havanais",
	"Havaneser",
	"Hawaiian Poi Dog",
	"Griechische Bracke",
	"Griechischer Laufhund",
	"Griechischer Spürhund",
	"Hellenikos Ichnilatis",
	"Hellinikos Ichnilatis",
	"Hertha Pointer",
	"Ainu Inu",
	"Ainu-Hund",
	"Hokkaido",
	"Hokkaido-Hund",
	"Hokkaido-Ken",
	"Herder",
	"Holländischer Schäferhund",
	"Hollandse Herderhond",
	"Hollandse Herdershond",
	"Nederlandse Herdershond",
	"Schäferhund (Holländischer Schäferhund)",
	"Holländischer Rattler",
	"Holländischer Smoushond",
	"Hollandse Smoushond",
	"Hovawart",
	"Hovawart Blond",
	"Hovawart Schwarz",
	"Hovawart Schwarzmarken",
	"Hrvatski ovcar",
	"Hrvatski ovčar",
	"Kroatischer Schäferhund",
	"Schäferhund (Kroatischer Schäferhund)",
	"Bloodhound",
	"Bluthund",
	"Chien (Chien de Saint Hubert )",
	"Chien de Saint Hubert",
	"Chien de Saint-Hubert",
	"Hound (Bloodhound)",
	"Hubertushund",
	"St. Hubertushund",
	"Huntaway",
	"Neuseeländer Huntaway",
	"Neuseeländischer Huntaway",
	"NZ Huntaway",
	"Eskimohund",
	"Husky",
	"Siberian Husky",
	"Bracke (Hygenbracke)",
	"Hygenbracke",
	"Hygenhund",
	"Hygenlaufhund",
	"Hound (Irish Wolfhound )",
	"Irischer Wolfshund",
	"Irish Wolfhound",
	"Wolfshund",
	"Wolfshund (Irischer Wolfshund)",
	"Irisch rot-weisser Setter",
	"Irischer Rot-Weißer Setter",
	"Irish Red and White Setter",
	"Setter (Irisch rot-weisser Setter)",
	"Setter (Irish Red and White Setter )",
	"Irischer Roter Setter",
	"Irish Red Setter",
	"Irish Setter",
	"Setter (Irish Setter)",
	"Irish Soft Coated Wheaten Terrier",
	"Soft Coated Wheaten Terrier",
	"Soft-Coated Wheaten Terrier",
	"Terrier (Irish Soft Coated Wheaten Terrier)",
	"Terrier (Soft Coated Wheaten Terrier)",
	"Terrier (Soft-Coated Wheaten Terrier)",
	"Irish Terrier",
	"Terrier (Irish Terrier)",
	"Irischer Wasserspaniel",
	"Irish Water Spaniel",
	"Spaniel (Irischer Wasserspaniel )",
	"Spaniel (Irish Water Spaniel)",
	"Islandhund",
	"Isländischer Schäferhund",
	"Isländischer Spitz",
	"Islandspitz",
	"Bracke (Kurzhaarige Istrianer Bracke)",
	"Istarski kratkodlaki gonic",
	"Istarski kratkodlaki gonič",
	"Kurzhaarige Istrianer Bracke",
	"Kurzhaarige Istrische Bracke",
	"Bracke (Rauhhaarige Istrianer Bracke)",
	"Gonic (Istarski Ostrodlaki Gonic )",
	"Istarski oštrodlaki gonic",
	"Istarski oštrodlaki gonič",
	"Rauhhaarige Istrianer Bracke",
	"Rauhhaarige Istrische Bracke",
	"Italian Greyhound",
	"Italienisches Windspiel",
	"Piccolo Levriero Italiano",
	"Windspiel",
	"Jack Russell Terrier",
	"Terrier (Jack Russell Terrier)",
	"Elchhund (Großer Schwedischer Elchhund )",
	"Großer Schwedischer Elchhund",
	"Jämthund",
	"Jämtländer",
	"Schwedischer Elchhund",
	"Japanischer Terrier",
	"Nihon Teria",
	"Nippon-Terrier",
	"Nishon-Terrier",
	"Terrier (Japanischer Terrier)",
	"Terrier (Nippon-Terrier)",
	"Terrier (Nishon-Terrier)",
	"Japan Spitz",
	"Japanischer Spitz",
	"Japan-Spitz",
	"Nihon Supittsu",
	"Spitz (Japan-Spitz)",
	"Jonangi",
	"Kai",
	"Kai Inu",
	"Kai toraken",
	"Tora Inu",
	"Kaikadi",
	"Canaan Dog",
	"Canaan Hund",
	"Israel-Spitz",
	"Kanaan Hund",
	"Kanaanhund",
	"Anatolischer Hirtenhund",
	"Coban Köpegi",
	"Çoban Köpegi",
	"Hirtenhund (Anatolischer Hirtenhund)",
	"Kangal",
	"Kangal Coban Köpegi",
	"Kangal Çoban Köpeği",
	"Kangal Köpegi",
	"Kangal Köpeği",
	"Kangal Sivas Köpegi",
	"Kangal Sivas Köpeği",
	"Kangal Turkish Shepherd",
	"Kangal-Hirtenhund",
	"Türkischer Hirtenhund",
	"Karabas",
	"Karabaş",
	"Karabasch",
	"Karabash",
	"Bulgarischer Hirtenhund",
	"Bulgarischer Schäferhund",
	"Karakatschan",
	"Karakatschan Bulgarischer Hirtenhund",
	"Bärenhund (Karelischer Bärenhund)",
	"Björnhund",
	"Karelischer Bärenhund",
	"Karjalankarhukoira",
	"Kafkas",
	"Kafkas (Kars-Hund)",
	"Kars",
	"Kars Hund",
	"Kars Köpegi",
	"Kars Köpeği",
	"Kars Turkish Shepherd",
	"Kars-Hirtenhund",
	"Kars-Hund",
	"Kars-Schäferhund",
	"Caucasian Owtscharka",
	"Kaukase",
	"Kaukasischer Owtcharka",
	"Kaukasischer Owtscharka",
	"Kaukasischer Schäferhund",
	"Kawkasky Owtscharka",
	"Kawkasskaja Owtscharka",
	"Kawkazskaja Owtcharka",
	"Hound (Pharaoh Hound )",
	"Kelb Tal Fenek",
	"Kelb tal-Fenek",
	"Pharaoh Hound",
	"Pharaonenhund",
	"Keltenbracke",
	"Keltische Bracke",
	"Segusier",
	"Kerry Beagle",
	"Pocadan",
	"Blauer Irischer Terrier",
	"Kerry Blue Terrier",
	"Terrier (Blauer Irischer Terrier)",
	"Terrier (Kerry Blue Terrier)",
	"Bandog",
	"Kettenhund",
	"Anjing Kintamani Bali",
	"Bali-Berghund",
	"Kintamani",
	"Kintamanihund (Bali-Berghund)",
	"Kishu",
	"Kishu Inu",
	"Kishu-Inu",
	"Kishū-Inu",
	"Kishu-Ken",
	"Heidewachtel",
	"Kleiner Münsterländer",
	"Kleiner Münsterländer Vorstehhund",
	"Kleinpudel",
	"Kleinspitz",
	"Komondor",
	"Ungarische Hirtenhunde",
	"Ungarischer Hirtenhund",
	"Epagneul Nain Continental",
	"Kontinentaler Zwergspaniel",
	"Papillon",
	"Papillon (mit Stehohren)",
	"Phalene",
	"Phalène",
	"Phalène (mit Hängeohren)",
	"Schmetterlingshündchen",
	"Jindo-gae",
	"Jindo-kyon",
	"Korea Jindo Dog",
	"Karst Schäferhund",
	"Karsthund",
	"Karst-Schäferhund",
	"Kraski ovcar",
	"Kraški ovcar",
	"Kraški ovčar",
	"Schäferhund (Karst Schäferhund)",
	"Cretan Hound",
	"Kretischer Hasenhund",
	"Kretischer Jagdhund",
	"Kretischer Spürhund",
	"Kritikos Ichnilatis",
	"Kritikos Lagonikos",
	"Kromfohrländer",
	"Kromfohrländer Glatthaar",
	"Kromfohrländer Rauhaar",
	"Collie Smooth",
	"Kurzhaarcollie",
	"Smooth Collie",
	"Kuvacs",
	"Kuvasz",
	"Kyi Leo",
	"Labradoodle",
	"Labrador",
	"Labrador Retriever",
	"Retriever (Labrador Retriever)",
	"Italienischer Trüffelhund",
	"Lagotto",
	"Lagotto Romagnolo",
	"Wasserhund der Romagna",
	"Jakutischer Laika",
	"Jakutischer Laïka",
	"Laika",
	"Laika Jakutisch",
	"Laika ostsibirisch",
	"Laika russisch-europäisch",
	"Ostsibirischer Laika",
	"Ostsibirischer Laïka",
	"Russisch-Europäischer Laika",
	"Russisch-Europäischer Laïka",
	"Russko-Evropeïskaïa Laika",
	"Russko-Evropeïskaïa Laïka",
	"Tschukotskaja Jesdowaja",
	"Tschuktschen-Laika",
	"Tschuktschen-Laïka",
	"Vostotchno-Sibirskaia Laika",
	"Vostotchno-Sibirskaïa Laïka",
	"Westsibirischer Laika",
	"Westsibirischer Laïka",
	"Zapadno-Sibirskaïa Laïka",
	"Zapadno-Sibirskaïa Laika",
	"Zapadno-Sibirskaïa Laïka",
	"Lakeland Terrier",
	"Terrier (Lakeland Terrier)",
	"Lancashire Heeler",
	"Ormskirk Heeler",
	"Landseer",
	"Collie Rough",
	"Langhaarcollie",
	"Rough Collie",
	"Berger des Pyrenees a poil long",
	"Berger des Pyrénées à poil long",
	"Langhaariger Pyrenäenschäferhund",
	"Langhaariger Pyrenäen-Schäferhund",
	"Pyrenäen Schäferhund (Langhaar)",
	"Pyrenäenschäferhund (Langhaar)",
	"Pyrenäenschäferhund, Langhaar",
	"Schäferhund (Pyrenäenschäferhund, Langhaar)",
	"Finnischer Lapphund",
	"Finnischer Lapplandhirtenhund",
	"Lapinkoira",
	"Lapinporokoira",
	"Lapphund",
	"Lapphund (Finnischer Lapphund)",
	"Lapphund (Schwedischer Lapphund)",
	"Lapphund (Svensk Lapphund )",
	"Lapplaendske Spets",
	"Lappländer Rentierhund",
	"Lappländischer Rentierhund",
	"Ruotsinlapinkoira",
	"Schwedischer Lapphund",
	"Suomenlapinkoira",
	"Svensk Lapphund",
	"Leonberger",
	"Lettische Bracke",
	"Lhasa Apso",
	"Lhasaterrier",
	"Löwenhund",
	"Longdog",
	"Langhaar Whippet",
	"Langhaarwhippet",
	"Longhaired Whippet",
	"Silken Windsprite",
	"Catahoula Leopard Dog",
	"Catahoula Cur",
	"Louisiana Catahoula Leopard Dog",
	"Loewchen",
	"Löwchen",
	"Petit chien lion",
	"Italienischer Wolfhund",
	"Lupo Italiano",
	"Lurcher",
	"Magyar Agar",
	"Magyar Agár",
	"Ungarischer Windhund",
	"Windhund (Ungarischer Windhund)",
	"Bardino",
	"Majorero Canario",
	"Perro de Ganado Majorero",
	"Perro Majorero",
	"Verdino",
	"Malteser",
	"Maltipoo",
	"Manchester Terrier",
	"Terrier (Manchester Terrier)",
	"Cane (Cane Da Pastore Maremmano Abruzzese )",
	"Cane da Pastore",
	"Cane da Pastore Maremmano Abruzzese",
	"Cane da Pastore Maremmano-Abruzzese",
	"Maremmaner Berghund",
	"Maremmaner Hirtenhund",
	"Maremmen-Abruzze",
	"Maremmen-Abruzzen-Schäferhund",
	"Schäferhund (Maremmen-Abruzzen-Schäferhund)",
	"Hollandse Tulphond",
	"Markiesje",
	"Mastiff",
	"Old English Mastiff",
	"Dogge (Italienische Dogge)",
	"Italienische Dogge",
	"Mastino Napoletano",
	"Napoletano",
	"Neapolitanischer Mastiff",
	"Römischer Kampfhund",
	"McNab",
	"Miniature American Eskimo Dog",
	"Miniatur Australian Shepherd",
	"Miniature American Shepherd",
	"Miniature Australian Shepherd",
	"Kasachskaja Tazi",
	"Mittelasiatischer Tazi",
	"Sredneaziatskaya Borzaya",
	"Tasi",
	"Tazi",
	"Tazy",
	"Mittelschnauzer",
	"Schnauzer",
	"Mittelspitz",
	"Molosser",
	"Crnogorski Planinski Gonic",
	"Crnogorski Planinski Gonič",
	"Gebirgslaufhund (Jugoslawischer Gebirgslaufhund)",
	"Gonic (Jugoslavenski Planinski Gonic )",
	"Jugoslawischer Gebirgslaufhund",
	"Montenegrinische Gebirgsbracke",
	"Montenegrinischer Gebirgslaufhund",
	"Carlin",
	"Carlino",
	"Doguillo",
	"Mops",
	"Pug",
	"Moskauer Wachhund",
	"Moskovskaya Storozhevaya",
	"Mucuchies",
	"Perro de los Paramos",
	"Perro de los Páramos",
	"Perro Gocho",
	"Caravan Hound",
	"Karwani",
	"Mudhol Hound",
	"Pashmi",
	"Mudi",
	"Ungarischer Schäferhund",
	"Kooikerhondje",
	"Nederlandse Kooikerhondje",
	"Niederländischer Kooikerhondje",
	"Nederlandse Schapendoes",
	"Niederländischer Schafspudel",
	"Pudel (Niederländischer Schafspudel)",
	"Schapendoes",
	"Neufundländer",
	"Newfoundland",
	"Hallstrom Hund",
	"Hallstromhund",
	"Hallstrom-Hund",
	"Neuguinea-Dingo",
	"Neuguineahund",
	"New Guinea Singing dog",
	"Urwalddingo",
	"Norfolk Terrier",
	"Terrier (Norfolk Terrier)",
	"Norbotten Spets",
	"Norbottenspets",
	"Norbottenspitz",
	"Norrbottenspitz",
	"Pohjanpystykorvat",
	"Spitz (Norbottenspitz)",
	"Buhund",
	"Buhund (Norwegischer Buhund)",
	"Norsk Buhund",
	"Norwegischer Buhund",
	"Elchhund (Norwegischer Elchhund)",
	"Grahund",
	"Gråhund",
	"Hound (Norwegian Hound )",
	"Norsk Elghund Grå",
	"Norsk Elghund Sort",
	"Norwegian Elkhound",
	"Norwegian Hound",
	"Norwegischer Elchhund",
	"Norwegischer Elchhund grau",
	"Norwegischer Elchhund schwarz",
	"sort Dyrehund",
	"Lundehund",
	"Lundehund (Norwegischer Lundehund)",
	"Norsk Lundehund",
	"Norwegischer Lundehund",
	"Norwich Terrier",
	"Terrier (Norwich Terrier)",
	"Nova Scotia Duck Tolling",
	"Nova Scotia Duck Tolling Retriever",
	"Nova Scotia Retriever",
	"Retriever (Nova Scotia Duck Tolling Retriever)",
	"Toller",
	"Bracke (Polnische Bracke)",
	"Ogar Polski",
	"Polnische Bracke",
	"Leavitt Bulldog",
	"Old English Bulldog",
	"Olde English Bulldog",
	"Olde English Bulldogge",
	"Österreichischer Kurzhaarpinscher",
	"Österreichischer Pinscher",
	"Pinscher (Österreichischer Kurzhaariger Pinscher)",
	"Osteuropäischer Schäferhund",
	"Wostotschnojewropeiskaja owtscharka",
	"Hound (Otterhound )",
	"Otterhound",
	"Otterhund",
	"Owtscharka",
	"Russischer Owtscharka",
	"Navarro Pointer",
	"Old Spanish Pointer",
	"Pachon De Victoria",
	"Pachon Navarro",
	"Perdiguero Navarro",
	"Pariahund",
	"Pariahunde",
	"Schensi Hunde",
	"Schensihund",
	"Parson Jack Russel Terrier",
	"Parson Jack Russell Terrier",
	"Parson Russell Terrier",
	"Terrier (Parson Jack Russel Terrier )",
	"Terrier (Parson Russell Terrier)",
	"Pastor Garafiano",
	"Patterdale Terrier",
	"Palasthund (Peking Palasthund)",
	"Pekinese",
	"Peking Palasthund",
	"Pekingese",
	"Peking-Palasthund",
	"Hühnerhund (Portugiesischer Hühnerhund)",
	"Perdigueiro Portugues",
	"Perdigueiro Português",
	"Portugiesischer Hühnerhund",
	"Portugiesischer Vorstehhund",
	"Burgos-Hühnerhund",
	"Hühnerhund (Burgos-Hühnerhund)",
	"Perdiguere de Burgos",
	"Perdiguero de Burgos",
	"Euskal Artzain Txakurra",
	"Pastor Vasco",
	"Pastor Vasco Euskal Artzain Txakurra",
	"Perro Pastor Vasco",
	"Inka-Nackthund",
	"Nackthund (Peruanischer Nackthund)",
	"Perro (Perro sin Pelo del Peru)",
	"Perro sin Pelo del Peru",
	"Peruanischer Nackthund",
	"Viringo",
	"Griffon (Petit Basset Griffon Vendeen)",
	"Kleiner Basset Griffon Vendeen",
	"Kleiner Basset Griffon Vendéen",
	"Petit Basset Griffon Vendeen",
	"Petit Basset Griffon Vendéen",
	"Petit Bleu de Gascogne",
	"Brabanter Griffon",
	"Kleiner Brabanter",
	"Petit Brabancon",
	"Petit Brabançon",
	"Petit gascon saintongeois",
	"Phu Quoc Ridgeback",
	"Pinscher",
	"Plott Coonhound",
	"Plott Hound",
	"Podenco",
	"Podenco Andaluz",
	"Maneto",
	"Podenco Andaluz Maneto",
	"Podenco Canario",
	"Podenco Enano",
	"Balearen-Hund",
	"Balearen-Laufhund",
	"Ibiza-Laufhund",
	"Laufhund (Balearen Laufhund)",
	"Podenco Ibicenco",
	"Podenco Malgueno",
	"Podengo Portugues",
	"Podengo Português",
	"Haut-Poitou",
	"Poitevin",
	"Polnischer Niederungshütehund",
	"Polski Owczarek Nizinny",
	"PON",
	"Goralenhund",
	"Liptak",
	"Owczarek Podhalanski",
	"Podhalaner",
	"Podhalenhund",
	"Polnischer Berghund",
	"Polski Owczarek Podhalanski",
	"Tatra-Berghund",
	"Tatra-Hirtenhund",
	"Tatrahund",
	"Chien de Franche-Comte",
	"Chien de Franche-Comté",
	"Porcelaine",
	"Bracke (Savetal Bracke)",
	"Posavatz Laufhund",
	"Posavatz-Bracke",
	"Posavski Gonic",
	"Posavski Gonič",
	"San Bracke",
	"Save Bracke",
	"Savetal Bracke",
	"Savetalbracke",
	"Prager Rattler",
	"Prazsky Krysarik",
	"Pražský krysařík",
	"Pudel (Pudelpointer)",
	"Pudelpointer",
	"Puli",
	"Pumi",
	"Experimenteller Pudel-Wolfs-Hybride",
	"Puwo",
	"Chien de Montagne des Pyrenees",
	"Chien de Montagne des Pyrénées",
	"Patou",
	"Pyrenäen Berghund",
	"Pyrenäenberghund",
	"Pyrenäen-Berghund",
	"Pyrenäenhund",
	"Mastin de los Pirineos",
	"Mastin del Pirineo",
	"Mastín del Pirineo",
	"Pyrenäen-Mastiff",
	"Berger des Pyrenees",
	"Berger des Pyrénées",
	"Pyrenäenhütehund",
	"Pyrenäenschäferhund",
	"Pyrenäen-Schäferhund",
	"Berger des Pyrenees a face rase",
	"Berger des Pyrénées à face rase",
	"Pyrenäen Schäferhund (Kurzhaar)",
	"Pyrenäenschäferhund (Kurzhaar)",
	"Pyrenäen-Schäferhund mit kurzhaarigem Gesicht",
	"Schäferhund (Pyreäenschäferhund, Kurzhaar)",
	"Rafeiro do Alentejo",
	"Paleiyakaran",
	"Poligar Hound",
	"Rajapalayam",
	"Rampur Hound",
	"Rampuree",
	"Rampuri",
	"Rat Terrier",
	"Terrier (Rat Terrier)",
	"Andalusian Mouse-Hunting Dog",
	"Bodeguero Andaluz",
	"Perro Ratonero Bodeguero",
	"Ratonero Andaluz",
	"Ratonero Bodeguero Andaluz",
	"Ca Rater Mallorquí",
	"Rater Mallorqui",
	"Ratonero Mallorquin",
	"Ratonero Mallorquín",
	"Ca (de) Rater Valencia",
	"Ca (de) Rater Valencià",
	"Gos Rater Valencia",
	"Gos Rater Valencià",
	"Ratonero Valenciano",
	"Redbone Coonhound",
	"Retriever",
	"Rhodesian Ridgeback",
	"Ridgeback (Rhodesian Ridgeback)",
	"Riesenschnauzer",
	"Schnauzer (Riesenschnauzer )",
	"Rottweiler",
	"Russischer Schwarzer Terrier",
	"Russkiy Tchiorny Terrier",
	"Schwarzer Russischer Terrier",
	"Schwarzer Terrier",
	"Tchiorny Terrier",
	"Terrier (Schwarzer Russischer Terrier )",
	"Terrier (Schwarzer Terrier)",
	"Moskauer Langhaariger Toy Terrier",
	"Russischer Toy",
	"Russischer Zwergspaniel",
	"Russkiy Toy",
	"Hound (Saarloos Wolfhound)",
	"Saarloos Wolfhound",
	"Saarlooswolfhond",
	"Sabsal Gae",
	"Sabsal Hund",
	"Sapsali",
	"Sapsaree",
	"Sabueso Espanol",
	"Sabueso Español",
	"Persischer Windhund",
	"Saluki",
	"Samoiedskaia Sobaka",
	"Samoiedskaïa Sobaka",
	"Samojede",
	"Samoyede",
	"Sanshu",
	"GebirgsJugoslawisch",
	"Ilirski ovcar",
	"Ilirski ovčar",
	"Illyrischer Schäferhund",
	"Istrischer Schäferhund",
	"Sarplaninac",
	"Šarplaninac",
	"Schäferhund (Illyrischer Schäferhund )",
	"Schäferhund",
	"Schiller-Bracke",
	"Schiller-Laufhund",
	"Schillerstövare",
	"Schipperke",
	"Schwarzer",
	"Schwarzer (Altdeutscher Hütehund)",
	"Bayerischer Gebirgsschweißhund",
	"Gebirgsschweisshund (Bayrischer Gebirgsschweisshund)",
	"Hannoverscher Schweißhund",
	"Schweißhund",
	"Schweisshund (Hannoverscher Schweisshund)",
	"Berner Laufhund",
	"Bernese Hound",
	"Blausprenkel",
	"Bruno du Jura",
	"Courant bernois",
	"Courant lucernois",
	"Courant schwytzois",
	"Jura Hound",
	"Jura Laufhund",
	"Laufhund (Berner Laufhund )",
	"Laufhund (Luzerner Laufhund )",
	"Laufhund (Schweizer Laufhund)",
	"Laufhund (Schwyzer Laufhund )",
	"Lucerne Hound",
	"Luzerner Laufhund",
	"Rotschecke",
	"Sabueso Bernes",
	"Sabueso Bernés",
	"Sabueso de Lucerna",
	"Sabueso de Schwyz",
	"Sabueso del Jura",
	"Schweizer Laufhund",
	"Schwyz Hound",
	"Schwyzer Laufhund",
	"Berner Niederlaufhund Glatthaar",
	"Berner Niederlaufhund Rauhhaar",
	"Jura Niederlaufhund",
	"Luzerner Niederlaufhund",
	"Niederlaufhund",
	"Niederlaufhund (Schweizer Niederlaufhund)",
	"Petit chien courant suisse",
	"Schweizerischer Niederlaufhund",
	"Schwyzer Niederlaufhund",
	"Scottish Terrier",
	"Terrier (Scottish Terrier)",
	"Sealyham Terrier",
	"Terrier (Sealyham Terrier)",
	"Drahthaariger Italienischer Laufhund",
	"Italienischer Laufhund",
	"Kurzhaariger Italienischer Laufhund",
	"Laufhund (Italienischer Laufhund)",
	"Segugio Italiano",
	"Segugio Italiano (Drahthaar)",
	"Segugio Italiano (Kurzhaar)",
	"Segugio Italiano a pelo forte",
	"Segugio Italiano a pelo raso",
	"Balkan Bracke",
	"Balkanbracke",
	"Balkanski gonic",
	"Bracke (Balkan Bracke)",
	"Gonic (Balkanski Gonic )",
	"Serbische Bracke",
	"Serbischer Laufhund",
	"Srpski Gonic",
	"Chinesischer Faltenhund",
	"Pai",
	"Shar-Pei",
	"Shar-Pei",
	"Sheepdog (Shetland Sheepdog)",
	"Sheltie",
	"Shetland Sheepdog",
	"Shiba",
	"Shiba Inu",
	"Shiba-Inu",
	"Shiba-ken",
	"Shih Tzu",
	"Shih-Tzu",
	"Kochi-Ken",
	"Mikawa Inu",
	"Shikoku",
	"Shikoku Inu",
	"Silken Windhound",
	"Silken Windhund",
	"Cirneco dell’Etna",
	"Sizilianische Bracke",
	"Skye Terrier",
	"Terrier (Skye Terrier)",
	"Arabischer Windhund",
	"Berber Windhund",
	"Nordafrikanische Windhund",
	"Oskai",
	"Oskay",
	"Sloughi",
	"Windhund (Arabischer Windhund)",
	"Cuvac Slovensky",
	"Slovensky (Cuvac Slovensky)",
	"Slovensky cuvac",
	"Slovenský cuvac",
	"Slovenský čuvač",
	"Slowakischer Tchuwatsch",
	"Slowakischer tschuvatsch",
	"Bracke (Slowakische Schwarzwildbracke)",
	"Kopov",
	"Kopov Slovensky",
	"Schwarzwildbracke",
	"Slovensky (Kopov Slovensky)",
	"Slovensky Kopov",
	"Slovenský kopov",
	"Slowakische Schwarzwildbracke",
	"Slowakischer Laufhund",
	"Hruborsty Stavac Slovensky",
	"Ohar",
	"Slovensky (Hruborsty Stavac Slovensky)",
	"Slovensky hrubosrsty stavac",
	"Slovenský Hrubosrsty Stavac",
	"Slovenský Hrubosrstý Stavač",
	"Slowakischer Drahthaariger Vorstehhund",
	"Slowakischer Rauhbart",
	"Slowakischer Vorstehhund (drahthaar)",
	"Vorstehhund (Slowakischer Drahthaariger Vorstehhund)",
	"Smaland-Bracke",
	"Småland-Bracke",
	"Smaland-Laufhund",
	"Småland-Laufhund",
	"Smalandsstövare",
	"Smålandsstövare",
	"Dogge (Spanische Dogge)",
	"Mastin aus Leon",
	"Mastín aus León",
	"Mastin der Extremadura",
	"Mastín der Extremadura",
	"Mastin der Mancha",
	"Mastín der Mancha",
	"Mastin Espanol",
	"Mastín Español",
	"Mastin extremeno",
	"mastín extremeño",
	"Mastin leones",
	"Mastín leonés",
	"Mastin manchego",
	"mastín manchego",
	"Spanische Dogge",
	"Spanischer Mastiff",
	"Spanish Mastiff",
	"Perro (Perro de Agua Espanol )",
	"Perro de Agua Espanol",
	"Perro de Agua Español",
	"perro Turco",
	"Spanischer Wasserhund",
	"Turco Andaluz",
	"Wasserhund (Spanischer Wasserhund)",
	"Italienischer Rauhhaariger Vorstehhund",
	"Spinone",
	"Spinone Italiano",
	"Stabijhoun",
	"Stabyhond",
	"Stabyhoun",
	"Staff",
	"Staffordshire Bull Terrier",
	"Staffordshire Bullterrier",
	"Staffordshire Terrier",
	"Terrier (Staffordshire Bullterrier)",
	"Bracke (Steirische Rauhaarige Hochgebirgsbracke)",
	"Hochgebirgsbracke",
	"Peintinger-Bracke",
	"Steirische Bracke",
	"Steirische Rauhhaarbracke",
	"Barac",
	"Barak",
	"Bosanski Ostrodlaki Gonic",
	"Bosanski Oštrodlaki Gonic",
	"Bosnischer Laufhund",
	"Ilirski Gonic",
	"Illyrischer Laufhund",
	"Stichelhaariger Bosnischer Laufhund",
	"Altdeutscher Hütehund (Strobel)",
	"Strobel",
	"Strobel (Altdeutscher Hütehund)",
	"Ioujnorousskaia Ovtcharka",
	"Ioujnorousskaïa Ovtcharka",
	"Juschnorusskaja Owtcharka",
	"Südrussischer Owtscharka",
	"Südrussischer Schäferhund",
	"Spaniel (Sussex Spaniel)",
	"Sussex Spaniel",
	"Kirghiz Barzoi",
	"Kirgisischer Windhund",
	"Taigan",
	"Tajgan",
	"Taiwan Dog",
	"Taiwanhund",
	"Finnish Tamaskan Dog",
	"Tamaskan",
	"Tamaskan Dog",
	"Tamaskan Husky",
	"Tamaskan Wolf-dog",
	"Telomian",
	"Telomian Dog",
	"Bangkaew Dog",
	"Thai Bangkaew Dog",
	"Thai Ridgeback",
	"Spaniel (Tibet Spaniel)",
	"Tibet Spaniel",
	"Tibetanischer Spaniel",
	"Tibet-Spaniel",
	"Terrier (Tibet Terrier)",
	"Tibet Terrier",
	"Tibetan Terrier",
	"Tibetanischer Terrier",
	"Tibet-Terrier",
	"Tsang Apso",
	"Bracke (Tiroler Bracke)",
	"Tiroler Bracke",
	"Bosnischer Berghund",
	"Bosnisch-herzegowinischer-kroatischer Schäferhund",
	"Kroatischer Berghund",
	"Tornjak",
	"Inu",
	"Japanischer Kampfhund",
	"Tosa Inu",
	"Tosa Ken",
	"Tosa Token",
	"Toy American Eskimo",
	"Toy Pudel",
	"Toypudel",
	"Toy-Pudel",
	"Treeing Tennessee Brindle",
	"Treeing Tennessee Brindle Coonhound",
	"Treeing Tennessee Brindle Dog",
	"Treeing Walker Coonhound",
	"TWC",
	"Cesky horsky pes",
	"Český horský pes",
	"Tschechischer Berghund",
	"Böhmischer Terrier",
	"Cesky Terier",
	"Ceský Terier",
	"Český Teriér",
	"Cesky Terrier",
	"Ceský Terrier",
	"Terrier (Cesky Terrier)",
	"Tschechischer Terrier",
	"Ceskoslovensky vlcak",
	"Československý vlčák",
	"Ceskoslovensky vlciak",
	"Československý vlčiak",
	"Tschechoslowakischer Wolfhund",
	"Wolfhund",
	"Türk Kopoylari",
	"Türk Kopoyları",
	"Aksaray Malaklisi",
	"Anadolu Mastifi",
	"Anatolischer Mastiff",
	"Malakli",
	"Türk Mastifi",
	"Türkischer Mastiff",
	"Anadolu Sultan Tazisi",
	"Anadolu Sultan Tazısı",
	"Anatolischer Sultanhund",
	"Sultan Tazisi",
	"Sultan Tazısı",
	"Türkischer Windhund",
	"Turkish Greyhound",
	"Drahthaar Vizsla",
	"Drahthaariger Ungarischer Vorstehhund",
	"Drotszoru Magyar Vizsla",
	"Drótszoru Magyar Vizsla",
	"Kurzhaariger Ungarischer Vorstehhund",
	"Magyar Vizsla",
	"Magyar Vizsla (Drahthaar)",
	"Magyar Vizsla (Kurzhaar)",
	"Rauhaariger Vizsla",
	"Rövidszoru Magyar Vizsla",
	"Ungarischer Vorstehhund",
	"Vizsla",
	"Schwedischer Schäferspitz",
	"Schwedischer Vallhund",
	"Spitz (Schwedischer Schäferspitz)",
	"Swedish Cattle Dog",
	"Vallhund",
	"Västgötaspets",
	"Westgotenspitz",
	"Villano de las Encartaciones",
	"Baskischer Ratero",
	"Villanuco de Encartaciones",
	"Villanuco de Las Encartaciones",
	"Italienischer Kleinspitz",
	"Volpino Italiano",
	"Wäller",
	"Weimaraner",
	"Welsh Corgi Cardigan",
	"Welsh Corgi Pembroke",
	"Bugeilgi",
	"Ci Defaid Cymreig",
	"Welsh Collie",
	"Welsh Sheepdog",
	"Spaniel (Welsh Springer Spaniel)",
	"Welsh Springer Spaniel",
	"Terrier (Welsh Terrier)",
	"Welsh Terrier",
	"Poltalloch Terrier",
	"Roseneath Terrier",
	"Terrier (Weißer Hochlandterrier)",
	"Terrier (West Highland White Terrier)",
	"Weißer Hochlandterrier",
	"West Highland White Terrier",
	"Westie",
	"Bracke (Sauerländer Dachsbracke)",
	"Bracke (Westfälische Dachsbracke)",
	"Sauerländer Dachsbracke",
	"Westfälische Dachsbracke",
	"Friesischer Wasserhund",
	"Wetterhond",
	"Wetterhoun",
	"Kleiner Englischer Windhund",
	"Whippet",
	"Windhund",
	"Holländischer Spitz",
	"Keeshond",
	"Wolfsspitz",
	"Mexikanischer Nackthund",
	"Nackthund (Mexikanischer Nackthund)",
	"Tepeizeuintli",
	"Xolo",
	"Xoloitzcuintle",
	"Xoloitzcuintli",
	"Terrier (Yorkshire Terrier)",
	"Yorkshire Terrier",
	"Central Asian Shepherd Dog",
	"Mittelasiatischer Owtscharka",
	"Mittelasiatischer Schäferhund",
	"Sredneasiatskaja Owtcharka",
	"Sredneasiatskaja owtscharka",
	"Zentralasiatischer Ovtscharka",
	"Zentralasiatischer Owtscharka",
	"Zentralasiatischer Schäferhund",
	"Mini Bull",
	"Mini Bull Terrier",
	"Mini Bullterrier",
	"Miniature Bull Terrier",
	"Miniature Bullterrier",
	"Terrier (Miniature Bullterrier)",
	"Terrier (Zwergbullterrier)",
	"Zwerg Bull Terrier",
	"Zwergbullterrier",
	"Miniature Pinscher",
	"Minidoberman",
	"Minpin",
	"Pinscher (Zwergpinscher)",
	"Rehpinscher",
	"Rehrattler",
	"Zwergpinscher",
	"Zwergpudel",
	"Schnauzer (Zwergschnauzer )",
	"Zwergschnauzer",
	"Pomeranian",
	"Pommer",
	"Pommeraner",
	"Zwergspitz",
	"Kokoni",
	"Small Greek Domestic Dog",
	"Neu Englische Bulldogge",
	"New English Bulldog",
	"Agassin",
	"Alaunt",
	"Braque (Braque Dupuy)",
	"Braque du Puy",
	"Braque Dupuy",
	"Braque Levrier",
	"Braque Lévrier",
	"Chambray",
	"Chien de Trait Belge",
	"Harlekinpinscher",
	"Designerhund",
	"Hybridhund",
	"Matin",
	"Mâtin",
	"Rastreador Brasileiro",
	"Urrador",
	"Tesem",
	"Torfhund",
	"Turnspit Dog",
	"Urhund",
	"Old English White",
	"Otto"
].sort();
