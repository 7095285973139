import React from "react";
import {Col, Form, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {useField} from "formik";

const TextInput = (props) => {
	const {handleChange} = props;

	const {item, label, extraLabel, append, extraText, number, infoButton, disabled} = props;

	const [field, meta] = useField(item);

	return (
			<React.Fragment>
				<Form.Group as={Row} md="2" controlId={"formField-" + item}>
					<Form.Label column md={4}>
						{label} {extraLabel && <small><br/>{extraLabel}</small>}
						{infoButton && <OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id={'tooltip-gibsgeld'}>
										<div dangerouslySetInnerHTML={{__html: infoButton}}></div>
									</Tooltip>
								}
						>
							<span className='ar-btn ar-icon-info'/>
						</OverlayTrigger>}
					</Form.Label>
					<Col md={8}>
						<InputGroup>
							<Form.Control
									type={number ? "number" : "text"}
									placeholder={"Bitte eingeben"}
									aria-describedby={"textInputItem-" + item}
									name={item}
									value={meta.value}
									disabled={disabled}
									style={(meta.error && meta.touched)?{borderBottomColor:"#dc3545"}:{}}
									onChange={handleChange ? handleChange : field.onChange}
									isInvalid={meta.error && meta.touched && true}
									onBlur={field.onBlur}
							/>

							{append &&
							 <InputGroup.Append style={{
								 position: 'absolute',
								 right: 0
							 }}>
								 {append}
							 </InputGroup.Append>
							}

							{/*{'touched: ' + touched}*/}
							<Form.Control.Feedback type="invalid">
								{meta.error}
							</Form.Control.Feedback>
						</InputGroup>
						{extraText && <small>{extraText}</small>}
					</Col>
				</Form.Group>
			</React.Fragment>

	)
}

export default TextInput;
