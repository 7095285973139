import {
	dateConverter,
	translateBerufsgruppe,
	translateFamilienstand,
	translateZahlungsweise
} from "../translaterForAPI";

export const getQuoteLegalCosts = (values, deckung) => {

	const parseVersicherteObjekte = (typeOfUse) => {
		if (values.addresse_gleich_bei_versicherten_objekten) {
			return [{
				"objectInsured": {
					"street": values.versicherte_objekte[0].strasse_vo,
					"streetNumber": values.versicherte_objekte[0].hausnr_vo,
					"postalCode": values.versicherte_objekte[0].postleitzahl_vo,
					"city": values.versicherte_objekte[0].ort_vo,
					"countryCode": "D"
				},
				"typeOfUse": typeOfUse,
				"countOfHousingUnits": values.anzahl_wohnungen ? parseInt(values.anzahl_wohnungen) : "1"
			}]
		} else {
			return values.versicherte_objekte.map(versichertes_objekt => ({
				"objectInsured": {
					"street": versichertes_objekt.strasse_vo,
					"streetNumber": versichertes_objekt.hausnr_vo,
					"postalCode": versichertes_objekt.postleitzahl_vo,
					"city": versichertes_objekt.ort_vo,
					"countryCode": "D"
				},
				"typeOfUse": typeOfUse,
				"countOfHousingUnits": 1
			}))
		}
	}

	const extendedProductCombination = () => {
		return `${values.produkt}${values.zusatzbausteinErweiterterStraftrechtsschutz? "+E" : ""}${values.zusatzbausteinPflegeberatung ? "+Pflege" : ""}`
	}

	let res = {
		"salesPartnerNumber": sessionStorage.getItem("vermittlernummer"),
		"externalIdent": sessionStorage.getItem("externeIdent"),
		"policyholder":
				{
					"birthday": dateConverter(values.geburtsdatum_VN),
					"civilService": (values.berufsgruppeVN === 'oeffentlicher_dienst'),
					"address":
							{
								"street": values.strasse,
								"streetNumber": values.hausnummer,
								"postalCode": values.postleitzahl,
								"city": values.ort,
								"countryCode": "D"
							},
					"phoneNumber": values.telefon?.replace(/\s/g, ''),
					"emailAddress": values.email
				},
		"insuranceData": {
			"countOfPreviousDamages": values.vorschaeden,
			"countOfDamageFreeYears": values.schadenfreieJahre,
			"productCombination": extendedProductCombination(),
			"paymentInterval": translateZahlungsweise(values.zahlungsweise),
			"insuranceBegin": dateConverter(values.beginn),
			"contractTermInYears": parseInt(values.versicherungsdauer),
			"tariffSelection": deckung,
			"professionalStatus": translateBerufsgruppe(values.berufsgruppeVN),
			"deductible": values.selbstbehalt,
			"houseHoldKind": translateFamilienstand(values.familienstand),
		}
	};

	if (values.produkt.includes("Wohn") && !values.produkt.includes("Vermiet")) {
		res.insuranceData['realEstateInclusions'] = parseVersicherteObjekte("USED_YOURSELF");
	}

	if (values.produkt.includes("Vermiet") && !values.produkt.includes("Wohn")) {
		res.insuranceData['realEstateInclusions'] = parseVersicherteObjekte("USED_BY_OTHER")	}

	if (values.produkt.includes("Vermiet") && values.produkt.includes("Wohn")) {
		res.insuranceData['realEstateInclusions'] = [
				...parseVersicherteObjekte("USED_YOURSELF"),
				...parseVersicherteObjekte("USED_BY_OTHER")
		];
	}

	return res;
};
