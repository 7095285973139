import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import SelectInput from "../formitems/SelectInput";
import {labels} from "../../../_params/form_labels";
import {
	anredeOptions,
	gekundigtDurchRadioOptions,
	JaNeinSwitcher
} from "../../utils/optionsSelection";
import TextInput from "../formitems/TextInput";
import DateInput from "../formitems/DateInput";
import RadioGroupInput from "../formitems/RadioGroupInput";
import {Button, Col} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {insuranceTypes} from "../../../_params/variables";
import {dateOptions} from "../../utils/formHelper";
import _ from "lodash";
import DownloadPdfForm from "../DownloadPdfForm";

const labels_common = labels.COMMON;

const CustomerInfoForm = (props) => {

	const {handlePrev, offer, tariffHighlights} = props;

	const [dateFieldDisabled, setDateFieldDisabled] = useState(false)

	const formik = useFormikContext();
	const {values, errors, validateForm, setFieldValue} = formik;

	useEffect(() => {
		validateForm()
				.then(r => formik.setErrors(r))
		setDateFieldDisabled(Boolean(values.geburtsdatum_VN))
	}, [])

	const returnToPageOne = () => {
		setFieldValue("berechneter_preis", null)

		if(offer !== insuranceTypes.LEGAL_COSTS && offer !== insuranceTypes.ACCIDENT) {
			setFieldValue("tarif", "")
		}
		handlePrev()
	}

	useEffect(() => {
		if (!values.vorversicherung) {
			setFieldValue("gesellschaftsname", null)
			setFieldValue("vorversicherungsnummer", null)
			setFieldValue("gekuendigt_durch", null)
		}
	}, [values.vorversicherung, setFieldValue])

	return (
			<>

				<h3>Persönliche Angaben</h3>
				<SelectInput item={"anrede"} label={labels_common.KUNDENDATEN.anrede} options={anredeOptions}/>
				<TextInput item={"vorname"} label={labels_common.KUNDENDATEN.vorname}/>
				<TextInput item={"nachname"} label={labels_common.KUNDENDATEN.nachname}/>
				<DateInput item={"geburtsdatum_VN"} label={labels_common.KUNDENDATEN.geburtsdatum_VN} disabled={dateFieldDisabled}/>
				<TextInput item={"strasse"} label={labels_common.KUNDENDATEN.strasse}/>
				<TextInput item={"hausnummer"} label={labels_common.KUNDENDATEN.hausnummer}/>
				<TextInput item={"postleitzahl"} label={labels_common.KUNDENDATEN.postleitzahl}/>
				<TextInput item={"ort"} label={labels_common.KUNDENDATEN.ort}/>
				<TextInput item={"telefon"} label={labels_common.KUNDENDATEN.telefon}/>
				<TextInput item={"email"} label={labels_common.KUNDENDATEN.email}/>

				{/*// Customized only for Unfallversicherung*/}
				{offer === insuranceTypes.ACCIDENT &&
				 <>
					 {['mich_und_partner', 'mich_partner_und_kind(er)'].includes(values.haushalt) && (
					 		<>
								<hr/>
								<h3>Partner {(values.partner && values.partner.geburtsdatum) && (new Date(values.partner?.geburtsdatum)?.toLocaleDateString('DE',
										dateOptions))}</h3>
								<TextInput item={"partner.vorname"} label={labels_common.KUNDENDATEN.vorname}/>
								<TextInput item={"partner.nachname"} label={labels_common.KUNDENDATEN.nachname}/>
							</>
					 )}

					 {['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(values.haushalt) && (
					 		<>

								<hr/>
								<h3>Kind(er)</h3>
								{_.range(1, parseInt(values.anzahl_kinder) + 1, 1).map(child => (
										<>
											<TextInput item={`kind${child}.vorname`} label={labels_common.KUNDENDATEN.vorname}/>
											<TextInput item={`kind${child}.nachname`} label={labels_common.KUNDENDATEN.nachname}/>
											{child < values.anzahl_kinder && <hr/>}
										</>
								))}
							</>
					 )}
				 </>
				}

				<hr style={{backgroundColor: '#fff', marginTop:'30px'}}/>
				<h3>Kontodaten angeben</h3>
				<TextInput item={"kontoinhaber"} label={labels_common.KONTODATEN.kontoinhaber}/>
				<TextInput item={"iban"} label={labels_common.KONTODATEN.iban}/>

				<hr style={{backgroundColor: '#fff', marginTop:'30px'}}/>
				<h3>Vorversicherung</h3>
				<RadioGroupInput item={"vorversicherung"}
									  label={labels_common.VORVERSICHERUNGSDATEN.vorversicherung}
									  options={JaNeinSwitcher}
									  muiJaNeinSwitcher
				/>
				{
					values.vorversicherung &&
					<>
						<TextInput item={"gesellschaftsname"} label={labels_common.VORVERSICHERUNGSDATEN.gesellschaftsname}/>
						<TextInput item={"vorversicherungsnummer"}
									  label={labels_common.VORVERSICHERUNGSDATEN.vorversicherungsnummer}/>
						<RadioGroupInput item={"gekuendigt_durch"}
											  label={labels_common.VORVERSICHERUNGSDATEN.gekuendigt_durch}
											  options={gekundigtDurchRadioOptions}/>
					</>
				}




					{
							Object.keys(errors).length === 0 && <DownloadPdfForm
																		offer={offer}
																		values={values}
																		hideCustomerData={false}
																		tariffPrices={tariffHighlights}
																/>
					}


				<Row>
					<Col md={4}/>
					<Col>
						<Button className="btn btn-primary btn-info"
								  style={{width:'40px', padding: '0 26px 0 12px'}}
								  onClick={returnToPageOne}
						><div className={'ar-btn ar-btn-reward ar-icon-forward'}/></Button>
						<Button className={"btn btn-weiter"}
								  disabled={Object.keys(errors).length > 0}
								  type="submit"
						>
							Weiter
							<span className={'ar-btn ar-icon-forward'}/>
						</Button>
					</Col>
				</Row>
				{/*<code>
					<pre>{JSON.stringify(values, null, 2)}</pre>
					<pre>{JSON.stringify(errors, null, 2)}</pre>
					<pre>{JSON.stringify(touched, null, 2)}</pre>
				</code>*/}
			</>
	)
}

export default CustomerInfoForm;
