import React from "react";
import privathaftpflichtFormdata from "../utils/initialvalues/privathaftpflichtFormdata";
import validationSchemaLiability from "../utils/validationschemas/validationSchemaPrivateLiability";
import {insuranceTypes} from "../../_params/variables";
import {getQuotePrivateLiability} from "../utils/getquotes/getQuotePrivateLiability";
import {setOrderPrivateLiability} from "../utils/setOrders/setOrderPrivateLiability";
import DateInput from "../components/formitems/DateInput";
import SelectInput from "../components/formitems/SelectInput";
import {
	familienstandOptions,
	selbstbehaltOptions_Privathaftpflicht, versicherungsDauerOptions,
	vorschaedenOptions,
	zahlweiseOptions
} from "../utils/optionsSelection";
import RadioGroupInput from "../components/formitems/RadioGroupInput";
import {labels} from "../../_params/form_labels";
import FormWrapper from "../components/forms/FormWrapper";

const OFFER_URL = "LiabilityInsurance";
const labels_LiabilityInsurance = labels.LiabilityInsurance;

const PrivateLiabilityPage = () => {
	const CalculationFormRender = () => {
		return (
				<>
					<DateInput item={"beginn"} label={labels_LiabilityInsurance.beginn}/>
					<RadioGroupInput item={"versicherungsdauer"} label={labels_LiabilityInsurance.versicherungsdauer}
										  options={versicherungsDauerOptions}
					/>
					<SelectInput item={"familienstand"} label={labels_LiabilityInsurance.familienstand}
									 options={familienstandOptions}/>
					<DateInput item={"geburtsdatum_VN"} label={labels.COMMON.KUNDENDATEN.geburtsdatum_VN}/>
					<SelectInput item={"vorschaeden"} label={labels_LiabilityInsurance.vorschaeden}
									 options={vorschaedenOptions}/>
					<SelectInput item={"selbstbehalt"} label={labels_LiabilityInsurance.selbstbehalt}
									 options={selbstbehaltOptions_Privathaftpflicht}/>
					<SelectInput item={"zahlungsweise"} label={labels_LiabilityInsurance.zahlungsweise}
									 options={zahlweiseOptions}/>
				</>
		)
	}

	return (<>
				<FormWrapper
					validationSchemaCalculation={validationSchemaLiability}
					OFFER_URL={OFFER_URL}
					CalculationForm={CalculationFormRender}
					calcList={['L', 'XL', 'XXL']}
					getQuoteHandler={getQuotePrivateLiability}
					setOrder={setOrderPrivateLiability}
					initValuesForm={privathaftpflichtFormdata}
					tariffName={insuranceTypes.PRIVATE_LIABILITY}/>
				</>
	)
}

export default PrivateLiabilityPage;
