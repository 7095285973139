import React from "react";
import {Col, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {useField} from "formik";

const SelectInput = (props) => {

	const {item, label, disabled, extraLabel, infoButton} = props;

	const [field, meta] = useField(item);

	return (
			<React.Fragment>
				<Form.Group as={Row} md="2" controlId={"formField-" + item}>
					<Form.Label column md={4}>
						{label}
						{extraLabel && <small><br/>{extraLabel}</small>}

						{infoButton && <OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id={'tooltip-gibsgeld'}>
										<div dangerouslySetInnerHTML={{__html: infoButton}}></div>
									</Tooltip>
								}
						>
							<span className='ar-btn ar-icon-info'/>
						</OverlayTrigger>}
					</Form.Label>
					<Col md={8} className={'selectCell'}>
						<Form.Control
								disabled={disabled}
								as="select"
								placeholder="Bitte auswählen"
								aria-describedby={"selectInputItem-" + item}
								name={item}
								value={meta.value ? meta.value : ''}
								onChange={field.onChange}
								isInvalid={meta.error && meta.touched && true}
								onBlur={field.onBlur}
						>
							<option key="none" value="">Bitte auswählen</option>
							{props.options.map((o, i) => (
									<option key={i} value={o.value} disabled={o.disabled}>{o.label}</option>
							))}
						</Form.Control>

						{/*{'touched: ' + this.props.touched}*/}
						{/*{'\nvalue: ' + this.props.value}*/}
						<Form.Control.Feedback type="invalid">
							{meta.error}
						</Form.Control.Feedback>
					</Col>
				</Form.Group>
			</React.Fragment>

	)
}

export default SelectInput;
