import Annahmerichtlinien_Privathaftpflicht from "../../adamriese_docs/PHV/Adam Riese Annahmerichtlinien PHV.pdf";
import Informationsblatt_Privathaftpflicht from "../../adamriese_docs/PHV/Adam Riese IPID PHV.pdf";
import Leistungsuebersicht_Privathaftpflicht from "../../adamriese_docs/PHV/Adam Riese Leistungsübersicht PHV.pdf";
import Versicherungsbedingungen_Privathaftpflicht_BESSER from "../../adamriese_docs/PHV/Adam Riese AVB PHV BESSER.pdf";
import Versicherungsbedingungen_Privathaftpflicht_Tarif_GUT from "../../adamriese_docs/PHV/Adam Riese AVB PHV GUT.pdf";
import Versicherungsbedinungen_Privathatpflicht_RIESIG from "../../adamriese_docs/PHV/Adam Riese AVB PHV RIESIG.pdf";

import Informationsblatt_Hundehalterhaftpflichtversicherung from "../../adamriese_docs/HHV/Adam Riese IPID HHV.pdf";
import Versicherungsbedingungen_Hundehalterhaftpflicht_XL from "../../adamriese_docs/HHV/Adam Riese AVB HHV XL.pdf";
import Versicherungsbedingungen_Hundehalterhaftpflicht_XXL from "../../adamriese_docs/HHV/Adam Riese AVB HHV XXL.pdf";
import Annahmerichtlinien_HHV from "../../adamriese_docs/HHV/Adam Riese Annahmerichtlinien HHV.pdf";
import Adam_Riese_Leistungsuebersicht_HHV from "../../adamriese_docs/HHV/Adam Riese Leistungsübersicht HHV.pdf";

import Annahmerichtlinien_Hausratversicherung from "../../adamriese_docs/HR/Adam Riese Annahmerichtlinien HR.pdf";
import Informationsblatt_Hausratversicherung from "../../adamriese_docs/HR/Adam Riese IPID HR.pdf";
import Leistungsuebersicht_Hausrat from "../../adamriese_docs/HR/Adam Riese Leistungsübersicht HR.pdf";
import Versicherungsbedingungen_Hausrat_L from "../../adamriese_docs/HR/Adam Riese AVB HR Gut.pdf";
import Versicherungsbedingungen_Hausrat_XL from "../../adamriese_docs/HR/Adam Riese AVB HR Besser.pdf";
import Versicherungsbedingungen_Hausrat_XXL from "../../adamriese_docs/HR/Adam Riese AVB HR Riesig.pdf";
import Maklerinformationsblatt_Hausrat from "../../adamriese_docs/HR/Adam Riese Maklerinformationsblatt HR.pdf";


import Annahmerichtlinien_Rechtschutz from "../../adamriese_docs/RS/Adam Riese Annahmerichtlinien RS.pdf";
import Informationsblatt_Rechtschutz from "../../adamriese_docs/RS/Adam Riese IPID RS.pdf";
import AVB_RS from "../../adamriese_docs/RS/Adam Riese AVB RS.pdf";
import Leistungsuebersicht_RS from "../../adamriese_docs/RS/Adam Riese Leistungsübersicht RS.pdf";

import Unfall_Annahmerichtlinien from "../../adamriese_docs/UV/Adam Riese Unfall Annahmerichtlinien.pdf";
import AUB_GUT from "../../adamriese_docs/UV/Adam Riese Unfallbedingungen AUB Gut.pdf";
import AUB_Besser from "../../adamriese_docs/UV/Adam Riese Unfallbedingungen AUB Besser.pdf";
import AUB_Riesig from "../../adamriese_docs/UV/Adam Riese Unfallbedingungen AUB Riesig.pdf";
import Unfall_IPID_Makler_AdamRiese072020 from "../../adamriese_docs/UV/Adam Riese IPID Unfall.pdf";
import Unfall_Leistungsuebersicht_Makler_AdamRiese072020
	from "../../adamriese_docs/UV/Adam Riese Leistungsübersicht Unfall.pdf";
import Vorvertragliche_Anzeigepflichten from "../../adamriese_docs/UV/Vorvertragliche Anzeigepflichten.pdf";
import Zusatzbedinungen_Notfall_Assistent_Unfall
	from "../../adamriese_docs/UV/Adam Riese Zusatzbedinungen Notfall Assistent Unfall.pdf";
import Unfall_Maerkblatt from "../../adamriese_docs/UV/Adam Riese Unfall Maklerblatt.pdf";

import Annahmerichtlinie_WOG from "../../adamriese_docs/WOG/Adam Riese Annahmerichtlinie WOG.pdf";
import AVB_Besser_WOG from "../../adamriese_docs/WOG/Adam Riese AVB Besser WOG.pdf";
import AVB_Gut_WOG from "../../adamriese_docs/WOG/Adam Riese AVB Gut WOG.pdf";
import AVB_Riesig_WOG from "../../adamriese_docs/WOG/Adam Riese AVB Riesig WOG.pdf";
import IPID_WOG from "../../adamriese_docs/WOG/Adam Riese IPID WOG.pdf";
import Leistungsuebersicht_WOG from "../../adamriese_docs/WOG/Adam Riese Leistungsübersicht WOG.pdf";
import Maerkblatt_WOG from "../../adamriese_docs/WOG/Adam Riese Maklerblatt WOG.pdf";

import IPID_FV from "../../adamriese_docs/FV/Adam Riese IPID FV.pdf";
import AVB_Besser_FV from "../../adamriese_docs/FV/Adam Riese AVB Besser FV.pdf";
import AVB_Riesig_FV from "../../adamriese_docs/FV/Adam Riese AVB Riesig FV.pdf";
import Leistungsuebersicht_FV from "../../adamriese_docs/FV/Adam Riese Leistungsübersicht FV.pdf";
import Annahmerichtlinien_FV from "../../adamriese_docs/FV/Adam Riese Annahmerichtlinie FV.pdf";
import Maerkblatt_FV from "../../adamriese_docs/FV/Adam Riese Maklerblatt FV.pdf";

import {insuranceTypes} from "../../_params/variables";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import React from "react";

import readXlsxFile from "read-excel-file";

export function docsListRender(tariff) {
	let list = [];

	switch (tariff) {
		case insuranceTypes.PRIVATE_LIABILITY:
			list.push(Annahmerichtlinien_Privathaftpflicht, Informationsblatt_Privathaftpflicht,
					Leistungsuebersicht_Privathaftpflicht, Versicherungsbedingungen_Privathaftpflicht_BESSER,
					Versicherungsbedingungen_Privathaftpflicht_Tarif_GUT, Versicherungsbedinungen_Privathatpflicht_RIESIG);
			break;
		case insuranceTypes.PET_LIABILITY:
			list.push(Informationsblatt_Hundehalterhaftpflichtversicherung, Adam_Riese_Leistungsuebersicht_HHV,
					Versicherungsbedingungen_Hundehalterhaftpflicht_XL, Versicherungsbedingungen_Hundehalterhaftpflicht_XXL,
					Annahmerichtlinien_HHV);
			break;
		case insuranceTypes.HOUSE_HOLD:
			list.push(Annahmerichtlinien_Hausratversicherung, Informationsblatt_Hausratversicherung,
					Leistungsuebersicht_Hausrat, Versicherungsbedingungen_Hausrat_L, Versicherungsbedingungen_Hausrat_XL,
					Versicherungsbedingungen_Hausrat_XXL, Maklerinformationsblatt_Hausrat);
			break;
		case insuranceTypes.LEGAL_COSTS:
			list.push(Annahmerichtlinien_Rechtschutz, Informationsblatt_Rechtschutz,
					AVB_RS, Leistungsuebersicht_RS);
			break;
		case insuranceTypes.ACCIDENT:
			list.push(Unfall_Annahmerichtlinien, AUB_GUT, AUB_Besser,
					AUB_Riesig, Unfall_IPID_Makler_AdamRiese072020, Unfall_Leistungsuebersicht_Makler_AdamRiese072020,
					Vorvertragliche_Anzeigepflichten, Zusatzbedinungen_Notfall_Assistent_Unfall, Unfall_Maerkblatt);
			break;
		case insuranceTypes.RESIDENTIAL:
			list.push(Annahmerichtlinie_WOG, AVB_Besser_WOG, AVB_Gut_WOG, AVB_Riesig_WOG, IPID_WOG,
					Leistungsuebersicht_WOG,Maerkblatt_WOG);
			break;
		case insuranceTypes.BICYCLE:
			list.push(Annahmerichtlinien_FV, AVB_Besser_FV, AVB_Riesig_FV, IPID_FV,
					Leistungsuebersicht_FV, Maerkblatt_FV);
			break;
		default:
			break;
	}

	return list;
}

export function offerNameRender(v) {
	switch (v) {
		case insuranceTypes.PRIVATE_LIABILITY:
			return INSURANCE_GERMAN_NAMES.PRIVATE_LIABILITY;
		case insuranceTypes.PET_LIABILITY:
			return INSURANCE_GERMAN_NAMES.PET_LIABILITY;
		case insuranceTypes.HOUSE_HOLD:
			return INSURANCE_GERMAN_NAMES.HOUSE_HOLD;
		case insuranceTypes.LEGAL_COSTS:
			return INSURANCE_GERMAN_NAMES.LEGAL_COSTS;
		case insuranceTypes.ACCIDENT:
			return INSURANCE_GERMAN_NAMES.ACCIDENT;
		case insuranceTypes.RESIDENTIAL:
			return INSURANCE_GERMAN_NAMES.RESIDENTIAL;
		case insuranceTypes.BICYCLE:
			return INSURANCE_GERMAN_NAMES.BICYCLE;
		default:
			return "falscherTarifname"
	}
}

export function offerNameRenderForPdf(v) {
	if (v === insuranceTypes.ACCIDENT) {
		return INSURANCE_GERMAN_NAMES.ACCIDENT
	}
	else if (v === insuranceTypes.RESIDENTIAL) {
		return "Wohngebäudeversicherung"
	}
	else if (v === insuranceTypes.BICYCLE) {
		return INSURANCE_GERMAN_NAMES.BICYCLE
	}
	else {
		return `${offerNameRender(v)}versicherung`
	}
}

export function offerNameChecker(offer, f) {
	switch (offer) {
		case insuranceTypes.PRIVATE_LIABILITY:
			return (f.includes(INSURANCE_GERMAN_NAMES.PRIVATE_LIABILITY))
		case insuranceTypes.PET_LIABILITY:
			return (f.includes(INSURANCE_GERMAN_NAMES.PET_LIABILITY))
		case insuranceTypes.HOUSE_HOLD:
			return (f.includes(INSURANCE_GERMAN_NAMES.HOUSE_HOLD))
		case insuranceTypes.LEGAL_COSTS:
			return (f.includes(INSURANCE_GERMAN_NAMES.LEGAL_COSTS))
		case insuranceTypes.ACCIDENT:
			return (f.includes(INSURANCE_GERMAN_NAMES.ACCIDENT));
		case insuranceTypes.RESIDENTIAL:
			return (f.includes(INSURANCE_GERMAN_NAMES.RESIDENTIAL));
		case insuranceTypes.BICYCLE:
			return (f.includes(INSURANCE_GERMAN_NAMES.BICYCLE));
		default:
	}
}

export function translateZahlungsweiseToNoun(zahlungsweise) {
	switch (zahlungsweise) {
		case "jaehrlich":
			return "Jahr"

		case "halbjaehrlich":
			return "Halbjahr"

		case "vierteljaehrlich":
			return "Vierteljahr"

		case "monatlich":
			return "Monat"

		default:
			return ""
	}
}

export function getOptionsFromXLSX(file, coloumnNumberValues, coloumnNumberLabels) {

	let optionList = [];
	fetch(file)
			.then(response => response.blob())
			.then(blob => readXlsxFile(blob))
			.then((rows) => {
				rows.map((row, key) => {

					if (row[coloumnNumberValues] !== null) {
						optionList.push({value: row[coloumnNumberValues], label: row[coloumnNumberLabels]})
					}

				})
				optionList.splice(0, 1) // Tabellenkopf entfernen
				optionList.sort((a, b) => a.label - b.label)
			})
	return optionList

}

export const notifyToast = (type, message) => {
	switch (type) {
		case 'error':
			toast.error(
					<div>
						<strong className="mr-auto">{'Fehler!'}</strong><br/>
						<small>{message}</small>
					</div>
			);
			break;
		case 'successSubmit':
			toast.success('Daten wurden erfolgreich übertragen.\n', {autoClose: 5000});
			break;
		default:
			break;
	}
	//		toast.clearWaitingQueue();
}

const INSURANCE_GERMAN_NAMES = {
	PRIVATE_LIABILITY: 'Privathaftpflicht',
	PET_LIABILITY: 'Hundehalterhaftpflicht',
	HOUSE_HOLD: 'Hausrat',
	LEGAL_COSTS: 'Rechtsschutz',
	ACCIDENT: 'Unfallversicherung',
	RESIDENTIAL: 'Wohngebaeudeversicherung',
	BICYCLE: 'E-Bike-Versicherung',
}

export const INSURANCES_ROUTES = {
	PRIVATE_LIABILITY: 'privathaftpflicht',
	PET_LIABILITY: 'hundehalterhaftpflicht',
	HOUSE_HOLD: 'hausrat',
	LEGAL_COSTS: 'rechtsschutz',
	ACCIDENT: 'unfallversicherung',
	RESIDENTIAL: 'wohngebaeudeversicherung',
	BICYCLE: 'ebikeversicherung',
}


export const dateOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
