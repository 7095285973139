import React, {Fragment} from "react";
import {Col, Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {useFormikContext} from "formik";
import DownloadPdfForm from "./DownloadPdfForm";

const SubmitResultComponent = (props) => {

	const {handlePrev, orderResult, setOrderResult, setActiveStep, offer, tariffPrices} = props;

	const {values, resetForm} = useFormikContext();

	function restartForm() {
		resetForm();
		setActiveStep(1);
		setOrderResult(null);
	}

	return (
			<Container>
				<Fragment>
					{orderResult &&
					 <React.Fragment>
						 {orderResult.error ?
								 <>
									 <h3 style={{color: '#bf1414',textAlign: 'center'}} className={"containerForm"}>{'Fehler!'}</h3>
									 <small style={{color: '#bf1414'}}
											  className={"containerForm"}>{orderResult.message}</small>
								 </>
								 :
								 <>
									 <h3 style={{textAlign: 'center'}} className={"containerForm"}>{'Ihr Antrag wurde erfolgreich versendet!'}</h3>
									 <DownloadPdfForm
											 resultData={true}
											 offer={offer}
											 values={values}
									 		 tariffPrices={tariffPrices}
											 hideCustomerData={false}
											 offerSuccessful
									 />
								 </>
						 }
						 <br/>
						 <Row style={{marginLeft: 0}}>
							 {orderResult.error ?
									 <>
										 <Col md={4}>
										 </Col>
										 <Col md={8}>
											 <Button className="btn btn-primary btn-info"
														onClick={handlePrev}
														style={{width:'40px', padding: '0 26px 0 12px'}}
											 >
												 <div className={'ar-btn ar-btn-reward ar-icon-forward'}/>
											 </Button>
											 <Button
													 className="btn btn-weiter"
													 onClick={restartForm}
											 >
												 Neue Anfrage
											 </Button>
										 </Col>
									 </>
									 :
									 <>
										 <Col md={5}/>
										 <Col md={7}>
											 <Button
													 className="btn btn-weiter"
													 onClick={restartForm}
											 >
												 Neue Anfrage
											 </Button>
										 </Col>
									 </>
							 }
						 </Row>
					 </React.Fragment>
					}
				</Fragment>
			</Container>
	)
}

export default SubmitResultComponent;
