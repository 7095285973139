import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {
   berufsgruppeOptions_Rechtsschutz,
   familienstandOptions, produktOptions_Rechtsschutz,
   selbstbehaltOptions_Rechtsschutz, zahlweiseOptions
} from "../optionsSelection";
import {requiring_address_products} from "../../pages/LegalCostsPage";

const errors_LegalCostsInsurance = errors.LegalCostsInsurance;
const errors_common = errors.COMMON;

const today = new Date();
let minBirthdate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

export const versichertes_objektSchema = Yup.object({
   strasse_vo: Yup.string().min(2, errors_common.strasse).required(errors_common.strasse).nullable(),
   hausnr_vo: Yup.string().required(errors_common.hausnummer).nullable().typeError(errors_common.typeErrorNumber),
   postleitzahl_vo: Yup.string().matches(/^[0-9]{5}$/, errors_common.postleitzahl).required(errors_common.postleitzahl).nullable(),
   ort_vo: Yup.string().min(3, errors_common.ort).required(errors_common.ort).nullable()
})

export default Yup.object().shape({
    beginn: Yup.date().min(new Date(), errors_LegalCostsInsurance.beginn).required(errors_LegalCostsInsurance.beginn).nullable(),
    geburtsdatum_VN: Yup.date()
          .max(minBirthdate, errors_LegalCostsInsurance.geburtsdatum_min18)
          .required(errors_LegalCostsInsurance.geburtsdatum).nullable(),
    familienstand: Yup.string().required(errors_LegalCostsInsurance.familienstand).oneOf(familienstandOptions.map(item => {return item.value}), errors_LegalCostsInsurance.familienstand).nullable(),
    berufsgruppeVN: Yup.string().required(errors_LegalCostsInsurance.berufsgruppeVN).oneOf(berufsgruppeOptions_Rechtsschutz.map(item => {return item.value}), errors_LegalCostsInsurance.berufsgruppeVN).nullable(),
   postleitzahl: Yup.string().matches(/^[0-9]{5}$/, errors_common.postleitzahl).required(errors_common.postleitzahl).nullable(),
   berufsgruppePartner: Yup.string().notRequired().oneOf(berufsgruppeOptions_Rechtsschutz.map(item => {return item.value}), errors_LegalCostsInsurance.berufsgruppeVN).nullable(),
    produkt: Yup.string().required(errors_LegalCostsInsurance.produkt).oneOf(produktOptions_Rechtsschutz.map(item => {return item.value}), errors_LegalCostsInsurance.produkt).nullable(),
   anzahl_wohnungen: Yup.number()
         .typeError(errors_common.typeErrorNumber)
         .when("produkt", {
            is: value => value && requiring_address_products.includes(value),
            then: (schema) => schema.required(errors_LegalCostsInsurance.anzahl_wohnungen)
                  .min(1, errors_LegalCostsInsurance.anzahl_wohnungen)
                  .max(3, errors_LegalCostsInsurance.anzahl_wohnungen),
            otherwise: (schema) => schema.notRequired().nullable()
         }),
   vorschaeden: Yup.number().required(errors_LegalCostsInsurance.vorschaeden).
         max(2, errors_LegalCostsInsurance.vorschaeden_max2).nullable(),
   schadenfreieJahre: Yup.string().matches(/^[0-9]*$/, errors_LegalCostsInsurance.schadenfreieJahre)
         .required(errors_LegalCostsInsurance.schadenfreieJahre).nullable(),
    selbstbehalt: Yup.string().required(errors_LegalCostsInsurance.selbstbehalt).oneOf(selbstbehaltOptions_Rechtsschutz.map(item => {return item.value.toString()}), errors_LegalCostsInsurance.selbstbehalt).nullable(),
    zahlungsweise: Yup.string().required(errors_LegalCostsInsurance.zahlungsweise).oneOf(zahlweiseOptions.map(item => {return item.value}), errors_LegalCostsInsurance.zahlungsweise).nullable(),
   versicherte_objekte: Yup.array().when('produkt', {
      is: value => value && requiring_address_products.includes(value),
      then: schema => schema.of(versichertes_objektSchema)
   }),
});
