import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {
	familienstandOptions,
	selbstbehaltOptions,
	zahlweiseOptions
} from "../optionsSelection";

const errors_LiabilityInsurance = errors.LiabilityInsurance;

const today = new Date();
let minBirthdate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

export default Yup.object({

	beginn: Yup.date().min(new Date(), errors_LiabilityInsurance.beginn).required(errors_LiabilityInsurance.beginn).nullable(),
	familienstand: Yup.string().required(errors_LiabilityInsurance.familienstand).oneOf(familienstandOptions.map(item => {return item.value}), errors_LiabilityInsurance.familienstand).nullable(),
	geburtsdatum_VN: Yup.date().max(minBirthdate, errors_LiabilityInsurance.geburtsdatum_min18).required(errors_LiabilityInsurance.geburtsdatum).nullable(),
	vorschaeden: Yup.string().matches(/^[0-2]$/, errors_LiabilityInsurance.vorschaeden).required(errors_LiabilityInsurance.vorschaeden).nullable(),
	selbstbehalt: Yup.string().required(errors_LiabilityInsurance.selbstbehalt).oneOf(selbstbehaltOptions.map(item => {return item.value.toString()}), errors_LiabilityInsurance.selbstbehalt).nullable(),
	zahlungsweise: Yup.string().required(errors_LiabilityInsurance.zahlungsweise).oneOf(zahlweiseOptions.map(item => {return item.value}), errors_LiabilityInsurance.zahlungsweise).nullable(),
});
