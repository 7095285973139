import {BrowserRouter} from "react-router-dom";
import React from "react";
import AppWrapper from "./version_2/AppWrapper";

import 'bootstrap/dist/css/bootstrap.min.css';
import './resources/style/styles.css';

import {QueryParamsProvider} from "./version_2/contexts/QueryParamsContext";

const AdamRieseApp = (props) => {
	return (
			<BrowserRouter>
				<QueryParamsProvider>
					<AppWrapper/>
				</QueryParamsProvider>
			</BrowserRouter>
	)
}

export default AdamRieseApp;
