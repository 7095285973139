import {Button, Col, Spinner} from "react-bootstrap";
import React, {useMemo} from "react";
import Row from "react-bootstrap/Row";
import RadioGroupInput from "../formitems/RadioGroupInput";
import BerechnenResult from "../BerechnenResult";
import {JaNeinRadioOptions, tariffOptions} from "../../utils/optionsSelection";

const CalculationWrapperExtensions_ACC = (props) => {
	const {values, resultRIESIG, resultBESSER, resultGUT, goNextStep, calcLoading, media} = props
	const Gesundheitsfragen = () => {
		return (
				<div>
					<hr/>

					<h3 style={{textAlign: 'center', marginBottom: '30px'}}>Gesundheitsfrage</h3>
					<Row>
						<Col md={2}></Col>
						<Col md={8}>
							<div>
								Um bei uns eine Unfallversicherung abschließen zu können, dürfen die zu
								versichernden Personen
								<b> aktuell sowie in den letzten 5 Jahren</b> unter keiner der folgenden Krankheiten
								und
								Beeinträchtigungen <b>leiden, bzw. gelitten haben:</b><br/>
								<ul className={'unfallUl'}>
									<li>Osteoporose</li>
									<li>Knochenkrebs</li>
									<li>Diabetes mellitus Typ 1 oder 2</li>
									<li>Glasknochenkrankheit</li>
									<li>Multiple Sklerose</li>
									<li>Erwerbsunfähigkeit</li>
									<li>Schwerbehinderung (Grad der Behinderung > 49 %)</li>
									<li>Pflegebedürftigkeit</li>
								</ul>
								<br/><br/>
								<div className={'dottedBox'}><b>Die nachstehende Frage muss nach bestem Wissen sorgfältig,
									vollständig
									und
									richtig beantwortet werden, da sonst der Versicherungsschutz gefährdet
									ist.</b><br/>
									Ausführliche Hinweise befinden sich <a
											href={"https://s3.eu-central-1.amazonaws.com/adamriese-assets/assets/docs/Vorvertragliche_Anzeigepflichten.pdf"}
											target="_blank" className={'coloredLink'}>hier</a>.
								</div>
							</div>
							<br/>
							<b>Sind alle zu versichernden Personen frei von den oben genannten Krankheiten und
								Beeinträchtigungen?</b><br/><br/>
							<RadioGroupInput item={"gesundheitsfragen"} label={""} options={JaNeinRadioOptions}/>
						</Col>

						<Col md={2}></Col>
					</Row>
					<hr/>
				</div>
		)
	}


	const result = useMemo(() => {
		switch (values.tarif) {
			case "L":
				return resultGUT
			case "XL":
				return resultBESSER
			case "XXL":
				return resultRIESIG
		}
	}, [values.tarif, resultGUT, resultBESSER, resultRIESIG])


	return (
			<>
				<div>
					{media !== 'print' && <>
						<div>
							<h3 style={{textAlign: 'center', marginBottom: '30px'}}>Gewählte Bausteine</h3>
							<div className={'zusaetzlicheBausteine'}>
								<Row>
									<Col sm={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
										<RadioGroupInput switcher item={'zusatzbausteinMitwirkung'} overwriteDefaultStyle={{
											border: "none",
											marginRight: "-50px",
											marginTop: "25px"
										}}/>

									</Col>

									<Col sm={8}>
										<h5><b>Mitwirkungsbaustein</b></h5>
										Mitwirkungsbaustein (100%). Beträgt der Mitwirkungsanteil weniger als 100 Prozent,
										nehmen wir im Fall eines Unfalls bei dem eine Vorerkrankung mitgewirkt hat,
										keine Minderung vor.
									</Col>
									<Col sm={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
									</Col>
								</Row>
							</div>
						</div>
						{(values.tarif !== 'XXL' || (values.tarif === 'XXL' && values.zusatzbausteinMitwirkung)) &&
						 <Gesundheitsfragen/>
						}
					</>}
					<div style={{margin: "30px", display: "flex", justifyContent: "center", alignItems: "center"}}>
						{calcLoading ?
								<Spinner animation="border" variant="info"/> :
								<>
									{
											result && <BerechnenResult error={result.calculationError}
																				result={result.calculationResult.grossPremium}
																				messages={result.calculationMessages}
																				zahlungsweise={values.zahlungsweise}
													 />
									}
									<Button
											style={{marginLeft: "20px"}}
											disabled={!result || result.calculationResult.grossPremium === 'NaN'}
											onClick={() => goNextStep(values.tarif, result.calculationResult)}
											className="btn btn-weiter btn-weiter-values.tarif"
									>
										Weiter mit Tarif {tariffOptions.find(el => el.value === values.tarif)?.label}
									</Button>
								</>
						}
					</div>
				</div>
			</>
	)
}

export default CalculationWrapperExtensions_ACC
