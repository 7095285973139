import {CommonFormData} from "./commonFormData";

export default {
	"beginn": null,
	"versicherungsdauer": "1",
	"tarif": "",
	// "versicherungssumme": "",
	"familienstand": "",
	//"geburtsdatum": null,
	"berufsgruppe": "freie_wirtschaft",
	"vorschaeden": "",
	"selbstbehalt": "",
	"zahlungsweise": "",

	...CommonFormData
}
