import React, {useContext, useState} from "react";
import {Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import MaskedTextInput from "react-text-mask";
import {QueryParamsContext} from "../contexts/QueryParamsContext";

const vmNrInputMask = [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/];

const SignInPage = () => {
	const {loading, login, loginError} = useContext(QueryParamsContext);

	const [loginInput, setLoginInput] = useState('');

	const handleLogin = () => {
		login(loginInput);
	}

	return (
			<Container>
				<Card>
					<Card.Body className={"paddingContent alignCenter"}>
						<div className={'anmeldung-top'}>
							<img src="https://www.adam-riese.de/assets/img/ar_logo.svg" alt="Adam Riese Logo" style={{width: '120px'}}/>
						</div>
						<div className={'anmeldeDiv'}>
							<h1 className={'anmeldung'}>Geben Sie Ihre<br/>Vermittlernummer ein


							</h1>
							<div className={'vmnummer'}>
								<MaskedTextInput className="vmnummer form-control"
													  type={"text"}
													  mask={vmNrInputMask}
													  style={loginError !== null?{borderBottomColor:"#dc3545"}:{}}
													  placeholder={"Bitte eingeben"}
													  value={loginInput}
													  onChange={(e) => setLoginInput(e.target.value)}
													  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
														  if (event.key === 'Enter' || event.key === "NumpadEnter") {
															  handleLogin();
														  }
													  }}

								/>
								<span className={'vmnummerError'}>{loginError}</span>
							</div>

							<Button className="btn btn-success btn-anmeldung"
									  onClick={handleLogin}
							>
								{'Anmelden '}
								{loading ?
										<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
										/>
										:
										<span className={'ar-btn ar-icon-forward'}/>
								}
							</Button>
							<Row>
								<Col>

								</Col>
							</Row>
						</div>
					</Card.Body>
				</Card>
			</Container>
	)
}

export default SignInPage;
