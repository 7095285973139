import {dateConverter, translateZahlungsweise} from "../translaterForAPI";

export const getQuoteBicycleInsurance = (values, deckung) => {

	let res = {
		"salesPartnerNumber": sessionStorage.getItem("vermittlernummer"),
		"externalIdent": sessionStorage.getItem("externeIdent"),
		"policyholder":
				{
					"birthday": dateConverter(values.geburtsdatum_VN),
					"address":
							{
								"street": values.strasse,
								"streetNumber": values.hausnummer,
								"postalCode": values.postleitzahl,
								"city": values.ort,
								"countryCode": "D"
							},
					"phoneNumber": values.telefon?.replace(/\s/g, ''),
					"emailAddress": values.email
				},
		"insuranceData": {
			"countOfPreviousDamages": Number(values.vorschaeden) || 0,
			"paymentInterval": translateZahlungsweise(values.zahlungsweise),
			"insuranceBegin": dateConverter(values.beginn),
			"tariffSelection": deckung,
			"sumInsured": parseInt(values.versichertesfahrrad.kaufsumme),
			"theftInclusion": values.zusatzbausteinDiebstahl,
			"repairInclusion": values.zusatzbausteinReparatur,
			"breakdownProtectionLetterInclusion": values.zusatzbausteinPannenhilfe,
			"insuredBicycle": {
				"type": values.versichertesfahrrad.typ,
				"bikeType": values.versichertesfahrrad.fahrradtyp,
				"manufacturer": values.versichertesfahrrad.fahrradhersteller,
				"condition": values.versichertesfahrrad.condition,
				"leasing": values.versichertesfahrrad.leasing,
				"hasCarbonFrame": values.versichertesfahrrad.carbon,
				"originalInvoiceExists": values.versichertesfahrrad.originalrechnung,
				"originalInvoiceDate": dateConverter(values.versichertesfahrrad.kaufdatum),
				"bicycleCoding": '',
				"frameNumber": values.versichertesfahrrad.rahmennummer,
			}
		}
	};


	return res;
};
