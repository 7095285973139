import React from "react";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import CalculationWrapperExtensions_LC from "./CalculationWrapperExtensions_LC";

const CalculationWrapper_LC = (props) => {
	const {values, resultGUT, resultBESSER, resultRIESIG, calcLoading, goNextStep, config, setFieldValue} = props;
	const placement = 'top'

	const chooseTarif = (deckung) => {
		setFieldValue('tarif', deckung);
	}

	return (
			<div>
				<Row className={'calculationWrapperRow'}>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "L" ? "markBox" : "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "L" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'} style={values.tarif === "L" ? {color: "#000", backgroundColor: "#4fd4e7"} : {}}>Gut</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Rechtsschutz-Schäden bis <b>1 Mio. €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Versicherungssumme weltweit <b>200.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Dauer Auslandsaufenthalt weltweit <b>1 Jahr</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Telefonische Rechtsberatung
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Freie Rechtsanwaltswahl</li>
								</ul>
								<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
									<li><span className={'ar-icon ar-icon-cross'}/>Kostenloser Check von Miet- und Arbeitsverträgen bis 250 €</li>
									<li><span className={'ar-icon ar-icon-cross'}/>
										<OverlayTrigger
												placement={placement}
												overlay={
													<Tooltip id={'tooltip-gibsgeld'}>
														Künftige Tarifverbesserungen greifen automatisch auch für
														bestehende Verträge.
													</Tooltip>
												}
										>
											<span className={'calculationTooltip'}>Update-Garantie</span>
										</OverlayTrigger>
									</li>
								</ul>
								<Button
										style={{width: '100%'}}
										onClick={() => chooseTarif("L")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XL" ? "markBox" : "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "XL" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'} style={values.tarif === "XL" ? {color: "#000", backgroundColor: "#4fd4e7"} : {}}>Besser</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper flexGrow'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Rechtsschutz-Schäden <b>unbegrenzt</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Versicherungssumme weltweit <b>400.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Dauer Auslandsaufenthalt weltweit <b>2 Jahre</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Telefonische Rechtsberatung
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Freie Rechtsanwaltswahl</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Kostenloser Check von Miet- und Arbeitsverträgen bis <b>250 €</b></li>
									<li>
										<span className={'ar-icon ar-icon-checkmark'}/>
										<OverlayTrigger
												placement={placement}
												overlay={
													<Tooltip id={'tooltip-gibsgeld'}>
														Künftige Tarifverbesserungen greifen automatisch auch für
														bestehende Verträge.
													</Tooltip>
												}
										>
											<span className={'calculationTooltip'}>Update-Garantie</span>
										</OverlayTrigger>
									</li>
								</ul>
								<Button
										style={{width: '100%'}}
										onClick={() => chooseTarif("XL")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XXL" ? "markBox" : "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "XXL" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'} style={values.tarif === "XXL" ? {color: "#000", backgroundColor: "#4fd4e7"} : {}}>Riesig</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper flexGrow'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Rechtsschutz-Schäden <b>unbegrenzt</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Versicherungssumme weltweit <b>1.200.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Dauer Auslandsaufenthalt weltweit <b>3 Jahre</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Telefonische Rechtsberatung
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Freie Rechtsanwaltswahl</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Kostenloser Check von Miet- und Arbeitsverträgen bis 250 €</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>
										<OverlayTrigger
												placement={placement}
												overlay={
													<Tooltip id={'tooltip-gibsgeld'}>
														Künftige Tarifverbesserungen greifen automatisch auch für
														bestehende Verträge.
													</Tooltip>
												}
										>
											<span className={'calculationTooltip'}>Update-Garantie</span>
										</OverlayTrigger>
									</li>
								</ul>
								<Button
										style={{width: '100%', marginTop: "auto"}}
										onClick={() => chooseTarif("XXL")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
				</Row>
				{values.tarif && <CalculationWrapperExtensions_LC
						values={values}
						resultGUT={resultGUT}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						goNextStep={goNextStep}
				/>}
			</div>
	)
}

export default CalculationWrapper_LC;
