import React from "react";
import {useField, useFormikContext} from "formik";
import Select from "react-select";

const SelectAutocomplete = (props) => {

	const {item, placeholder = 'Bitte eingeben', options, convertValueFunc} = props;

	const [meta] = useField(item);

	const {setFieldValue, setFieldTouched} = useFormikContext();

	return (
			<React.Fragment>
				<Select
						name={item}
						options={options}
						value={(meta.value !== null && convertValueFunc(meta.value)) ?? null}
						onChange={v => {
							setFieldValue(item, v.value)
						}}
						onBlur={() => setFieldTouched(item)}
						placeholder={placeholder}
						styles={stylingFunc(meta.error, meta.touched)}
				/>
				{(meta.error && meta.touched && true) &&
				 <small style={{color: '#dc3545'}}>{meta.error}</small>}
			</React.Fragment>

	)
}

export const stylingFunc = (error, touched) => ({
	control: styles => ({
		...styles,
		minHeight: '46px',
		background: '#ececec',
		borderTopRightRadius: '3px',
		borderTopLeftRadius: '3px',
		borderBottomRightRadius: '0',
		borderBottomLeftRadius: '0',
		borderTop: '0',
		borderLeft: '0',
		borderRight: '0',
		borderColor: (error && touched) ? '#dc3545' : '#495057'
	}),
	indicatorSeparator: styles => ({
		...styles,
		display: 'none'
	}),
	indicatorsContainer: styles => ({
		...styles,
		display: 'none'
	}),
})


export default SelectAutocomplete;
