import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {INSURANCES_ROUTES} from "../utils/formHelper";
import {Row, Col, Form} from "react-bootstrap";
import {insuranceTypes} from "../../_params/variables";
import {QueryParamsContext} from "../contexts/QueryParamsContext";

const NavField = (props) => {
	const history = useHistory();
	const {selectedTarifs} = useContext(QueryParamsContext);
	return (
			<Form.Group as={Row} md="2" controlId={"selectedValue"}>
				<Form.Label column md={4}>
					{'Versicherungsart wählen'}
				</Form.Label>
				<Col md={8} className={'selectCell'}>
					<Form.Control
							value={history.location.pathname.substring(1)}
							as="select" name={'tarifauswahl'} onChange={event => history.push(event.target.value)}>
						{(selectedTarifs.includes(insuranceTypes.BICYCLE) || selectedTarifs.length === 0) &&
						 <option
								 value={INSURANCES_ROUTES.BICYCLE}>
							 E-Bike
						 </option>
						}
						{(selectedTarifs.includes(insuranceTypes.PRIVATE_LIABILITY) || selectedTarifs.length === 0) &&
						 <option
								 value={INSURANCES_ROUTES.PRIVATE_LIABILITY}>
							 Privathaftpflicht
						 </option>
						}
						{(selectedTarifs.includes(insuranceTypes.LEGAL_COSTS) || selectedTarifs.length === 0) &&
						 <option value={INSURANCES_ROUTES.LEGAL_COSTS}>
							 Rechtsschutz
						 </option>
						}
						{(selectedTarifs.includes(insuranceTypes.HOUSE_HOLD) || selectedTarifs.length === 0) &&
						<option value={INSURANCES_ROUTES.HOUSE_HOLD}>
							Hausrat
						</option>
						}
						{(selectedTarifs.includes(insuranceTypes.PET_LIABILITY) || selectedTarifs.length === 0) &&
						<option value={INSURANCES_ROUTES.PET_LIABILITY}>
							Hundehalterhaftpflicht
						</option>
						}
						{(selectedTarifs.includes(insuranceTypes.ACCIDENT) || selectedTarifs.length === 0) &&
						<option value={INSURANCES_ROUTES.ACCIDENT}>
							Unfallversicherung
						</option>
						}
						{(selectedTarifs.includes(insuranceTypes.RESIDENTIAL) || selectedTarifs.length === 0) &&
						 <option value={INSURANCES_ROUTES.RESIDENTIAL}>
							 Wohngebäudeversicherung
						 </option>
						}

					</Form.Control>
				</Col>
			</Form.Group>
	)
}

export default NavField;
