import React, {useState} from "react";
import {Spinner} from "react-bootstrap";
import {offerNameRender} from "../utils/formHelper";

const DownloadDataBox = (props) => {

	const {values, offer} = props;

	const [loading, setLoading] = useState(false);

	function downloadFile() {
		setLoading(true);
		setTimeout(() => {
			let dataToDownload = JSON.stringify(values)
					.slice(1,-1)
					.replaceAll('[', '[\n')
					.replaceAll('},"', '},\n\n"')
					.replaceAll('{"', '{\n"')
					.replaceAll(",\"",",\n\"")
					.replaceAll("]}", "]}\n")
			let filename = '';
			filename += values.nachname && values.nachname + ' ';
			filename += values.vorname && values.vorname + ' ';
			filename += offerNameRender(offer) + '.txt';
			let contentType = "text/plain;charset=utf-8;";
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				let blob = new Blob([decodeURIComponent(encodeURI(dataToDownload))],
						{type: contentType});
				navigator.msSaveOrOpenBlob(blob, filename);
			}
			else {
				let a = document.createElement('a');
				a.download = filename;
				a.href = 'data:' + contentType + ',' + encodeURIComponent(dataToDownload);
				a.target = '_blank';
				document.body.appendChild(a);

				a.click();
				document.body.removeChild(a);
			}
			setLoading(false);
		}, 1500);
	}


	return (
			<>
				<span className={'downloadLink'} onClick={downloadFile} title={'Tarifberechnung für später speichern'}>
					<span className={'ar-btn ar-icon-download'}/>
					{loading ?
							<span><Spinner animation="border" size="sm"/></span>
							:
							<span>Tarifberechnung für später speichern</span>
					}
				</span>
			</>
	)
}

export default DownloadDataBox;
