import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {
    accidentHouseholdOptions,
    beginn_unfall_tagegeld_options,
    berufsgruppeRadioOptions,
    dailyHospitalBenefitSumInsuredOptions,
    deathSumInsuredChildOptions,
    deathSumInsuredOptions,
    invalidityProgressionOptions,
    invaliditySumInsuredOptions,
    JaNeinRadioOptions,
    pensionSumInsuredChildOptions,
    pensionSumInsuredOptions,
    plasterMoneyBlockInsuredChildOptions,
    plasterMoneyBlockInsuredOptions,
    sicknessDailyAllowanceSumInsuredOptions,
    transitionalBenefitSumInsuredOptions,
    zahlweiseOptions
} from "../optionsSelection";
import berufData from "../berufData";

const errors_AccidentInsurance = errors.AccidentInsurance;
const today = new Date();
let minBirthdate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

const childSchema = () => Yup.object().shape({
    geburtsdatum: Yup.date().max(new Date(), errors_AccidentInsurance.geburtsdatum).required(errors_AccidentInsurance.geburtsdatum).nullable(),
    invaliditaet: Yup.string().required(errors_AccidentInsurance.invaliditaet).oneOf(invaliditySumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.invaliditaet).nullable(),
    progression: Yup.string().required(errors_AccidentInsurance.progression).oneOf(invalidityProgressionOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.progression).nullable(),
    unfallrente: Yup.string().required(errors_AccidentInsurance.unfallrente).oneOf(pensionSumInsuredChildOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.unfallrente).nullable(),
    krankenhaustagegeld: Yup.string().required(errors_AccidentInsurance.krankenhaustagegeld).oneOf(dailyHospitalBenefitSumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.krankenhaustagegeld).nullable(),
    todesfallleistung: Yup.string().required(errors_AccidentInsurance.todesfallleistung).oneOf(deathSumInsuredChildOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.todesfallleistung).nullable(),
    gipsgeld: Yup.string().required(errors_AccidentInsurance.gipsgeld).oneOf(plasterMoneyBlockInsuredChildOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.gipsgeld).nullable(),
    uebergangsleistung: Yup.string().required(errors_AccidentInsurance.uebergangsleistung).oneOf(transitionalBenefitSumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.uebergangsleistung).nullable(),
    dynamik: Yup.string().required(errors_AccidentInsurance.dynamik).oneOf(JaNeinRadioOptions.map(item => {return item.value}), errors_AccidentInsurance.dynamik).nullable(),
});

const parentSchema = () => Yup.object().shape({
    geburtsdatum: Yup.date()
        .max(minBirthdate, errors_AccidentInsurance.geburtsdatum_min18)
        .required(errors_AccidentInsurance.geburtsdatum).nullable(),
    beruf: Yup.string().required(errors_AccidentInsurance.beruf).oneOf(berufData.map(item => {return item.value}), errors_AccidentInsurance.beruf).nullable(),
    berufsgruppe: Yup.string().required(errors_AccidentInsurance.berufsgruppe).oneOf(berufsgruppeRadioOptions.map(item => {return item.value}), errors_AccidentInsurance.berufsgruppe).nullable(),
    invaliditaet: Yup.string().required(errors_AccidentInsurance.invaliditaet).oneOf(invaliditySumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.invaliditaet).nullable(),
    progression: Yup.string().required(errors_AccidentInsurance.progression).oneOf(invalidityProgressionOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.progression).nullable(),
    unfallrente: Yup.string().required(errors_AccidentInsurance.unfallrente).oneOf(pensionSumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.unfallrente).nullable(),
    krankenhaustagegeld: Yup.string().required(errors_AccidentInsurance.krankenhaustagegeld).oneOf(dailyHospitalBenefitSumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.krankenhaustagegeld).nullable(),
    todesfallleistung: Yup.string().required(errors_AccidentInsurance.todesfallleistung).oneOf(deathSumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.todesfallleistung).nullable(),
    gipsgeld: Yup.string().required(errors_AccidentInsurance.gipsgeld).oneOf(plasterMoneyBlockInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.gipsgeld).nullable(),
    uebergangsleistung: Yup.string().required(errors_AccidentInsurance.uebergangsleistung).oneOf(transitionalBenefitSumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.uebergangsleistung).nullable(),
    unfall_tagegeld: Yup.string().required(errors_AccidentInsurance.unfall_tagegeld).oneOf(sicknessDailyAllowanceSumInsuredOptions.map(item => {return item.value.toString()}), errors_AccidentInsurance.unfall_tagegeld).nullable(),
    beginn_unfall_tagegeld: Yup.string().when('unfall_tagegeld', {
        is: val => val && parseInt(val) > 0,
        then: schema => schema.required(errors_AccidentInsurance.beginn_unfall_tagegeld).oneOf(beginn_unfall_tagegeld_options.map(item => {return item.value.toString()}), errors_AccidentInsurance.beginn_unfall_tagegeld).nullable(),
        otherwise: schema => schema.notRequired().nullable()
    }),
    dynamik: Yup.string().required(errors_AccidentInsurance.dynamik).oneOf(JaNeinRadioOptions.map(item => {return item.value}), errors_AccidentInsurance.dynamik).nullable(),
})

export default Yup.object().shape({
    beginn: Yup.date().min(new Date(), errors_AccidentInsurance.beginn).required(errors_AccidentInsurance.beginn).nullable(),
    haushalt: Yup.string().required(errors_AccidentInsurance.haushalt).oneOf(accidentHouseholdOptions.map(item => {return item.value}), errors_AccidentInsurance.haushalt).nullable(),
    zahlungsweise: Yup.string().required(errors_AccidentInsurance.zahlungsweise).oneOf(zahlweiseOptions.map(item => {return item.value}), errors_AccidentInsurance.zahlungsweise).nullable(),

    mich: Yup.object().when(['haushalt'], {
        is: value => value && value !== 'kind(er)',
        then: parentSchema
    }),
    gesundheitsfragen: Yup.string().when(['tarif', 'zusatzbausteinMitwirkung'], {
        is: (tarif, zusatzbausteinMitwirkung) => tarif !== 'XXL' || zusatzbausteinMitwirkung === true,
        then: schema => schema.required(errors_AccidentInsurance.gesundheitsfragen).nullable().oneOf(['ja'], errors_AccidentInsurance.gesundheitsfragen),
        otherwise: schema => schema.notRequired().nullable(),
    }),

    partner: Yup.object().when('haushalt', {
        is: value => value && ['mich_und_partner', 'mich_partner_und_kind(er)'].includes(value),
        then: parentSchema
    }),

    anzahl_kinder: Yup.string().when('haushalt', {
        is: val => val && ['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(val),
        then: schema => schema.required(errors_AccidentInsurance.anzahl_kinder)
            .oneOf(["1", "2", "3"], errors_AccidentInsurance.anzahl_kinder).nullable(),
        otherwise: schema => schema.notRequired().nullable(),
    }),

    kind1: Yup.object().when(['haushalt', 'anzahl_kinder'], {
        is: (val_1, val_2) => val_2 && parseInt(val_2) > 0 && val_1 && ['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(val_1),
        then: childSchema
    }),

    kind2: Yup.object().when(['haushalt', 'anzahl_kinder'], {
        is: (val_1, val_2) => val_2 && val_2 > 1 && val_1 && ['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(val_1),
        then: childSchema
    }),

    kind3: Yup.object().when(['haushalt', 'anzahl_kinder'], {
        is: (val_1, val_2) => val_2 && val_2 > 2 && val_1 && ['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(val_1),
        then: childSchema
    }),
});
