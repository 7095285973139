import React, {useContext, useState} from "react";
import {Card} from "react-bootstrap";
import validationSchemaCustomerData from "../../utils/validationschemas/validationSchemaCustomerData";
import APIService from "../../services/API.service";
import {notifyToast} from "../../utils/formHelper";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import CustomerInfoForm from "./CustomerInfoForm";
import ConfirmTermsForm from "./ConfirmTermsForm";
import SubmitResultComponent from "../SubmitResultComponent";
import CalculationDataForm from "./CalculationDataForm";
import TopBar from "../TopBar";
import {QueryParamsContext} from "../../contexts/QueryParamsContext";
import NavField from "../NavField";

const FormWrapper = ({
	CalculationForm,
	OFFER_URL,
	calcList,
	getQuoteHandler,
	initValuesForm,
	setOrder,
	tariffName,
	validationSchemaCalculation
}) => {

	const {currentUser} = useContext(QueryParamsContext);
	const [activeStep, setActiveStep] = useState(1);
	const [orderResult, setOrderResult] = useState(null);
	const [loading, setLoading] = useState(false);
	const [tariffHighlights, setTariffHighlights] = useState(null)

	const isLastStep = () => {
		return activeStep === 3;
	}

	const handlePrev = () => {
		setActiveStep(Math.max(activeStep - 1, 0));
	}

	const handleNext = () => {
		setActiveStep(Math.min(activeStep + 1, 4));
	}

	const validationSchema = () => {
		switch (activeStep) {
			case 1:
				return validationSchemaCalculation;
			case 2:
				return validationSchemaCustomerData;
			default:
				return Yup.object({});
		}
	}

	const handleSubmit = (values, helpers) => {

		if (!isLastStep()) {
			handleNext();
			return;
		}

		setLoading(true);
		APIService.setOrder(OFFER_URL, setOrder(values))
				.then((res) => {
					console.log(res)
					if (res.status === 406 || res.status === 500) {
						setOrderResult({
							error: true,
							message: res.errorText,
						});
						notifyToast('error', res.errorText ? res.errorText : JSON.stringify(res));
						return;
					}

					setOrderResult({
						error: false,
						message: String(res),
						//						submitSuccess: res.adamRieseProcessNumber,
					});
					if (res.error) {
						notifyToast('error', res.message);
					}
					else if (res.adamRieseProcessNumber) {
						notifyToast('successSubmit', null);
					}
				})
				.catch(error => {
					console.error('Error: ' + error);
					setOrderResult({
						error: true,
						message: String(error),
					});
					notifyToast('error', String(error));
				})
				.finally(() => {
					setLoading(false);
					handleNext();
				});
	}

	return (
			<Card>

				<Formik
						initialValues={initValuesForm}
						onSubmit={handleSubmit}
						validationSchema={validationSchema}
				>

					{(formik) =>
							<Form>

								{activeStep === 1 &&
								 <>
									 {currentUser && <TopBar offer={tariffName}/>}
									 <h1 style={{textAlign: 'center', marginBottom:'30px'}}>Tarifberechnung</h1>
									 <hr style={{backgroundColor: '#fff'}}/>
									 <NavField />
								 </>
								}
								{activeStep === 2 &&
								 <>
									 {currentUser && <TopBar offer={tariffName} disabled={true}/>}
									 <h1 style={{textAlign: 'center'}}>Kundendaten</h1>
								 </>
								}
								{activeStep === 3 &&
								 <>
									 {currentUser && <TopBar offer={tariffName} disabled={true}/>}
									 <h1 style={{textAlign: 'center'}}>Rechtliches & Dokumente</h1>
								 </>
								}
								{activeStep === 4 &&
								 <>
									 {currentUser && <TopBar offer={tariffName} disabled={true}/>}
									 <h1 style={{textAlign: 'center'}}></h1>
								 </>
								}
								<hr style={{backgroundColor: '#fff'}}/>
								{activeStep === 1 &&
								 <CalculationDataForm
										 activeStep={activeStep}
										 handleNext={handleSubmit}
										 calcList={calcList}
										 getQuoteHandler={getQuoteHandler}
										 offer={tariffName}
										 offerURL={OFFER_URL}
										 setTariffHighlights={setTariffHighlights}
								 >
									 {CalculationForm(formik)}
								 </CalculationDataForm>
								}
								{activeStep === 2 &&
								 <CustomerInfoForm
										 tariffHighlights={tariffHighlights}
										 handlePrev={handlePrev}
										 offer={tariffName}
								 />
								}
								{activeStep === 3 &&
								 <ConfirmTermsForm handlePrev={handlePrev}
														 tariffHighlights={tariffHighlights}
														 offer={tariffName}
														 tariffName={tariffName}
														 loading={loading}
								 />
								}
								{activeStep === 4 &&
								 <SubmitResultComponent
										 offer={tariffName}
										 tariffPrices={tariffHighlights}
										 handlePrev={handlePrev}
										 orderResult={orderResult}
										 setOrderResult={setOrderResult}
										 setActiveStep={setActiveStep}
								 />
								}
							</Form>
					}

				</Formik>
			</Card>
	)
}

export default FormWrapper;
