import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import CalculationWrapperExtensions_FV from "./CalculationWrapperExtensions_FV";

const CalculationWrapper_FV = (props) => {
	const {values, resultBESSER, resultRIESIG, calcLoading, goNextStep, config, setFieldValue} = props;
	const chooseTarif = (deckung) => {
		setFieldValue('tarif', deckung);
	}

	return (
			<div>
				<Row className={'calculationWrapperRow'}>

					<Col sm={12} md={6} className={`calculationWrapperCell ${config && (config?.markBox === "XL" ? "markBox"
							: "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "XL" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'}
									style={values.tarif === "XL" ? {color: "#000", backgroundColor: "#4fd4e7"}
											: {}}>Besser</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper flexGrow'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Keine Selbstbeteiligung</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Versicherungssumme <b>individuell
										wählbar</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Versichert: Dein E-Bike & einzelne
										Fahrradteile
									</li>
								</ul>
								<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>

									<li><span className={'ar-icon ar-icon-cross'}/>Bonus bei Kauf von Neufahrrad nach
										Totalschaden <b>10 %</b></li>
									<li><span className={'ar-icon ar-icon-cross'}/>Bonus bei Kauf von gebrauchtem Fahrrad
										nach Totalschaden <b>20 %</b></li>
									<li><span className={'ar-icon ar-icon-cross'}/>Grobe Fahrlässigkeit</li>
									<li><span className={'ar-icon ar-icon-cross'}/>Update-Garantie</li>
									<li><span className={'ar-icon ar-icon-cross'}/>Besitzstandsgarantie</li>
								</ul>
									<Button
											style={{width: '100%'}}
											onClick={() => chooseTarif("XL")}
											className="btn btn-weiter btn-weiter-tarif"
									>
										Tarif auswählen
									</Button>
							</div>
						</div>
					</Col>
					<Col sm={12} md={6} className={`calculationWrapperCell ${config && (config?.markBox === "XXL" ? "markBox"
							: "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "XXL" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'}
									style={values.tarif === "XXL" ? {color: "#000", backgroundColor: "#4fd4e7"}
											: {}}>Riesig</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper flexGrow'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Keine Selbstbeteiligung</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Versicherungssumme <b>individuell
										wählbar</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Versichert: Dein E-Bike & einzelne
										Fahrradteile
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Bonus bei Kauf von Neufahrrad nach
										Totalschaden <b>10 %</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Bonus bei Kauf von gebrauchtem Fahrrad
										nach Totalschaden <b>20 %</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Grobe Fahrlässigkeit</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Update-Garantie</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Besitzstandsgarantie</li>
								</ul>
								<Button
										style={{width: '100%', marginTop: "auto"}}
										onClick={() => chooseTarif("XXL")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
				</Row>
				{values.tarif && <CalculationWrapperExtensions_FV
						values={values}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						goNextStep={goNextStep}
				/>}
			</div>
)
}

export default CalculationWrapper_FV;
