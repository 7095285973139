import _ from "lodash";
import {dateConverter, translateZahlungsweise} from "../translaterForAPI";

export const getQuoteAccidentInsurance = (values, deckung) => {

    let insuredPersonsList = [];
    if (['mich_und_partner', 'mich_partner_und_kind(er)'].includes(values.haushalt)) {
        let personData = {
            "person": {
                "firstName": values.partner?.vorname ? values.partner?.vorname : " ",
                "lastName": values.partner?.nachname ? values.partner?.nachname : " ",
                "salutation": "Herr",
                "birthday": [
                    new Date(values.partner?.geburtsdatum).getFullYear(),
                    new Date(values.partner?.geburtsdatum).getMonth() + 1,
                    new Date(values.partner?.geburtsdatum).getDate(),
                ],
                "gender": "MALE",
                "title": "",
                "civilService": (values.partner.berufsgruppe === 'oeffentlicher_dienst'),
                "address": {
                    "street": " ",
                    "streetNumber": " ",
                    "postalCode": " ",
                    "city": " ",
                    "countryCode": "D"
                }
            },
            "tariffSelection": deckung,
            "paymentInterval": translateZahlungsweise(values.zahlungsweise),
            "additionalPersonData": {
                "consentSEE": values.gesundheitsfragen === 'ja',
                "healthy": values.gesundheitsfragen === 'ja',
                "job": values.partner?.beruf,
                "relationShipKind": "02"
            },
            "blocks": {
                "dynamic": values.partner.dynamik === 'ja',
                "withAssistanceBlock": values.partner.notfall_assistent === 'ja',
                "invalidityBlock": {
                    "sumInsured": parseInt(values.partner.invaliditaet),
                    "progression": parseInt(values.partner.progression)
                },
            }
        };

        if (values.zusatzbausteinMitwirkung === true) {
            personData.blocks["participationShare"] = 'MWA100'
        }
        if (values.partner.todesfallleistung > 0) {
            personData.blocks["deathBlock"] = {
                "sumInsured": parseInt(values.partner.todesfallleistung)
            }
        }
        if (values.partner.krankenhaustagegeld > 0) {
            personData.blocks["dailyHospitalBenefitBlock"] = {
                "sumInsured": parseInt(values.partner.krankenhaustagegeld)
            }
        }
        if (values.partner.uebergangsleistung > 0) {
            personData.blocks["transitionalBenefitBlock"] = {
                "sumInsured": parseInt(values.partner.uebergangsleistung)
            }
        }
        if (values.partner.unfallrente > 0) {
            personData.blocks["pensionBlock"] = {
                "sumInsured": parseInt(values.partner.unfallrente)
            }
        }
        if (values.partner.unfall_tagegeld > 0) {
            personData.blocks["sicknessDailyAllowance"] = {
                "sumInsured": parseInt(values.partner.unfall_tagegeld),
                "benefitFromDay": values.partner.beginn_unfall_tagegeld
                    ? parseInt(values.partner.beginn_unfall_tagegeld)
                    : 1,
            }
        }
        if (values.partner.gipsgeld !== "0") {
            personData.blocks["plasterMoneyBlock"] = {
                "sumInsured": parseInt(values.partner.gipsgeld)
            }
        }

        insuredPersonsList.push(personData);
    }

    if (['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(values.haushalt)) {
        _.range(1, parseInt(values.anzahl_kinder) + 1, 1).forEach(child => {

            let childData = {
                "person": {
                    "firstName": values["kind" + child]?.vorname ? values["kind" + child]?.vorname : " ",
                    "lastName": values["kind" + child]?.nachname ? values["kind" + child]?.nachname : " ",
                    "salutation": "Herr",
                    "birthday": [
                        new Date(values["kind" + child]?.geburtsdatum).getFullYear(),
                        new Date(values["kind" + child]?.geburtsdatum).getMonth() + 1,
                        new Date(values["kind" + child]?.geburtsdatum).getDate(),
                    ],
                    "gender": "MALE",
                    "title": "",
                    "civilService": false,
                    "address": {
                        "street": values.strasse,
                        "streetNumber": values.hausnummer,
                        "postalCode": values.postleitzahl,
                        "city": values.ort ? values.ort : " ",
                        "countryCode": "D"
                    }
                },
                "tariffSelection": deckung,
                "paymentInterval": translateZahlungsweise(values.zahlungsweise),
                "additionalPersonData": {
                    "consentSEE": values.gesundheitsfragen === 'ja',
                    "healthy": values.gesundheitsfragen === 'ja',
                    "job": "Kind",
                    "relationShipKind": "03"
                },
                "blocks": {
                    "dynamic": values["kind" + child].dynamik === 'ja',
                    "withAssistanceBlock": false,
                    "invalidityBlock": {
                        "sumInsured": parseInt(values["kind" + child].invaliditaet),
                        "progression": parseInt(values["kind" + child].progression)
                    },
                    /*"sicknessDailyAllowance": {
                        // "sumInsured": parseInt(values["child" + child].sicknessDailyAllowance_sumInsured),
                        "sumInsured": parseInt(values["child" + child].sicknessDailyAllowance_sumInsured),
                        // "benefitFromDay": parseInt(values["child" + child].sicknessDailyAllowance_benefitFromDay)
                        "benefitFromDay":
                            // (values["child" + child].sicknessDailyAllowance_benefitFromDay
                            // && values["child" + child].sicknessDailyAllowance_sumInsured
                            // && values["child" + child].sicknessDailyAllowance_sumInsured > 0)
                            // ? parseInt(values["child" + child].sicknessDailyAllowance_benefitFromDay)
                            // :
                                1,
                    },*/
                }
            };


            if (values.zusatzbausteinMitwirkung === true) {
                childData.blocks["participationShare"] = 'MWA100'
            }

            if (values["kind" + child].todesfallleistung > 0) {
                childData.blocks["deathBlock"] = {
                    "sumInsured": parseInt(values["kind" + child].todesfallleistung)
                }
            }
            if (values["kind" + child].krankenhaustagegeld > 0) {
                childData.blocks["dailyHospitalBenefitBlock"] = {
                    "sumInsured": parseInt(values["kind" + child].krankenhaustagegeld)
                }
            }
            if (values["kind" + child].uebergangsleistung > 0) {
                childData.blocks["transitionalBenefitBlock"] = {
                    "sumInsured": parseInt(values["kind" + child].uebergangsleistung)
                }
            }
            if (values["kind" + child].unfallrente > 0) {
                childData.blocks["pensionBlock"] = {
                    "sumInsured": parseInt(values["kind" + child].unfallrente)
                }
            }
            if (values["kind" + child].gipsgeld !== "0") {
                childData.blocks["plasterMoneyBlock"] = {
                    "sumInsured": parseInt(values["kind" + child].gipsgeld)
                }
            }

            insuredPersonsList.push(childData);
        })
    }

    let result = {
        "salesPartnerNumber": sessionStorage.getItem("vermittlernummer"),
        "externalIdent": sessionStorage.getItem("externeIdent"),
        "policyholder": {
            "firstName": values.vorname ? values.vorname : " ",
            "lastName": values.nachname ? values.nachname : " ",
            "salutation": "Herr",
            "gender": "MALE",
//            "title": "Dr.",
            "birthday": dateConverter(values.geburtsdatum_VN),
            "civilService": (values.berufsgruppe === 'oeffentlicher_dienst'),
            "address":
                {
                    "street": values.strasse,
                    "streetNumber": values.hausnummer,
                    "postalCode": values.postleitzahl,
                    "city": values.ort ? values.ort : " ",
                    "countryCode": "D"
                },
            "phoneNumber": values.telefon?.replace(/\s/g, ''),
            "emailAddress": values.email
        },
        "insuranceData": {
            "countOfPreviousDamages": values.vorschaeden,
            "paymentInterval": translateZahlungsweise(values.zahlungsweise),
            "insuranceBegin": dateConverter(values.beginn),
            "contractTermInYears": parseInt(values.versicherungsdauer),
            "tariffSelection": deckung,
            "insuredPersonData": insuredPersonsList,
        }
    };

    if (values.haushalt && values.haushalt !== 'kind(er)') {
        result.insuranceData["additionalPolicyHolderData"] = {
            "consentSEE": values.gesundheitsfragen === 'ja',
            "healthy": values.gesundheitsfragen === 'ja',
            "job": values.mich.beruf
        };
        result.insuranceData["blocks4PolicyHolder"] = {
            "dynamic": values.mich.dynamik === 'ja',
            "withAssistanceBlock": (values.mich.notfall_assistent === 'ja'),
            "invalidityBlock": {
                "sumInsured": parseInt(values.mich.invaliditaet),
                "progression": parseInt(values.mich.progression)
            },
        };
        if (values.zusatzbausteinMitwirkung === true) {
            result.insuranceData.blocks4PolicyHolder["participationShare"] = 'MWA100'
        }
        if (values.mich.todesfallleistung > 0) {
            result.insuranceData.blocks4PolicyHolder["deathBlock"] = {
                "sumInsured": parseInt(values.mich.todesfallleistung)
            }
        }
        if (values.mich.krankenhaustagegeld > 0) {
            result.insuranceData.blocks4PolicyHolder["dailyHospitalBenefitBlock"] = {
                "sumInsured": parseInt(values.mich.krankenhaustagegeld)
            }
        }
        if (values.mich.uebergangsleistung > 0) {
            result.insuranceData.blocks4PolicyHolder["transitionalBenefitBlock"] = {
                "sumInsured": parseInt(values.mich.uebergangsleistung)
            }
        }
        if (values.mich.unfallrente > 0) {
            result.insuranceData.blocks4PolicyHolder["pensionBlock"] = {
                "sumInsured": parseInt(values.mich.unfallrente)
            }
        }
        if (values.mich.unfall_tagegeld > 0) {
            result.insuranceData.blocks4PolicyHolder["sicknessDailyAllowance"] = {
                "sumInsured": parseInt(values.mich.unfall_tagegeld),
                "benefitFromDay": values.mich.beginn_unfall_tagegeld
                    ? parseInt(values.mich.beginn_unfall_tagegeld)
                    : 1,
            }
        }


        if (values.mich.gipsgeld !== "0") {
            result.insuranceData.blocks4PolicyHolder["plasterMoneyBlock"] = {
                "sumInsured": parseInt(values.mich.gipsgeld)
            }
        }
    }

    /* if (result.insuranceData.blocks4PolicyHolder.sicknessDailyAllowance.sumInsured &&
         parseInt(result.insuranceData.blocks4PolicyHolder.sicknessDailyAllowance.sumInsured) > 0) {
         result.insuranceData.blocks4PolicyHolder.sicknessDailyAllowance["benefitFromDay"]
             = parseInt(values.sicknessDailyAllowance_benefitFromDay);
         insuredPersonsList.forEach(x => x.blocks.sicknessDailyAllowance["benefitFromDay"]
             = parseInt(values.sicknessDailyAllowance_benefitFromDay));
     }*/

    return result;
};
