import React, {useEffect, useState} from "react";
import {Col, Container, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {useField, useFormikContext} from "formik";

const RadioGroupInput = (props) => {
	const {switcher, checkbox, disabled, offer, item, label, options, infoButton, hideError, muiJaNeinSwitcher,className, overwriteDefaultStyle} = props;

	const [field, meta, form] = useField(item);

	const formikContext = useFormikContext();

	const items = item.split('.');

	const [component, setComponent] = useState(null);

	useEffect(() => {

		if (switcher) {
			setComponent(
					<>
						<Form.Check
								type="switch"
								id={item + "-switcher" + '-' + offer}
								style={{backgroundColor:'transparent', border:'2px solid #ececec', ...overwriteDefaultStyle}}
								label={label}
								className={className}
								name={item}
								value={meta.value}
								checked={meta.value}
								onChange={field.onChange}
								onBlur={field.onBlur}
								isInvalid={meta.error && meta.touched && true}
								disabled={disabled}
						/>
						{infoButton && <OverlayTrigger
								placement='auto'
								overlay={
									<Tooltip id={'tooltip-gibsgeld'}><div dangerouslySetInnerHTML={{__html: infoButton}}></div></Tooltip>
								}
						>
							<span className='ar-btn ar-icon-info' style={{position: 'absolute',top: '15px', right: '30px'}}/>
						</OverlayTrigger>}
					</>
			)
		}
		else if (checkbox) {
			setComponent(
					<Form.Check
							id={item + "-checkbox" + '-' + offer}
							label={label}
							style={{...overwriteDefaultStyle}}
							name={item}
							value={meta.value}
							checked={meta.value}
							onChange={field.onChange}
							onBlur={field.onBlur}
							isInvalid={meta.error && meta.touched && true}
							disabled={disabled}
					/>
			)
		}
		else {


			setComponent(
					<React.Fragment>
						<Container>
							<Row>
								{options.map((o, i) => (
										<Col onClick={() => form.setValue(o.value)} xs={6} style={i + 1 === options.length ? {paddingLeft: '0'} : {}}
											  key={o.value}>
											<Form.Check type="radio"
															id={`${offer + items + o.value}`}
															name={item}
															style={(items?.length === 1) ? o.value?.toString()
																									 === formikContext.values[item]?.toString()
																			? {border: '2px solid #37afc9'} : {border: '2px solid #ececec'}
																	: {border: '2px solid #ececec'}}
															value={o.value}
															checked={(items?.length === 1) ?
																	o.value === formikContext.values[item] :
																	o.value === formikContext.values[items[0]]?.[items[1]]}
															label={o.label}
															onChange={event => muiJaNeinSwitcher ? form.setValue(event.target.value === "true") : field.onChange(event)}
															onBlur={field.onBlur}
															onClick={field.onBlur}
															custom inline
															disabled={disabled}
											/>
										</Col>
								))}
							</Row>
						</Container>

					</React.Fragment>
			)
		}
	}, [meta.value, disabled]);


	return (
			<React.Fragment>
				<Form.Group as={Row} md="2" controlId={"formField-" + item}>
					{(!checkbox && label) && <Form.Label column md={4}>
						{label}
						{infoButton && <OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id={'tooltip-gibsgeld'}>
										<div dangerouslySetInnerHTML={{__html: infoButton}}></div>
									</Tooltip>
								}
						>
							<span className='ar-btn ar-icon-info'/>
						</OverlayTrigger>}
					</Form.Label>}
					<Col md={8}>
						{component}
						{/*<Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>*/}
						{
								!hideError &&
								<>
									{meta.error && meta.touched && true &&
									 <small style={{color: '#dc3545'}}>
										 <br/>{meta.error}
									 </small>
									}
								</>
						}
					</Col>
				</Form.Group>
			</React.Fragment>

	)
};

export default RadioGroupInput;
