const baseURL = process.env.NODE_ENV === "development" && process.env.REACT_APP_BACKEND_URL
		? process.env.REACT_APP_BACKEND_URL : window.env.REACT_APP_BACKEND_URL;

const initParams = (jsonBody) => {
	return (
			{
				method: 'POST',
				//				mode: 'cors',
				headers: {
					'Accept': '*/*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(jsonBody)
			})
};

class APIService {
	getQuote(offerURL, bodyRequest) {
		const url = baseURL + '/tariffing/' + offerURL;

		return fetch(url, initParams(bodyRequest))
				.then(async res => {
					if (res.status === 406) {
						let error_406 = await res.text();
						return {
							errorText: error_406,
							status: res.status,
						};
					}
					else {
						return res.json();
					}
				});
	}

	setOrder(offerURL, bodyRequest) {
		const url = baseURL + '/createOrder/' + offerURL;

		return fetch(url, initParams(bodyRequest))
				.then(async res => {
					if (res.status === 406) {
						let error_406 = await res.text();
						return {
							errorText: error_406,
							status: res.status,
						};
					}
					else {
						return res.json();
					}
				});
	}
}

export default new APIService();
