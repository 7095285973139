export default
[
    {
        "label": "Meister/in Schneidwerkzeugmechanikerhandwerk",
        "value": "Meister/in Schneidwerkzeugmechanikerhandwerk"
    },
    {
        "label": "Abbrucharbeiter/in",
        "value": "Abbrucharbeiter/in"
    },
    {
        "label": "Abfallbeseitiger/in",
        "value": "Abfallbeseitiger/in"
    },
    {
        "label": "Abfalltechniker/in",
        "value": "Abfalltechniker/in"
    },
    {
        "label": "Abfüller/in",
        "value": "Abfüller/in"
    },
    {
        "label": "Abgeordnete/r",
        "value": "Abgeordnete/r"
    },
    {
        "label": "Abfüllmaschineneinsteller/in",
        "value": "Abfüllmaschineneinsteller/in"
    },
    {
        "label": "Absatzfachkraft",
        "value": "Absatzfachkraft"
    },
    {
        "label": "Abschleppwagenfahrer/in",
        "value": "Abschleppwagenfahrer/in"
    },
    {
        "label": "Abteilungsleiter/in",
        "value": "Abteilungsleiter/in"
    },
    {
        "label": "Abteilungsleiter/in Bauzeichenbüro",
        "value": "Abteilungsleiter/in Bauzeichenbüro"
    },
    {
        "label": "Abteilungsmeister/in",
        "value": "Abteilungsmeister/in"
    },
    {
        "label": "Abwassertechniker/in",
        "value": "Abwassertechniker/in"
    },
    {
        "label": "Abwassermeister/in",
        "value": "Abwassermeister/in"
    },
    {
        "label": "Wasserwirtschaftsingenieur/in (FS) - Wasserversorgung/Abwasser",
        "value": "Wasserwirtschaftsingenieur/in (FS) - Wasserversorgung/Abwasser"
    },
    {
        "label": "Abwicklungsingenieur/in",
        "value": "Abwicklungsingenieur/in"
    },
    {
        "label": "Accounting Manager/in",
        "value": "Accounting Manager/in"
    },
    {
        "label": "Straßenwachtfahrer/in",
        "value": "Straßenwachtfahrer/in"
    },
    {
        "label": "Administrator/in (Verwaltung)",
        "value": "Administrator/in (Verwaltung)"
    },
    {
        "label": "Adremadrucker/in",
        "value": "Adremadrucker/in"
    },
    {
        "label": "Agenturinhaber/in (Versandhandel)",
        "value": "Agenturinhaber/in (Versandhandel)"
    },
    {
        "label": "Agrarbiologe/-biologin",
        "value": "Agrarbiologe/-biologin"
    },
    {
        "label": "Agraringenieur/in",
        "value": "Agraringenieur/in"
    },
    {
        "label": "Agrartechniker/in",
        "value": "Agrartechniker/in"
    },
    {
        "label": "Agrarwissenschaftler/in",
        "value": "Agrarwissenschaftler/in"
    },
    {
        "label": "Agrotechniker/in/Mechanisator/in",
        "value": "Agrotechniker/in/Mechanisator/in"
    },
    {
        "label": "Bandarbeiter/in (Fließband)",
        "value": "Bandarbeiter/in (Fließband)"
    },
    {
        "label": "Akquisiteur/in (Vertrieb)",
        "value": "Akquisiteur/in (Vertrieb)"
    },
    {
        "label": "Akrobat/in",
        "value": "Akrobat/in"
    },
    {
        "label": "Aktuar/in",
        "value": "Aktuar/in"
    },
    {
        "label": "Akustikmonteur/in",
        "value": "Akustikmonteur/in"
    },
    {
        "label": "Alarmanlagenmonteur/in",
        "value": "Alarmanlagenmonteur/in"
    },
    {
        "label": "Allgemeinarzt/-ärztin",
        "value": "Allgemeinarzt/-ärztin"
    },
    {
        "label": "Altbausanierer/in",
        "value": "Altbausanierer/in"
    },
    {
        "label": "Altenpfleger/in",
        "value": "Altenpfleger/in"
    },
    {
        "label": "Altenpflegehelfer/in",
        "value": "Altenpflegehelfer/in"
    },
    {
        "label": "Altentherapeut/in",
        "value": "Altentherapeut/in"
    },
    {
        "label": "Gebrauchtwarenhändler/in",
        "value": "Gebrauchtwarenhändler/in"
    },
    {
        "label": "Ambulante/r Händler/in",
        "value": "Ambulante/r Händler/in"
    },
    {
        "label": "Beamter/Beamtin Allgemeine Innere Verwaltung (geh. Dienst)",
        "value": "Beamter/Beamtin Allgemeine Innere Verwaltung (geh. Dienst)"
    },
    {
        "label": "Anwalt/Anwältin",
        "value": "Anwalt/Anwältin"
    },
    {
        "label": "Amtsbote/-botin",
        "value": "Amtsbote/-botin"
    },
    {
        "label": "Amtsleiter/in",
        "value": "Amtsleiter/in"
    },
    {
        "label": "Tierarzt/-ärztin",
        "value": "Tierarzt/-ärztin"
    },
    {
        "label": "Analyst/in (Finanzen)",
        "value": "Analyst/in (Finanzen)"
    },
    {
        "label": "EDV-Analytiker/in",
        "value": "EDV-Analytiker/in"
    },
    {
        "label": "Anästhesist/in",
        "value": "Anästhesist/in"
    },
    {
        "label": "Anatomische/r Assistent/in",
        "value": "Anatomische/r Assistent/in"
    },
    {
        "label": "Handlungsreisende/r",
        "value": "Handlungsreisende/r"
    },
    {
        "label": "Angebotssachbearbeiter/in (Kalkulator/in)",
        "value": "Angebotssachbearbeiter/in (Kalkulator/in)"
    },
    {
        "label": "Änderungsnäher/in",
        "value": "Änderungsnäher/in"
    },
    {
        "label": "Änderungsschneider/in",
        "value": "Änderungsschneider/in"
    },
    {
        "label": "Bundeswehrsoldat/in",
        "value": "Bundeswehrsoldat/in"
    },
    {
        "label": "Kaufmännische/r Angestellte/r",
        "value": "Kaufmännische/r Angestellte/r"
    },
    {
        "label": "Kaufmännische EDV-Fachkraft",
        "value": "Kaufmännische EDV-Fachkraft"
    },
    {
        "label": "Sonstige Arbeitskraft / Arbeiter/-in",
        "value": "Sonstige Arbeitskraft / Arbeiter/-in"
    },
    {
        "label": "Beamter/Beamtin Länder (mittl. Dienst)",
        "value": "Beamter/Beamtin Länder (mittl. Dienst)"
    },
    {
        "label": "Technische/r Angestellte/r - Feingeräteelektronik",
        "value": "Technische/r Angestellte/r - Feingeräteelektronik"
    },
    {
        "label": "Animateur/in - Freizeit",
        "value": "Animateur/in - Freizeit"
    },
    {
        "label": "Anknüpfer/in",
        "value": "Anknüpfer/in"
    },
    {
        "label": "Anlageberater/in",
        "value": "Anlageberater/in"
    },
    {
        "label": "Anlagenmechaniker/in",
        "value": "Anlagenmechaniker/in"
    },
    {
        "label": "Anlagenbediener/in",
        "value": "Anlagenbediener/in"
    },
    {
        "label": "EDV-Anwendungsberater/in",
        "value": "EDV-Anwendungsberater/in"
    },
    {
        "label": "Anlagenelektroniker/in",
        "value": "Anlagenelektroniker/in"
    },
    {
        "label": "Anlagenfahrer/in - Chemiebetrieb",
        "value": "Anlagenfahrer/in - Chemiebetrieb"
    },
    {
        "label": "Anlagenführer/in",
        "value": "Anlagenführer/in"
    },
    {
        "label": "Anlagenmechaniker/in Apparatetechnik",
        "value": "Anlagenmechaniker/in Apparatetechnik"
    },
    {
        "label": "Anlagenmechaniker/in Versorgungstechnik",
        "value": "Anlagenmechaniker/in Versorgungstechnik"
    },
    {
        "label": "Anlagenmonteur/in",
        "value": "Anlagenmonteur/in"
    },
    {
        "label": "Anlagenreiniger/in",
        "value": "Anlagenreiniger/in"
    },
    {
        "label": "Betriebsschlosser/in",
        "value": "Betriebsschlosser/in"
    },
    {
        "label": "Anlagentechniker/in",
        "value": "Anlagentechniker/in"
    },
    {
        "label": "Anlagenwart/in",
        "value": "Anlagenwart/in"
    },
    {
        "label": "Anstreicher/in",
        "value": "Anstreicher/in"
    },
    {
        "label": "Antennenbauer/in",
        "value": "Antennenbauer/in"
    },
    {
        "label": "Antiquar/in",
        "value": "Antiquar/in"
    },
    {
        "label": "Antiquitätenhändler/in",
        "value": "Antiquitätenhändler/in"
    },
    {
        "label": "Antragsbearbeiter/in (Versicherung)",
        "value": "Antragsbearbeiter/in (Versicherung)"
    },
    {
        "label": "Anwalts- und Notargehilfe/-gehilfin",
        "value": "Anwalts- und Notargehilfe/-gehilfin"
    },
    {
        "label": "Anwaltssekretär/in",
        "value": "Anwaltssekretär/in"
    },
    {
        "label": "Anwendungsberater/in",
        "value": "Anwendungsberater/in"
    },
    {
        "label": "Anwendungsentwickler/in",
        "value": "Anwendungsentwickler/in"
    },
    {
        "label": "Anwendungsingenieur/in",
        "value": "Anwendungsingenieur/in"
    },
    {
        "label": "Anwendungsprogrammierer/in",
        "value": "Anwendungsprogrammierer/in"
    },
    {
        "label": "Anwendungstechniker/in",
        "value": "Anwendungstechniker/in"
    },
    {
        "label": "Anzeigenfachberater/in",
        "value": "Anzeigenfachberater/in"
    },
    {
        "label": "Apotheker/in / Pharmazeut/in",
        "value": "Apotheker/in / Pharmazeut/in"
    },
    {
        "label": "Apothekerassistent/in",
        "value": "Apothekerassistent/in"
    },
    {
        "label": "Apothekenhelfer/in",
        "value": "Apothekenhelfer/in"
    },
    {
        "label": "Apparateschlosser/in",
        "value": "Apparateschlosser/in"
    },
    {
        "label": "Applikationsingenieur/in",
        "value": "Applikationsingenieur/in"
    },
    {
        "label": "Aquarienwärter/in",
        "value": "Aquarienwärter/in"
    },
    {
        "label": "Arbeiter",
        "value": "Arbeiter"
    },
    {
        "label": "Arbeitstherapeut/in",
        "value": "Arbeitstherapeut/in"
    },
    {
        "label": "Arbeits- und Berufsberater/in",
        "value": "Arbeits- und Berufsberater/in"
    },
    {
        "label": "Arbeitserzieher/in",
        "value": "Arbeitserzieher/in"
    },
    {
        "label": "Arbeitsmedizinische/r Assistent/in",
        "value": "Arbeitsmedizinische/r Assistent/in"
    },
    {
        "label": "Arbeitspsychologe/-psychologin",
        "value": "Arbeitspsychologe/-psychologin"
    },
    {
        "label": "Arbeitssuchender/-de",
        "value": "Arbeitssuchender/-de"
    },
    {
        "label": "Arbeitsvermittler/in",
        "value": "Arbeitsvermittler/in"
    },
    {
        "label": "Arbeitsvorbereiter/in",
        "value": "Arbeitsvorbereiter/in"
    },
    {
        "label": "Archäologe/Archäologin",
        "value": "Archäologe/Archäologin"
    },
    {
        "label": "Architekt/in",
        "value": "Architekt/in"
    },
    {
        "label": "Archivar/in",
        "value": "Archivar/in"
    },
    {
        "label": "Archiv-, Bibliotheks- und Informationsassistent/in",
        "value": "Archiv-, Bibliotheks- und Informationsassistent/in"
    },
    {
        "label": "Art-Direktor/in",
        "value": "Art-Direktor/in"
    },
    {
        "label": "Artist/in",
        "value": "Artist/in"
    },
    {
        "label": "Arzt/Ärztin",
        "value": "Arzt/Ärztin"
    },
    {
        "label": "Chirurg/in",
        "value": "Chirurg/in"
    },
    {
        "label": "Arzt/Ärztin Allgemeinmedizin",
        "value": "Arzt/Ärztin Allgemeinmedizin"
    },
    {
        "label": "Orthopäde/Orthopädin",
        "value": "Orthopäde/Orthopädin"
    },
    {
        "label": "Arzt/Ärztin Chirurgie",
        "value": "Arzt/Ärztin Chirurgie"
    },
    {
        "label": "Veterinär/in",
        "value": "Veterinär/in"
    },
    {
        "label": "Arzt/Ärztin Kieferchirurgie",
        "value": "Arzt/Ärztin Kieferchirurgie"
    },
    {
        "label": "Arzthelfer/in",
        "value": "Arzthelfer/in"
    },
    {
        "label": "Arztsekretär/in",
        "value": "Arztsekretär/in"
    },
    {
        "label": "Asphaltbauer/in",
        "value": "Asphaltbauer/in"
    },
    {
        "label": "Asphalteur/in",
        "value": "Asphalteur/in"
    },
    {
        "label": "Assekuranzmakler/in",
        "value": "Assekuranzmakler/in"
    },
    {
        "label": "Rechtsassessor/in",
        "value": "Rechtsassessor/in"
    },
    {
        "label": "Assistent/in Vertrieb",
        "value": "Assistent/in Vertrieb"
    },
    {
        "label": "Assistent/in Automatisierungs- und Computertechnik",
        "value": "Assistent/in Automatisierungs- und Computertechnik"
    },
    {
        "label": "Assistent/in Automatisierungstechnik",
        "value": "Assistent/in Automatisierungstechnik"
    },
    {
        "label": "Assistent/in betriebswirtschaftlich",
        "value": "Assistent/in betriebswirtschaftlich"
    },
    {
        "label": "Assistent/in Geschäftsleitung",
        "value": "Assistent/in Geschäftsleitung"
    },
    {
        "label": "Assistent/in Informatik",
        "value": "Assistent/in Informatik"
    },
    {
        "label": "Ingenieurassistent/in Bautechnik",
        "value": "Ingenieurassistent/in Bautechnik"
    },
    {
        "label": "Assistent/in Elektrotechnik",
        "value": "Assistent/in Elektrotechnik"
    },
    {
        "label": "Assistent/in Maschinenbautechnik",
        "value": "Assistent/in Maschinenbautechnik"
    },
    {
        "label": "Assistent/in Innenarchitektur",
        "value": "Assistent/in Innenarchitektur"
    },
    {
        "label": "Kaufmännische/r Assistent/in",
        "value": "Kaufmännische/r Assistent/in"
    },
    {
        "label": "Assistent/in Medizinisch-technisches Laboratorium",
        "value": "Assistent/in Medizinisch-technisches Laboratorium"
    },
    {
        "label": "Assistent/in Radiologie",
        "value": "Assistent/in Radiologie"
    },
    {
        "label": "Assistent/in Medientechnik",
        "value": "Assistent/in Medientechnik"
    },
    {
        "label": "Pädagogische/r Assistent/in",
        "value": "Pädagogische/r Assistent/in"
    },
    {
        "label": "Pharmazeutisch-technische/r Assistent/in",
        "value": "Pharmazeutisch-technische/r Assistent/in"
    },
    {
        "label": "Assistent/in Rettungsdienst",
        "value": "Assistent/in Rettungsdienst"
    },
    {
        "label": "Laborassistent/in Chemie",
        "value": "Laborassistent/in Chemie"
    },
    {
        "label": "Assistent/in Zytologie",
        "value": "Assistent/in Zytologie"
    },
    {
        "label": "Assistenzarzt/-ärztin",
        "value": "Assistenzarzt/-ärztin"
    },
    {
        "label": "Astronom/in",
        "value": "Astronom/in"
    },
    {
        "label": "Atomphysiker/in",
        "value": "Atomphysiker/in"
    },
    {
        "label": "Atomtechniker/in",
        "value": "Atomtechniker/in"
    },
    {
        "label": "Aufbereiter/in",
        "value": "Aufbereiter/in"
    },
    {
        "label": "Aufbereitungsmechaniker/in Naturstein",
        "value": "Aufbereitungsmechaniker/in Naturstein"
    },
    {
        "label": "Aufnahmeleiter/in - Film und Fernsehen",
        "value": "Aufnahmeleiter/in - Film und Fernsehen"
    },
    {
        "label": "Aufseher/in",
        "value": "Aufseher/in"
    },
    {
        "label": "Auftragskoodinierer/in",
        "value": "Auftragskoodinierer/in"
    },
    {
        "label": "Aufzugmonteur/in",
        "value": "Aufzugmonteur/in"
    },
    {
        "label": "Augenarzt/-ärztin",
        "value": "Augenarzt/-ärztin"
    },
    {
        "label": "Augenoptiker/in",
        "value": "Augenoptiker/in"
    },
    {
        "label": "Augenoptikermeister/in",
        "value": "Augenoptikermeister/in"
    },
    {
        "label": "Augenoptiktechniker/in",
        "value": "Augenoptiktechniker/in"
    },
    {
        "label": "Ausbaufacharbeiter/in",
        "value": "Ausbaufacharbeiter/in"
    },
    {
        "label": "Ausbilder/in",
        "value": "Ausbilder/in"
    },
    {
        "label": "Ausbildungsleiter/in",
        "value": "Ausbildungsleiter/in"
    },
    {
        "label": "Ausbilder/in Gewerbliche Wirtschaft",
        "value": "Ausbilder/in Gewerbliche Wirtschaft"
    },
    {
        "label": "Ausbildungsmeister/in",
        "value": "Ausbildungsmeister/in"
    },
    {
        "label": "Aushilfe",
        "value": "Aushilfe"
    },
    {
        "label": "Aushilfen",
        "value": "Aushilfen"
    },
    {
        "label": "Auslieferungsfahrer/in (nicht Verkaufsfahrer/in)",
        "value": "Auslieferungsfahrer/in (nicht Verkaufsfahrer/in)"
    },
    {
        "label": "Außendienstmitarbeiter/in",
        "value": "Außendienstmitarbeiter/in"
    },
    {
        "label": "Außendienstmonteur/in",
        "value": "Außendienstmonteur/in"
    },
    {
        "label": "Außendiensttechniker/in",
        "value": "Außendiensttechniker/in"
    },
    {
        "label": "Verkäufer/in",
        "value": "Verkäufer/in"
    },
    {
        "label": "Außenhandelskaufmann/-kauffrau",
        "value": "Außenhandelskaufmann/-kauffrau"
    },
    {
        "label": "Ausstellungsgestalter/in",
        "value": "Ausstellungsgestalter/in"
    },
    {
        "label": "Auszubildende,r (handwerklich)",
        "value": "Auszubildende,r (handwerklich)"
    },
    {
        "label": "Autoelektriker/in",
        "value": "Autoelektriker/in"
    },
    {
        "label": "Autoglaser/in",
        "value": "Autoglaser/in"
    },
    {
        "label": "Autohändler/in",
        "value": "Autohändler/in"
    },
    {
        "label": "Autokranführer/in",
        "value": "Autokranführer/in"
    },
    {
        "label": "Autolackierer/in",
        "value": "Autolackierer/in"
    },
    {
        "label": "Autolackierermeister/in",
        "value": "Autolackierermeister/in"
    },
    {
        "label": "Automatenaufsteller/in (Verleiher/in)",
        "value": "Automatenaufsteller/in (Verleiher/in)"
    },
    {
        "label": "Automatenfachmann/-frau",
        "value": "Automatenfachmann/-frau"
    },
    {
        "label": "Dreher/in",
        "value": "Dreher/in"
    },
    {
        "label": "Automateneinrichter/in",
        "value": "Automateneinrichter/in"
    },
    {
        "label": "Automateneinsteller/in (Feinmechanik)",
        "value": "Automateneinsteller/in (Feinmechanik)"
    },
    {
        "label": "Automatenmechaniker/in",
        "value": "Automatenmechaniker/in"
    },
    {
        "label": "Automatisierungsingenieur/in",
        "value": "Automatisierungsingenieur/in"
    },
    {
        "label": "Automatisierungstechniker/in (Elektrotechnik)",
        "value": "Automatisierungstechniker/in (Elektrotechnik)"
    },
    {
        "label": "Automechaniker/in",
        "value": "Automechaniker/in"
    },
    {
        "label": "Fahrzeugbauer/in",
        "value": "Fahrzeugbauer/in"
    },
    {
        "label": "Automobilkaufmann/-frau",
        "value": "Automobilkaufmann/-frau"
    },
    {
        "label": "Automobilmechaniker/in",
        "value": "Automobilmechaniker/in"
    },
    {
        "label": "Automobilverkäufer/in",
        "value": "Automobilverkäufer/in"
    },
    {
        "label": "Autopfleger/in",
        "value": "Autopfleger/in"
    },
    {
        "label": "Autor/in (Schriftsteller/in)",
        "value": "Autor/in (Schriftsteller/in)"
    },
    {
        "label": "Autosattler/in",
        "value": "Autosattler/in"
    },
    {
        "label": "Autovermieter/in",
        "value": "Autovermieter/in"
    },
    {
        "label": "Fahrzeugwaschanlagenbediener/in",
        "value": "Fahrzeugwaschanlagenbediener/in"
    },
    {
        "label": "Autowäscher/in",
        "value": "Autowäscher/in"
    },
    {
        "label": "Azubi (handwerklich)",
        "value": "Azubi (handwerklich)"
    },
    {
        "label": "Bäcker/in",
        "value": "Bäcker/in"
    },
    {
        "label": "Konditor/in und Bäcker/in",
        "value": "Konditor/in und Bäcker/in"
    },
    {
        "label": "Bäcker- und Konditormeister/in",
        "value": "Bäcker- und Konditormeister/in"
    },
    {
        "label": "Bäckereifachverkäufer/in",
        "value": "Bäckereifachverkäufer/in"
    },
    {
        "label": "Bäckergehilfe/-gehilfin",
        "value": "Bäckergehilfe/-gehilfin"
    },
    {
        "label": "Bäckerhelfer/in",
        "value": "Bäckerhelfer/in"
    },
    {
        "label": "Bäckereimaschinenführer/in",
        "value": "Bäckereimaschinenführer/in"
    },
    {
        "label": "Bäckereiverkäufer/in",
        "value": "Bäckereiverkäufer/in"
    },
    {
        "label": "Bäckermeister/in",
        "value": "Bäckermeister/in"
    },
    {
        "label": "Backwarenhersteller/in",
        "value": "Backwarenhersteller/in"
    },
    {
        "label": "Backwarenverkäufer/in",
        "value": "Backwarenverkäufer/in"
    },
    {
        "label": "Bademeister/in",
        "value": "Bademeister/in"
    },
    {
        "label": "Badewärter/in (Schwimmbad)",
        "value": "Badewärter/in (Schwimmbad)"
    },
    {
        "label": "Baggerfahrer/in",
        "value": "Baggerfahrer/in"
    },
    {
        "label": "Baggerführer/in",
        "value": "Baggerführer/in"
    },
    {
        "label": "Bahnbeamter/-beamtin (geh. techn. Dienst)",
        "value": "Bahnbeamter/-beamtin (geh. techn. Dienst)"
    },
    {
        "label": "Facharbeiter/in Eisenbahnbetrieb",
        "value": "Facharbeiter/in Eisenbahnbetrieb"
    },
    {
        "label": "Bahnbetriebsschlosser/in",
        "value": "Bahnbetriebsschlosser/in"
    },
    {
        "label": "Balletttänzer/in",
        "value": "Balletttänzer/in"
    },
    {
        "label": "Bandagist/in",
        "value": "Bandagist/in"
    },
    {
        "label": "Bandaufseher/in",
        "value": "Bandaufseher/in"
    },
    {
        "label": "Bandmeister/in",
        "value": "Bandmeister/in"
    },
    {
        "label": "Bankfachmann/-frau",
        "value": "Bankfachmann/-frau"
    },
    {
        "label": "Bankbeamter/-beamtin (mittl. Dienst)",
        "value": "Bankbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Bankbetriebswirt/in (HS)",
        "value": "Bankbetriebswirt/in (HS)"
    },
    {
        "label": "Bankdirektor/in",
        "value": "Bankdirektor/in"
    },
    {
        "label": "Bankfachwirt/in",
        "value": "Bankfachwirt/in"
    },
    {
        "label": "Bankkaufmann/-frau",
        "value": "Bankkaufmann/-frau"
    },
    {
        "label": "Bankrevisor/in",
        "value": "Bankrevisor/in"
    },
    {
        "label": "Bankmitarbeiter/in",
        "value": "Bankmitarbeiter/in"
    },
    {
        "label": "Barkeeper/in",
        "value": "Barkeeper/in"
    },
    {
        "label": "Barmixer/in",
        "value": "Barmixer/in"
    },
    {
        "label": "Bauarbeiter/in",
        "value": "Bauarbeiter/in"
    },
    {
        "label": "Bauaufseher/in (Baukontrolle)",
        "value": "Bauaufseher/in (Baukontrolle)"
    },
    {
        "label": "Bautechnische/r Berater/in",
        "value": "Bautechnische/r Berater/in"
    },
    {
        "label": "Monteur/in",
        "value": "Monteur/in"
    },
    {
        "label": "Bauer/Bäuerin",
        "value": "Bauer/Bäuerin"
    },
    {
        "label": "Baufacharbeiter/in Ausbau",
        "value": "Baufacharbeiter/in Ausbau"
    },
    {
        "label": "Baufachwerker/in Ausbau (§66 BBiG/§42m HwO)",
        "value": "Baufachwerker/in Ausbau (§66 BBiG/§42m HwO)"
    },
    {
        "label": "Bauführer/in",
        "value": "Bauführer/in"
    },
    {
        "label": "Baugeräteführer/in",
        "value": "Baugeräteführer/in"
    },
    {
        "label": "Bauglaser/in",
        "value": "Bauglaser/in"
    },
    {
        "label": "Baugerätehandwerker/in",
        "value": "Baugerätehandwerker/in"
    },
    {
        "label": "Bauhelfer/in",
        "value": "Bauhelfer/in"
    },
    {
        "label": "Bauhilfsarbeiter/in",
        "value": "Bauhilfsarbeiter/in"
    },
    {
        "label": "Bauhofarbeiter/in",
        "value": "Bauhofarbeiter/in"
    },
    {
        "label": "Bauhofverwalter/in",
        "value": "Bauhofverwalter/in"
    },
    {
        "label": "Bauingenieur/in",
        "value": "Bauingenieur/in"
    },
    {
        "label": "Bauklempner/in",
        "value": "Bauklempner/in"
    },
    {
        "label": "Baukletterer/-kletterin",
        "value": "Baukletterer/-kletterin"
    },
    {
        "label": "Baukontrolleur/in (Sicherheitskontrolleur/in)",
        "value": "Baukontrolleur/in (Sicherheitskontrolleur/in)"
    },
    {
        "label": "Baukranführer/in",
        "value": "Baukranführer/in"
    },
    {
        "label": "Bauleiter/in",
        "value": "Bauleiter/in"
    },
    {
        "label": "Baumarktverkäufer/in",
        "value": "Baumarktverkäufer/in"
    },
    {
        "label": "Baumaschinenführer/in",
        "value": "Baumaschinenführer/in"
    },
    {
        "label": "Baumaschinenmechaniker/in",
        "value": "Baumaschinenmechaniker/in"
    },
    {
        "label": "Baumaschinenmeister/in",
        "value": "Baumaschinenmeister/in"
    },
    {
        "label": "Baumaschinenschlosser/in",
        "value": "Baumaschinenschlosser/in"
    },
    {
        "label": "Baumaschinenpolier/in",
        "value": "Baumaschinenpolier/in"
    },
    {
        "label": "Baumaschinist/in",
        "value": "Baumaschinist/in"
    },
    {
        "label": "Baumgärtner/in",
        "value": "Baumgärtner/in"
    },
    {
        "label": "Baumeister/in",
        "value": "Baumeister/in"
    },
    {
        "label": "Baumpfleger/in",
        "value": "Baumpfleger/in"
    },
    {
        "label": "Baumschulenarbeiter/in",
        "value": "Baumschulenarbeiter/in"
    },
    {
        "label": "Baumschulgärtner/in",
        "value": "Baumschulgärtner/in"
    },
    {
        "label": "Baufacharbeiter/in Fliesen-, Platten-, Mosaiklegen",
        "value": "Baufacharbeiter/in Fliesen-, Platten-, Mosaiklegen"
    },
    {
        "label": "Baupolier/in",
        "value": "Baupolier/in"
    },
    {
        "label": "Bausachverständige/r",
        "value": "Bausachverständige/r"
    },
    {
        "label": "Bauschlosser/in",
        "value": "Bauschlosser/in"
    },
    {
        "label": "Bauschreiner/in",
        "value": "Bauschreiner/in"
    },
    {
        "label": "Bausparkassenberater/in",
        "value": "Bausparkassenberater/in"
    },
    {
        "label": "Bausparkassenfachmann/-frau",
        "value": "Bausparkassenfachmann/-frau"
    },
    {
        "label": "Bausparkassensachbearbeiter/in",
        "value": "Bausparkassensachbearbeiter/in"
    },
    {
        "label": "Statiker/in",
        "value": "Statiker/in"
    },
    {
        "label": "Baustellenaufseher/in",
        "value": "Baustellenaufseher/in"
    },
    {
        "label": "Baustellenleiter/in",
        "value": "Baustellenleiter/in"
    },
    {
        "label": "Baustellentechniker/in",
        "value": "Baustellentechniker/in"
    },
    {
        "label": "Baustofffachberater/in",
        "value": "Baustofffachberater/in"
    },
    {
        "label": "Baustoffkaufmann/-frau (Einzelhandel)",
        "value": "Baustoffkaufmann/-frau (Einzelhandel)"
    },
    {
        "label": "Baustoffprüfer/in",
        "value": "Baustoffprüfer/in"
    },
    {
        "label": "Bautechniker/in",
        "value": "Bautechniker/in"
    },
    {
        "label": "Bautischler/in",
        "value": "Bautischler/in"
    },
    {
        "label": "Bauunternehmer/in",
        "value": "Bauunternehmer/in"
    },
    {
        "label": "Bauwerksabdichter/in",
        "value": "Bauwerksabdichter/in"
    },
    {
        "label": "Bauzeichner/in",
        "value": "Bauzeichner/in"
    },
    {
        "label": "Bauzimmerer/-zimmerin",
        "value": "Bauzimmerer/-zimmerin"
    },
    {
        "label": "Grenzpolizist/in",
        "value": "Grenzpolizist/in"
    },
    {
        "label": "Beamter/Beamtin Allgemeine Innere Verwaltung (mittl. Dienst)",
        "value": "Beamter/Beamtin Allgemeine Innere Verwaltung (mittl. Dienst)"
    },
    {
        "label": "Berater/in Außendienst",
        "value": "Berater/in Außendienst"
    },
    {
        "label": "Berufsberater/in",
        "value": "Berufsberater/in"
    },
    {
        "label": "Beamter/Beamtin Bahnwesen (geh. techn. Dienst)",
        "value": "Beamter/Beamtin Bahnwesen (geh. techn. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Bundesbank (mittl. Dienst)",
        "value": "Beamter/Beamtin Bundesbank (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Bundeswehrverwaltung (mittl. techn. Dienst)",
        "value": "Beamter/Beamtin Bundeswehrverwaltung (mittl. techn. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Feuerwehr (mittl. techn. Dienst)",
        "value": "Beamter/Beamtin Feuerwehr (mittl. techn. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Gewerbeaufsichtsverwaltung (mittl. techn. Dienst)",
        "value": "Beamter/Beamtin Gewerbeaufsichtsverwaltung (mittl. techn. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Justizdienst (geh. Dienst)",
        "value": "Beamter/Beamtin Justizdienst (geh. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Justizvollzugsdienst (mittl. Dienst)",
        "value": "Beamter/Beamtin Justizvollzugsdienst (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Kommunalverwaltung (mittl. Dienst)",
        "value": "Beamter/Beamtin Kommunalverwaltung (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Kriminaldienst (mittl. Dienst)",
        "value": "Beamter/Beamtin Kriminaldienst (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin wissenschaftliche Bibliotheken (höh. Dienst)",
        "value": "Beamter/Beamtin wissenschaftliche Bibliotheken (höh. Dienst)"
    },
    {
        "label": "Polizeibeamter/-beamtin (mittl. Dienst)",
        "value": "Polizeibeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Polizeivollzugsdienst (mittl. Dienst)",
        "value": "Beamter/Beamtin Polizeivollzugsdienst (mittl. Dienst)"
    },
    {
        "label": "Postbeamter/-beamtin (einf. techn. Dienst)",
        "value": "Postbeamter/-beamtin (einf. techn. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Postdienst (geh. techn. Dienst)",
        "value": "Beamter/Beamtin Postdienst (geh. techn. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Sozialverwaltung (mittl. Dienst)",
        "value": "Beamter/Beamtin Sozialverwaltung (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Steuerverwaltung (mittl. Dienst)",
        "value": "Beamter/Beamtin Steuerverwaltung (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Bund (mittl. Dienst)",
        "value": "Beamter/Beamtin Bund (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Behörden der Länder (mittl. Dienst)",
        "value": "Beamter/Beamtin Behörden der Länder (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Wasser-, Schifffahrtsverwaltung (geh. Dienst)",
        "value": "Beamter/Beamtin Wasser-, Schifffahrtsverwaltung (geh. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Zolldienst (geh. Dienst)",
        "value": "Beamter/Beamtin Zolldienst (geh. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Zolldienst (mittl. Dienst)",
        "value": "Beamter/Beamtin Zolldienst (mittl. Dienst)"
    },
    {
        "label": "Flugzeugführer/in",
        "value": "Flugzeugführer/in"
    },
    {
        "label": "Beamter/Beamtin Archivdienst (mittl. Dienst)",
        "value": "Beamter/Beamtin Archivdienst (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Auswärtiger Dienst (mittl. Dienst)",
        "value": "Beamter/Beamtin Auswärtiger Dienst (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Bergverwaltung (mittl. Dienst)",
        "value": "Beamter/Beamtin Bergverwaltung (mittl. Dienst)"
    },
    {
        "label": "Bedienung (Restaurant)",
        "value": "Bedienung (Restaurant)"
    },
    {
        "label": "Beerdigungsgehilfe/-gehilfin",
        "value": "Beerdigungsgehilfe/-gehilfin"
    },
    {
        "label": "Behälterbauer/in",
        "value": "Behälterbauer/in"
    },
    {
        "label": "Behälterreiniger/in",
        "value": "Behälterreiniger/in"
    },
    {
        "label": "Behindertensozialpädagoge/-pädagogin",
        "value": "Behindertensozialpädagoge/-pädagogin"
    },
    {
        "label": "Behindertenpfleger/in",
        "value": "Behindertenpfleger/in"
    },
    {
        "label": "Beifahrer/in (Kraftfahrer)",
        "value": "Beifahrer/in (Kraftfahrer)"
    },
    {
        "label": "Beigeordnete/r",
        "value": "Beigeordnete/r"
    },
    {
        "label": "Beikoch/-köchin",
        "value": "Beikoch/-köchin"
    },
    {
        "label": "Beizer/in Holz",
        "value": "Beizer/in Holz"
    },
    {
        "label": "Bekleidungsingenieur/in",
        "value": "Bekleidungsingenieur/in"
    },
    {
        "label": "Bekleidungsschneider/in",
        "value": "Bekleidungsschneider/in"
    },
    {
        "label": "Bekleidungstechniker/in",
        "value": "Bekleidungstechniker/in"
    },
    {
        "label": "Bekleidungstechnische/r Assistent/in",
        "value": "Bekleidungstechnische/r Assistent/in"
    },
    {
        "label": "Bekleidungsteilenäher/in",
        "value": "Bekleidungsteilenäher/in"
    },
    {
        "label": "Belader/in",
        "value": "Belader/in"
    },
    {
        "label": "Beleuchter/in",
        "value": "Beleuchter/in"
    },
    {
        "label": "Beleuchtungsmeister/in",
        "value": "Beleuchtungsmeister/in"
    },
    {
        "label": "Beratende/r Ingenieur/in",
        "value": "Beratende/r Ingenieur/in"
    },
    {
        "label": "EDV-Berater/in",
        "value": "EDV-Berater/in"
    },
    {
        "label": "Planungsberater/in",
        "value": "Planungsberater/in"
    },
    {
        "label": "Beratungslehrer/in",
        "value": "Beratungslehrer/in"
    },
    {
        "label": "Beratungstechniker/in",
        "value": "Beratungstechniker/in"
    },
    {
        "label": "Berechnungsingenieur/in",
        "value": "Berechnungsingenieur/in"
    },
    {
        "label": "Bereichsleiter/in",
        "value": "Bereichsleiter/in"
    },
    {
        "label": "Bereiter/in",
        "value": "Bereiter/in"
    },
    {
        "label": "Bereitschaftspolizist/in",
        "value": "Bereitschaftspolizist/in"
    },
    {
        "label": "Berg- und Maschinenmann",
        "value": "Berg- und Maschinenmann"
    },
    {
        "label": "Bergbaufacharbeiter/in",
        "value": "Bergbaufacharbeiter/in"
    },
    {
        "label": "Bergbauingenieur/in",
        "value": "Bergbauingenieur/in"
    },
    {
        "label": "Bergbaumaschinentechniker/in",
        "value": "Bergbaumaschinentechniker/in"
    },
    {
        "label": "Helfer/in Bergbau",
        "value": "Helfer/in Bergbau"
    },
    {
        "label": "Bergbautechniker/in",
        "value": "Bergbautechniker/in"
    },
    {
        "label": "Bergführer/in",
        "value": "Bergführer/in"
    },
    {
        "label": "Bergtechniker/in",
        "value": "Bergtechniker/in"
    },
    {
        "label": "Bergvermessungstechniker/in",
        "value": "Bergvermessungstechniker/in"
    },
    {
        "label": "Berufssoldat/in",
        "value": "Berufssoldat/in"
    },
    {
        "label": "Berufssportler/in",
        "value": "Berufssportler/in"
    },
    {
        "label": "Berufskraftfahrer/in",
        "value": "Berufskraftfahrer/in"
    },
    {
        "label": "Berufsfeuerwehrmann/-frau",
        "value": "Berufsfeuerwehrmann/-frau"
    },
    {
        "label": "Berufsreit- und Fahrlehrer/in",
        "value": "Berufsreit- und Fahrlehrer/in"
    },
    {
        "label": "Berufsschullehrer/in",
        "value": "Berufsschullehrer/in"
    },
    {
        "label": "Berufstaucher/in",
        "value": "Berufstaucher/in"
    },
    {
        "label": "Besamungstechniker/in",
        "value": "Besamungstechniker/in"
    },
    {
        "label": "Beschichter/in (Oberflächenbeschichtung)",
        "value": "Beschichter/in (Oberflächenbeschichtung)"
    },
    {
        "label": "Bestattungsfachkraft",
        "value": "Bestattungsfachkraft"
    },
    {
        "label": "Bestattungsgehilfe/-gehilfin",
        "value": "Bestattungsgehilfe/-gehilfin"
    },
    {
        "label": "Bestattungsunternehmer/in",
        "value": "Bestattungsunternehmer/in"
    },
    {
        "label": "Besteckschmied/in",
        "value": "Besteckschmied/in"
    },
    {
        "label": "Bestücker/in (Elektromontage)",
        "value": "Bestücker/in (Elektromontage)"
    },
    {
        "label": "Beton- und Stahlbetonbauermeister/in",
        "value": "Beton- und Stahlbetonbauermeister/in"
    },
    {
        "label": "Beton- und Stahlbetonbauer/in",
        "value": "Beton- und Stahlbetonbauer/in"
    },
    {
        "label": "Betonbauer/in",
        "value": "Betonbauer/in"
    },
    {
        "label": "Betonfertigteilbauer/in",
        "value": "Betonfertigteilbauer/in"
    },
    {
        "label": "Betonfertigteilemontierer/in",
        "value": "Betonfertigteilemontierer/in"
    },
    {
        "label": "Betonierer/in",
        "value": "Betonierer/in"
    },
    {
        "label": "Betonmaschinist/in",
        "value": "Betonmaschinist/in"
    },
    {
        "label": "Betonpumpenmaschinist/in",
        "value": "Betonpumpenmaschinist/in"
    },
    {
        "label": "Betonsanierer/in",
        "value": "Betonsanierer/in"
    },
    {
        "label": "Betonsteinhersteller/in",
        "value": "Betonsteinhersteller/in"
    },
    {
        "label": "Straßenbauer/in",
        "value": "Straßenbauer/in"
    },
    {
        "label": "Betontechnologe/in",
        "value": "Betontechnologe/in"
    },
    {
        "label": "Betonwerker/in",
        "value": "Betonwerker/in"
    },
    {
        "label": "Betreuungshelfer/in",
        "value": "Betreuungshelfer/in"
    },
    {
        "label": "Betriebsarzt/-ärztin",
        "value": "Betriebsarzt/-ärztin"
    },
    {
        "label": "Betriebsassistent/in",
        "value": "Betriebsassistent/in"
    },
    {
        "label": "Betriebsberater/in",
        "value": "Betriebsberater/in"
    },
    {
        "label": "Betriebselektriker/in",
        "value": "Betriebselektriker/in"
    },
    {
        "label": "Betriebshandwerker/in",
        "value": "Betriebshandwerker/in"
    },
    {
        "label": "Betriebsinformatiker/in",
        "value": "Betriebsinformatiker/in"
    },
    {
        "label": "Betriebsingenieur/in",
        "value": "Betriebsingenieur/in"
    },
    {
        "label": "Betriebsinhaber/in",
        "value": "Betriebsinhaber/in"
    },
    {
        "label": "Brauereifacharbeiter/in",
        "value": "Brauereifacharbeiter/in"
    },
    {
        "label": "Kaufmännische/r Betriebsleiter/in",
        "value": "Kaufmännische/r Betriebsleiter/in"
    },
    {
        "label": "Betriebsmeister/in",
        "value": "Betriebsmeister/in"
    },
    {
        "label": "Betriebsorganisator/in",
        "value": "Betriebsorganisator/in"
    },
    {
        "label": "Betriebsprüfer/in",
        "value": "Betriebsprüfer/in"
    },
    {
        "label": "Betriebsrat/-rätin",
        "value": "Betriebsrat/-rätin"
    },
    {
        "label": "Betriebsratsvorsitzende/r",
        "value": "Betriebsratsvorsitzende/r"
    },
    {
        "label": "Betriebssanitäter/in",
        "value": "Betriebssanitäter/in"
    },
    {
        "label": "Fachkaufmann/-frau Sekretariat",
        "value": "Fachkaufmann/-frau Sekretariat"
    },
    {
        "label": "Betriebsstellenleiter/in",
        "value": "Betriebsstellenleiter/in"
    },
    {
        "label": "Betriebstechniker/in",
        "value": "Betriebstechniker/in"
    },
    {
        "label": "Betriebswirt/in (HS)",
        "value": "Betriebswirt/in (HS)"
    },
    {
        "label": "Betriebswirt/in (Weiterbildung)",
        "value": "Betriebswirt/in (Weiterbildung)"
    },
    {
        "label": "Betriebswirtschaftliche/r Assistent/in",
        "value": "Betriebswirtschaftliche/r Assistent/in"
    },
    {
        "label": "Personenschützer/in",
        "value": "Personenschützer/in"
    },
    {
        "label": "Bewährungshelfer/in",
        "value": "Bewährungshelfer/in"
    },
    {
        "label": "Bewegungstherapeut/in",
        "value": "Bewegungstherapeut/in"
    },
    {
        "label": "Bezirksdirektor/in",
        "value": "Bezirksdirektor/in"
    },
    {
        "label": "Bezirksleiter/in",
        "value": "Bezirksleiter/in"
    },
    {
        "label": "Bezirksschornsteinfeger/in",
        "value": "Bezirksschornsteinfeger/in"
    },
    {
        "label": "Bezirksschornsteinfegermeister/in",
        "value": "Bezirksschornsteinfegermeister/in"
    },
    {
        "label": "Bezirksverkaufsleiter/in",
        "value": "Bezirksverkaufsleiter/in"
    },
    {
        "label": "Bibliothekar/in",
        "value": "Bibliothekar/in"
    },
    {
        "label": "Bibliothekarassistent/in",
        "value": "Bibliothekarassistent/in"
    },
    {
        "label": "Bierbrauer/in",
        "value": "Bierbrauer/in"
    },
    {
        "label": "Bilanzbuchhalter/in",
        "value": "Bilanzbuchhalter/in"
    },
    {
        "label": "Bildberichterstatter/in",
        "value": "Bildberichterstatter/in"
    },
    {
        "label": "Bildende/r Künstler/in",
        "value": "Bildende/r Künstler/in"
    },
    {
        "label": "Bilderrahmenglaser/in",
        "value": "Bilderrahmenglaser/in"
    },
    {
        "label": "Bildhauer/in",
        "value": "Bildhauer/in"
    },
    {
        "label": "Medieningenieur/in",
        "value": "Medieningenieur/in"
    },
    {
        "label": "Bildjournalist/in",
        "value": "Bildjournalist/in"
    },
    {
        "label": "Bildmischer/in",
        "value": "Bildmischer/in"
    },
    {
        "label": "Bildtechniker/in",
        "value": "Bildtechniker/in"
    },
    {
        "label": "Bildungsberater/in",
        "value": "Bildungsberater/in"
    },
    {
        "label": "Bildungsreferent/in",
        "value": "Bildungsreferent/in"
    },
    {
        "label": "Binnenfischer/in",
        "value": "Binnenfischer/in"
    },
    {
        "label": "Binnenschiffer/in",
        "value": "Binnenschiffer/in"
    },
    {
        "label": "Biochemiker/in",
        "value": "Biochemiker/in"
    },
    {
        "label": "Bioingenieur/in",
        "value": "Bioingenieur/in"
    },
    {
        "label": "Biolaborant/in",
        "value": "Biolaborant/in"
    },
    {
        "label": "Biologe/Biologin",
        "value": "Biologe/Biologin"
    },
    {
        "label": "Biologielaborant/in",
        "value": "Biologielaborant/in"
    },
    {
        "label": "Biologiemodellmacher/in",
        "value": "Biologiemodellmacher/in"
    },
    {
        "label": "Biologisch-chemisch-technische/r Assistent/in",
        "value": "Biologisch-chemisch-technische/r Assistent/in"
    },
    {
        "label": "Biologisch-technische/r Assistent/in Biologie/Biotechnologie",
        "value": "Biologisch-technische/r Assistent/in Biologie/Biotechnologie"
    },
    {
        "label": "Biologisch-technische/r Assistent/in",
        "value": "Biologisch-technische/r Assistent/in"
    },
    {
        "label": "Biotechniker/in",
        "value": "Biotechniker/in"
    },
    {
        "label": "Biotechnologe/-technologin",
        "value": "Biotechnologe/-technologin"
    },
    {
        "label": "Blechbearbeiter/in",
        "value": "Blechbearbeiter/in"
    },
    {
        "label": "Blechbläser/in",
        "value": "Blechbläser/in"
    },
    {
        "label": "Blechkarosseriebauer/in",
        "value": "Blechkarosseriebauer/in"
    },
    {
        "label": "Blechlocher/in",
        "value": "Blechlocher/in"
    },
    {
        "label": "Blechner/in",
        "value": "Blechner/in"
    },
    {
        "label": "Blechpresser/in",
        "value": "Blechpresser/in"
    },
    {
        "label": "Blechschlosser/in",
        "value": "Blechschlosser/in"
    },
    {
        "label": "Blechstanzer/in",
        "value": "Blechstanzer/in"
    },
    {
        "label": "Blechverarbeitungstechniker/in",
        "value": "Blechverarbeitungstechniker/in"
    },
    {
        "label": "Blechzieher/in",
        "value": "Blechzieher/in"
    },
    {
        "label": "Bleigießer/in",
        "value": "Bleigießer/in"
    },
    {
        "label": "Blitzableiterbauer/in (Elektro)",
        "value": "Blitzableiterbauer/in (Elektro)"
    },
    {
        "label": "Blumenhändler/in",
        "value": "Blumenhändler/in"
    },
    {
        "label": "BMSR-Techniker/in (Betriebsmess-, Steuerungs- und Regelungstechniker/in)",
        "value": "BMSR-Techniker/in (Betriebsmess-, Steuerungs- und Regelungstechniker/in)"
    },
    {
        "label": "Bodenleger/in",
        "value": "Bodenleger/in"
    },
    {
        "label": "Bodensteward/ess",
        "value": "Bodensteward/ess"
    },
    {
        "label": "Body Guard",
        "value": "Body Guard"
    },
    {
        "label": "Bohrer/in",
        "value": "Bohrer/in"
    },
    {
        "label": "Bohrmaschinist/in",
        "value": "Bohrmaschinist/in"
    },
    {
        "label": "Bohrtechniker/in",
        "value": "Bohrtechniker/in"
    },
    {
        "label": "Bohrwerkdreher/in",
        "value": "Bohrwerkdreher/in"
    },
    {
        "label": "Bootsbauer/in",
        "value": "Bootsbauer/in"
    },
    {
        "label": "Bootsführer/in",
        "value": "Bootsführer/in"
    },
    {
        "label": "Bootsmann/-frau Seeschifffahrt",
        "value": "Bootsmann/-frau Seeschifffahrt"
    },
    {
        "label": "Bootsvermieter/in",
        "value": "Bootsvermieter/in"
    },
    {
        "label": "Bordfunker/in",
        "value": "Bordfunker/in"
    },
    {
        "label": "Börsenmakler/in",
        "value": "Börsenmakler/in"
    },
    {
        "label": "Bote/Botin",
        "value": "Bote/Botin"
    },
    {
        "label": "Botschafter/in",
        "value": "Botschafter/in"
    },
    {
        "label": "Böttcher/in",
        "value": "Böttcher/in"
    },
    {
        "label": "Feuerwehrbeamter/-beamtin (mittl. techn. Dienst)",
        "value": "Feuerwehrbeamter/-beamtin (mittl. techn. Dienst)"
    },
    {
        "label": "Feuerwehrmann/-frau (mittl. techn. Dienst)",
        "value": "Feuerwehrmann/-frau (mittl. techn. Dienst)"
    },
    {
        "label": "Brandschutzfachkraft",
        "value": "Brandschutzfachkraft"
    },
    {
        "label": "Brandschutzfachkraft (Brandschutzmonteur/in)",
        "value": "Brandschutzfachkraft (Brandschutzmonteur/in)"
    },
    {
        "label": "Brandschutzingenieur/in",
        "value": "Brandschutzingenieur/in"
    },
    {
        "label": "Brandschutzprüfer/in",
        "value": "Brandschutzprüfer/in"
    },
    {
        "label": "Brandschutztechniker/in",
        "value": "Brandschutztechniker/in"
    },
    {
        "label": "Branntsteinbrenner, -trockner, -glasierer/in",
        "value": "Branntsteinbrenner, -trockner, -glasierer/in"
    },
    {
        "label": "Brannsteinhersteller/in",
        "value": "Brannsteinhersteller/in"
    },
    {
        "label": "Brauer/in",
        "value": "Brauer/in"
    },
    {
        "label": "Brauer/in und Mälzer/in",
        "value": "Brauer/in und Mälzer/in"
    },
    {
        "label": "Brautechniker/in",
        "value": "Brautechniker/in"
    },
    {
        "label": "Brenner/in",
        "value": "Brenner/in"
    },
    {
        "label": "Brennereitechniker/in",
        "value": "Brennereitechniker/in"
    },
    {
        "label": "Brennschneider/in",
        "value": "Brennschneider/in"
    },
    {
        "label": "Briefmarkenhändler/in",
        "value": "Briefmarkenhändler/in"
    },
    {
        "label": "Briefsortierer/in",
        "value": "Briefsortierer/in"
    },
    {
        "label": "Briefträger/in",
        "value": "Briefträger/in"
    },
    {
        "label": "Briefzusteller/in",
        "value": "Briefzusteller/in"
    },
    {
        "label": "Brillenmacher/in",
        "value": "Brillenmacher/in"
    },
    {
        "label": "Brillenoptikschleifermeister/in",
        "value": "Brillenoptikschleifermeister/in"
    },
    {
        "label": "Broker/in",
        "value": "Broker/in"
    },
    {
        "label": "Meister/in Brückenbau",
        "value": "Meister/in Brückenbau"
    },
    {
        "label": "Brückenbaukonstrukteur/in",
        "value": "Brückenbaukonstrukteur/in"
    },
    {
        "label": "Brückenkranführer/in",
        "value": "Brückenkranführer/in"
    },
    {
        "label": "Brückenwärter/in",
        "value": "Brückenwärter/in"
    },
    {
        "label": "Brunnenbauer/in",
        "value": "Brunnenbauer/in"
    },
    {
        "label": "Buchhändler/in",
        "value": "Buchhändler/in"
    },
    {
        "label": "Buchbinder/in",
        "value": "Buchbinder/in"
    },
    {
        "label": "Buchbinderhelfer/in",
        "value": "Buchbinderhelfer/in"
    },
    {
        "label": "Buchdrucker/in",
        "value": "Buchdrucker/in"
    },
    {
        "label": "Buchdruckermeister/in",
        "value": "Buchdruckermeister/in"
    },
    {
        "label": "Büchereihelfer/in",
        "value": "Büchereihelfer/in"
    },
    {
        "label": "Buchhalter/in",
        "value": "Buchhalter/in"
    },
    {
        "label": "Buchprüfer/in (vereidigte/r)",
        "value": "Buchprüfer/in (vereidigte/r)"
    },
    {
        "label": "Büchsenmacher/in",
        "value": "Büchsenmacher/in"
    },
    {
        "label": "Büchsenmachermeister/in",
        "value": "Büchsenmachermeister/in"
    },
    {
        "label": "Buchverleger/in",
        "value": "Buchverleger/in"
    },
    {
        "label": "Büfettgehilfe/-gehilfin",
        "value": "Büfettgehilfe/-gehilfin"
    },
    {
        "label": "Büfettverkäufer/in",
        "value": "Büfettverkäufer/in"
    },
    {
        "label": "Bügler/in",
        "value": "Bügler/in"
    },
    {
        "label": "Bühnenarbeiter/in",
        "value": "Bühnenarbeiter/in"
    },
    {
        "label": "Bühnenausstatter/in",
        "value": "Bühnenausstatter/in"
    },
    {
        "label": "Bühnenbildner/in",
        "value": "Bühnenbildner/in"
    },
    {
        "label": "Bühnenleiter/in",
        "value": "Bühnenleiter/in"
    },
    {
        "label": "Bühnenmaler/in",
        "value": "Bühnenmaler/in"
    },
    {
        "label": "Hilfsmaschinist/in",
        "value": "Hilfsmaschinist/in"
    },
    {
        "label": "Bühnenmeister/in",
        "value": "Bühnenmeister/in"
    },
    {
        "label": "Bühnentechniker/in",
        "value": "Bühnentechniker/in"
    },
    {
        "label": "Bühnentischler/in",
        "value": "Bühnentischler/in"
    },
    {
        "label": "Bundesbankbeamter/-beamtin (mittl. Dienst)",
        "value": "Bundesbankbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Bundesfreiwilligendienst / FSJ",
        "value": "Bundesfreiwilligendienst / FSJ"
    },
    {
        "label": "Bürgermeister/in",
        "value": "Bürgermeister/in"
    },
    {
        "label": "Bürofachkraft",
        "value": "Bürofachkraft"
    },
    {
        "label": "Büroassistent/in",
        "value": "Büroassistent/in"
    },
    {
        "label": "Bürobote/Bürobotin",
        "value": "Bürobote/Bürobotin"
    },
    {
        "label": "Bürofachwirt/in",
        "value": "Bürofachwirt/in"
    },
    {
        "label": "Bürogehilfe/-gehilfin",
        "value": "Bürogehilfe/-gehilfin"
    },
    {
        "label": "Bürohelfer/in",
        "value": "Bürohelfer/in"
    },
    {
        "label": "Bürohilfskraft",
        "value": "Bürohilfskraft"
    },
    {
        "label": "Büroinformationselektroniker/in",
        "value": "Büroinformationselektroniker/in"
    },
    {
        "label": "Bürokaufmann/-frau",
        "value": "Bürokaufmann/-frau"
    },
    {
        "label": "Bürokommunikationselektroniker/in",
        "value": "Bürokommunikationselektroniker/in"
    },
    {
        "label": "Fachangestellte/r für Bürokommunikation",
        "value": "Fachangestellte/r für Bürokommunikation"
    },
    {
        "label": "Bürokraft (§66 BBiG/§42m HwO)",
        "value": "Bürokraft (§66 BBiG/§42m HwO)"
    },
    {
        "label": "Büroleiter/in",
        "value": "Büroleiter/in"
    },
    {
        "label": "Büromaschinenmechaniker/in",
        "value": "Büromaschinenmechaniker/in"
    },
    {
        "label": "Büroorganisator/in",
        "value": "Büroorganisator/in"
    },
    {
        "label": "Bürstenmacher/in",
        "value": "Bürstenmacher/in"
    },
    {
        "label": "Busfahrer/in",
        "value": "Busfahrer/in"
    },
    {
        "label": "CAD-Bauzeichner/in",
        "value": "CAD-Bauzeichner/in"
    },
    {
        "label": "CAD-Designer/in",
        "value": "CAD-Designer/in"
    },
    {
        "label": "CAD-Konstrukteur/in",
        "value": "CAD-Konstrukteur/in"
    },
    {
        "label": "CAD-Zeichner/in",
        "value": "CAD-Zeichner/in"
    },
    {
        "label": "Cafebesitzer/in",
        "value": "Cafebesitzer/in"
    },
    {
        "label": "Kantinenbetriebsleiter/in",
        "value": "Kantinenbetriebsleiter/in"
    },
    {
        "label": "Call-Center-Agent/in",
        "value": "Call-Center-Agent/in"
    },
    {
        "label": "Campingplatzwart/in",
        "value": "Campingplatzwart/in"
    },
    {
        "label": "Caterer/Caterin",
        "value": "Caterer/Caterin"
    },
    {
        "label": "Catering-Manager/in",
        "value": "Catering-Manager/in"
    },
    {
        "label": "Cellist/in",
        "value": "Cellist/in"
    },
    {
        "label": "Chauffeur/in",
        "value": "Chauffeur/in"
    },
    {
        "label": "Chef de la cuisine",
        "value": "Chef de la cuisine"
    },
    {
        "label": "Chef de rang",
        "value": "Chef de rang"
    },
    {
        "label": "Chefarzt/-ärztin",
        "value": "Chefarzt/-ärztin"
    },
    {
        "label": "Chefassistent/in",
        "value": "Chefassistent/in"
    },
    {
        "label": "Chefkoch/-köchin",
        "value": "Chefkoch/-köchin"
    },
    {
        "label": "Chefredakteur/in",
        "value": "Chefredakteur/in"
    },
    {
        "label": "Chefsekretär/in",
        "value": "Chefsekretär/in"
    },
    {
        "label": "Chefsteward/-stewardess",
        "value": "Chefsteward/-stewardess"
    },
    {
        "label": "Chemisch-technische/r Assistent/in",
        "value": "Chemisch-technische/r Assistent/in"
    },
    {
        "label": "Chemiearbeiter/in",
        "value": "Chemiearbeiter/in"
    },
    {
        "label": "Chemiebetriebsjungwerker/in",
        "value": "Chemiebetriebsjungwerker/in"
    },
    {
        "label": "Chemiebetriebswerker/in",
        "value": "Chemiebetriebswerker/in"
    },
    {
        "label": "Chemiefacharbeiter/in",
        "value": "Chemiefacharbeiter/in"
    },
    {
        "label": "Chemiefachwerker/in",
        "value": "Chemiefachwerker/in"
    },
    {
        "label": "Chemiefaserhersteller/in",
        "value": "Chemiefaserhersteller/in"
    },
    {
        "label": "Chemiehelfer/in",
        "value": "Chemiehelfer/in"
    },
    {
        "label": "Chemiehilfsarbeiter/in",
        "value": "Chemiehilfsarbeiter/in"
    },
    {
        "label": "Chemieingenieur/in",
        "value": "Chemieingenieur/in"
    },
    {
        "label": "Chemielaborant/in",
        "value": "Chemielaborant/in"
    },
    {
        "label": "Chemielaborjungwerker/in",
        "value": "Chemielaborjungwerker/in"
    },
    {
        "label": "Chemielaborwerker/in",
        "value": "Chemielaborwerker/in"
    },
    {
        "label": "Chemietechniker/in",
        "value": "Chemietechniker/in"
    },
    {
        "label": "Chemiewerker/in",
        "value": "Chemiewerker/in"
    },
    {
        "label": "Chemikant/in",
        "value": "Chemikant/in"
    },
    {
        "label": "Chemiker/in",
        "value": "Chemiker/in"
    },
    {
        "label": "Chemischreiniger/in",
        "value": "Chemischreiniger/in"
    },
    {
        "label": "Chemotechniker/in",
        "value": "Chemotechniker/in"
    },
    {
        "label": "Chirurgiemechaniker/in",
        "value": "Chirurgiemechaniker/in"
    },
    {
        "label": "Chorleiter/in",
        "value": "Chorleiter/in"
    },
    {
        "label": "Chorsänger/in",
        "value": "Chorsänger/in"
    },
    {
        "label": "Clown/in",
        "value": "Clown/in"
    },
    {
        "label": "CNC-Fachkraft",
        "value": "CNC-Fachkraft"
    },
    {
        "label": "CNC-Dreher/in",
        "value": "CNC-Dreher/in"
    },
    {
        "label": "CNC-Maschineneinrichter/in",
        "value": "CNC-Maschineneinrichter/in"
    },
    {
        "label": "CNC-Fräser/in",
        "value": "CNC-Fräser/in"
    },
    {
        "label": "CNC-Maschinenbediener/in",
        "value": "CNC-Maschinenbediener/in"
    },
    {
        "label": "CNC-Mechaniker/in",
        "value": "CNC-Mechaniker/in"
    },
    {
        "label": "CNC-Programmierer/in",
        "value": "CNC-Programmierer/in"
    },
    {
        "label": "CNC-Schleifer/in",
        "value": "CNC-Schleifer/in"
    },
    {
        "label": "CNC-Techniker/in",
        "value": "CNC-Techniker/in"
    },
    {
        "label": "Codierer/in (EDV)",
        "value": "Codierer/in (EDV)"
    },
    {
        "label": "Computeroperator/in",
        "value": "Computeroperator/in"
    },
    {
        "label": "Computerelektroniker/in",
        "value": "Computerelektroniker/in"
    },
    {
        "label": "Computerfachmann/-frau",
        "value": "Computerfachmann/-frau"
    },
    {
        "label": "Computergrafiker/in",
        "value": "Computergrafiker/in"
    },
    {
        "label": "Computerfachhändler/in",
        "value": "Computerfachhändler/in"
    },
    {
        "label": "Sachbearbeiter/in EDV/IDV",
        "value": "Sachbearbeiter/in EDV/IDV"
    },
    {
        "label": "Computertechniker/in",
        "value": "Computertechniker/in"
    },
    {
        "label": "Consulter",
        "value": "Consulter"
    },
    {
        "label": "Controller/in",
        "value": "Controller/in"
    },
    {
        "label": "Controllingassistent/in",
        "value": "Controllingassistent/in"
    },
    {
        "label": "Co-Pilot/in",
        "value": "Co-Pilot/in"
    },
    {
        "label": "Croupier/Croupière",
        "value": "Croupier/Croupière"
    },
    {
        "label": "Cutter/in",
        "value": "Cutter/in"
    },
    {
        "label": "Dachdecker/in",
        "value": "Dachdecker/in"
    },
    {
        "label": "Dachdeckerhelfer/in",
        "value": "Dachdeckerhelfer/in"
    },
    {
        "label": "Dachdeckermeister/in",
        "value": "Dachdeckermeister/in"
    },
    {
        "label": "Abdichter/in (Dachdeckerei)",
        "value": "Abdichter/in (Dachdeckerei)"
    },
    {
        "label": "Damenschneider/in",
        "value": "Damenschneider/in"
    },
    {
        "label": "Dampfkraftwerkmaschinist/in",
        "value": "Dampfkraftwerkmaschinist/in"
    },
    {
        "label": "Dampfmaschinenmaschinist/in",
        "value": "Dampfmaschinenmaschinist/in"
    },
    {
        "label": "Gesangssolist/in",
        "value": "Gesangssolist/in"
    },
    {
        "label": "Datenbankadministrator/in",
        "value": "Datenbankadministrator/in"
    },
    {
        "label": "Datenerfasser/in",
        "value": "Datenerfasser/in"
    },
    {
        "label": "Datenschutzbeauftragte/r",
        "value": "Datenschutzbeauftragte/r"
    },
    {
        "label": "Datentechniker/in",
        "value": "Datentechniker/in"
    },
    {
        "label": "Verwalter/in Datenbanken",
        "value": "Verwalter/in Datenbanken"
    },
    {
        "label": "Datentypist/in",
        "value": "Datentypist/in"
    },
    {
        "label": "Datenverarbeiter/in",
        "value": "Datenverarbeiter/in"
    },
    {
        "label": "Fachinformatiker/in",
        "value": "Fachinformatiker/in"
    },
    {
        "label": "Deckshelfer/in",
        "value": "Deckshelfer/in"
    },
    {
        "label": "Decksmann/-frau Seeschifffahrt",
        "value": "Decksmann/-frau Seeschifffahrt"
    },
    {
        "label": "Deckstationswärter/in",
        "value": "Deckstationswärter/in"
    },
    {
        "label": "Deichbauer/in",
        "value": "Deichbauer/in"
    },
    {
        "label": "Dekorateur/in",
        "value": "Dekorateur/in"
    },
    {
        "label": "Dekorationenmaler/in",
        "value": "Dekorationenmaler/in"
    },
    {
        "label": "Dekorationsnäher/in",
        "value": "Dekorationsnäher/in"
    },
    {
        "label": "Bevölkerungswissenschaftler/in",
        "value": "Bevölkerungswissenschaftler/in"
    },
    {
        "label": "Denkmalpfleger/in",
        "value": "Denkmalpfleger/in"
    },
    {
        "label": "Dentalfachberater/in",
        "value": "Dentalfachberater/in"
    },
    {
        "label": "Dental-Fachkraft",
        "value": "Dental-Fachkraft"
    },
    {
        "label": "Dentalkaufmann/-frau (Handel)",
        "value": "Dentalkaufmann/-frau (Handel)"
    },
    {
        "label": "Deponieaufsicht (Mülldeponie)",
        "value": "Deponieaufsicht (Mülldeponie)"
    },
    {
        "label": "Designer/in (BFS)",
        "value": "Designer/in (BFS)"
    },
    {
        "label": "Designer/in (BFS) Foto",
        "value": "Designer/in (BFS) Foto"
    },
    {
        "label": "Designer/in (BFS) Grafik",
        "value": "Designer/in (BFS) Grafik"
    },
    {
        "label": "Designer/in (HS) Industriedesign",
        "value": "Designer/in (HS) Industriedesign"
    },
    {
        "label": "Designer/in (HS) Mode- und Kostümdesign",
        "value": "Designer/in (HS) Mode- und Kostümdesign"
    },
    {
        "label": "Designer/in (HS) Textildesign",
        "value": "Designer/in (HS) Textildesign"
    },
    {
        "label": "Desinfektor/in",
        "value": "Desinfektor/in"
    },
    {
        "label": "Apparateführer/in (Brenner/in, Destillateur/in)",
        "value": "Apparateführer/in (Brenner/in, Destillateur/in)"
    },
    {
        "label": "Detektiv/in",
        "value": "Detektiv/in"
    },
    {
        "label": "Berufsdetektiv/in",
        "value": "Berufsdetektiv/in"
    },
    {
        "label": "Diabetesberater/in",
        "value": "Diabetesberater/in"
    },
    {
        "label": "Diakon/in",
        "value": "Diakon/in"
    },
    {
        "label": "Bestattungsredner/in",
        "value": "Bestattungsredner/in"
    },
    {
        "label": "Dialysefachkraft",
        "value": "Dialysefachkraft"
    },
    {
        "label": "Diamantschleifer/in",
        "value": "Diamantschleifer/in"
    },
    {
        "label": "Diätassistent/in",
        "value": "Diätassistent/in"
    },
    {
        "label": "Diätkoch/-köchin",
        "value": "Diätkoch/-köchin"
    },
    {
        "label": "Servicehilfskraft",
        "value": "Servicehilfskraft"
    },
    {
        "label": "Postbote/-botin",
        "value": "Postbote/-botin"
    },
    {
        "label": "Dienststellenleiter/in",
        "value": "Dienststellenleiter/in"
    },
    {
        "label": "Baubetriebsingenieur/in",
        "value": "Baubetriebsingenieur/in"
    },
    {
        "label": "Archivleiter/in",
        "value": "Archivleiter/in"
    },
    {
        "label": "Chemiker/in allgemeine Chemie",
        "value": "Chemiker/in allgemeine Chemie"
    },
    {
        "label": "Dolmetscher/in",
        "value": "Dolmetscher/in"
    },
    {
        "label": "Elektroingenieur/in",
        "value": "Elektroingenieur/in"
    },
    {
        "label": "Elektroniktechniker/in",
        "value": "Elektroniktechniker/in"
    },
    {
        "label": "Finanzwirt/in",
        "value": "Finanzwirt/in"
    },
    {
        "label": "Geograf/in",
        "value": "Geograf/in"
    },
    {
        "label": "Geologe/Geologin",
        "value": "Geologe/Geologin"
    },
    {
        "label": "Geophysiker/in",
        "value": "Geophysiker/in"
    },
    {
        "label": "Handelsschullehrer/in",
        "value": "Handelsschullehrer/in"
    },
    {
        "label": "Heilpädagoge/-pädagogin",
        "value": "Heilpädagoge/-pädagogin"
    },
    {
        "label": "Immobilienfachwirt/in",
        "value": "Immobilienfachwirt/in"
    },
    {
        "label": "Industriedesigner/in (HS)",
        "value": "Industriedesigner/in (HS)"
    },
    {
        "label": "Informatiker/in (HS)",
        "value": "Informatiker/in (HS)"
    },
    {
        "label": "Informatiker/in (HS) Medieninformatik",
        "value": "Informatiker/in (HS) Medieninformatik"
    },
    {
        "label": "Informatiktechniker/in",
        "value": "Informatiktechniker/in"
    },
    {
        "label": "Ingenieur/in Agrartechnik",
        "value": "Ingenieur/in Agrartechnik"
    },
    {
        "label": "Ingenieur/in Anlagenbau/-technik",
        "value": "Ingenieur/in Anlagenbau/-technik"
    },
    {
        "label": "Ingenieur/in Anlagenbetriebstechnik und Schiffsbetriebstechnik",
        "value": "Ingenieur/in Anlagenbetriebstechnik und Schiffsbetriebstechnik"
    },
    {
        "label": "Leiter/in Arbeitsvorbereitung",
        "value": "Leiter/in Arbeitsvorbereitung"
    },
    {
        "label": "Ingenieur/in Architektur",
        "value": "Ingenieur/in Architektur"
    },
    {
        "label": "Ingenieur/in Augenoptik",
        "value": "Ingenieur/in Augenoptik"
    },
    {
        "label": "Ingenieur/in Bau (allgemeines Bauwesen)",
        "value": "Ingenieur/in Bau (allgemeines Bauwesen)"
    },
    {
        "label": "Ingenieur/in Bekleidungstechnik",
        "value": "Ingenieur/in Bekleidungstechnik"
    },
    {
        "label": "Ingenieur/in Biotechnologie",
        "value": "Ingenieur/in Biotechnologie"
    },
    {
        "label": "Ingenieur/in Chemietechnik",
        "value": "Ingenieur/in Chemietechnik"
    },
    {
        "label": "Ingenieur/in Elektroniktechnologie",
        "value": "Ingenieur/in Elektroniktechnologie"
    },
    {
        "label": "Ingenieur/in Elektrotechnik (allgemeine Elektrotechnik)",
        "value": "Ingenieur/in Elektrotechnik (allgemeine Elektrotechnik)"
    },
    {
        "label": "Ingenieur/in Energieanlagentechnik",
        "value": "Ingenieur/in Energieanlagentechnik"
    },
    {
        "label": "Ingenieur/in Fahrzeugtechnik",
        "value": "Ingenieur/in Fahrzeugtechnik"
    },
    {
        "label": "Ingenieur/in Feinwerktechnik",
        "value": "Ingenieur/in Feinwerktechnik"
    },
    {
        "label": "Ingenieur/in Forstwirtschaft",
        "value": "Ingenieur/in Forstwirtschaft"
    },
    {
        "label": "Ingenieur/in Holztechnik",
        "value": "Ingenieur/in Holztechnik"
    },
    {
        "label": "Ingenieur/in Konstruktionstechnik (Maschinenwesen)",
        "value": "Ingenieur/in Konstruktionstechnik (Maschinenwesen)"
    },
    {
        "label": "Logistik-Ingenieur/in",
        "value": "Logistik-Ingenieur/in"
    },
    {
        "label": "Ingenieur/in Luft- und Raumfahrttechnik",
        "value": "Ingenieur/in Luft- und Raumfahrttechnik"
    },
    {
        "label": "Ingenieur/in Maschinenbau (allgemeiner Maschinenbau)",
        "value": "Ingenieur/in Maschinenbau (allgemeiner Maschinenbau)"
    },
    {
        "label": "Ingenieur/in Mechatronik",
        "value": "Ingenieur/in Mechatronik"
    },
    {
        "label": "Ingenieur/in Medientechnik/Multimedia",
        "value": "Ingenieur/in Medientechnik/Multimedia"
    },
    {
        "label": "Ingenieur/in Mikrosystemtechnik",
        "value": "Ingenieur/in Mikrosystemtechnik"
    },
    {
        "label": "Ingenieur/in Nachrichtentechnik",
        "value": "Ingenieur/in Nachrichtentechnik"
    },
    {
        "label": "Ingenieur/in Produktionstechnik",
        "value": "Ingenieur/in Produktionstechnik"
    },
    {
        "label": "Ingenieur/in Schiffsbetrieb",
        "value": "Ingenieur/in Schiffsbetrieb"
    },
    {
        "label": "Ingenieur/in Umweltschutz und -technik",
        "value": "Ingenieur/in Umweltschutz und -technik"
    },
    {
        "label": "Ingenieur/in Verfahrenstechnik",
        "value": "Ingenieur/in Verfahrenstechnik"
    },
    {
        "label": "Ingenieur/in Verarbeitung und Verfahrenstechnik (Papiertechnik)",
        "value": "Ingenieur/in Verarbeitung und Verfahrenstechnik (Papiertechnik)"
    },
    {
        "label": "Ingenieur/in Versorgungstechnik",
        "value": "Ingenieur/in Versorgungstechnik"
    },
    {
        "label": "Oberingenieur/in (HS)",
        "value": "Oberingenieur/in (HS)"
    },
    {
        "label": "Ingenieur/in Wasserwirtschaft",
        "value": "Ingenieur/in Wasserwirtschaft"
    },
    {
        "label": "Ingenieur/in Wasserwirtschaft und Kulturtechnik",
        "value": "Ingenieur/in Wasserwirtschaft und Kulturtechnik"
    },
    {
        "label": "Wirtschaftsingenieur/in",
        "value": "Wirtschaftsingenieur/in"
    },
    {
        "label": "Journalist/in",
        "value": "Journalist/in"
    },
    {
        "label": "Kirchenmusiker/in",
        "value": "Kirchenmusiker/in"
    },
    {
        "label": "Kommunikations- und Fotodesigner/in",
        "value": "Kommunikations- und Fotodesigner/in"
    },
    {
        "label": "Kulturwissenschaftler/in",
        "value": "Kulturwissenschaftler/in"
    },
    {
        "label": "Beschäftigungs- und Kunsttherapeut/in",
        "value": "Beschäftigungs- und Kunsttherapeut/in"
    },
    {
        "label": "Landschaftsplaner/in",
        "value": "Landschaftsplaner/in"
    },
    {
        "label": "Mathematiker/in",
        "value": "Mathematiker/in"
    },
    {
        "label": "Mineraloge/Mineralogin",
        "value": "Mineraloge/Mineralogin"
    },
    {
        "label": "Musiklehrer/in (HS)",
        "value": "Musiklehrer/in (HS)"
    },
    {
        "label": "Musikpädagoge/-pädagogin",
        "value": "Musikpädagoge/-pädagogin"
    },
    {
        "label": "Ernährungs- und Hauswirtschaftswissenschaftler/in",
        "value": "Ernährungs- und Hauswirtschaftswissenschaftler/in"
    },
    {
        "label": "Ökonom/in",
        "value": "Ökonom/in"
    },
    {
        "label": "Önologe/Önologin",
        "value": "Önologe/Önologin"
    },
    {
        "label": "Pädagoge/Pädagogin",
        "value": "Pädagoge/Pädagogin"
    },
    {
        "label": "Philologe/Philogin",
        "value": "Philologe/Philogin"
    },
    {
        "label": "Psychologe/Psychologin",
        "value": "Psychologe/Psychologin"
    },
    {
        "label": "Rechtspfleger/in",
        "value": "Rechtspfleger/in"
    },
    {
        "label": "Evangelische/r Religionspädagoge/-pädagogin",
        "value": "Evangelische/r Religionspädagoge/-pädagogin"
    },
    {
        "label": "Restaurator/in",
        "value": "Restaurator/in"
    },
    {
        "label": "Sozialarbeiter/in",
        "value": "Sozialarbeiter/in"
    },
    {
        "label": "Sozialpädagoge/-pädagogin",
        "value": "Sozialpädagoge/-pädagogin"
    },
    {
        "label": "Sozialwissenschaftler/in",
        "value": "Sozialwissenschaftler/in"
    },
    {
        "label": "Soziologe/Soziologin",
        "value": "Soziologe/Soziologin"
    },
    {
        "label": "Übersetzer/in",
        "value": "Übersetzer/in"
    },
    {
        "label": "Vermessungsingenieur/in",
        "value": "Vermessungsingenieur/in"
    },
    {
        "label": "Verwaltungsfachwirt/in",
        "value": "Verwaltungsfachwirt/in"
    },
    {
        "label": "Verwaltungswissenschaftler/in",
        "value": "Verwaltungswissenschaftler/in"
    },
    {
        "label": "Volkswirt/in",
        "value": "Volkswirt/in"
    },
    {
        "label": "Wirtschafter/in Hauswirtschaft",
        "value": "Wirtschafter/in Hauswirtschaft"
    },
    {
        "label": "Wirtschaftsmathematiker/in",
        "value": "Wirtschaftsmathematiker/in"
    },
    {
        "label": "Forstwirt/in",
        "value": "Forstwirt/in"
    },
    {
        "label": "Kaufmännische Bürokraft",
        "value": "Kaufmännische Bürokraft"
    },
    {
        "label": "Landwirt/in",
        "value": "Landwirt/in"
    },
    {
        "label": "Physiker/in",
        "value": "Physiker/in"
    },
    {
        "label": "Sportökonom/in",
        "value": "Sportökonom/in"
    },
    {
        "label": "Direktionsassistent/in",
        "value": "Direktionsassistent/in"
    },
    {
        "label": "Direktionsbeauftragte/r (Versicherung)",
        "value": "Direktionsbeauftragte/r (Versicherung)"
    },
    {
        "label": "Direktor/in allgemeinbildende Schulen",
        "value": "Direktor/in allgemeinbildende Schulen"
    },
    {
        "label": "Dirigent/in",
        "value": "Dirigent/in"
    },
    {
        "label": "Diskjockey",
        "value": "Diskjockey"
    },
    {
        "label": "Dispatcher/in Rechenzentrum",
        "value": "Dispatcher/in Rechenzentrum"
    },
    {
        "label": "Disponent/in Prozessorganisation",
        "value": "Disponent/in Prozessorganisation"
    },
    {
        "label": "Doktorand/in",
        "value": "Doktorand/in"
    },
    {
        "label": "Dokumentar/in",
        "value": "Dokumentar/in"
    },
    {
        "label": "Dokumentationsassistent/in",
        "value": "Dokumentationsassistent/in"
    },
    {
        "label": "Dorfhelfer/in",
        "value": "Dorfhelfer/in"
    },
    {
        "label": "Dozent/in Hochschulen und Akademien",
        "value": "Dozent/in Hochschulen und Akademien"
    },
    {
        "label": "Dozent/in berufliche IT-Weiterbildung",
        "value": "Dozent/in berufliche IT-Weiterbildung"
    },
    {
        "label": "Drahtseiler/in",
        "value": "Drahtseiler/in"
    },
    {
        "label": "Drahtseilmacher/in",
        "value": "Drahtseilmacher/in"
    },
    {
        "label": "Drahtverformer/in",
        "value": "Drahtverformer/in"
    },
    {
        "label": "Drahtwalzer/in",
        "value": "Drahtwalzer/in"
    },
    {
        "label": "Drahtwarenmacher/in",
        "value": "Drahtwarenmacher/in"
    },
    {
        "label": "Drahtzieher/in",
        "value": "Drahtzieher/in"
    },
    {
        "label": "Drechslermeister/in",
        "value": "Drechslermeister/in"
    },
    {
        "label": "Drehbuchautor/in",
        "value": "Drehbuchautor/in"
    },
    {
        "label": "Drehkranführer/in",
        "value": "Drehkranführer/in"
    },
    {
        "label": "Drehmaschinenbediener/in",
        "value": "Drehmaschinenbediener/in"
    },
    {
        "label": "Mannequin/Dressman",
        "value": "Mannequin/Dressman"
    },
    {
        "label": "Dressmen",
        "value": "Dressmen"
    },
    {
        "label": "Drogist/in",
        "value": "Drogist/in"
    },
    {
        "label": "Drucker/in",
        "value": "Drucker/in"
    },
    {
        "label": "Kaufmann/-frau Bürokommunikation",
        "value": "Kaufmann/-frau Bürokommunikation"
    },
    {
        "label": "Druckereiarbeiter/in",
        "value": "Druckereiarbeiter/in"
    },
    {
        "label": "Druckereifacharbeiter/in",
        "value": "Druckereifacharbeiter/in"
    },
    {
        "label": "Druckereitechniker/in",
        "value": "Druckereitechniker/in"
    },
    {
        "label": "Druckermeister/in",
        "value": "Druckermeister/in"
    },
    {
        "label": "Druckformhersteller/in",
        "value": "Druckformhersteller/in"
    },
    {
        "label": "Druckstockhersteller/in",
        "value": "Druckstockhersteller/in"
    },
    {
        "label": "Drucktechniker/in",
        "value": "Drucktechniker/in"
    },
    {
        "label": "Druckvorlagenhersteller/in",
        "value": "Druckvorlagenhersteller/in"
    },
    {
        "label": "Fluggastabfertiger/in",
        "value": "Fluggastabfertiger/in"
    },
    {
        "label": "DV-Berater/in",
        "value": "DV-Berater/in"
    },
    {
        "label": "DV-Koordinator/in",
        "value": "DV-Koordinator/in"
    },
    {
        "label": "DV-Leiter/in",
        "value": "DV-Leiter/in"
    },
    {
        "label": "DV-Organisator/in",
        "value": "DV-Organisator/in"
    },
    {
        "label": "Vertriebsfachmann/-frau",
        "value": "Vertriebsfachmann/-frau"
    },
    {
        "label": "Edelmetallprüfer/in",
        "value": "Edelmetallprüfer/in"
    },
    {
        "label": "Edelmetallschmied/in",
        "value": "Edelmetallschmied/in"
    },
    {
        "label": "Edelsteinbearbeiterhelfer/in",
        "value": "Edelsteinbearbeiterhelfer/in"
    },
    {
        "label": "EDV-Techniker/in",
        "value": "EDV-Techniker/in"
    },
    {
        "label": "EDV-Fachmann/-frau",
        "value": "EDV-Fachmann/-frau"
    },
    {
        "label": "EDV-Ingenieur/in",
        "value": "EDV-Ingenieur/in"
    },
    {
        "label": "Eheberater/in",
        "value": "Eheberater/in"
    },
    {
        "label": "Eichbeamter/-beamtin",
        "value": "Eichbeamter/-beamtin"
    },
    {
        "label": "Eicher/in",
        "value": "Eicher/in"
    },
    {
        "label": "Einkäufer/in",
        "value": "Einkäufer/in"
    },
    {
        "label": "Einkaufsassistent/in",
        "value": "Einkaufsassistent/in"
    },
    {
        "label": "Einkaufsleiter/in",
        "value": "Einkaufsleiter/in"
    },
    {
        "label": "Einrichter/in Maschinen",
        "value": "Einrichter/in Maschinen"
    },
    {
        "label": "Einrichtungsfachberater/in",
        "value": "Einrichtungsfachberater/in"
    },
    {
        "label": "Einsatzleiter/in Baumaschinen",
        "value": "Einsatzleiter/in Baumaschinen"
    },
    {
        "label": "Einschaler/in",
        "value": "Einschaler/in"
    },
    {
        "label": "Einsteller/in Fräsmaschinen",
        "value": "Einsteller/in Fräsmaschinen"
    },
    {
        "label": "Einzelhandelskaufmann/-frau",
        "value": "Einzelhandelskaufmann/-frau"
    },
    {
        "label": "Einzelhändler/in",
        "value": "Einzelhändler/in"
    },
    {
        "label": "Eiscafebesitzer/in",
        "value": "Eiscafebesitzer/in"
    },
    {
        "label": "Kaufmann/-frau Eisenbahn- und Straßenverkehr - Eisenbahnverkehr",
        "value": "Kaufmann/-frau Eisenbahn- und Straßenverkehr - Eisenbahnverkehr"
    },
    {
        "label": "Eisenbahner/in",
        "value": "Eisenbahner/in"
    },
    {
        "label": "Bauingenieur/in Eisenbahnbau (FS)",
        "value": "Bauingenieur/in Eisenbahnbau (FS)"
    },
    {
        "label": "Eisenbahnbetriebsregler/in",
        "value": "Eisenbahnbetriebsregler/in"
    },
    {
        "label": "Eisenbahner/in Betriebsdienst - Fahrweg",
        "value": "Eisenbahner/in Betriebsdienst - Fahrweg"
    },
    {
        "label": "Eisenbieger/in",
        "value": "Eisenbieger/in"
    },
    {
        "label": "Eisenanstreicher/in und -entroster/in",
        "value": "Eisenanstreicher/in und -entroster/in"
    },
    {
        "label": "Eisenflechter/in",
        "value": "Eisenflechter/in"
    },
    {
        "label": "Eisenschiffbauer/in",
        "value": "Eisenschiffbauer/in"
    },
    {
        "label": "Eishockeyspieler/in",
        "value": "Eishockeyspieler/in"
    },
    {
        "label": "Elektriker/in",
        "value": "Elektriker/in"
    },
    {
        "label": "Elektrikermeister/in",
        "value": "Elektrikermeister/in"
    },
    {
        "label": "Elektroanlageninstallateur/in",
        "value": "Elektroanlageninstallateur/in"
    },
    {
        "label": "Elektroanlagentechniker/in",
        "value": "Elektroanlagentechniker/in"
    },
    {
        "label": "Elektrofacharbeiter/in",
        "value": "Elektrofacharbeiter/in"
    },
    {
        "label": "Elektrogerätebauer/in",
        "value": "Elektrogerätebauer/in"
    },
    {
        "label": "Elektrogerätemechaniker/in",
        "value": "Elektrogerätemechaniker/in"
    },
    {
        "label": "Elektrogerätemontierer/in",
        "value": "Elektrogerätemontierer/in"
    },
    {
        "label": "Elektrogeräteprüfer/in",
        "value": "Elektrogeräteprüfer/in"
    },
    {
        "label": "Meister/in Elektroindustrie",
        "value": "Meister/in Elektroindustrie"
    },
    {
        "label": "Elektroinstallateur/in",
        "value": "Elektroinstallateur/in"
    },
    {
        "label": "Elektroinstallateurmeister/in",
        "value": "Elektroinstallateurmeister/in"
    },
    {
        "label": "Elektrokonstrukteur/in",
        "value": "Elektrokonstrukteur/in"
    },
    {
        "label": "Elektromaschinenbauer/in",
        "value": "Elektromaschinenbauer/in"
    },
    {
        "label": "Elektromaschinenmonteur/in",
        "value": "Elektromaschinenmonteur/in"
    },
    {
        "label": "Elektromaschinenwickler/in",
        "value": "Elektromaschinenwickler/in"
    },
    {
        "label": "Elektromaschinist/in",
        "value": "Elektromaschinist/in"
    },
    {
        "label": "Elektromechaniker/in",
        "value": "Elektromechaniker/in"
    },
    {
        "label": "Elektromeister/in",
        "value": "Elektromeister/in"
    },
    {
        "label": "Elektromonteur/in",
        "value": "Elektromonteur/in"
    },
    {
        "label": "Elektromotorenmonteur/in",
        "value": "Elektromotorenmonteur/in"
    },
    {
        "label": "Elektronikassistent/in",
        "value": "Elektronikassistent/in"
    },
    {
        "label": "Elektronikentwickler/in",
        "value": "Elektronikentwickler/in"
    },
    {
        "label": "Elektroniker/in",
        "value": "Elektroniker/in"
    },
    {
        "label": "Elektronikfacharbeiter/in",
        "value": "Elektronikfacharbeiter/in"
    },
    {
        "label": "Elektronikingenieur/in",
        "value": "Elektronikingenieur/in"
    },
    {
        "label": "Elektroprüfer/in",
        "value": "Elektroprüfer/in"
    },
    {
        "label": "Elektroschweißer/in",
        "value": "Elektroschweißer/in"
    },
    {
        "label": "Elektrotechniker/in",
        "value": "Elektrotechniker/in"
    },
    {
        "label": "Elektrotechnikmeister/in",
        "value": "Elektrotechnikmeister/in"
    },
    {
        "label": "Elektrozeichner/in",
        "value": "Elektrozeichner/in"
    },
    {
        "label": "Eloxierer/in",
        "value": "Eloxierer/in"
    },
    {
        "label": "Emaillierer/in",
        "value": "Emaillierer/in"
    },
    {
        "label": "Emailschriftenmaler/in",
        "value": "Emailschriftenmaler/in"
    },
    {
        "label": "Empfangskraft",
        "value": "Empfangskraft"
    },
    {
        "label": "Endkontrolleur/in",
        "value": "Endkontrolleur/in"
    },
    {
        "label": "Endproduktprüfer/in",
        "value": "Endproduktprüfer/in"
    },
    {
        "label": "Energieanlagenelektroniker/in",
        "value": "Energieanlagenelektroniker/in"
    },
    {
        "label": "Energieberater/in",
        "value": "Energieberater/in"
    },
    {
        "label": "Energieelektroniker/in - Anlagentechnik",
        "value": "Energieelektroniker/in - Anlagentechnik"
    },
    {
        "label": "Energieelektroniker/in - Betriebstechnik",
        "value": "Energieelektroniker/in - Betriebstechnik"
    },
    {
        "label": "Energiegeräteelektroniker/in",
        "value": "Energiegeräteelektroniker/in"
    },
    {
        "label": "Energiemaschinist/in",
        "value": "Energiemaschinist/in"
    },
    {
        "label": "Hebamme/Entbindungspfleger",
        "value": "Hebamme/Entbindungspfleger"
    },
    {
        "label": "Entgrater/in",
        "value": "Entgrater/in"
    },
    {
        "label": "Entlacker/in",
        "value": "Entlacker/in"
    },
    {
        "label": "Entsorger/in",
        "value": "Entsorger/in"
    },
    {
        "label": "Entsorgungsfachmann/-frau",
        "value": "Entsorgungsfachmann/-frau"
    },
    {
        "label": "Entwicklungsingenieur/in",
        "value": "Entwicklungsingenieur/in"
    },
    {
        "label": "Entwicklungsleiter/in",
        "value": "Entwicklungsleiter/in"
    },
    {
        "label": "Entwicklungstechniker/in",
        "value": "Entwicklungstechniker/in"
    },
    {
        "label": "Entwurfszeichner/in",
        "value": "Entwurfszeichner/in"
    },
    {
        "label": "Gasversorgungstechniker/in",
        "value": "Gasversorgungstechniker/in"
    },
    {
        "label": "Erdgasgewinner/in",
        "value": "Erdgasgewinner/in"
    },
    {
        "label": "Erdölgewinner/in",
        "value": "Erdölgewinner/in"
    },
    {
        "label": "Ergotherapeut/in",
        "value": "Ergotherapeut/in"
    },
    {
        "label": "Ermittlungsbeamter/-beamtin",
        "value": "Ermittlungsbeamter/-beamtin"
    },
    {
        "label": "Ernährungsberater/in",
        "value": "Ernährungsberater/in"
    },
    {
        "label": "Ernährungswissenschaftler/in",
        "value": "Ernährungswissenschaftler/in"
    },
    {
        "label": "Erodierer/in",
        "value": "Erodierer/in"
    },
    {
        "label": "Erzaufbereiter/in",
        "value": "Erzaufbereiter/in"
    },
    {
        "label": "Erzieher/in",
        "value": "Erzieher/in"
    },
    {
        "label": "Erziehungswissenschaftler/in",
        "value": "Erziehungswissenschaftler/in"
    },
    {
        "label": "Estrichleger/in",
        "value": "Estrichleger/in"
    },
    {
        "label": "Ethnologe/Ethnologin",
        "value": "Ethnologe/Ethnologin"
    },
    {
        "label": "Rentner/-in ab 65 Jahre",
        "value": "Rentner/-in ab 65 Jahre"
    },
    {
        "label": "Europa-Sekretär/in",
        "value": "Europa-Sekretär/in"
    },
    {
        "label": "Expedient/in",
        "value": "Expedient/in"
    },
    {
        "label": "Exportkaufmann/-frau",
        "value": "Exportkaufmann/-frau"
    },
    {
        "label": "Exportleiter/in",
        "value": "Exportleiter/in"
    },
    {
        "label": "Exportsachbearbeiter/in",
        "value": "Exportsachbearbeiter/in"
    },
    {
        "label": "Fabrikant/in",
        "value": "Fabrikant/in"
    },
    {
        "label": "Agrarfachwirt/in",
        "value": "Agrarfachwirt/in"
    },
    {
        "label": "Fachangestellte/r Archiv",
        "value": "Fachangestellte/r Archiv"
    },
    {
        "label": "Fachangestellte/r für Bäderbetriebe",
        "value": "Fachangestellte/r für Bäderbetriebe"
    },
    {
        "label": "Fachangestellte/r für Medien- und Informations Dienste",
        "value": "Fachangestellte/r für Medien- und Informations Dienste"
    },
    {
        "label": "Fachangestellte/r für Arbeitsförderung",
        "value": "Fachangestellte/r für Arbeitsförderung"
    },
    {
        "label": "Facharbeiter/in Anlagentechnik",
        "value": "Facharbeiter/in Anlagentechnik"
    },
    {
        "label": "Fachkraft Lagerwirtschaft",
        "value": "Fachkraft Lagerwirtschaft"
    },
    {
        "label": "Fachberater/in Einzelhandel",
        "value": "Fachberater/in Einzelhandel"
    },
    {
        "label": "Fachbereichsleiter/in Gesamtschulen",
        "value": "Fachbereichsleiter/in Gesamtschulen"
    },
    {
        "label": "Kaufmann/-frau Einzelhandel",
        "value": "Kaufmann/-frau Einzelhandel"
    },
    {
        "label": "Fachgehilfe/-gehilfin steuer- und wirtschaftsberatende Berufe",
        "value": "Fachgehilfe/-gehilfin steuer- und wirtschaftsberatende Berufe"
    },
    {
        "label": "Fachhochschullehrer/in",
        "value": "Fachhochschullehrer/in"
    },
    {
        "label": "Fachkaufmann/-frau Außenwirtschaft",
        "value": "Fachkaufmann/-frau Außenwirtschaft"
    },
    {
        "label": "Fachkaufmann/-frau Marketing",
        "value": "Fachkaufmann/-frau Marketing"
    },
    {
        "label": "Fachkaufmann/-frau Einkauf/Materialwirtschaft",
        "value": "Fachkaufmann/-frau Einkauf/Materialwirtschaft"
    },
    {
        "label": "EDV-Fachkraft",
        "value": "EDV-Fachkraft"
    },
    {
        "label": "Fachkraft Logistik/Materialwirtschaft",
        "value": "Fachkraft Logistik/Materialwirtschaft"
    },
    {
        "label": "Fachkraft Fluggastabfertigung",
        "value": "Fachkraft Fluggastabfertigung"
    },
    {
        "label": "Fachkraft Veranstaltungstechnik",
        "value": "Fachkraft Veranstaltungstechnik"
    },
    {
        "label": "Fachkraft Lebensmitteltechnik",
        "value": "Fachkraft Lebensmitteltechnik"
    },
    {
        "label": "Fachkrankenschwester/-pfleger",
        "value": "Fachkrankenschwester/-pfleger"
    },
    {
        "label": "Fachlehrer/in",
        "value": "Fachlehrer/in"
    },
    {
        "label": "Fachlehrer/in musisch-technische Fächer",
        "value": "Fachlehrer/in musisch-technische Fächer"
    },
    {
        "label": "Referent/in Öffentlichkeitsarbeit/Marketing",
        "value": "Referent/in Öffentlichkeitsarbeit/Marketing"
    },
    {
        "label": "Lehrer/in Berufsschulen",
        "value": "Lehrer/in Berufsschulen"
    },
    {
        "label": "Fachübersetzer/in",
        "value": "Fachübersetzer/in"
    },
    {
        "label": "Fachverkäufer/in Haushaltswaren",
        "value": "Fachverkäufer/in Haushaltswaren"
    },
    {
        "label": "Fachverkäufer/in Lebensmittelhandwerk",
        "value": "Fachverkäufer/in Lebensmittelhandwerk"
    },
    {
        "label": "Fachwerker/in Möbel-, Küchen-, Umzugsservice (§66 BBiG/§42m HwO)",
        "value": "Fachwerker/in Möbel-, Küchen-, Umzugsservice (§66 BBiG/§42m HwO)"
    },
    {
        "label": "Fachwirt/in Wirtschaft",
        "value": "Fachwirt/in Wirtschaft"
    },
    {
        "label": "Fahrlehrer/in",
        "value": "Fahrlehrer/in"
    },
    {
        "label": "Fahrbetriebsleiter/in (Eisenbahn)",
        "value": "Fahrbetriebsleiter/in (Eisenbahn)"
    },
    {
        "label": "Fahrdienstleiter/in",
        "value": "Fahrdienstleiter/in"
    },
    {
        "label": "Fahrer/in (Landwirtschaft)",
        "value": "Fahrer/in (Landwirtschaft)"
    },
    {
        "label": "Fahrer/in (Baufahrzeuge)",
        "value": "Fahrer/in (Baufahrzeuge)"
    },
    {
        "label": "Fahrkartenkontrolleur/in",
        "value": "Fahrkartenkontrolleur/in"
    },
    {
        "label": "Fährmann/-frau",
        "value": "Fährmann/-frau"
    },
    {
        "label": "Fahrradfachverkäufer/in",
        "value": "Fahrradfachverkäufer/in"
    },
    {
        "label": "Fahrradkurier/in",
        "value": "Fahrradkurier/in"
    },
    {
        "label": "Fahrradmechaniker/in",
        "value": "Fahrradmechaniker/in"
    },
    {
        "label": "Fahrschullehrer/in",
        "value": "Fahrschullehrer/in"
    },
    {
        "label": "Fahrstuhlführer/in",
        "value": "Fahrstuhlführer/in"
    },
    {
        "label": "Fahrverkäufer/in",
        "value": "Fahrverkäufer/in"
    },
    {
        "label": "Fahrzeugbauingenieur/in",
        "value": "Fahrzeugbauingenieur/in"
    },
    {
        "label": "Fahrzeugbautechniker/in",
        "value": "Fahrzeugbautechniker/in"
    },
    {
        "label": "Rettungswagenbegleiter/in",
        "value": "Rettungswagenbegleiter/in"
    },
    {
        "label": "Fahrzeugglaser/in",
        "value": "Fahrzeugglaser/in"
    },
    {
        "label": "Fahrzeuglackierer/in",
        "value": "Fahrzeuglackierer/in"
    },
    {
        "label": "Fahrzeugpfleger/in",
        "value": "Fahrzeugpfleger/in"
    },
    {
        "label": "Fahrzeugpolsterer/-polsterin",
        "value": "Fahrzeugpolsterer/-polsterin"
    },
    {
        "label": "Fahrzeugreiniger/in",
        "value": "Fahrzeugreiniger/in"
    },
    {
        "label": "Fahrzeugsattler/in",
        "value": "Fahrzeugsattler/in"
    },
    {
        "label": "Fahrzeugstellmacher/in",
        "value": "Fahrzeugstellmacher/in"
    },
    {
        "label": "Fakturist/in",
        "value": "Fakturist/in"
    },
    {
        "label": "Familienhelfer/in",
        "value": "Familienhelfer/in"
    },
    {
        "label": "Haus- und Familienpfleger/in",
        "value": "Haus- und Familienpfleger/in"
    },
    {
        "label": "Farbgestalter/in",
        "value": "Farbgestalter/in"
    },
    {
        "label": "Farbentechniker/in",
        "value": "Farbentechniker/in"
    },
    {
        "label": "Färbereiarbeiter/in",
        "value": "Färbereiarbeiter/in"
    },
    {
        "label": "Farbköchemeister",
        "value": "Farbköchemeister"
    },
    {
        "label": "Farbmischer/in",
        "value": "Farbmischer/in"
    },
    {
        "label": "Fassadenbauer/in",
        "value": "Fassadenbauer/in"
    },
    {
        "label": "Fassadenmaurer/in",
        "value": "Fassadenmaurer/in"
    },
    {
        "label": "Fassadenmonteur/in",
        "value": "Fassadenmonteur/in"
    },
    {
        "label": "Fassadenputzer/in (Reiniger/in)",
        "value": "Fassadenputzer/in (Reiniger/in)"
    },
    {
        "label": "Federmacher/in",
        "value": "Federmacher/in"
    },
    {
        "label": "Bettfedernreiniger/in",
        "value": "Bettfedernreiniger/in"
    },
    {
        "label": "Feiler/in",
        "value": "Feiler/in"
    },
    {
        "label": "Feinblechner/in",
        "value": "Feinblechner/in"
    },
    {
        "label": "Feingeräteelektroniker/in",
        "value": "Feingeräteelektroniker/in"
    },
    {
        "label": "Feinlederwarenhersteller/in",
        "value": "Feinlederwarenhersteller/in"
    },
    {
        "label": "Feinmechaniker/in",
        "value": "Feinmechaniker/in"
    },
    {
        "label": "Feinoptiker/in",
        "value": "Feinoptiker/in"
    },
    {
        "label": "Feinsattler/in",
        "value": "Feinsattler/in"
    },
    {
        "label": "Feinspinner/in",
        "value": "Feinspinner/in"
    },
    {
        "label": "Feintäschner/in",
        "value": "Feintäschner/in"
    },
    {
        "label": "Feinwerker/in (Elektronik)",
        "value": "Feinwerker/in (Elektronik)"
    },
    {
        "label": "Feinwerkingenieur/in",
        "value": "Feinwerkingenieur/in"
    },
    {
        "label": "Feinwerktechniker/in",
        "value": "Feinwerktechniker/in"
    },
    {
        "label": "Fellschneider/in",
        "value": "Fellschneider/in"
    },
    {
        "label": "Fellverarbeiter/in",
        "value": "Fellverarbeiter/in"
    },
    {
        "label": "Fensterbauer/in Glas",
        "value": "Fensterbauer/in Glas"
    },
    {
        "label": "Fensterbauer/in",
        "value": "Fensterbauer/in"
    },
    {
        "label": "Fensterbautechniker/in",
        "value": "Fensterbautechniker/in"
    },
    {
        "label": "Fenstermonteur/in",
        "value": "Fenstermonteur/in"
    },
    {
        "label": "Fensterputzer/in",
        "value": "Fensterputzer/in"
    },
    {
        "label": "Fensterreiniger/in",
        "value": "Fensterreiniger/in"
    },
    {
        "label": "Fernmeldeelektroniker/in",
        "value": "Fernmeldeelektroniker/in"
    },
    {
        "label": "Fernfahrer/in",
        "value": "Fernfahrer/in"
    },
    {
        "label": "Fernmeldeanlagenelektroniker/in",
        "value": "Fernmeldeanlagenelektroniker/in"
    },
    {
        "label": "Fernmeldebaumonteur/in",
        "value": "Fernmeldebaumonteur/in"
    },
    {
        "label": "Fernmeldehelfer/in",
        "value": "Fernmeldehelfer/in"
    },
    {
        "label": "Fernmelder/in",
        "value": "Fernmelder/in"
    },
    {
        "label": "Fernmeldeinstallationspraktiker/in",
        "value": "Fernmeldeinstallationspraktiker/in"
    },
    {
        "label": "Fernmeldemonteurhelfer/in",
        "value": "Fernmeldemonteurhelfer/in"
    },
    {
        "label": "Fernmeldemechaniker/in",
        "value": "Fernmeldemechaniker/in"
    },
    {
        "label": "Fernmeldemonteur/in",
        "value": "Fernmeldemonteur/in"
    },
    {
        "label": "Fernmeldetechniker/in (Elektrotechniker/in)",
        "value": "Fernmeldetechniker/in (Elektrotechniker/in)"
    },
    {
        "label": "Fernsehmechaniker/in",
        "value": "Fernsehmechaniker/in"
    },
    {
        "label": "Fernsehmoderator/in",
        "value": "Fernsehmoderator/in"
    },
    {
        "label": "Fernsehredakteur/in",
        "value": "Fernsehredakteur/in"
    },
    {
        "label": "Fernsehsprecher/in",
        "value": "Fernsehsprecher/in"
    },
    {
        "label": "Fernsehtechniker/in",
        "value": "Fernsehtechniker/in"
    },
    {
        "label": "Fernsehtechnikermeister/in",
        "value": "Fernsehtechnikermeister/in"
    },
    {
        "label": "Monteur/in Gas-, Wasser-, Strom- und Fernwärmeversorgung",
        "value": "Monteur/in Gas-, Wasser-, Strom- und Fernwärmeversorgung"
    },
    {
        "label": "Fertiggerichtekonservierer/in",
        "value": "Fertiggerichtekonservierer/in"
    },
    {
        "label": "Fertighausmonteur/in (Beton)",
        "value": "Fertighausmonteur/in (Beton)"
    },
    {
        "label": "Fertigungsassistent/in",
        "value": "Fertigungsassistent/in"
    },
    {
        "label": "Fertigungsingenieur/in",
        "value": "Fertigungsingenieur/in"
    },
    {
        "label": "Fertigungsleiter/in",
        "value": "Fertigungsleiter/in"
    },
    {
        "label": "Fertigungsmechaniker/in",
        "value": "Fertigungsmechaniker/in"
    },
    {
        "label": "Fertigungsmeister/in",
        "value": "Fertigungsmeister/in"
    },
    {
        "label": "Fertigungsplaner/in",
        "value": "Fertigungsplaner/in"
    },
    {
        "label": "Fertigungssteuerer/-steuerin",
        "value": "Fertigungssteuerer/-steuerin"
    },
    {
        "label": "Fertigungstechniker/in",
        "value": "Fertigungstechniker/in"
    },
    {
        "label": "Festmacher/in",
        "value": "Festmacher/in"
    },
    {
        "label": "Feuerlöscherprüfer/in",
        "value": "Feuerlöscherprüfer/in"
    },
    {
        "label": "Feuerungs- und Schornsteinbauer/in",
        "value": "Feuerungs- und Schornsteinbauer/in"
    },
    {
        "label": "Feuerungsbauermeister/in",
        "value": "Feuerungsbauermeister/in"
    },
    {
        "label": "Feuerungsmaurer/in",
        "value": "Feuerungsmaurer/in"
    },
    {
        "label": "Feuerwehrbeamter/-beamtin (höh. techn. Dienst)",
        "value": "Feuerwehrbeamter/-beamtin (höh. techn. Dienst)"
    },
    {
        "label": "Feuerwerker/in",
        "value": "Feuerwerker/in"
    },
    {
        "label": "Filialleiter/in",
        "value": "Filialleiter/in"
    },
    {
        "label": "Videotechniker/in",
        "value": "Videotechniker/in"
    },
    {
        "label": "Filmausstatter/in",
        "value": "Filmausstatter/in"
    },
    {
        "label": "Bühnen- und Kostümbildner/in",
        "value": "Bühnen- und Kostümbildner/in"
    },
    {
        "label": "Filmemacher/in",
        "value": "Filmemacher/in"
    },
    {
        "label": "Filmlaborant/in",
        "value": "Filmlaborant/in"
    },
    {
        "label": "Filmproduzent/in",
        "value": "Filmproduzent/in"
    },
    {
        "label": "Filmtechniker/in (Studio)",
        "value": "Filmtechniker/in (Studio)"
    },
    {
        "label": "Filzmacher/in",
        "value": "Filzmacher/in"
    },
    {
        "label": "Kalkulator/in",
        "value": "Kalkulator/in"
    },
    {
        "label": "Finanzbeamter/-beamtin - Bundesbank (mittl. Dienst)",
        "value": "Finanzbeamter/-beamtin - Bundesbank (mittl. Dienst)"
    },
    {
        "label": "Finanzberater/in",
        "value": "Finanzberater/in"
    },
    {
        "label": "Finanzbuchhalter/in",
        "value": "Finanzbuchhalter/in"
    },
    {
        "label": "Finanzdienstleister/in",
        "value": "Finanzdienstleister/in"
    },
    {
        "label": "Finanzmakler/in",
        "value": "Finanzmakler/in"
    },
    {
        "label": "Leiter/in Finanz- und Rechnungswesen",
        "value": "Leiter/in Finanz- und Rechnungswesen"
    },
    {
        "label": "Fingernagelmodellist/in",
        "value": "Fingernagelmodellist/in"
    },
    {
        "label": "Fischer/in",
        "value": "Fischer/in"
    },
    {
        "label": "Fischverarbeiter/in",
        "value": "Fischverarbeiter/in"
    },
    {
        "label": "Fischzüchter/in",
        "value": "Fischzüchter/in"
    },
    {
        "label": "Fitnessberater/in (Gesundheit/Sport)",
        "value": "Fitnessberater/in (Gesundheit/Sport)"
    },
    {
        "label": "Fitnessstudioleiter/in",
        "value": "Fitnessstudioleiter/in"
    },
    {
        "label": "Fitnessfachwirt/in",
        "value": "Fitnessfachwirt/in"
    },
    {
        "label": "Fitnesstrainer/in",
        "value": "Fitnesstrainer/in"
    },
    {
        "label": "Flachdachdecker/in",
        "value": "Flachdachdecker/in"
    },
    {
        "label": "Flachdrucker/in",
        "value": "Flachdrucker/in"
    },
    {
        "label": "Flachglasmaschinenbediener/in",
        "value": "Flachglasmaschinenbediener/in"
    },
    {
        "label": "Flachglasmechaniker/in",
        "value": "Flachglasmechaniker/in"
    },
    {
        "label": "Flachgraveur/in",
        "value": "Flachgraveur/in"
    },
    {
        "label": "Flaschenreiniger/in",
        "value": "Flaschenreiniger/in"
    },
    {
        "label": "Flaschner/in",
        "value": "Flaschner/in"
    },
    {
        "label": "Fleischbeschauer/in",
        "value": "Fleischbeschauer/in"
    },
    {
        "label": "Fleischer/in",
        "value": "Fleischer/in"
    },
    {
        "label": "Fleischereiverkäufer/in",
        "value": "Fleischereiverkäufer/in"
    },
    {
        "label": "Fleischermeister/in",
        "value": "Fleischermeister/in"
    },
    {
        "label": "Fleischfachverkäufer/in",
        "value": "Fleischfachverkäufer/in"
    },
    {
        "label": "Kommissionär/in",
        "value": "Kommissionär/in"
    },
    {
        "label": "Fleischwarenverkäufer/in",
        "value": "Fleischwarenverkäufer/in"
    },
    {
        "label": "Fliesenleger/in",
        "value": "Fliesenleger/in"
    },
    {
        "label": "Fliesenlegermeister/in",
        "value": "Fliesenlegermeister/in"
    },
    {
        "label": "Fließbandarbeiter/in",
        "value": "Fließbandarbeiter/in"
    },
    {
        "label": "Florist/in",
        "value": "Florist/in"
    },
    {
        "label": "Flötist/in",
        "value": "Flötist/in"
    },
    {
        "label": "Flugbegleiter/in",
        "value": "Flugbegleiter/in"
    },
    {
        "label": "Flugdatenbearbeiter/in",
        "value": "Flugdatenbearbeiter/in"
    },
    {
        "label": "Flugdienstberater/in",
        "value": "Flugdienstberater/in"
    },
    {
        "label": "Flugdiensttechniker/in",
        "value": "Flugdiensttechniker/in"
    },
    {
        "label": "Flugdisponent/in",
        "value": "Flugdisponent/in"
    },
    {
        "label": "Fluggastkontrolleur/in",
        "value": "Fluggastkontrolleur/in"
    },
    {
        "label": "Fluggerätbauer/in",
        "value": "Fluggerätbauer/in"
    },
    {
        "label": "Fluggerätmechaniker/in",
        "value": "Fluggerätmechaniker/in"
    },
    {
        "label": "Flugingenieur/in",
        "value": "Flugingenieur/in"
    },
    {
        "label": "Fluglehrer/in",
        "value": "Fluglehrer/in"
    },
    {
        "label": "Fluglotse/Fluglotsin",
        "value": "Fluglotse/Fluglotsin"
    },
    {
        "label": "Flugsicherungsfachkraft",
        "value": "Flugsicherungsfachkraft"
    },
    {
        "label": "Flugsicherungssimulator-Pilot/in",
        "value": "Flugsicherungssimulator-Pilot/in"
    },
    {
        "label": "Flugtriebwerkmechaniker/in",
        "value": "Flugtriebwerkmechaniker/in"
    },
    {
        "label": "Flugzeugabfertiger/in",
        "value": "Flugzeugabfertiger/in"
    },
    {
        "label": "Flugzeugbauer/in",
        "value": "Flugzeugbauer/in"
    },
    {
        "label": "Flugzeugbauingenieur/in",
        "value": "Flugzeugbauingenieur/in"
    },
    {
        "label": "Flugzeugbautechniker/in",
        "value": "Flugzeugbautechniker/in"
    },
    {
        "label": "Flugzeugelektroniker/in",
        "value": "Flugzeugelektroniker/in"
    },
    {
        "label": "Flugzeugmechaniker/in",
        "value": "Flugzeugmechaniker/in"
    },
    {
        "label": "Flugzeugprüfer/in",
        "value": "Flugzeugprüfer/in"
    },
    {
        "label": "Flugzeugtankwart/in",
        "value": "Flugzeugtankwart/in"
    },
    {
        "label": "Flugzeugtechniker/in",
        "value": "Flugzeugtechniker/in"
    },
    {
        "label": "Flurbereinigungstechniker/in",
        "value": "Flurbereinigungstechniker/in"
    },
    {
        "label": "Fondsmanager/in",
        "value": "Fondsmanager/in"
    },
    {
        "label": "Förderbandbediener/in",
        "value": "Förderbandbediener/in"
    },
    {
        "label": "Fördermaschinist/in",
        "value": "Fördermaschinist/in"
    },
    {
        "label": "Formenbauer/in",
        "value": "Formenbauer/in"
    },
    {
        "label": "Former-, Kernmacherhelfer/in",
        "value": "Former-, Kernmacherhelfer/in"
    },
    {
        "label": "Kunststoff-Formgeber/in",
        "value": "Kunststoff-Formgeber/in"
    },
    {
        "label": "Formgestalter/in",
        "value": "Formgestalter/in"
    },
    {
        "label": "Formgießer/in",
        "value": "Formgießer/in"
    },
    {
        "label": "Formsteinhersteller/in - Steine- und Erdenindustrie",
        "value": "Formsteinhersteller/in - Steine- und Erdenindustrie"
    },
    {
        "label": "Formentischler/in",
        "value": "Formentischler/in"
    },
    {
        "label": "Forscher/in",
        "value": "Forscher/in"
    },
    {
        "label": "Forschungsingenieur/in",
        "value": "Forschungsingenieur/in"
    },
    {
        "label": "Forstamtsleiter/in",
        "value": "Forstamtsleiter/in"
    },
    {
        "label": "Forstarbeiter/in",
        "value": "Forstarbeiter/in"
    },
    {
        "label": "Forstfacharbeiter/in",
        "value": "Forstfacharbeiter/in"
    },
    {
        "label": "Forsthilfsarbeiter/in",
        "value": "Forsthilfsarbeiter/in"
    },
    {
        "label": "Forstingenieur/in",
        "value": "Forstingenieur/in"
    },
    {
        "label": "Forstmeister/in",
        "value": "Forstmeister/in"
    },
    {
        "label": "Forstschützer/in",
        "value": "Forstschützer/in"
    },
    {
        "label": "Forstverwalter/in",
        "value": "Forstverwalter/in"
    },
    {
        "label": "Forstwirtschaftsmeister/in",
        "value": "Forstwirtschaftsmeister/in"
    },
    {
        "label": "Film- und Videolaborant/in",
        "value": "Film- und Videolaborant/in"
    },
    {
        "label": "Designer/in (HS) Foto",
        "value": "Designer/in (HS) Foto"
    },
    {
        "label": "Fotofachverkäufer/in",
        "value": "Fotofachverkäufer/in"
    },
    {
        "label": "Fotograf/in",
        "value": "Fotograf/in"
    },
    {
        "label": "Fotografenmeister/in",
        "value": "Fotografenmeister/in"
    },
    {
        "label": "Fotojournalist/in",
        "value": "Fotojournalist/in"
    },
    {
        "label": "Fotolaborant/in",
        "value": "Fotolaborant/in"
    },
    {
        "label": "Fotomodell",
        "value": "Fotomodell"
    },
    {
        "label": "Fotosetzer/in",
        "value": "Fotosetzer/in"
    },
    {
        "label": "Frachtabfertiger/in",
        "value": "Frachtabfertiger/in"
    },
    {
        "label": "Frachtarbeiter/in (Transport)",
        "value": "Frachtarbeiter/in (Transport)"
    },
    {
        "label": "Frachtfahrer/in",
        "value": "Frachtfahrer/in"
    },
    {
        "label": "Franchise-Fachkraft",
        "value": "Franchise-Fachkraft"
    },
    {
        "label": "Fräser/in",
        "value": "Fräser/in"
    },
    {
        "label": "Frauenarzt/-ärztin",
        "value": "Frauenarzt/-ärztin"
    },
    {
        "label": "Freiberufliche,r Mitarbeiter,in",
        "value": "Freiberufliche,r Mitarbeiter,in"
    },
    {
        "label": "Freileitungsmonteur/in",
        "value": "Freileitungsmonteur/in"
    },
    {
        "label": "Freizeitlehrer/in",
        "value": "Freizeitlehrer/in"
    },
    {
        "label": "Fremdenführer/in",
        "value": "Fremdenführer/in"
    },
    {
        "label": "Fremdsprachenassistent/in",
        "value": "Fremdsprachenassistent/in"
    },
    {
        "label": "Fremdsprachenkorrespondent/in",
        "value": "Fremdsprachenkorrespondent/in"
    },
    {
        "label": "Fremdsprachensekretär/in",
        "value": "Fremdsprachensekretär/in"
    },
    {
        "label": "Friedhofsarbeiter/in",
        "value": "Friedhofsarbeiter/in"
    },
    {
        "label": "Friedhofsgärtner/in",
        "value": "Friedhofsgärtner/in"
    },
    {
        "label": "Friseur/in",
        "value": "Friseur/in"
    },
    {
        "label": "Friseurmeister/in",
        "value": "Friseurmeister/in"
    },
    {
        "label": "Rentner/-in bis 64 Jahre",
        "value": "Rentner/-in bis 64 Jahre"
    },
    {
        "label": "Fuger/in",
        "value": "Fuger/in"
    },
    {
        "label": "Taxiunternehmer/in",
        "value": "Taxiunternehmer/in"
    },
    {
        "label": "Fuhrparkleiter/in",
        "value": "Fuhrparkleiter/in"
    },
    {
        "label": "Fuhrunternehmer/in",
        "value": "Fuhrunternehmer/in"
    },
    {
        "label": "Funkelektroniker/in",
        "value": "Funkelektroniker/in"
    },
    {
        "label": "Funker/in",
        "value": "Funker/in"
    },
    {
        "label": "Funktechniker/in",
        "value": "Funktechniker/in"
    },
    {
        "label": "Furnierer/in",
        "value": "Furnierer/in"
    },
    {
        "label": "Furnierhersteller/in",
        "value": "Furnierhersteller/in"
    },
    {
        "label": "Fußballer/in",
        "value": "Fußballer/in"
    },
    {
        "label": "Sportlehrer/in Sportvereine",
        "value": "Sportlehrer/in Sportvereine"
    },
    {
        "label": "Fußballtrainer/in",
        "value": "Fußballtrainer/in"
    },
    {
        "label": "Fußbodenleger/in",
        "value": "Fußbodenleger/in"
    },
    {
        "label": "Fußpfleger/in",
        "value": "Fußpfleger/in"
    },
    {
        "label": "Futtermitteltechniker/in",
        "value": "Futtermitteltechniker/in"
    },
    {
        "label": "Gabelstaplerfahrer/in",
        "value": "Gabelstaplerfahrer/in"
    },
    {
        "label": "Galerist/in",
        "value": "Galerist/in"
    },
    {
        "label": "Berufstrainer/in (Galopprennsport)",
        "value": "Berufstrainer/in (Galopprennsport)"
    },
    {
        "label": "Galvaniseur/in",
        "value": "Galvaniseur/in"
    },
    {
        "label": "Galvanotechniker/in",
        "value": "Galvanotechniker/in"
    },
    {
        "label": "Garderobenwärter/in",
        "value": "Garderobenwärter/in"
    },
    {
        "label": "Gardinennäher/in",
        "value": "Gardinennäher/in"
    },
    {
        "label": "Gärführer/in (Brauerei)",
        "value": "Gärführer/in (Brauerei)"
    },
    {
        "label": "Gartenarchitekt/in",
        "value": "Gartenarchitekt/in"
    },
    {
        "label": "Garten- und Landschaftsbauer/in",
        "value": "Garten- und Landschaftsbauer/in"
    },
    {
        "label": "Garten- und Landschaftsbautechniker/in",
        "value": "Garten- und Landschaftsbautechniker/in"
    },
    {
        "label": "Garten- und Landschaftsgestalter/in",
        "value": "Garten- und Landschaftsgestalter/in"
    },
    {
        "label": "Gartenarbeiter/in",
        "value": "Gartenarbeiter/in"
    },
    {
        "label": "Gartenbauberater/in",
        "value": "Gartenbauberater/in"
    },
    {
        "label": "Gartenbauer/in",
        "value": "Gartenbauer/in"
    },
    {
        "label": "Gartenbaufacharbeiter/in",
        "value": "Gartenbaufacharbeiter/in"
    },
    {
        "label": "Gartenbauhelfer/in",
        "value": "Gartenbauhelfer/in"
    },
    {
        "label": "Gartenbauingenieur/in",
        "value": "Gartenbauingenieur/in"
    },
    {
        "label": "Gartenbautechniker/in",
        "value": "Gartenbautechniker/in"
    },
    {
        "label": "Gartenbauverwalter/in",
        "value": "Gartenbauverwalter/in"
    },
    {
        "label": "Gärtner/in",
        "value": "Gärtner/in"
    },
    {
        "label": "Gärtnermeister/in",
        "value": "Gärtnermeister/in"
    },
    {
        "label": "Gas- und Wasserinstallateur/in",
        "value": "Gas- und Wasserinstallateur/in"
    },
    {
        "label": "Gas- und Wasserinstallateurmeister/in",
        "value": "Gas- und Wasserinstallateurmeister/in"
    },
    {
        "label": "Gasinstallateur/in",
        "value": "Gasinstallateur/in"
    },
    {
        "label": "Gasmonteur/in",
        "value": "Gasmonteur/in"
    },
    {
        "label": "Gästebetreuer/in",
        "value": "Gästebetreuer/in"
    },
    {
        "label": "Messtechniker/in",
        "value": "Messtechniker/in"
    },
    {
        "label": "Gasthofgehilfe/-gehilfin",
        "value": "Gasthofgehilfe/-gehilfin"
    },
    {
        "label": "Gastroenterologe/Gastroenterologin",
        "value": "Gastroenterologe/Gastroenterologin"
    },
    {
        "label": "Gastronom/in",
        "value": "Gastronom/in"
    },
    {
        "label": "Gaststättenbesitzer/in",
        "value": "Gaststättenbesitzer/in"
    },
    {
        "label": "Gaststättengeschäftsführer/in",
        "value": "Gaststättengeschäftsführer/in"
    },
    {
        "label": "Gaststätteninhaber/in",
        "value": "Gaststätteninhaber/in"
    },
    {
        "label": "Gaststättenkaufmann/-frau",
        "value": "Gaststättenkaufmann/-frau"
    },
    {
        "label": "Gastwirt/in",
        "value": "Gastwirt/in"
    },
    {
        "label": "Gebäudeisolierer/in",
        "value": "Gebäudeisolierer/in"
    },
    {
        "label": "Gebäudereiniger/in",
        "value": "Gebäudereiniger/in"
    },
    {
        "label": "Gebäudereinigermeister/in",
        "value": "Gebäudereinigermeister/in"
    },
    {
        "label": "Gebäudetechniker/in",
        "value": "Gebäudetechniker/in"
    },
    {
        "label": "Gebietsleiter/in",
        "value": "Gebietsleiter/in"
    },
    {
        "label": "Gebietsverkaufsleiter/in",
        "value": "Gebietsverkaufsleiter/in"
    },
    {
        "label": "Schauwerbegestalter/in",
        "value": "Schauwerbegestalter/in"
    },
    {
        "label": "Gebrauchtwagenhändler/in",
        "value": "Gebrauchtwagenhändler/in"
    },
    {
        "label": "Geburtshelfer/in",
        "value": "Geburtshelfer/in"
    },
    {
        "label": "Gefängnisaufseher/in",
        "value": "Gefängnisaufseher/in"
    },
    {
        "label": "Strafvollzugsbeamter/-beamtin (mittl. Dienst)",
        "value": "Strafvollzugsbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Geflügelzüchter/in",
        "value": "Geflügelzüchter/in"
    },
    {
        "label": "Geigenbauer/in",
        "value": "Geigenbauer/in"
    },
    {
        "label": "Wissenschaftler/in",
        "value": "Wissenschaftler/in"
    },
    {
        "label": "Geistliche/r",
        "value": "Geistliche/r"
    },
    {
        "label": "Geldeinnehmer/in",
        "value": "Geldeinnehmer/in"
    },
    {
        "label": "Geldtransportbegleiter/in",
        "value": "Geldtransportbegleiter/in"
    },
    {
        "label": "Geldtransportfahrer/in",
        "value": "Geldtransportfahrer/in"
    },
    {
        "label": "Gemeindeangestellte/r",
        "value": "Gemeindeangestellte/r"
    },
    {
        "label": "Gemeindearbeiter/in",
        "value": "Gemeindearbeiter/in"
    },
    {
        "label": "Gemeindediener/in",
        "value": "Gemeindediener/in"
    },
    {
        "label": "Gemeindehelfer/in",
        "value": "Gemeindehelfer/in"
    },
    {
        "label": "Gemeindekrankenschwester/-pfleger",
        "value": "Gemeindekrankenschwester/-pfleger"
    },
    {
        "label": "Gemüsebauer/-bäuerin",
        "value": "Gemüsebauer/-bäuerin"
    },
    {
        "label": "Gemüsekonservierer/in",
        "value": "Gemüsekonservierer/in"
    },
    {
        "label": "Gemüseverkäufer/in",
        "value": "Gemüseverkäufer/in"
    },
    {
        "label": "Generalvertreter/in",
        "value": "Generalvertreter/in"
    },
    {
        "label": "Genußmittelverkäufer/in",
        "value": "Genußmittelverkäufer/in"
    },
    {
        "label": "Geologietechniker/in",
        "value": "Geologietechniker/in"
    },
    {
        "label": "Geoökologe/-ökologin",
        "value": "Geoökologe/-ökologin"
    },
    {
        "label": "Geowissenschaftler/in",
        "value": "Geowissenschaftler/in"
    },
    {
        "label": "Gepäck-Servicekraft",
        "value": "Gepäck-Servicekraft"
    },
    {
        "label": "Gepäckträger/in",
        "value": "Gepäckträger/in"
    },
    {
        "label": "Ausbilder/in Pharmareferenten/-innen",
        "value": "Ausbilder/in Pharmareferenten/-innen"
    },
    {
        "label": "Schädlingsbekämpfer/in",
        "value": "Schädlingsbekämpfer/in"
    },
    {
        "label": "Wirtschaftsinformatiker/in - IT-Systeme (geprüft)",
        "value": "Wirtschaftsinformatiker/in - IT-Systeme (geprüft)"
    },
    {
        "label": "Gerätefahrer/in",
        "value": "Gerätefahrer/in"
    },
    {
        "label": "Gerätewart/in",
        "value": "Gerätewart/in"
    },
    {
        "label": "Gerätezusammensetzer/in",
        "value": "Gerätezusammensetzer/in"
    },
    {
        "label": "Gerber/in",
        "value": "Gerber/in"
    },
    {
        "label": "Gerichtsdiener/in",
        "value": "Gerichtsdiener/in"
    },
    {
        "label": "Gerichtsvollzieher/in",
        "value": "Gerichtsvollzieher/in"
    },
    {
        "label": "Germanist/in",
        "value": "Germanist/in"
    },
    {
        "label": "Gerüstbauer/in",
        "value": "Gerüstbauer/in"
    },
    {
        "label": "Gesamtschullehrer/in",
        "value": "Gesamtschullehrer/in"
    },
    {
        "label": "Gesangslehrer/in",
        "value": "Gesangslehrer/in"
    },
    {
        "label": "Geschäftsbereichsleiter/in",
        "value": "Geschäftsbereichsleiter/in"
    },
    {
        "label": "Geschäftsführer/in",
        "value": "Geschäftsführer/in"
    },
    {
        "label": "Geschäftsführerassistent/in",
        "value": "Geschäftsführerassistent/in"
    },
    {
        "label": "Geschäftsinhaber/in",
        "value": "Geschäftsinhaber/in"
    },
    {
        "label": "Geschäftsleiter/in",
        "value": "Geschäftsleiter/in"
    },
    {
        "label": "Geschäftsstellenleiter/in",
        "value": "Geschäftsstellenleiter/in"
    },
    {
        "label": "Geschäftsführende/r Gesellschafter/in",
        "value": "Geschäftsführende/r Gesellschafter/in"
    },
    {
        "label": "Gesenkschmied/in",
        "value": "Gesenkschmied/in"
    },
    {
        "label": "Produktgestalter/in",
        "value": "Produktgestalter/in"
    },
    {
        "label": "Gestalter/in Werbegestaltung",
        "value": "Gestalter/in Werbegestaltung"
    },
    {
        "label": "Gestalter/in Edelstein, Schmuck und Gerät",
        "value": "Gestalter/in Edelstein, Schmuck und Gerät"
    },
    {
        "label": "Gestaltungstechnische/r Assistent/in",
        "value": "Gestaltungstechnische/r Assistent/in"
    },
    {
        "label": "Gesundheitsaufseher/in",
        "value": "Gesundheitsaufseher/in"
    },
    {
        "label": "Gesundheitsberater/in",
        "value": "Gesundheitsberater/in"
    },
    {
        "label": "Gesundheitspfleger/in",
        "value": "Gesundheitspfleger/in"
    },
    {
        "label": "Gesundheitsingenieur/in",
        "value": "Gesundheitsingenieur/in"
    },
    {
        "label": "Gesundheitstechniker/in",
        "value": "Gesundheitstechniker/in"
    },
    {
        "label": "Getränkefahrer/in",
        "value": "Getränkefahrer/in"
    },
    {
        "label": "Getränkehändler/in (Einzelhandel)",
        "value": "Getränkehändler/in (Einzelhandel)"
    },
    {
        "label": "Getränkehersteller/in",
        "value": "Getränkehersteller/in"
    },
    {
        "label": "Laborant/in Lebensmittel",
        "value": "Laborant/in Lebensmittel"
    },
    {
        "label": "Getränkeleitungsreiniger/in",
        "value": "Getränkeleitungsreiniger/in"
    },
    {
        "label": "Getränkeingenieur/in",
        "value": "Getränkeingenieur/in"
    },
    {
        "label": "Getriebeschlosser/in",
        "value": "Getriebeschlosser/in"
    },
    {
        "label": "Gewandmeister/in",
        "value": "Gewandmeister/in"
    },
    {
        "label": "Gewerbeaufseher/in",
        "value": "Gewerbeaufseher/in"
    },
    {
        "label": "Gewerbeaufsichtbeamter/-beamtin (mittl. techn. Dienst)",
        "value": "Gewerbeaufsichtbeamter/-beamtin (mittl. techn. Dienst)"
    },
    {
        "label": "Gewerbegehilfe/-gehilfin",
        "value": "Gewerbegehilfe/-gehilfin"
    },
    {
        "label": "Gewerkschaftsfunktionär/in",
        "value": "Gewerkschaftsfunktionär/in"
    },
    {
        "label": "Gewerkschaftssekretär/in",
        "value": "Gewerkschaftssekretär/in"
    },
    {
        "label": "Gießereiarbeiter/in",
        "value": "Gießereiarbeiter/in"
    },
    {
        "label": "Gießereifacharbeiter/in",
        "value": "Gießereifacharbeiter/in"
    },
    {
        "label": "Gießereiingenieur/in",
        "value": "Gießereiingenieur/in"
    },
    {
        "label": "Gießereimechaniker/in",
        "value": "Gießereimechaniker/in"
    },
    {
        "label": "Gießereitechniker/in",
        "value": "Gießereitechniker/in"
    },
    {
        "label": "Gießereiwerker/in",
        "value": "Gießereiwerker/in"
    },
    {
        "label": "Gipser/in (Stuckateur/in)",
        "value": "Gipser/in (Stuckateur/in)"
    },
    {
        "label": "Gipsformengießer/in",
        "value": "Gipsformengießer/in"
    },
    {
        "label": "Gitarrist/in",
        "value": "Gitarrist/in"
    },
    {
        "label": "Glasreiniger/in",
        "value": "Glasreiniger/in"
    },
    {
        "label": "Glasapparatebauer/in",
        "value": "Glasapparatebauer/in"
    },
    {
        "label": "Glasbearbeiter/in",
        "value": "Glasbearbeiter/in"
    },
    {
        "label": "Glasätzer/in",
        "value": "Glasätzer/in"
    },
    {
        "label": "Glasbläser/in",
        "value": "Glasbläser/in"
    },
    {
        "label": "Glaser/in",
        "value": "Glaser/in"
    },
    {
        "label": "Glasermeister/in",
        "value": "Glasermeister/in"
    },
    {
        "label": "Glasgraveur/in",
        "value": "Glasgraveur/in"
    },
    {
        "label": "Glasinstrumentenmacher/in",
        "value": "Glasinstrumentenmacher/in"
    },
    {
        "label": "Glasmaler/in",
        "value": "Glasmaler/in"
    },
    {
        "label": "Glasmassehersteller/in",
        "value": "Glasmassehersteller/in"
    },
    {
        "label": "Glassäger/in",
        "value": "Glassäger/in"
    },
    {
        "label": "Glasschleifer/in",
        "value": "Glasschleifer/in"
    },
    {
        "label": "Glastechniker/in",
        "value": "Glastechniker/in"
    },
    {
        "label": "Glasveredler/in",
        "value": "Glasveredler/in"
    },
    {
        "label": "Glaswerker/in",
        "value": "Glaswerker/in"
    },
    {
        "label": "Gleisbauer/in",
        "value": "Gleisbauer/in"
    },
    {
        "label": "Gleisbaufacharbeiter/in",
        "value": "Gleisbaufacharbeiter/in"
    },
    {
        "label": "Glasbaumeister/in",
        "value": "Glasbaumeister/in"
    },
    {
        "label": "Gleiswerker/in",
        "value": "Gleiswerker/in"
    },
    {
        "label": "Glockengießer/in",
        "value": "Glockengießer/in"
    },
    {
        "label": "Glüher/in (Schmiedehelfer/in)",
        "value": "Glüher/in (Schmiedehelfer/in)"
    },
    {
        "label": "Goldschmied/in",
        "value": "Goldschmied/in"
    },
    {
        "label": "Goldschmiedemeister/in",
        "value": "Goldschmiedemeister/in"
    },
    {
        "label": "Golflehrer/in",
        "value": "Golflehrer/in"
    },
    {
        "label": "Trauerredner/in",
        "value": "Trauerredner/in"
    },
    {
        "label": "Graderfahrer/in",
        "value": "Graderfahrer/in"
    },
    {
        "label": "Gestaltungstechnische/r Assistent/in Grafik",
        "value": "Gestaltungstechnische/r Assistent/in Grafik"
    },
    {
        "label": "Grafiker/in",
        "value": "Grafiker/in"
    },
    {
        "label": "Graphologe/Graphologin",
        "value": "Graphologe/Graphologin"
    },
    {
        "label": "Graveur/in",
        "value": "Graveur/in"
    },
    {
        "label": "Greenkeeper/in (Golf)",
        "value": "Greenkeeper/in (Golf)"
    },
    {
        "label": "Groß- und Außenhandelskaufmann/-frau",
        "value": "Groß- und Außenhandelskaufmann/-frau"
    },
    {
        "label": "Großhandelskaufmann/-frau",
        "value": "Großhandelskaufmann/-frau"
    },
    {
        "label": "Großhändler/in",
        "value": "Großhändler/in"
    },
    {
        "label": "Großküchenkoch/-köchin",
        "value": "Großküchenkoch/-köchin"
    },
    {
        "label": "Grubenschlosser/in",
        "value": "Grubenschlosser/in"
    },
    {
        "label": "Grünanlagenpfleger/in",
        "value": "Grünanlagenpfleger/in"
    },
    {
        "label": "Grund- und Hauptschullehrer/in",
        "value": "Grund- und Hauptschullehrer/in"
    },
    {
        "label": "Grundschullehrer/in",
        "value": "Grundschullehrer/in"
    },
    {
        "label": "Grundstücksverwalter/in",
        "value": "Grundstücksverwalter/in"
    },
    {
        "label": "Grünlandwirt/in",
        "value": "Grünlandwirt/in"
    },
    {
        "label": "Auskleider/in (Kunststoff- und Kautschuktechnik)",
        "value": "Auskleider/in (Kunststoff- und Kautschuktechnik)"
    },
    {
        "label": "Gummihersteller/in",
        "value": "Gummihersteller/in"
    },
    {
        "label": "Gummistrumpfstricker/in",
        "value": "Gummistrumpfstricker/in"
    },
    {
        "label": "Gürtler/in und Metalldrücker/in",
        "value": "Gürtler/in und Metalldrücker/in"
    },
    {
        "label": "Gussputzer/in",
        "value": "Gussputzer/in"
    },
    {
        "label": "Gutachter/in Immobilienwirtschaft",
        "value": "Gutachter/in Immobilienwirtschaft"
    },
    {
        "label": "Güteprüfer/in",
        "value": "Güteprüfer/in"
    },
    {
        "label": "Güterkraftverkehrsunternehmer/in",
        "value": "Güterkraftverkehrsunternehmer/in"
    },
    {
        "label": "Gymnasiallehrer/in",
        "value": "Gymnasiallehrer/in"
    },
    {
        "label": "Gymnastiklehrer/in",
        "value": "Gymnastiklehrer/in"
    },
    {
        "label": "Gymnastiktrainer/in",
        "value": "Gymnastiktrainer/in"
    },
    {
        "label": "Gynäkologe/Gynäkologin",
        "value": "Gynäkologe/Gynäkologin"
    },
    {
        "label": "Hafenarbeiter/in",
        "value": "Hafenarbeiter/in"
    },
    {
        "label": "Hafenfacharbeiter/in",
        "value": "Hafenfacharbeiter/in"
    },
    {
        "label": "Hafenlagerarbeiter/in",
        "value": "Hafenlagerarbeiter/in"
    },
    {
        "label": "Hafenmeister/in",
        "value": "Hafenmeister/in"
    },
    {
        "label": "Hafenschiffer/in",
        "value": "Hafenschiffer/in"
    },
    {
        "label": "Hallengehilfe/-gehilfin",
        "value": "Hallengehilfe/-gehilfin"
    },
    {
        "label": "Hallenmeister/in",
        "value": "Hallenmeister/in"
    },
    {
        "label": "HNO-Arzt/-Ärztin",
        "value": "HNO-Arzt/-Ärztin"
    },
    {
        "label": "Hammerschmied/in (Stahlschmieden)",
        "value": "Hammerschmied/in (Stahlschmieden)"
    },
    {
        "label": "Handelsagent/in",
        "value": "Handelsagent/in"
    },
    {
        "label": "Handelsassistent/in",
        "value": "Handelsassistent/in"
    },
    {
        "label": "Handelsfachpacker/in",
        "value": "Handelsfachpacker/in"
    },
    {
        "label": "Handelsfachwirt/in",
        "value": "Handelsfachwirt/in"
    },
    {
        "label": "Handelskaufmann/-frau",
        "value": "Handelskaufmann/-frau"
    },
    {
        "label": "Handelsmakler/in",
        "value": "Handelsmakler/in"
    },
    {
        "label": "Handelsvertreter/in",
        "value": "Handelsvertreter/in"
    },
    {
        "label": "Amerikaformer/in",
        "value": "Amerikaformer/in"
    },
    {
        "label": "Sortenhändler/in",
        "value": "Sortenhändler/in"
    },
    {
        "label": "EDV-Fachwirt/in",
        "value": "EDV-Fachwirt/in"
    },
    {
        "label": "Handschuhmacher/in",
        "value": "Handschuhmacher/in"
    },
    {
        "label": "Handsetzer/in",
        "value": "Handsetzer/in"
    },
    {
        "label": "Handstricker/in",
        "value": "Handstricker/in"
    },
    {
        "label": "Handweber/in",
        "value": "Handweber/in"
    },
    {
        "label": "Vorhandwerker/in",
        "value": "Vorhandwerker/in"
    },
    {
        "label": "Handwerksmeister/in",
        "value": "Handwerksmeister/in"
    },
    {
        "label": "Hardware-Entwickler/in",
        "value": "Hardware-Entwickler/in"
    },
    {
        "label": "Harfenspieler/in",
        "value": "Harfenspieler/in"
    },
    {
        "label": "Härter/in",
        "value": "Härter/in"
    },
    {
        "label": "Aushauer/in (Bergbau)",
        "value": "Aushauer/in (Bergbau)"
    },
    {
        "label": "Hauptbuchhalter/in",
        "value": "Hauptbuchhalter/in"
    },
    {
        "label": "Hauptschullehrer/in",
        "value": "Hauptschullehrer/in"
    },
    {
        "label": "Alltagsbetreuer/in",
        "value": "Alltagsbetreuer/in"
    },
    {
        "label": "Haus- und Ernährungswirtschaftler/in",
        "value": "Haus- und Ernährungswirtschaftler/in"
    },
    {
        "label": "Hausdiener/in",
        "value": "Hausdiener/in"
    },
    {
        "label": "Hausdame/Housekeeper",
        "value": "Hausdame/Housekeeper"
    },
    {
        "label": "Hausfrau, -mann",
        "value": "Hausfrau, -mann"
    },
    {
        "label": "Hausgerätetechniker/in (Elektromechanik)",
        "value": "Hausgerätetechniker/in (Elektromechanik)"
    },
    {
        "label": "Haushälter/in",
        "value": "Haushälter/in"
    },
    {
        "label": "Haushaltshilfe",
        "value": "Haushaltshilfe"
    },
    {
        "label": "Haushaltswissenschaftler/in",
        "value": "Haushaltswissenschaftler/in"
    },
    {
        "label": "Haushandwerker/in",
        "value": "Haushandwerker/in"
    },
    {
        "label": "Gesundheits- und Krankenpfleger/in",
        "value": "Gesundheits- und Krankenpfleger/in"
    },
    {
        "label": "Hausleiter/in (Altenheim)",
        "value": "Hausleiter/in (Altenheim)"
    },
    {
        "label": "Hausmeister/in",
        "value": "Hausmeister/in"
    },
    {
        "label": "Hauspfleger/in",
        "value": "Hauspfleger/in"
    },
    {
        "label": "Hausratreiniger/in",
        "value": "Hausratreiniger/in"
    },
    {
        "label": "Berater/in Inneneinrichtung und Gestaltung",
        "value": "Berater/in Inneneinrichtung und Gestaltung"
    },
    {
        "label": "Haustechniker/in",
        "value": "Haustechniker/in"
    },
    {
        "label": "Hausverwalter/in",
        "value": "Hausverwalter/in"
    },
    {
        "label": "Hauswart/in",
        "value": "Hauswart/in"
    },
    {
        "label": "Hauswirtschafter/in",
        "value": "Hauswirtschafter/in"
    },
    {
        "label": "Fachhauswirtschafter/in",
        "value": "Fachhauswirtschafter/in"
    },
    {
        "label": "Hauswirtschaftsleiter/in",
        "value": "Hauswirtschaftsleiter/in"
    },
    {
        "label": "Hauswirtschaftsmeister/in",
        "value": "Hauswirtschaftsmeister/in"
    },
    {
        "label": "Hautarzt/-ärztin",
        "value": "Hautarzt/-ärztin"
    },
    {
        "label": "Heilerziehungspfleger/in",
        "value": "Heilerziehungspfleger/in"
    },
    {
        "label": "Heilerziehungspflegehelfer/in",
        "value": "Heilerziehungspflegehelfer/in"
    },
    {
        "label": "Heileurythmist/in",
        "value": "Heileurythmist/in"
    },
    {
        "label": "Heilgymnast/in",
        "value": "Heilgymnast/in"
    },
    {
        "label": "Heilpraktiker/in",
        "value": "Heilpraktiker/in"
    },
    {
        "label": "Heimarbeiter o.n.T.",
        "value": "Heimarbeiter o.n.T."
    },
    {
        "label": "Heimerzieher/in",
        "value": "Heimerzieher/in"
    },
    {
        "label": "Heimlehrer/in",
        "value": "Heimlehrer/in"
    },
    {
        "label": "Heimleiter/in",
        "value": "Heimleiter/in"
    },
    {
        "label": "Heizanlagenwärter/in",
        "value": "Heizanlagenwärter/in"
    },
    {
        "label": "Heizer/in",
        "value": "Heizer/in"
    },
    {
        "label": "Anlagenmechaniker/in Sanitär-, Heizungs- und Klimatechnik",
        "value": "Anlagenmechaniker/in Sanitär-, Heizungs- und Klimatechnik"
    },
    {
        "label": "Handwerksmeister/in Ofen- und Luftheizungsbau",
        "value": "Handwerksmeister/in Ofen- und Luftheizungsbau"
    },
    {
        "label": "Heizungsbauer/in",
        "value": "Heizungsbauer/in"
    },
    {
        "label": "Heizungsinstallateur/in",
        "value": "Heizungsinstallateur/in"
    },
    {
        "label": "Heizungsmonteur/in",
        "value": "Heizungsmonteur/in"
    },
    {
        "label": "Heizungstechniker/in",
        "value": "Heizungstechniker/in"
    },
    {
        "label": "Fachkinderkrankenschwester/-pfleger ambulante Pflege",
        "value": "Fachkinderkrankenschwester/-pfleger ambulante Pflege"
    },
    {
        "label": "Helfer,in",
        "value": "Helfer,in"
    },
    {
        "label": "Hellseher/in",
        "value": "Hellseher/in"
    },
    {
        "label": "Fremdenheimleiter/in",
        "value": "Fremdenheimleiter/in"
    },
    {
        "label": "Hilfsarbeiter",
        "value": "Hilfsarbeiter"
    },
    {
        "label": "Betriebsgärtner/in",
        "value": "Betriebsgärtner/in"
    },
    {
        "label": "Historiker/in",
        "value": "Historiker/in"
    },
    {
        "label": "Hobler/in",
        "value": "Hobler/in"
    },
    {
        "label": "Hochbaufacharbeiter/in",
        "value": "Hochbaufacharbeiter/in"
    },
    {
        "label": "Bauleiter/in Hochbau",
        "value": "Bauleiter/in Hochbau"
    },
    {
        "label": "Hochbautechniker/in",
        "value": "Hochbautechniker/in"
    },
    {
        "label": "Hochbauvorarbeiter/in",
        "value": "Hochbauvorarbeiter/in"
    },
    {
        "label": "Hochbauhelfer/in",
        "value": "Hochbauhelfer/in"
    },
    {
        "label": "Drucker/in Hochdruck",
        "value": "Drucker/in Hochdruck"
    },
    {
        "label": "Hochdruckrohrschlosser/in",
        "value": "Hochdruckrohrschlosser/in"
    },
    {
        "label": "Hochfrequenztechniker/in",
        "value": "Hochfrequenztechniker/in"
    },
    {
        "label": "Hochofenarbeiter/in",
        "value": "Hochofenarbeiter/in"
    },
    {
        "label": "Hochschulassistent/in",
        "value": "Hochschulassistent/in"
    },
    {
        "label": "Hochschuldozent/in",
        "value": "Hochschuldozent/in"
    },
    {
        "label": "Hochschullehrer/in",
        "value": "Hochschullehrer/in"
    },
    {
        "label": "Hochseefischer/in (Matrose/Matrosin)",
        "value": "Hochseefischer/in (Matrose/Matrosin)"
    },
    {
        "label": "Glastechniker/in Hohlglas",
        "value": "Glastechniker/in Hohlglas"
    },
    {
        "label": "Holz- und Bautenschützer/in",
        "value": "Holz- und Bautenschützer/in"
    },
    {
        "label": "Holzarbeiter,in",
        "value": "Holzarbeiter,in"
    },
    {
        "label": "Holzbauer",
        "value": "Holzbauer"
    },
    {
        "label": "Holzbearbeiter,in",
        "value": "Holzbearbeiter,in"
    },
    {
        "label": "Holzbearbeitungsmechaniker/in",
        "value": "Holzbearbeitungsmechaniker/in"
    },
    {
        "label": "Holzbildhauer/in",
        "value": "Holzbildhauer/in"
    },
    {
        "label": "Bläser/in (Musiker/in)",
        "value": "Bläser/in (Musiker/in)"
    },
    {
        "label": "Holzfacharbeiter/in - Holzerzeugnisse",
        "value": "Holzfacharbeiter/in - Holzerzeugnisse"
    },
    {
        "label": "Fachwirt/in Holztechnik und Tischlermeister/in",
        "value": "Fachwirt/in Holztechnik und Tischlermeister/in"
    },
    {
        "label": "Holzfäller/in",
        "value": "Holzfäller/in"
    },
    {
        "label": "Holzfertigteilbauer/in",
        "value": "Holzfertigteilbauer/in"
    },
    {
        "label": "Holzfräser/in",
        "value": "Holzfräser/in"
    },
    {
        "label": "Holzgerätebauer/in",
        "value": "Holzgerätebauer/in"
    },
    {
        "label": "Holzhändler/in",
        "value": "Holzhändler/in"
    },
    {
        "label": "Holzhobler/in",
        "value": "Holzhobler/in"
    },
    {
        "label": "Holzkonstruktionsbauer/in",
        "value": "Holzkonstruktionsbauer/in"
    },
    {
        "label": "Holzlackierer",
        "value": "Holzlackierer"
    },
    {
        "label": "Holzmechaniker/in",
        "value": "Holzmechaniker/in"
    },
    {
        "label": "Holzoberflächenveredler/in",
        "value": "Holzoberflächenveredler/in"
    },
    {
        "label": "Holzrücker/in",
        "value": "Holzrücker/in"
    },
    {
        "label": "Holzschleifer/in",
        "value": "Holzschleifer/in"
    },
    {
        "label": "Holzspielwarenmacher/in",
        "value": "Holzspielwarenmacher/in"
    },
    {
        "label": "Holzspielzeugmacher/in",
        "value": "Holzspielzeugmacher/in"
    },
    {
        "label": "Holztechniker/in",
        "value": "Holztechniker/in"
    },
    {
        "label": "Holzwarenmacher/in",
        "value": "Holzwarenmacher/in"
    },
    {
        "label": "Hörgeräteakustiker/in",
        "value": "Hörgeräteakustiker/in"
    },
    {
        "label": "Hörfunkredakteur/in",
        "value": "Hörfunkredakteur/in"
    },
    {
        "label": "Hörgeräteakustikermeister/in",
        "value": "Hörgeräteakustikermeister/in"
    },
    {
        "label": "Hostess",
        "value": "Hostess"
    },
    {
        "label": "Hotelassistent/in",
        "value": "Hotelassistent/in"
    },
    {
        "label": "Gaststättengehilfe/-gehilfin",
        "value": "Gaststättengehilfe/-gehilfin"
    },
    {
        "label": "Hotelbesitzer/in",
        "value": "Hotelbesitzer/in"
    },
    {
        "label": "Hotelbetriebswirt/in",
        "value": "Hotelbetriebswirt/in"
    },
    {
        "label": "Hoteldiener/in",
        "value": "Hoteldiener/in"
    },
    {
        "label": "Front Office Cashier (Hotelempfang)",
        "value": "Front Office Cashier (Hotelempfang)"
    },
    {
        "label": "Hotelfachmann/-frau",
        "value": "Hotelfachmann/-frau"
    },
    {
        "label": "Hotelgehilfe/-gehilfin",
        "value": "Hotelgehilfe/-gehilfin"
    },
    {
        "label": "Hotelgeschäftsführer/in",
        "value": "Hotelgeschäftsführer/in"
    },
    {
        "label": "Hotelier",
        "value": "Hotelier"
    },
    {
        "label": "Hotelkaufmann/-frau",
        "value": "Hotelkaufmann/-frau"
    },
    {
        "label": "Hotelmanager/in",
        "value": "Hotelmanager/in"
    },
    {
        "label": "Hotelportier",
        "value": "Hotelportier"
    },
    {
        "label": "Hubschrauberführer/in",
        "value": "Hubschrauberführer/in"
    },
    {
        "label": "Hufbeschlagschmied/in",
        "value": "Hufbeschlagschmied/in"
    },
    {
        "label": "Hufschmied/in",
        "value": "Hufschmied/in"
    },
    {
        "label": "Humanmediziner/in",
        "value": "Humanmediziner/in"
    },
    {
        "label": "Humanbiologe/-biologin",
        "value": "Humanbiologe/-biologin"
    },
    {
        "label": "Hundeabrichter/in",
        "value": "Hundeabrichter/in"
    },
    {
        "label": "Hundefriseur/in",
        "value": "Hundefriseur/in"
    },
    {
        "label": "Hundeführer/in",
        "value": "Hundeführer/in"
    },
    {
        "label": "Hundepfleger/in",
        "value": "Hundepfleger/in"
    },
    {
        "label": "Hundetrainer/in",
        "value": "Hundetrainer/in"
    },
    {
        "label": "Hundezüchter/in",
        "value": "Hundezüchter/in"
    },
    {
        "label": "Hutmacher/in",
        "value": "Hutmacher/in"
    },
    {
        "label": "Hüttenfacharbeiter/in",
        "value": "Hüttenfacharbeiter/in"
    },
    {
        "label": "Hütteningenieur/in",
        "value": "Hütteningenieur/in"
    },
    {
        "label": "Hüttentechniker/in",
        "value": "Hüttentechniker/in"
    },
    {
        "label": "Hydrauliker/in",
        "value": "Hydrauliker/in"
    },
    {
        "label": "Hydraulikmonteur/in",
        "value": "Hydraulikmonteur/in"
    },
    {
        "label": "Hygienefachkraft",
        "value": "Hygienefachkraft"
    },
    {
        "label": "Hypnotiseur/in (Heilpraktiker/in)",
        "value": "Hypnotiseur/in (Heilpraktiker/in)"
    },
    {
        "label": "Illustrator/in",
        "value": "Illustrator/in"
    },
    {
        "label": "Imbissverkäufer/in",
        "value": "Imbissverkäufer/in"
    },
    {
        "label": "Imker/in",
        "value": "Imker/in"
    },
    {
        "label": "Immobilienberater/in",
        "value": "Immobilienberater/in"
    },
    {
        "label": "Immobilienmakler/in",
        "value": "Immobilienmakler/in"
    },
    {
        "label": "Immobilienverkäufer/in",
        "value": "Immobilienverkäufer/in"
    },
    {
        "label": "Immobilienvermittler/in",
        "value": "Immobilienvermittler/in"
    },
    {
        "label": "Importkaufmann/-frau",
        "value": "Importkaufmann/-frau"
    },
    {
        "label": "Importeur/in",
        "value": "Importeur/in"
    },
    {
        "label": "Inbetriebnahmetechniker/in",
        "value": "Inbetriebnahmetechniker/in"
    },
    {
        "label": "Inbetriebnahmeingenieur/in",
        "value": "Inbetriebnahmeingenieur/in"
    },
    {
        "label": "Industrieanlagenelektroniker/in",
        "value": "Industrieanlagenelektroniker/in"
    },
    {
        "label": "Industrieanlagenmechaniker/in",
        "value": "Industrieanlagenmechaniker/in"
    },
    {
        "label": "Industriemonteur/in",
        "value": "Industriemonteur/in"
    },
    {
        "label": "Industrialdesigner/in",
        "value": "Industrialdesigner/in"
    },
    {
        "label": "Industrieelektriker/in",
        "value": "Industrieelektriker/in"
    },
    {
        "label": "Industriefachwirt/in",
        "value": "Industriefachwirt/in"
    },
    {
        "label": "Industriefotograf/in",
        "value": "Industriefotograf/in"
    },
    {
        "label": "Industrieglasfertiger/in",
        "value": "Industrieglasfertiger/in"
    },
    {
        "label": "Industriekaufmann/-frau",
        "value": "Industriekaufmann/-frau"
    },
    {
        "label": "Industriekeramiker/in Anlagentechnik",
        "value": "Industriekeramiker/in Anlagentechnik"
    },
    {
        "label": "Industriekletterer/-kletterin",
        "value": "Industriekletterer/-kletterin"
    },
    {
        "label": "Industrielackierer/in",
        "value": "Industrielackierer/in"
    },
    {
        "label": "Industriemaler/in",
        "value": "Industriemaler/in"
    },
    {
        "label": "Industriemechaniker/in",
        "value": "Industriemechaniker/in"
    },
    {
        "label": "Industriemeister/in",
        "value": "Industriemeister/in"
    },
    {
        "label": "Industrienäher/in",
        "value": "Industrienäher/in"
    },
    {
        "label": "Industriereiniger/in (Gebäudereinigung)",
        "value": "Industriereiniger/in (Gebäudereinigung)"
    },
    {
        "label": "Industrieschlosser/in",
        "value": "Industrieschlosser/in"
    },
    {
        "label": "Industrieschreiner/in",
        "value": "Industrieschreiner/in"
    },
    {
        "label": "Informatikassistent/in",
        "value": "Informatikassistent/in"
    },
    {
        "label": "Informatiker/in (geprüft)",
        "value": "Informatiker/in (geprüft)"
    },
    {
        "label": "Informatiker/in (HS) Ingenieurinformatik",
        "value": "Informatiker/in (HS) Ingenieurinformatik"
    },
    {
        "label": "Informationselektriker/in",
        "value": "Informationselektriker/in"
    },
    {
        "label": "Informationsorganisator/in",
        "value": "Informationsorganisator/in"
    },
    {
        "label": "Informationstechnikermeister/in",
        "value": "Informationstechnikermeister/in"
    },
    {
        "label": "Informationstechnische/r Assistent/in",
        "value": "Informationstechnische/r Assistent/in"
    },
    {
        "label": "Betriebsingenieur",
        "value": "Betriebsingenieur"
    },
    {
        "label": "Verkaufsingenieur/in",
        "value": "Verkaufsingenieur/in"
    },
    {
        "label": "Ingenieur/in Informationstechnik",
        "value": "Ingenieur/in Informationstechnik"
    },
    {
        "label": "Ingenieur/in Medizintechnik",
        "value": "Ingenieur/in Medizintechnik"
    },
    {
        "label": "Ingenieurassistent/in Maschinenbautechnik",
        "value": "Ingenieurassistent/in Maschinenbautechnik"
    },
    {
        "label": "Ingenieurassistent/in Elektrotechnik",
        "value": "Ingenieurassistent/in Elektrotechnik"
    },
    {
        "label": "Maschinenbauingenieur/in",
        "value": "Maschinenbauingenieur/in"
    },
    {
        "label": "Inkassobeauftragte/r",
        "value": "Inkassobeauftragte/r"
    },
    {
        "label": "Innenausbauschreiner/in",
        "value": "Innenausbauschreiner/in"
    },
    {
        "label": "Innendienstmitarbeiter/in",
        "value": "Innendienstmitarbeiter/in"
    },
    {
        "label": "Innendienstleiter/in",
        "value": "Innendienstleiter/in"
    },
    {
        "label": "Inspektor/in Versicherung",
        "value": "Inspektor/in Versicherung"
    },
    {
        "label": "Installateur/in",
        "value": "Installateur/in"
    },
    {
        "label": "Installateurhelfer/in",
        "value": "Installateurhelfer/in"
    },
    {
        "label": "Installateurmeister/in",
        "value": "Installateurmeister/in"
    },
    {
        "label": "Instandhaltungsmechaniker/in",
        "value": "Instandhaltungsmechaniker/in"
    },
    {
        "label": "Instandhaltungsmeister/in",
        "value": "Instandhaltungsmeister/in"
    },
    {
        "label": "Instandhaltungstechniker/in",
        "value": "Instandhaltungstechniker/in"
    },
    {
        "label": "Instrumentallehrer/in",
        "value": "Instrumentallehrer/in"
    },
    {
        "label": "Musiker/in",
        "value": "Musiker/in"
    },
    {
        "label": "Musikinstrumentenbauer/in",
        "value": "Musikinstrumentenbauer/in"
    },
    {
        "label": "Krankenschwester/-pfleger",
        "value": "Krankenschwester/-pfleger"
    },
    {
        "label": "Internist/in",
        "value": "Internist/in"
    },
    {
        "label": "Investmentberater/in",
        "value": "Investmentberater/in"
    },
    {
        "label": "Isolierer/in",
        "value": "Isolierer/in"
    },
    {
        "label": "IT-System-Kaufmann/-frau",
        "value": "IT-System-Kaufmann/-frau"
    },
    {
        "label": "Jagdwaffenmechaniker/in",
        "value": "Jagdwaffenmechaniker/in"
    },
    {
        "label": "Jäger/in (Berufsjäger/in)",
        "value": "Jäger/in (Berufsjäger/in)"
    },
    {
        "label": "Jalousiebauer/in",
        "value": "Jalousiebauer/in"
    },
    {
        "label": "Jockey",
        "value": "Jockey"
    },
    {
        "label": "Hörfunk- und Multimediajournalist/in",
        "value": "Hörfunk- und Multimediajournalist/in"
    },
    {
        "label": "Zeitungsjournalist/in",
        "value": "Zeitungsjournalist/in"
    },
    {
        "label": "Jugendamtsleiter/in",
        "value": "Jugendamtsleiter/in"
    },
    {
        "label": "Jugendbildungsreferent/in",
        "value": "Jugendbildungsreferent/in"
    },
    {
        "label": "Jugendheimerzieher/in",
        "value": "Jugendheimerzieher/in"
    },
    {
        "label": "Jugendhelfer/in",
        "value": "Jugendhelfer/in"
    },
    {
        "label": "Jugendherbergsvater/-mutter",
        "value": "Jugendherbergsvater/-mutter"
    },
    {
        "label": "Jugendreferent/in",
        "value": "Jugendreferent/in"
    },
    {
        "label": "Jurist/in",
        "value": "Jurist/in"
    },
    {
        "label": "Justierer/in Maschinenbau",
        "value": "Justierer/in Maschinenbau"
    },
    {
        "label": "Justitiar/in",
        "value": "Justitiar/in"
    },
    {
        "label": "Justizfachangestellte/r",
        "value": "Justizfachangestellte/r"
    },
    {
        "label": "Justizbeamter/-beamtin (mittl. Dienst)",
        "value": "Justizbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Justizfachwirt/in",
        "value": "Justizfachwirt/in"
    },
    {
        "label": "Justizwachtmeister/in (einf. Dienst)",
        "value": "Justizwachtmeister/in (einf. Dienst)"
    },
    {
        "label": "Justizvollzugsbeamter/-beamtin (mittl. Dienst)",
        "value": "Justizvollzugsbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Goldschmied/in Juwelen",
        "value": "Goldschmied/in Juwelen"
    },
    {
        "label": "Juwelier/in",
        "value": "Juwelier/in"
    },
    {
        "label": "Kabelfertigungsmechaniker/in",
        "value": "Kabelfertigungsmechaniker/in"
    },
    {
        "label": "Kabelkonfektionierer/in",
        "value": "Kabelkonfektionierer/in"
    },
    {
        "label": "Kabelkonfektionsarbeiter/in",
        "value": "Kabelkonfektionsarbeiter/in"
    },
    {
        "label": "Kabelleger/in",
        "value": "Kabelleger/in"
    },
    {
        "label": "Kabinenbegleiter/in",
        "value": "Kabinenbegleiter/in"
    },
    {
        "label": "Kachelofen- und Luftheizungsbauer/in",
        "value": "Kachelofen- und Luftheizungsbauer/in"
    },
    {
        "label": "Kachelofenbauer/in",
        "value": "Kachelofenbauer/in"
    },
    {
        "label": "Barista",
        "value": "Barista"
    },
    {
        "label": "Kalkwerker/in",
        "value": "Kalkwerker/in"
    },
    {
        "label": "Kälteanlagenbauer/in",
        "value": "Kälteanlagenbauer/in"
    },
    {
        "label": "Kälteisolierer/in",
        "value": "Kälteisolierer/in"
    },
    {
        "label": "Kältemechaniker/in",
        "value": "Kältemechaniker/in"
    },
    {
        "label": "Kältetechniker/in",
        "value": "Kältetechniker/in"
    },
    {
        "label": "Kaltwalzenführer/in",
        "value": "Kaltwalzenführer/in"
    },
    {
        "label": "Kaltwalzer/in",
        "value": "Kaltwalzer/in"
    },
    {
        "label": "Kamera-Assistent/in",
        "value": "Kamera-Assistent/in"
    },
    {
        "label": "Kameramann/-frau",
        "value": "Kameramann/-frau"
    },
    {
        "label": "Kaminbauer/in (Schornsteinbau)",
        "value": "Kaminbauer/in (Schornsteinbau)"
    },
    {
        "label": "Kaminkehrer/in",
        "value": "Kaminkehrer/in"
    },
    {
        "label": "Kaminkehrermeister/in",
        "value": "Kaminkehrermeister/in"
    },
    {
        "label": "Kammerjäger/in",
        "value": "Kammerjäger/in"
    },
    {
        "label": "Kampfmittelbeseitiger/in",
        "value": "Kampfmittelbeseitiger/in"
    },
    {
        "label": "Kanalmeister/in",
        "value": "Kanalmeister/in"
    },
    {
        "label": "Kanalbauer/in",
        "value": "Kanalbauer/in"
    },
    {
        "label": "Kanalbaufacharbeiter/in",
        "value": "Kanalbaufacharbeiter/in"
    },
    {
        "label": "Kanalmaurer/in",
        "value": "Kanalmaurer/in"
    },
    {
        "label": "Kanalreiniger/in",
        "value": "Kanalreiniger/in"
    },
    {
        "label": "Kantinenfachkraft",
        "value": "Kantinenfachkraft"
    },
    {
        "label": "Kantinenleiter/in",
        "value": "Kantinenleiter/in"
    },
    {
        "label": "Kantinengehilfe/-gehilfin",
        "value": "Kantinengehilfe/-gehilfin"
    },
    {
        "label": "Kantinenpächter/in",
        "value": "Kantinenpächter/in"
    },
    {
        "label": "Kantinenverkäufer/in",
        "value": "Kantinenverkäufer/in"
    },
    {
        "label": "Konzertdirigent/in",
        "value": "Konzertdirigent/in"
    },
    {
        "label": "Schiffskapitän/in Binnenschifffahrt",
        "value": "Schiffskapitän/in Binnenschifffahrt"
    },
    {
        "label": "Schiffskapitän/in Seeschifffahrt",
        "value": "Schiffskapitän/in Seeschifffahrt"
    },
    {
        "label": "Kardiologe/Kardiologin",
        "value": "Kardiologe/Kardiologin"
    },
    {
        "label": "Kardiotechniker/in",
        "value": "Kardiotechniker/in"
    },
    {
        "label": "Karosserie- und Fahrzeugbauer/in - Fahrzeugbau",
        "value": "Karosserie- und Fahrzeugbauer/in - Fahrzeugbau"
    },
    {
        "label": "Karosserie- und Fahrzeugbautechniker/in",
        "value": "Karosserie- und Fahrzeugbautechniker/in"
    },
    {
        "label": "Karosseriebauer/in",
        "value": "Karosseriebauer/in"
    },
    {
        "label": "Karosseriebaufacharbeiter/in",
        "value": "Karosseriebaufacharbeiter/in"
    },
    {
        "label": "Karosseriebaumeister/in",
        "value": "Karosseriebaumeister/in"
    },
    {
        "label": "Karosseriebautechniker/in",
        "value": "Karosseriebautechniker/in"
    },
    {
        "label": "Karosserieflaschner/in",
        "value": "Karosserieflaschner/in"
    },
    {
        "label": "Kartenkontrolleur/in",
        "value": "Kartenkontrolleur/in"
    },
    {
        "label": "Kartenverkäufer/in",
        "value": "Kartenverkäufer/in"
    },
    {
        "label": "Kartografieingenieur/in",
        "value": "Kartografieingenieur/in"
    },
    {
        "label": "Kartograf/in",
        "value": "Kartograf/in"
    },
    {
        "label": "Kartonagenmacher/in",
        "value": "Kartonagenmacher/in"
    },
    {
        "label": "Kassenbuchhalter/in",
        "value": "Kassenbuchhalter/in"
    },
    {
        "label": "Kassenleiter/in",
        "value": "Kassenleiter/in"
    },
    {
        "label": "Kassierer/in Banken",
        "value": "Kassierer/in Banken"
    },
    {
        "label": "Kassierer/in Handel",
        "value": "Kassierer/in Handel"
    },
    {
        "label": "Kassierer/in Restaurant",
        "value": "Kassierer/in Restaurant"
    },
    {
        "label": "Katastertechniker/in",
        "value": "Katastertechniker/in"
    },
    {
        "label": "Katholische/r Gemeindereferent/in",
        "value": "Katholische/r Gemeindereferent/in"
    },
    {
        "label": "Katholische/r Diakon/in",
        "value": "Katholische/r Diakon/in"
    },
    {
        "label": "Kaufhausdetektiv/in",
        "value": "Kaufhausdetektiv/in"
    },
    {
        "label": "Kaufmännische/r Leiter/in",
        "value": "Kaufmännische/r Leiter/in"
    },
    {
        "label": "Kaufmann/-frau EDV-Systemtechnik",
        "value": "Kaufmann/-frau EDV-Systemtechnik"
    },
    {
        "label": "Kaufmann/-frau Bank",
        "value": "Kaufmann/-frau Bank"
    },
    {
        "label": "Kaufmann/-frau Informations- und Telekommunikationssysteme",
        "value": "Kaufmann/-frau Informations- und Telekommunikationssysteme"
    },
    {
        "label": "Kaufmann/-frau Einzelhandel (Lebensmittel)",
        "value": "Kaufmann/-frau Einzelhandel (Lebensmittel)"
    },
    {
        "label": "Kaufmann/-frau Groß- und Außenhandel",
        "value": "Kaufmann/-frau Groß- und Außenhandel"
    },
    {
        "label": "Luftfahrtkaufmann/-frau",
        "value": "Luftfahrtkaufmann/-frau"
    },
    {
        "label": "Kaufmann/-frau Spedition und Logistikdienstleistung",
        "value": "Kaufmann/-frau Spedition und Logistikdienstleistung"
    },
    {
        "label": "Kaufmann/-frau Eisenbahn- und Straßenverkehr",
        "value": "Kaufmann/-frau Eisenbahn- und Straßenverkehr"
    },
    {
        "label": "Kaufmann/-frau Versicherungen",
        "value": "Kaufmann/-frau Versicherungen"
    },
    {
        "label": "Kaufmann/-frau Marketingkommunikation",
        "value": "Kaufmann/-frau Marketingkommunikation"
    },
    {
        "label": "Wirtschaftskaufmann/-frau Wohnungswirtschaft",
        "value": "Wirtschaftskaufmann/-frau Wohnungswirtschaft"
    },
    {
        "label": "Kaufmännische/r Assistent/in (Wirtschaftsassistent/in) DV/Rechnungswesen",
        "value": "Kaufmännische/r Assistent/in (Wirtschaftsassistent/in) DV/Rechnungswesen"
    },
    {
        "label": "Assistent/in Gesundheits- und Sozialwesen",
        "value": "Assistent/in Gesundheits- und Sozialwesen"
    },
    {
        "label": "Kaufmännische/r Assistent/in Rechnungswesen",
        "value": "Kaufmännische/r Assistent/in Rechnungswesen"
    },
    {
        "label": "Kaufmännische/r Ausbilder/in",
        "value": "Kaufmännische/r Ausbilder/in"
    },
    {
        "label": "Kaufmännische/r Direktor/in",
        "value": "Kaufmännische/r Direktor/in"
    },
    {
        "label": "Kautschuktechniker/in",
        "value": "Kautschuktechniker/in"
    },
    {
        "label": "Kellermeister/in",
        "value": "Kellermeister/in"
    },
    {
        "label": "Kellner/in",
        "value": "Kellner/in"
    },
    {
        "label": "Keramikarbeiter/in",
        "value": "Keramikarbeiter/in"
    },
    {
        "label": "Keramiktechniker/in",
        "value": "Keramiktechniker/in"
    },
    {
        "label": "Kerammaler/in",
        "value": "Kerammaler/in"
    },
    {
        "label": "Kernbohrer/in",
        "value": "Kernbohrer/in"
    },
    {
        "label": "Kernmacher/in",
        "value": "Kernmacher/in"
    },
    {
        "label": "Kernphysiker/in",
        "value": "Kernphysiker/in"
    },
    {
        "label": "Kerntechniker/in",
        "value": "Kerntechniker/in"
    },
    {
        "label": "Heizkesselbauer/in",
        "value": "Heizkesselbauer/in"
    },
    {
        "label": "Kesselschlosser/in",
        "value": "Kesselschlosser/in"
    },
    {
        "label": "Kesselwärter/in",
        "value": "Kesselwärter/in"
    },
    {
        "label": "Kraftfahrzeugmechaniker/in",
        "value": "Kraftfahrzeugmechaniker/in"
    },
    {
        "label": "Kfz-Mechaniker/in",
        "value": "Kfz-Mechaniker/in"
    },
    {
        "label": "Kfz-Mechatroniker/in",
        "value": "Kfz-Mechatroniker/in"
    },
    {
        "label": "Kraftfahrzeugsachverständige/r",
        "value": "Kraftfahrzeugsachverständige/r"
    },
    {
        "label": "Kieferchirurg/in",
        "value": "Kieferchirurg/in"
    },
    {
        "label": "Zahnarzthilfe - Kieferorthopädie",
        "value": "Zahnarzthilfe - Kieferorthopädie"
    },
    {
        "label": "Kiesgewinner/in",
        "value": "Kiesgewinner/in"
    },
    {
        "label": "Kind",
        "value": "Kind"
    },
    {
        "label": "Kinderarzt/-ärztin",
        "value": "Kinderarzt/-ärztin"
    },
    {
        "label": "Kinderbetreuer/in",
        "value": "Kinderbetreuer/in"
    },
    {
        "label": "Kindererzieher/in",
        "value": "Kindererzieher/in"
    },
    {
        "label": "Kinderfrau",
        "value": "Kinderfrau"
    },
    {
        "label": "Kindergärtner/in",
        "value": "Kindergärtner/in"
    },
    {
        "label": "Kindergartenleiter/in",
        "value": "Kindergartenleiter/in"
    },
    {
        "label": "Kinderkrankenpflegehelfer/-in",
        "value": "Kinderkrankenpflegehelfer/-in"
    },
    {
        "label": "Kinderkrankenschwester/-pfleger",
        "value": "Kinderkrankenschwester/-pfleger"
    },
    {
        "label": "Kindermädchen",
        "value": "Kindermädchen"
    },
    {
        "label": "Kinderpfleger/in",
        "value": "Kinderpfleger/in"
    },
    {
        "label": "Kindertagesstättenleiter/in",
        "value": "Kindertagesstättenleiter/in"
    },
    {
        "label": "Kioskverkäufer/in",
        "value": "Kioskverkäufer/in"
    },
    {
        "label": "Kirchendiener/in",
        "value": "Kirchendiener/in"
    },
    {
        "label": "Kläranlagenwärter/in",
        "value": "Kläranlagenwärter/in"
    },
    {
        "label": "Klavier- und Cembalobauer/in",
        "value": "Klavier- und Cembalobauer/in"
    },
    {
        "label": "Klavierlehrer/in",
        "value": "Klavierlehrer/in"
    },
    {
        "label": "Klavierstimmer/in",
        "value": "Klavierstimmer/in"
    },
    {
        "label": "Klebeabdichter/in",
        "value": "Klebeabdichter/in"
    },
    {
        "label": "Kleindarsteller/in",
        "value": "Kleindarsteller/in"
    },
    {
        "label": "Transporteur/in",
        "value": "Transporteur/in"
    },
    {
        "label": "Klempner/in",
        "value": "Klempner/in"
    },
    {
        "label": "Klempnermeister/in",
        "value": "Klempnermeister/in"
    },
    {
        "label": "Lüftungstechniker/in",
        "value": "Lüftungstechniker/in"
    },
    {
        "label": "Lüftungsmonteur/in",
        "value": "Lüftungsmonteur/in"
    },
    {
        "label": "Klimaanlagenwärter/in",
        "value": "Klimaanlagenwärter/in"
    },
    {
        "label": "Klinikdirektor/in",
        "value": "Klinikdirektor/in"
    },
    {
        "label": "Klinikreferent/in",
        "value": "Klinikreferent/in"
    },
    {
        "label": "Klöppler/in",
        "value": "Klöppler/in"
    },
    {
        "label": "Koch/Köchin",
        "value": "Koch/Köchin"
    },
    {
        "label": "Kommissionierer/in",
        "value": "Kommissionierer/in"
    },
    {
        "label": "Kommunalbeamter/-beamtin (einf. Dienst)",
        "value": "Kommunalbeamter/-beamtin (einf. Dienst)"
    },
    {
        "label": "Kommunikationselektroniker/in",
        "value": "Kommunikationselektroniker/in"
    },
    {
        "label": "Kommunikationstechniker/in",
        "value": "Kommunikationstechniker/in"
    },
    {
        "label": "Kommunikationstrainer/in",
        "value": "Kommunikationstrainer/in"
    },
    {
        "label": "Kommunikationswirt/in",
        "value": "Kommunikationswirt/in"
    },
    {
        "label": "Komparse/Komparsin",
        "value": "Komparse/Komparsin"
    },
    {
        "label": "Komponist/in",
        "value": "Komponist/in"
    },
    {
        "label": "Kompressormaschinist/in",
        "value": "Kompressormaschinist/in"
    },
    {
        "label": "Konditor/in",
        "value": "Konditor/in"
    },
    {
        "label": "Konditoreiverkäufer/in",
        "value": "Konditoreiverkäufer/in"
    },
    {
        "label": "Konfektionsnäher/in",
        "value": "Konfektionsnäher/in"
    },
    {
        "label": "Konfektmacher/in",
        "value": "Konfektmacher/in"
    },
    {
        "label": "Konrektor/in (an allgemeinbildenden Schulen)",
        "value": "Konrektor/in (an allgemeinbildenden Schulen)"
    },
    {
        "label": "Konservierer/in - Lebensmittel",
        "value": "Konservierer/in - Lebensmittel"
    },
    {
        "label": "Konstrukteur/in",
        "value": "Konstrukteur/in"
    },
    {
        "label": "Konstruktionsingenieur/in",
        "value": "Konstruktionsingenieur/in"
    },
    {
        "label": "Konstruktionsleiter/in",
        "value": "Konstruktionsleiter/in"
    },
    {
        "label": "Konstruktionsmechaniker/in",
        "value": "Konstruktionsmechaniker/in"
    },
    {
        "label": "Konstruktionstechniker/in",
        "value": "Konstruktionstechniker/in"
    },
    {
        "label": "Konstruktionszeichner/in",
        "value": "Konstruktionszeichner/in"
    },
    {
        "label": "Kontakter/in",
        "value": "Kontakter/in"
    },
    {
        "label": "Kontorist/in",
        "value": "Kontorist/in"
    },
    {
        "label": "Kontrolleur/in",
        "value": "Kontrolleur/in"
    },
    {
        "label": "Koordinator/in",
        "value": "Koordinator/in"
    },
    {
        "label": "Kopfschlächter/in",
        "value": "Kopfschlächter/in"
    },
    {
        "label": "Korbmacher/in",
        "value": "Korbmacher/in"
    },
    {
        "label": "Korbwarenmacher/in",
        "value": "Korbwarenmacher/in"
    },
    {
        "label": "Korrektor/in",
        "value": "Korrektor/in"
    },
    {
        "label": "Korrespondent/in",
        "value": "Korrespondent/in"
    },
    {
        "label": "Fachkraft Korrosions- und Betonschutz",
        "value": "Fachkraft Korrosions- und Betonschutz"
    },
    {
        "label": "Kosmetikberater/in",
        "value": "Kosmetikberater/in"
    },
    {
        "label": "Kosmetiker/in",
        "value": "Kosmetiker/in"
    },
    {
        "label": "Kosmetiklaborant/in",
        "value": "Kosmetiklaborant/in"
    },
    {
        "label": "Kostümbildner/in",
        "value": "Kostümbildner/in"
    },
    {
        "label": "Kraftfahrsachverständige/r",
        "value": "Kraftfahrsachverständige/r"
    },
    {
        "label": "Kraftfahrzeugelektriker/in",
        "value": "Kraftfahrzeugelektriker/in"
    },
    {
        "label": "Kraftfahrzeugelektrikermeister/in",
        "value": "Kraftfahrzeugelektrikermeister/in"
    },
    {
        "label": "Kraftfahrzeug- Elektromechaniker/in",
        "value": "Kraftfahrzeug- Elektromechaniker/in"
    },
    {
        "label": "Kraftfahrzeugingenieur/in",
        "value": "Kraftfahrzeugingenieur/in"
    },
    {
        "label": "Kfz-Kaufmann/-frau",
        "value": "Kfz-Kaufmann/-frau"
    },
    {
        "label": "Kraftfahrzeuglackierer/in",
        "value": "Kraftfahrzeuglackierer/in"
    },
    {
        "label": "Kraftfahrzeugmechanikermeister/in",
        "value": "Kraftfahrzeugmechanikermeister/in"
    },
    {
        "label": "Kraftfahrzeugmechatroniker/in",
        "value": "Kraftfahrzeugmechatroniker/in"
    },
    {
        "label": "Kraftfahrzeugmeister/in",
        "value": "Kraftfahrzeugmeister/in"
    },
    {
        "label": "Kfz-Schlosser/in",
        "value": "Kfz-Schlosser/in"
    },
    {
        "label": "Kraftfahrzeugtechniker/in",
        "value": "Kraftfahrzeugtechniker/in"
    },
    {
        "label": "Kraftmaschinenwärter/in",
        "value": "Kraftmaschinenwärter/in"
    },
    {
        "label": "Kraftwerker/in",
        "value": "Kraftwerker/in"
    },
    {
        "label": "Kraftwerksmeister/in",
        "value": "Kraftwerksmeister/in"
    },
    {
        "label": "Kranbauschlosser/in",
        "value": "Kranbauschlosser/in"
    },
    {
        "label": "Kranfahrer/in",
        "value": "Kranfahrer/in"
    },
    {
        "label": "Kranführer/in",
        "value": "Kranführer/in"
    },
    {
        "label": "Kranken- und Altenpflegehelfer/in",
        "value": "Kranken- und Altenpflegehelfer/in"
    },
    {
        "label": "Krankengymnast/in",
        "value": "Krankengymnast/in"
    },
    {
        "label": "Medizintechnikingenieur/in",
        "value": "Medizintechnikingenieur/in"
    },
    {
        "label": "Krankenpflegehelfer/in",
        "value": "Krankenpflegehelfer/in"
    },
    {
        "label": "Krankentransporteur/in",
        "value": "Krankentransporteur/in"
    },
    {
        "label": "Versicherungsfachmann/-frau",
        "value": "Versicherungsfachmann/-frau"
    },
    {
        "label": "Krankenversicherungssachbearbeiter/in",
        "value": "Krankenversicherungssachbearbeiter/in"
    },
    {
        "label": "Krankenwagenfahrer/in",
        "value": "Krankenwagenfahrer/in"
    },
    {
        "label": "Kranmonteur/in",
        "value": "Kranmonteur/in"
    },
    {
        "label": "Kranzbinder/in",
        "value": "Kranzbinder/in"
    },
    {
        "label": "Näher/in - Wäscheschneiderhandwerk",
        "value": "Näher/in - Wäscheschneiderhandwerk"
    },
    {
        "label": "Kreditberater/in",
        "value": "Kreditberater/in"
    },
    {
        "label": "Beamter/Beamtin geh. nichttechn. Dienst",
        "value": "Beamter/Beamtin geh. nichttechn. Dienst"
    },
    {
        "label": "Kriminaler/in",
        "value": "Kriminaler/in"
    },
    {
        "label": "Kriminalist/in",
        "value": "Kriminalist/in"
    },
    {
        "label": "Krippenerzieher/in",
        "value": "Krippenerzieher/in"
    },
    {
        "label": "Küchenfachkraft",
        "value": "Küchenfachkraft"
    },
    {
        "label": "Küchenchef/in",
        "value": "Küchenchef/in"
    },
    {
        "label": "Küchengehilfe/-gehilfin",
        "value": "Küchengehilfe/-gehilfin"
    },
    {
        "label": "Küchenhelfer/in",
        "value": "Küchenhelfer/in"
    },
    {
        "label": "Küchenhilfe",
        "value": "Küchenhilfe"
    },
    {
        "label": "Küchenleiter/in",
        "value": "Küchenleiter/in"
    },
    {
        "label": "Küchenmeister/in",
        "value": "Küchenmeister/in"
    },
    {
        "label": "Küchenmonteur/in",
        "value": "Küchenmonteur/in"
    },
    {
        "label": "Küfer/in",
        "value": "Küfer/in"
    },
    {
        "label": "Kühlhausverwalter/in",
        "value": "Kühlhausverwalter/in"
    },
    {
        "label": "Kultur-, Wasserbauhelfer/in",
        "value": "Kultur-, Wasserbauhelfer/in"
    },
    {
        "label": "Kulturarbeiter/in (HS)",
        "value": "Kulturarbeiter/in (HS)"
    },
    {
        "label": "Kulturbauarbeiter/in",
        "value": "Kulturbauarbeiter/in"
    },
    {
        "label": "Kulturmanager/in",
        "value": "Kulturmanager/in"
    },
    {
        "label": "Kundenberater/in",
        "value": "Kundenberater/in"
    },
    {
        "label": "Kundenbetreuer/in",
        "value": "Kundenbetreuer/in"
    },
    {
        "label": "Kundendienstberater/in",
        "value": "Kundendienstberater/in"
    },
    {
        "label": "Kundendienstleiter/in kaufmännisch",
        "value": "Kundendienstleiter/in kaufmännisch"
    },
    {
        "label": "Kundendienstmechaniker/in",
        "value": "Kundendienstmechaniker/in"
    },
    {
        "label": "Kundendienstmonteur/in",
        "value": "Kundendienstmonteur/in"
    },
    {
        "label": "Kundendienstsachbearbeiter/in",
        "value": "Kundendienstsachbearbeiter/in"
    },
    {
        "label": "Kundendiensttechniker/in",
        "value": "Kundendiensttechniker/in"
    },
    {
        "label": "Kundendienstmonteur/in, -techniker/in",
        "value": "Kundendienstmonteur/in, -techniker/in"
    },
    {
        "label": "Kunstaugenbläser/in",
        "value": "Kunstaugenbläser/in"
    },
    {
        "label": "Kunstfotograf/in",
        "value": "Kunstfotograf/in"
    },
    {
        "label": "Kunstgewerbler/in",
        "value": "Kunstgewerbler/in"
    },
    {
        "label": "Kunstglaser/in",
        "value": "Kunstglaser/in"
    },
    {
        "label": "Kunsthändler/in",
        "value": "Kunsthändler/in"
    },
    {
        "label": "Kunsthistoriker/in",
        "value": "Kunsthistoriker/in"
    },
    {
        "label": "Kunstlehrer/in",
        "value": "Kunstlehrer/in"
    },
    {
        "label": "Künstler/in",
        "value": "Künstler/in"
    },
    {
        "label": "Künstlervermittler/in (Personalvermittlung)",
        "value": "Künstlervermittler/in (Personalvermittlung)"
    },
    {
        "label": "Kunstmaler/in",
        "value": "Kunstmaler/in"
    },
    {
        "label": "Kunstreiter/in",
        "value": "Kunstreiter/in"
    },
    {
        "label": "Kunstschlosser/in",
        "value": "Kunstschlosser/in"
    },
    {
        "label": "Kunstschmied/in",
        "value": "Kunstschmied/in"
    },
    {
        "label": "Kunststoffbearbeiter/in",
        "value": "Kunststoffbearbeiter/in"
    },
    {
        "label": "Kunststoffschlosser/in",
        "value": "Kunststoffschlosser/in"
    },
    {
        "label": "Kunststoffschweißer/in",
        "value": "Kunststoffschweißer/in"
    },
    {
        "label": "Kunststofftechniker/in",
        "value": "Kunststofftechniker/in"
    },
    {
        "label": "Ingenieur/in Kunststofftechnik",
        "value": "Ingenieur/in Kunststofftechnik"
    },
    {
        "label": "Kunststoffverarbeiter/in",
        "value": "Kunststoffverarbeiter/in"
    },
    {
        "label": "Kunsttherapeut/in",
        "value": "Kunsttherapeut/in"
    },
    {
        "label": "Kunsttischler/in",
        "value": "Kunsttischler/in"
    },
    {
        "label": "Kunstzeichner/in",
        "value": "Kunstzeichner/in"
    },
    {
        "label": "Kupferschmied/in",
        "value": "Kupferschmied/in"
    },
    {
        "label": "Kurierdienstfahrer/in",
        "value": "Kurierdienstfahrer/in"
    },
    {
        "label": "Kurierfahrer/in",
        "value": "Kurierfahrer/in"
    },
    {
        "label": "Kürschner/in",
        "value": "Kürschner/in"
    },
    {
        "label": "Kursleiter/in - Erwachsenenbildung",
        "value": "Kursleiter/in - Erwachsenenbildung"
    },
    {
        "label": "Küstenfischer/in (Matrose/Matrosin)",
        "value": "Küstenfischer/in (Matrose/Matrosin)"
    },
    {
        "label": "Küstenwächter/in",
        "value": "Küstenwächter/in"
    },
    {
        "label": "Küster/in",
        "value": "Küster/in"
    },
    {
        "label": "Kutscher/in",
        "value": "Kutscher/in"
    },
    {
        "label": "Kybernetiker/in",
        "value": "Kybernetiker/in"
    },
    {
        "label": "Laborant/in Biologie",
        "value": "Laborant/in Biologie"
    },
    {
        "label": "Laborant/in Chemie",
        "value": "Laborant/in Chemie"
    },
    {
        "label": "Laborant/in Landwirtschaft",
        "value": "Laborant/in Landwirtschaft"
    },
    {
        "label": "Landwirtschaftlich-technische/r Laborant/in",
        "value": "Landwirtschaftlich-technische/r Laborant/in"
    },
    {
        "label": "Labormeister/in - Milchindustrie",
        "value": "Labormeister/in - Milchindustrie"
    },
    {
        "label": "Laborant/in Apotheke",
        "value": "Laborant/in Apotheke"
    },
    {
        "label": "Medizinische/r Laborassistent/in",
        "value": "Medizinische/r Laborassistent/in"
    },
    {
        "label": "Laborhelfer/in - Chemie",
        "value": "Laborhelfer/in - Chemie"
    },
    {
        "label": "Laboringenieur/in (Chemie)",
        "value": "Laboringenieur/in (Chemie)"
    },
    {
        "label": "Laborleiter/in Biologie",
        "value": "Laborleiter/in Biologie"
    },
    {
        "label": "Labortechniker/in Biologie",
        "value": "Labortechniker/in Biologie"
    },
    {
        "label": "Maler/in und Lackierer/in",
        "value": "Maler/in und Lackierer/in"
    },
    {
        "label": "Lackierer/in Holz und Metall",
        "value": "Lackierer/in Holz und Metall"
    },
    {
        "label": "Lackierermeister/in",
        "value": "Lackierermeister/in"
    },
    {
        "label": "Lacklaborant/in",
        "value": "Lacklaborant/in"
    },
    {
        "label": "Lacktechniker/in",
        "value": "Lacktechniker/in"
    },
    {
        "label": "Lader/in (Transport)",
        "value": "Lader/in (Transport)"
    },
    {
        "label": "Ladebetriebsleiter/in",
        "value": "Ladebetriebsleiter/in"
    },
    {
        "label": "Ladungskontrolleur/in",
        "value": "Ladungskontrolleur/in"
    },
    {
        "label": "Lademeister/in",
        "value": "Lademeister/in"
    },
    {
        "label": "Ladenbauer/in",
        "value": "Ladenbauer/in"
    },
    {
        "label": "Lagerarbeiter/in",
        "value": "Lagerarbeiter/in"
    },
    {
        "label": "Lagerbuchhalter/in",
        "value": "Lagerbuchhalter/in"
    },
    {
        "label": "Lagerversandkaufmann/-frau",
        "value": "Lagerversandkaufmann/-frau"
    },
    {
        "label": "Lagerfacharbeiter/in",
        "value": "Lagerfacharbeiter/in"
    },
    {
        "label": "Lagerhelfer/in",
        "value": "Lagerhelfer/in"
    },
    {
        "label": "Lagerist/in",
        "value": "Lagerist/in"
    },
    {
        "label": "Lagerleiter/in (Warenlager)",
        "value": "Lagerleiter/in (Warenlager)"
    },
    {
        "label": "Lagermeister/in",
        "value": "Lagermeister/in"
    },
    {
        "label": "Lagerverwalter/in (Warenlager)",
        "value": "Lagerverwalter/in (Warenlager)"
    },
    {
        "label": "Lagerwirtschaftsmeister/in",
        "value": "Lagerwirtschaftsmeister/in"
    },
    {
        "label": "Landarbeiter/in",
        "value": "Landarbeiter/in"
    },
    {
        "label": "Landarbeitsaufseher/in",
        "value": "Landarbeitsaufseher/in"
    },
    {
        "label": "Landbautechniker/in",
        "value": "Landbautechniker/in"
    },
    {
        "label": "Bachelor of Arts Public Administration",
        "value": "Bachelor of Arts Public Administration"
    },
    {
        "label": "Ingenieur/in Landespflege/Landschaftsplanung/Landschaftsarchitektur",
        "value": "Ingenieur/in Landespflege/Landschaftsplanung/Landschaftsarchitektur"
    },
    {
        "label": "Landmaschinenführer/in",
        "value": "Landmaschinenführer/in"
    },
    {
        "label": "Landmaschinenmechaniker/in",
        "value": "Landmaschinenmechaniker/in"
    },
    {
        "label": "Landmaschinenmechanikermeister/in",
        "value": "Landmaschinenmechanikermeister/in"
    },
    {
        "label": "Landrat/-rätin",
        "value": "Landrat/-rätin"
    },
    {
        "label": "Landschaftsarchitekt/in",
        "value": "Landschaftsarchitekt/in"
    },
    {
        "label": "Landschaftsgärtner/in",
        "value": "Landschaftsgärtner/in"
    },
    {
        "label": "Landschaftsgestalter/in",
        "value": "Landschaftsgestalter/in"
    },
    {
        "label": "Landschaftspfleger/in",
        "value": "Landschaftspfleger/in"
    },
    {
        "label": "Landtagsabgeordnete/r",
        "value": "Landtagsabgeordnete/r"
    },
    {
        "label": "Landwirtschaftliche/r Berater/in",
        "value": "Landwirtschaftliche/r Berater/in"
    },
    {
        "label": "Landwirtschaftliche/r Hauswirtschafter/in",
        "value": "Landwirtschaftliche/r Hauswirtschafter/in"
    },
    {
        "label": "Landwirtschaftliche/r Lohnunternehmer/in",
        "value": "Landwirtschaftliche/r Lohnunternehmer/in"
    },
    {
        "label": "Landwirtschaftlich-technische/r Assistent/in",
        "value": "Landwirtschaftlich-technische/r Assistent/in"
    },
    {
        "label": "Landzusteller/in",
        "value": "Landzusteller/in"
    },
    {
        "label": "Lasertechniker/in",
        "value": "Lasertechniker/in"
    },
    {
        "label": "Layouter/in",
        "value": "Layouter/in"
    },
    {
        "label": "Leasingfachwirt/in",
        "value": "Leasingfachwirt/in"
    },
    {
        "label": "Leasing-Berater/in",
        "value": "Leasing-Berater/in"
    },
    {
        "label": "Lebensmittelchemiker/in",
        "value": "Lebensmittelchemiker/in"
    },
    {
        "label": "Lebensmittelhändler/in (Einzelhandel)",
        "value": "Lebensmittelhändler/in (Einzelhandel)"
    },
    {
        "label": "Lebensmittelkontrolleur/in",
        "value": "Lebensmittelkontrolleur/in"
    },
    {
        "label": "Fachkraft Lebensmittellabor",
        "value": "Fachkraft Lebensmittellabor"
    },
    {
        "label": "Lebensmitteltechniker/in",
        "value": "Lebensmitteltechniker/in"
    },
    {
        "label": "Lebensmitteltechnologe/-technologin",
        "value": "Lebensmitteltechnologe/-technologin"
    },
    {
        "label": "Ingenieur/in Lebensmitteltechnologie",
        "value": "Ingenieur/in Lebensmitteltechnologie"
    },
    {
        "label": "Lebensmittelverkäufer/in",
        "value": "Lebensmittelverkäufer/in"
    },
    {
        "label": "Versicherungskaufmann/-frau",
        "value": "Versicherungskaufmann/-frau"
    },
    {
        "label": "Lederbekleidungsnäher/in, -schneider/in",
        "value": "Lederbekleidungsnäher/in, -schneider/in"
    },
    {
        "label": "Lederfärber/in",
        "value": "Lederfärber/in"
    },
    {
        "label": "Lederherstellerhelfer/in",
        "value": "Lederherstellerhelfer/in"
    },
    {
        "label": "Ledernäher/in",
        "value": "Ledernäher/in"
    },
    {
        "label": "Lederstepper/in",
        "value": "Lederstepper/in"
    },
    {
        "label": "Ledertechniker/in",
        "value": "Ledertechniker/in"
    },
    {
        "label": "Lederverarbeiterhelfer/in",
        "value": "Lederverarbeiterhelfer/in"
    },
    {
        "label": "Lederwarenzurichter/in",
        "value": "Lederwarenzurichter/in"
    },
    {
        "label": "Lehramtsanwärter/in",
        "value": "Lehramtsanwärter/in"
    },
    {
        "label": "Lehrbeauftragte/r",
        "value": "Lehrbeauftragte/r"
    },
    {
        "label": "Lehrer/in Berufsbildende Schulen",
        "value": "Lehrer/in Berufsbildende Schulen"
    },
    {
        "label": "Lehrer/in Musikalische Grundausbildung",
        "value": "Lehrer/in Musikalische Grundausbildung"
    },
    {
        "label": "Lehrer/in rhythmisch-musikalische Erziehung",
        "value": "Lehrer/in rhythmisch-musikalische Erziehung"
    },
    {
        "label": "Lehrling (handwerklich)",
        "value": "Lehrling (handwerklich)"
    },
    {
        "label": "Leibwächter/in",
        "value": "Leibwächter/in"
    },
    {
        "label": "Leichenbestatter/in",
        "value": "Leichenbestatter/in"
    },
    {
        "label": "Leichtflugzeugbauer/in",
        "value": "Leichtflugzeugbauer/in"
    },
    {
        "label": "Metallschlosser/in",
        "value": "Metallschlosser/in"
    },
    {
        "label": "Leitende/r kaufmännische/r Angestellte/r",
        "value": "Leitende/r kaufmännische/r Angestellte/r"
    },
    {
        "label": "EDV-Leiter/in",
        "value": "EDV-Leiter/in"
    },
    {
        "label": "Leiter/in Rechenzentrum",
        "value": "Leiter/in Rechenzentrum"
    },
    {
        "label": "Leitstandsfahrer/in (Energieerzeugung)",
        "value": "Leitstandsfahrer/in (Energieerzeugung)"
    },
    {
        "label": "Oberleitungsmonteur/in",
        "value": "Oberleitungsmonteur/in"
    },
    {
        "label": "Lektor/in - Verlage",
        "value": "Lektor/in - Verlage"
    },
    {
        "label": "Lichttechniker/in",
        "value": "Lichttechniker/in"
    },
    {
        "label": "Lichtreklamehersteller/in",
        "value": "Lichtreklamehersteller/in"
    },
    {
        "label": "Lichtsetzer/in",
        "value": "Lichtsetzer/in"
    },
    {
        "label": "Limonadenhersteller/in",
        "value": "Limonadenhersteller/in"
    },
    {
        "label": "Linguist/in",
        "value": "Linguist/in"
    },
    {
        "label": "Maschinenführer/in",
        "value": "Maschinenführer/in"
    },
    {
        "label": "Lithograf/in",
        "value": "Lithograf/in"
    },
    {
        "label": "Litzenflechter/in",
        "value": "Litzenflechter/in"
    },
    {
        "label": "Lastkraftwagenfahrer/in",
        "value": "Lastkraftwagenfahrer/in"
    },
    {
        "label": "LKW-Mechaniker/in",
        "value": "LKW-Mechaniker/in"
    },
    {
        "label": "Locher/in",
        "value": "Locher/in"
    },
    {
        "label": "Logistiker/in",
        "value": "Logistiker/in"
    },
    {
        "label": "Logistik-Manager/in",
        "value": "Logistik-Manager/in"
    },
    {
        "label": "Logistikleiter/in",
        "value": "Logistikleiter/in"
    },
    {
        "label": "Logopäde/Logopädin",
        "value": "Logopäde/Logopädin"
    },
    {
        "label": "Lohnbuchhalter/in",
        "value": "Lohnbuchhalter/in"
    },
    {
        "label": "Lohnberechner/in",
        "value": "Lohnberechner/in"
    },
    {
        "label": "Lokomotivführer/in",
        "value": "Lokomotivführer/in"
    },
    {
        "label": "Lokomotivschlosser/in",
        "value": "Lokomotivschlosser/in"
    },
    {
        "label": "Löter/in",
        "value": "Löter/in"
    },
    {
        "label": "Lotse/Lotsin",
        "value": "Lotse/Lotsin"
    },
    {
        "label": "Wett- und Lotterieannehmer/in",
        "value": "Wett- und Lotterieannehmer/in"
    },
    {
        "label": "Luftfahrtingenieur/in",
        "value": "Luftfahrtingenieur/in"
    },
    {
        "label": "Pilot/in",
        "value": "Pilot/in"
    },
    {
        "label": "Luftfahrzeugtechniker/in",
        "value": "Luftfahrzeugtechniker/in"
    },
    {
        "label": "Luftheizungsbauer/in",
        "value": "Luftheizungsbauer/in"
    },
    {
        "label": "Lüftungs- und Klimaanlagenbauer/in",
        "value": "Lüftungs- und Klimaanlagenbauer/in"
    },
    {
        "label": "Heizungs-, Lüftungs- und Klimatechniker/in",
        "value": "Heizungs-, Lüftungs- und Klimatechniker/in"
    },
    {
        "label": "Lüftungsbauer/in",
        "value": "Lüftungsbauer/in"
    },
    {
        "label": "Lüftungsinstallateur/in",
        "value": "Lüftungsinstallateur/in"
    },
    {
        "label": "Lüftungsanlagenwärter/in",
        "value": "Lüftungsanlagenwärter/in"
    },
    {
        "label": "Luftverkehrskaufmann/-frau",
        "value": "Luftverkehrskaufmann/-frau"
    },
    {
        "label": "Lungenarzt/-ärztin",
        "value": "Lungenarzt/-ärztin"
    },
    {
        "label": "Makler/in",
        "value": "Makler/in"
    },
    {
        "label": "Maler/in",
        "value": "Maler/in"
    },
    {
        "label": "Malerhelfer/in",
        "value": "Malerhelfer/in"
    },
    {
        "label": "Malermeister/in",
        "value": "Malermeister/in"
    },
    {
        "label": "Mälzer/in",
        "value": "Mälzer/in"
    },
    {
        "label": "Sachbearbeiter/in Büro",
        "value": "Sachbearbeiter/in Büro"
    },
    {
        "label": "Manager/in",
        "value": "Manager/in"
    },
    {
        "label": "Mangler/in",
        "value": "Mangler/in"
    },
    {
        "label": "Margarinehersteller/in",
        "value": "Margarinehersteller/in"
    },
    {
        "label": "Marketingberater/in",
        "value": "Marketingberater/in"
    },
    {
        "label": "Marketingdirektor/in",
        "value": "Marketingdirektor/in"
    },
    {
        "label": "Marketingleiter/in",
        "value": "Marketingleiter/in"
    },
    {
        "label": "Marketingmanager/in",
        "value": "Marketingmanager/in"
    },
    {
        "label": "Markisenmonteur/in",
        "value": "Markisenmonteur/in"
    },
    {
        "label": "Marktanalytiker/in",
        "value": "Marktanalytiker/in"
    },
    {
        "label": "Marktforscher/in",
        "value": "Marktforscher/in"
    },
    {
        "label": "Marktleiter/in",
        "value": "Marktleiter/in"
    },
    {
        "label": "Maschenwarenfertiger/in",
        "value": "Maschenwarenfertiger/in"
    },
    {
        "label": "Maschinenarbeiter/in",
        "value": "Maschinenarbeiter/in"
    },
    {
        "label": "Maschinenbauer/in - Waagenbau",
        "value": "Maschinenbauer/in - Waagenbau"
    },
    {
        "label": "Maschinenbaumechaniker/in",
        "value": "Maschinenbaumechaniker/in"
    },
    {
        "label": "Maschinenbaumechanikermeister/in",
        "value": "Maschinenbaumechanikermeister/in"
    },
    {
        "label": "Maschinenbaumeister/in",
        "value": "Maschinenbaumeister/in"
    },
    {
        "label": "Maschinenbauschlosser/in",
        "value": "Maschinenbauschlosser/in"
    },
    {
        "label": "Maschinenbautechniker/in",
        "value": "Maschinenbautechniker/in"
    },
    {
        "label": "Maschinenbauzeichner/in",
        "value": "Maschinenbauzeichner/in"
    },
    {
        "label": "Maschinenbediener/in",
        "value": "Maschinenbediener/in"
    },
    {
        "label": "Maschineneinsteller/in",
        "value": "Maschineneinsteller/in"
    },
    {
        "label": "Maschinenformer/in",
        "value": "Maschinenformer/in"
    },
    {
        "label": "Maschinenhelfer/in",
        "value": "Maschinenhelfer/in"
    },
    {
        "label": "Glaswarenerzeuger/in",
        "value": "Glaswarenerzeuger/in"
    },
    {
        "label": "Maschinenkontrolleur/in",
        "value": "Maschinenkontrolleur/in"
    },
    {
        "label": "Maschinenreiniger/in",
        "value": "Maschinenreiniger/in"
    },
    {
        "label": "Maschinenschlosser/in",
        "value": "Maschinenschlosser/in"
    },
    {
        "label": "Maschinenschreiber/in",
        "value": "Maschinenschreiber/in"
    },
    {
        "label": "Maschinensetzer/in",
        "value": "Maschinensetzer/in"
    },
    {
        "label": "Schmuckwerker/in (§66 BBiG/§42m HwO)",
        "value": "Schmuckwerker/in (§66 BBiG/§42m HwO)"
    },
    {
        "label": "Maschinentechniker/in",
        "value": "Maschinentechniker/in"
    },
    {
        "label": "Maschinenwart/in",
        "value": "Maschinenwart/in"
    },
    {
        "label": "Maskenbildner/in",
        "value": "Maskenbildner/in"
    },
    {
        "label": "Masseur/in",
        "value": "Masseur/in"
    },
    {
        "label": "Maßschuhmacher/in",
        "value": "Maßschuhmacher/in"
    },
    {
        "label": "Warendisponent/in",
        "value": "Warendisponent/in"
    },
    {
        "label": "Materialwirtschaftsleiter/in",
        "value": "Materialwirtschaftsleiter/in"
    },
    {
        "label": "Mathematisch-technische/r Assistent/in",
        "value": "Mathematisch-technische/r Assistent/in"
    },
    {
        "label": "Matratzenhersteller/in",
        "value": "Matratzenhersteller/in"
    },
    {
        "label": "Matrizenbohrer/in",
        "value": "Matrizenbohrer/in"
    },
    {
        "label": "Alleinmatrose/-matrosin",
        "value": "Alleinmatrose/-matrosin"
    },
    {
        "label": "Maurer/in",
        "value": "Maurer/in"
    },
    {
        "label": "Maurermeister/in",
        "value": "Maurermeister/in"
    },
    {
        "label": "Maurerpolier/in",
        "value": "Maurerpolier/in"
    },
    {
        "label": "Mechaniker/in Industrie",
        "value": "Mechaniker/in Industrie"
    },
    {
        "label": "Mechanikermeister/in",
        "value": "Mechanikermeister/in"
    },
    {
        "label": "Mechatroniker/in",
        "value": "Mechatroniker/in"
    },
    {
        "label": "Media-Fachkraft",
        "value": "Media-Fachkraft"
    },
    {
        "label": "Mediendesigner/in (BFS)",
        "value": "Mediendesigner/in (BFS)"
    },
    {
        "label": "Mediendesigner/in (HS)",
        "value": "Mediendesigner/in (HS)"
    },
    {
        "label": "Mediengestalter/in",
        "value": "Mediengestalter/in"
    },
    {
        "label": "Kultur- und Medienpädagoge/-pädagogin",
        "value": "Kultur- und Medienpädagoge/-pädagogin"
    },
    {
        "label": "Medientechniker/in",
        "value": "Medientechniker/in"
    },
    {
        "label": "Medizinische/r Bademeister/in",
        "value": "Medizinische/r Bademeister/in"
    },
    {
        "label": "Medizinische/r Laborant/in",
        "value": "Medizinische/r Laborant/in"
    },
    {
        "label": "Medizinisch-technische/r Radiologieassistent/in",
        "value": "Medizinisch-technische/r Radiologieassistent/in"
    },
    {
        "label": "Medizinisch-technische/r Röntgenassistent/in",
        "value": "Medizinisch-technische/r Röntgenassistent/in"
    },
    {
        "label": "Medizinisch-technische/r Fachassistent/in",
        "value": "Medizinisch-technische/r Fachassistent/in"
    },
    {
        "label": "Medizintechniker/in",
        "value": "Medizintechniker/in"
    },
    {
        "label": "Melker/in",
        "value": "Melker/in"
    },
    {
        "label": "Mess- und Regelmechaniker/in",
        "value": "Mess- und Regelmechaniker/in"
    },
    {
        "label": "Messebauer/in",
        "value": "Messebauer/in"
    },
    {
        "label": "Messerschmied/in",
        "value": "Messerschmied/in"
    },
    {
        "label": "Messinstrumentenbauer/in (Feinwerktechnik)",
        "value": "Messinstrumentenbauer/in (Feinwerktechnik)"
    },
    {
        "label": "Messwerkzeugmacher/in",
        "value": "Messwerkzeugmacher/in"
    },
    {
        "label": "Metallarbeiter/in",
        "value": "Metallarbeiter/in"
    },
    {
        "label": "Metallbauer/in",
        "value": "Metallbauer/in"
    },
    {
        "label": "Metallbauschlosser/in",
        "value": "Metallbauschlosser/in"
    },
    {
        "label": "Metallbautechniker/in",
        "value": "Metallbautechniker/in"
    },
    {
        "label": "Metallbearbeiter/in",
        "value": "Metallbearbeiter/in"
    },
    {
        "label": "Metallbeizer/in",
        "value": "Metallbeizer/in"
    },
    {
        "label": "Metallbieger/in",
        "value": "Metallbieger/in"
    },
    {
        "label": "Metallblasinstrumentenmacher/in",
        "value": "Metallblasinstrumentenmacher/in"
    },
    {
        "label": "Metallfärber/in",
        "value": "Metallfärber/in"
    },
    {
        "label": "Metallfeinbauer/in",
        "value": "Metallfeinbauer/in"
    },
    {
        "label": "Metallfeinwerkzeugbauer/in",
        "value": "Metallfeinwerkzeugbauer/in"
    },
    {
        "label": "Metallflugzeugbauer/in",
        "value": "Metallflugzeugbauer/in"
    },
    {
        "label": "Gießer/in (Metallerzeugung)",
        "value": "Gießer/in (Metallerzeugung)"
    },
    {
        "label": "Metallgewebemacher/in",
        "value": "Metallgewebemacher/in"
    },
    {
        "label": "Metallkleber/in",
        "value": "Metallkleber/in"
    },
    {
        "label": "Metalloberflächenveredler/in",
        "value": "Metalloberflächenveredler/in"
    },
    {
        "label": "Spritzer/in Metalloberflächenveredlung",
        "value": "Spritzer/in Metalloberflächenveredlung"
    },
    {
        "label": "Metallpolierer/in",
        "value": "Metallpolierer/in"
    },
    {
        "label": "Metallpräger/in",
        "value": "Metallpräger/in"
    },
    {
        "label": "Metallprüftechniker/in",
        "value": "Metallprüftechniker/in"
    },
    {
        "label": "Metallsäger/in",
        "value": "Metallsäger/in"
    },
    {
        "label": "Metallschleifer/in",
        "value": "Metallschleifer/in"
    },
    {
        "label": "Metallschneider/in",
        "value": "Metallschneider/in"
    },
    {
        "label": "Metallvergüter/in, -härter/in",
        "value": "Metallvergüter/in, -härter/in"
    },
    {
        "label": "Metallwerker/in (§66 BBiG/§42m HwO)",
        "value": "Metallwerker/in (§66 BBiG/§42m HwO)"
    },
    {
        "label": "Werkzeugbauer/in",
        "value": "Werkzeugbauer/in"
    },
    {
        "label": "Metallzieherhelfer/in",
        "value": "Metallzieherhelfer/in"
    },
    {
        "label": "Meteorologe/Meteorologin",
        "value": "Meteorologe/Meteorologin"
    },
    {
        "label": "Metzger/in",
        "value": "Metzger/in"
    },
    {
        "label": "Metzgereifachverkäufer/in",
        "value": "Metzgereifachverkäufer/in"
    },
    {
        "label": "Metzgermeister/in",
        "value": "Metzgermeister/in"
    },
    {
        "label": "Schneider/in",
        "value": "Schneider/in"
    },
    {
        "label": "Mietwagenfahrer/in",
        "value": "Mietwagenfahrer/in"
    },
    {
        "label": "Mikroelektroniker/in",
        "value": "Mikroelektroniker/in"
    },
    {
        "label": "Mikrosystemtechnikingenieur/in",
        "value": "Mikrosystemtechnikingenieur/in"
    },
    {
        "label": "Mikrofotograf/in",
        "value": "Mikrofotograf/in"
    },
    {
        "label": "Milchkäser/in",
        "value": "Milchkäser/in"
    },
    {
        "label": "Milchprüfer/in (Milchleistungskontrolle)",
        "value": "Milchprüfer/in (Milchleistungskontrolle)"
    },
    {
        "label": "Milchwirtschaftliche/r Laborant/in",
        "value": "Milchwirtschaftliche/r Laborant/in"
    },
    {
        "label": "Mineralaufbereiter/in",
        "value": "Mineralaufbereiter/in"
    },
    {
        "label": "Erdwissenschaftler/in Mineralogie",
        "value": "Erdwissenschaftler/in Mineralogie"
    },
    {
        "label": "Mineralölverkäufer/in",
        "value": "Mineralölverkäufer/in"
    },
    {
        "label": "Mineralwasserhersteller/in",
        "value": "Mineralwasserhersteller/in"
    },
    {
        "label": "Minister/in",
        "value": "Minister/in"
    },
    {
        "label": "Ministerialbeamter/-beamtin (geh. Dienst)",
        "value": "Ministerialbeamter/-beamtin (geh. Dienst)"
    },
    {
        "label": "Mischmeister/in (Tontechnik)",
        "value": "Mischmeister/in (Tontechnik)"
    },
    {
        "label": "Zugschaffner/in",
        "value": "Zugschaffner/in"
    },
    {
        "label": "Möbelgestalter/in",
        "value": "Möbelgestalter/in"
    },
    {
        "label": "Fachkraft Küchen- und Möbelservice",
        "value": "Fachkraft Küchen- und Möbelservice"
    },
    {
        "label": "Möbelschreiner/in",
        "value": "Möbelschreiner/in"
    },
    {
        "label": "Möbelverkäufer/in",
        "value": "Möbelverkäufer/in"
    },
    {
        "label": "Möbellackierer/in",
        "value": "Möbellackierer/in"
    },
    {
        "label": "Möbelmonteur/in",
        "value": "Möbelmonteur/in"
    },
    {
        "label": "Möbelpacker/in",
        "value": "Möbelpacker/in"
    },
    {
        "label": "Polsterer/Polsterin",
        "value": "Polsterer/Polsterin"
    },
    {
        "label": "Möbelrestaurator/in",
        "value": "Möbelrestaurator/in"
    },
    {
        "label": "Spediteur/in",
        "value": "Spediteur/in"
    },
    {
        "label": "Möbeltischler/in",
        "value": "Möbeltischler/in"
    },
    {
        "label": "Modeberater/in",
        "value": "Modeberater/in"
    },
    {
        "label": "Direktrice",
        "value": "Direktrice"
    },
    {
        "label": "Modellbauer/in",
        "value": "Modellbauer/in"
    },
    {
        "label": "Modellschlosser/in",
        "value": "Modellschlosser/in"
    },
    {
        "label": "Modellschneider/in",
        "value": "Modellschneider/in"
    },
    {
        "label": "Modelltischler/in",
        "value": "Modelltischler/in"
    },
    {
        "label": "Moderator/in",
        "value": "Moderator/in"
    },
    {
        "label": "Modist/in",
        "value": "Modist/in"
    },
    {
        "label": "Molkereifacharbeiter/in",
        "value": "Molkereifacharbeiter/in"
    },
    {
        "label": "Molkereimeister/in",
        "value": "Molkereimeister/in"
    },
    {
        "label": "Montagearbeiter/in",
        "value": "Montagearbeiter/in"
    },
    {
        "label": "Montagehelfer/in",
        "value": "Montagehelfer/in"
    },
    {
        "label": "Montageingenieur/in",
        "value": "Montageingenieur/in"
    },
    {
        "label": "Montageleiter/in",
        "value": "Montageleiter/in"
    },
    {
        "label": "Montageschlosser/in",
        "value": "Montageschlosser/in"
    },
    {
        "label": "Schreiner/in",
        "value": "Schreiner/in"
    },
    {
        "label": "Montagetechniker/in",
        "value": "Montagetechniker/in"
    },
    {
        "label": "Montagetischler/in",
        "value": "Montagetischler/in"
    },
    {
        "label": "Montagefachkraft",
        "value": "Montagefachkraft"
    },
    {
        "label": "Montierer/in",
        "value": "Montierer/in"
    },
    {
        "label": "Mosaikleger/in",
        "value": "Mosaikleger/in"
    },
    {
        "label": "Trainer/in Erwachsenenbildung",
        "value": "Trainer/in Erwachsenenbildung"
    },
    {
        "label": "Motopäde/Motopädin",
        "value": "Motopäde/Motopädin"
    },
    {
        "label": "Schlosser/in",
        "value": "Schlosser/in"
    },
    {
        "label": "Motorradhändler/in",
        "value": "Motorradhändler/in"
    },
    {
        "label": "Mühlenfacharbeiter/in",
        "value": "Mühlenfacharbeiter/in"
    },
    {
        "label": "Müllarbeiter/in",
        "value": "Müllarbeiter/in"
    },
    {
        "label": "Müller/in",
        "value": "Müller/in"
    },
    {
        "label": "Müllermeister/in",
        "value": "Müllermeister/in"
    },
    {
        "label": "Müllwerker/in",
        "value": "Müllwerker/in"
    },
    {
        "label": "Munitionsräumer/in",
        "value": "Munitionsräumer/in"
    },
    {
        "label": "Museologe/Museologin",
        "value": "Museologe/Museologin"
    },
    {
        "label": "Musikalienhändler/in",
        "value": "Musikalienhändler/in"
    },
    {
        "label": "Musikmanager/in",
        "value": "Musikmanager/in"
    },
    {
        "label": "Musikproduzent/in",
        "value": "Musikproduzent/in"
    },
    {
        "label": "Musikschullehrer/in",
        "value": "Musikschullehrer/in"
    },
    {
        "label": "Musikschulleiter/in",
        "value": "Musikschulleiter/in"
    },
    {
        "label": "Musikverleger/in",
        "value": "Musikverleger/in"
    },
    {
        "label": "Musikwissenschaftler/in",
        "value": "Musikwissenschaftler/in"
    },
    {
        "label": "Programmierer/in",
        "value": "Programmierer/in"
    },
    {
        "label": "Näher/in (§66 BBiG/§42m HwO)",
        "value": "Näher/in (§66 BBiG/§42m HwO)"
    },
    {
        "label": "Nachrichtengerätemechaniker/in",
        "value": "Nachrichtengerätemechaniker/in"
    },
    {
        "label": "Nachrichteningenieur/in",
        "value": "Nachrichteningenieur/in"
    },
    {
        "label": "Nachrichtentechniker/in",
        "value": "Nachrichtentechniker/in"
    },
    {
        "label": "Nachrichtentechnikingenieur/in",
        "value": "Nachrichtentechnikingenieur/in"
    },
    {
        "label": "Nachtwächter/in",
        "value": "Nachtwächter/in"
    },
    {
        "label": "Nadelmacher/in",
        "value": "Nadelmacher/in"
    },
    {
        "label": "Nagelstylist/in",
        "value": "Nagelstylist/in"
    },
    {
        "label": "Nagelmodellist/in",
        "value": "Nagelmodellist/in"
    },
    {
        "label": "Näher",
        "value": "Näher"
    },
    {
        "label": "Nähmaschinenmechaniker/in",
        "value": "Nähmaschinenmechaniker/in"
    },
    {
        "label": "Nährmittelhersteller/in",
        "value": "Nährmittelhersteller/in"
    },
    {
        "label": "Nahrungs- und Genussmittelkoster/in",
        "value": "Nahrungs- und Genussmittelkoster/in"
    },
    {
        "label": "Nahrungsmittelfachverkäufer/in",
        "value": "Nahrungsmittelfachverkäufer/in"
    },
    {
        "label": "Nahrungsmittelingenieur/in",
        "value": "Nahrungsmittelingenieur/in"
    },
    {
        "label": "Nahrungsmitteltechniker/in",
        "value": "Nahrungsmitteltechniker/in"
    },
    {
        "label": "Nahrungsmittelverkäufer/in",
        "value": "Nahrungsmittelverkäufer/in"
    },
    {
        "label": "Natursteinmechaniker/in - Schleiftechnik",
        "value": "Natursteinmechaniker/in - Schleiftechnik"
    },
    {
        "label": "Natursteinbearbeiter/in",
        "value": "Natursteinbearbeiter/in"
    },
    {
        "label": "Natursteinschleifer/in",
        "value": "Natursteinschleifer/in"
    },
    {
        "label": "Naturwissenschaftler/in - Geweblicher Rechtsschutz",
        "value": "Naturwissenschaftler/in - Geweblicher Rechtsschutz"
    },
    {
        "label": "Nautiker/in",
        "value": "Nautiker/in"
    },
    {
        "label": "Nautische/r Schiffsoffizier/in",
        "value": "Nautische/r Schiffsoffizier/in"
    },
    {
        "label": "NC-Maschineneinrichter/in",
        "value": "NC-Maschineneinrichter/in"
    },
    {
        "label": "Nephrologe/Nephrologin",
        "value": "Nephrologe/Nephrologin"
    },
    {
        "label": "Nervenarzt/-ärztin",
        "value": "Nervenarzt/-ärztin"
    },
    {
        "label": "Netzadministrator/in (EDV)",
        "value": "Netzadministrator/in (EDV)"
    },
    {
        "label": "Netzbetreuer/in (EDV)",
        "value": "Netzbetreuer/in (EDV)"
    },
    {
        "label": "Netzmacher/in",
        "value": "Netzmacher/in"
    },
    {
        "label": "Netzwerkadministrator/in",
        "value": "Netzwerkadministrator/in"
    },
    {
        "label": "Netzwerkberater/in (EDV)",
        "value": "Netzwerkberater/in (EDV)"
    },
    {
        "label": "Netzwerkkoordinator/in (EDV)",
        "value": "Netzwerkkoordinator/in (EDV)"
    },
    {
        "label": "Netzwerkspezialist/in",
        "value": "Netzwerkspezialist/in"
    },
    {
        "label": "Netzwerktechniker/in",
        "value": "Netzwerktechniker/in"
    },
    {
        "label": "Neurologe/Neurologin",
        "value": "Neurologe/Neurologin"
    },
    {
        "label": "Niederlassungsleiter/in",
        "value": "Niederlassungsleiter/in"
    },
    {
        "label": "Nieter/in",
        "value": "Nieter/in"
    },
    {
        "label": "Notar/in",
        "value": "Notar/in"
    },
    {
        "label": "Notarfachangestellte/r",
        "value": "Notarfachangestellte/r"
    },
    {
        "label": "Notargehilfe/-gehilfin",
        "value": "Notargehilfe/-gehilfin"
    },
    {
        "label": "Notariatsassistent/in",
        "value": "Notariatsassistent/in"
    },
    {
        "label": "Notarzt/-ärztin",
        "value": "Notarzt/-ärztin"
    },
    {
        "label": "Oberarzt/-ärztin",
        "value": "Oberarzt/-ärztin"
    },
    {
        "label": "Oberbekleidungsnäher/in",
        "value": "Oberbekleidungsnäher/in"
    },
    {
        "label": "Feuerwehrbeamter/-beamtin (geh. techn. Dienst)",
        "value": "Feuerwehrbeamter/-beamtin (geh. techn. Dienst)"
    },
    {
        "label": "Oberkellner/in",
        "value": "Oberkellner/in"
    },
    {
        "label": "Objektleiter/in (Gebäudereinigung)",
        "value": "Objektleiter/in (Gebäudereinigung)"
    },
    {
        "label": "Wachmann/-frau Objektschutz",
        "value": "Wachmann/-frau Objektschutz"
    },
    {
        "label": "Oboist/in",
        "value": "Oboist/in"
    },
    {
        "label": "Obst- und Gemüsehändler/in",
        "value": "Obst- und Gemüsehändler/in"
    },
    {
        "label": "Obstbauer/-bäuerin (Gärtner/in)",
        "value": "Obstbauer/-bäuerin (Gärtner/in)"
    },
    {
        "label": "Obstkonservierer/in",
        "value": "Obstkonservierer/in"
    },
    {
        "label": "Obstverkäufer/in",
        "value": "Obstverkäufer/in"
    },
    {
        "label": "Oekotroph/in",
        "value": "Oekotroph/in"
    },
    {
        "label": "Ofenbauer/in",
        "value": "Ofenbauer/in"
    },
    {
        "label": "Ofensetzer/in",
        "value": "Ofensetzer/in"
    },
    {
        "label": "Offsetdrucker/in",
        "value": "Offsetdrucker/in"
    },
    {
        "label": "Omnibusfahrer/in",
        "value": "Omnibusfahrer/in"
    },
    {
        "label": "Omnibusverkehrsunternehmer/in",
        "value": "Omnibusverkehrsunternehmer/in"
    },
    {
        "label": "Operator/in EDV",
        "value": "Operator/in EDV"
    },
    {
        "label": "Opernsänger/in",
        "value": "Opernsänger/in"
    },
    {
        "label": "Optiker/in",
        "value": "Optiker/in"
    },
    {
        "label": "Optikgerätemechaniker/in",
        "value": "Optikgerätemechaniker/in"
    },
    {
        "label": "Orchesterinstrumentalist/in",
        "value": "Orchesterinstrumentalist/in"
    },
    {
        "label": "Ordensgeistliche/r",
        "value": "Ordensgeistliche/r"
    },
    {
        "label": "Ordner/in bei Veranstaltungen",
        "value": "Ordner/in bei Veranstaltungen"
    },
    {
        "label": "Organisationsberater/in",
        "value": "Organisationsberater/in"
    },
    {
        "label": "Organisationsleiter/in",
        "value": "Organisationsleiter/in"
    },
    {
        "label": "Organisationsprogrammierer/in",
        "value": "Organisationsprogrammierer/in"
    },
    {
        "label": "Organisator/in",
        "value": "Organisator/in"
    },
    {
        "label": "Organist/in",
        "value": "Organist/in"
    },
    {
        "label": "Orgel- und Harmoniumbauer/in",
        "value": "Orgel- und Harmoniumbauer/in"
    },
    {
        "label": "Orgelbauer/in",
        "value": "Orgelbauer/in"
    },
    {
        "label": "Orthopädiemechaniker/in",
        "value": "Orthopädiemechaniker/in"
    },
    {
        "label": "Orthopädiemechaniker/in und Bandagist/in",
        "value": "Orthopädiemechaniker/in und Bandagist/in"
    },
    {
        "label": "Orthopädieschuhmacher/in",
        "value": "Orthopädieschuhmacher/in"
    },
    {
        "label": "Orthopädieschuhmachermeister/in",
        "value": "Orthopädieschuhmachermeister/in"
    },
    {
        "label": "Orthopädietechniker/in",
        "value": "Orthopädietechniker/in"
    },
    {
        "label": "Orthoptist/in",
        "value": "Orthoptist/in"
    },
    {
        "label": "Packer/in",
        "value": "Packer/in"
    },
    {
        "label": "Pädagogische/r Mitarbeiter/in",
        "value": "Pädagogische/r Mitarbeiter/in"
    },
    {
        "label": "Paketzusteller,in",
        "value": "Paketzusteller,in"
    },
    {
        "label": "Pannendienstfahrer/in",
        "value": "Pannendienstfahrer/in"
    },
    {
        "label": "Facharbeiter/in Anlagentechnik (Papier, Karton, Pappe)",
        "value": "Facharbeiter/in Anlagentechnik (Papier, Karton, Pappe)"
    },
    {
        "label": "Papierhersteller/in",
        "value": "Papierhersteller/in"
    },
    {
        "label": "Papiermacher/in - Papier-Karton-Pappe",
        "value": "Papiermacher/in - Papier-Karton-Pappe"
    },
    {
        "label": "Papiermaschinenführer/in",
        "value": "Papiermaschinenführer/in"
    },
    {
        "label": "Papiertechniker/in",
        "value": "Papiertechniker/in"
    },
    {
        "label": "Papierverarbeiter/in",
        "value": "Papierverarbeiter/in"
    },
    {
        "label": "Papierverarbeitungstechniker/in",
        "value": "Papierverarbeitungstechniker/in"
    },
    {
        "label": "Papierwarenhersteller/in",
        "value": "Papierwarenhersteller/in"
    },
    {
        "label": "Parfümeriefachverkäufer/in",
        "value": "Parfümeriefachverkäufer/in"
    },
    {
        "label": "Parkanlagenwächter/in",
        "value": "Parkanlagenwächter/in"
    },
    {
        "label": "Parkettleger/in",
        "value": "Parkettleger/in"
    },
    {
        "label": "Parlamentsstenograf/in",
        "value": "Parlamentsstenograf/in"
    },
    {
        "label": "Parteifunktionär/in",
        "value": "Parteifunktionär/in"
    },
    {
        "label": "Priester/in",
        "value": "Priester/in"
    },
    {
        "label": "Pastoralreferent/in",
        "value": "Pastoralreferent/in"
    },
    {
        "label": "Patentanwalt/-anwältin",
        "value": "Patentanwalt/-anwältin"
    },
    {
        "label": "Patentanwaltsfachangestellte/r",
        "value": "Patentanwaltsfachangestellte/r"
    },
    {
        "label": "Patentanwaltsgehilfe/-gehilfin",
        "value": "Patentanwaltsgehilfe/-gehilfin"
    },
    {
        "label": "Patentingenieur/in",
        "value": "Patentingenieur/in"
    },
    {
        "label": "Patentsachbearbeiter/in",
        "value": "Patentsachbearbeiter/in"
    },
    {
        "label": "Pathologe/Pathologin",
        "value": "Pathologe/Pathologin"
    },
    {
        "label": "Patissier/Patissière",
        "value": "Patissier/Patissière"
    },
    {
        "label": "Instrumental- und Orchestermusiker/in",
        "value": "Instrumental- und Orchestermusiker/in"
    },
    {
        "label": "PC-Techniker/in",
        "value": "PC-Techniker/in"
    },
    {
        "label": "Pelznäher/in",
        "value": "Pelznäher/in"
    },
    {
        "label": "Pelzveredler/in",
        "value": "Pelzveredler/in"
    },
    {
        "label": "Pelzwerker/in",
        "value": "Pelzwerker/in"
    },
    {
        "label": "Pensionär",
        "value": "Pensionär"
    },
    {
        "label": "Pensionsinhaber/in",
        "value": "Pensionsinhaber/in"
    },
    {
        "label": "Personalberater/in",
        "value": "Personalberater/in"
    },
    {
        "label": "Personalbetreuer/in",
        "value": "Personalbetreuer/in"
    },
    {
        "label": "Personalchef/in",
        "value": "Personalchef/in"
    },
    {
        "label": "Personaldisponent/in",
        "value": "Personaldisponent/in"
    },
    {
        "label": "Personalentwickler/in",
        "value": "Personalentwickler/in"
    },
    {
        "label": "Personalfachkaufmann/-kauffrau",
        "value": "Personalfachkaufmann/-kauffrau"
    },
    {
        "label": "Personalfachwirt/in",
        "value": "Personalfachwirt/in"
    },
    {
        "label": "Personalleiter/in",
        "value": "Personalleiter/in"
    },
    {
        "label": "Personalrat/-rätin",
        "value": "Personalrat/-rätin"
    },
    {
        "label": "Personalratsvorsitzende/r",
        "value": "Personalratsvorsitzende/r"
    },
    {
        "label": "Personalreferent/in",
        "value": "Personalreferent/in"
    },
    {
        "label": "Personalsachbearbeiter/in",
        "value": "Personalsachbearbeiter/in"
    },
    {
        "label": "Personal Trainer/in (Sport und Fitness)",
        "value": "Personal Trainer/in (Sport und Fitness)"
    },
    {
        "label": "Personalvermittler/in",
        "value": "Personalvermittler/in"
    },
    {
        "label": "Sicherheitsbegleiter/in (Personenschutz)",
        "value": "Sicherheitsbegleiter/in (Personenschutz)"
    },
    {
        "label": "Perückenmacher/in",
        "value": "Perückenmacher/in"
    },
    {
        "label": "Pfarrsekretär/in",
        "value": "Pfarrsekretär/in"
    },
    {
        "label": "Pferdepfleger/in",
        "value": "Pferdepfleger/in"
    },
    {
        "label": "Tiertrainer/in",
        "value": "Tiertrainer/in"
    },
    {
        "label": "Pferdewirt/in",
        "value": "Pferdewirt/in"
    },
    {
        "label": "Pferdewirtschaftsmeister/in",
        "value": "Pferdewirtschaftsmeister/in"
    },
    {
        "label": "Pferdezüchter/in",
        "value": "Pferdezüchter/in"
    },
    {
        "label": "Pflanzenzüchter/in",
        "value": "Pflanzenzüchter/in"
    },
    {
        "label": "Pflanzenschützer/in",
        "value": "Pflanzenschützer/in"
    },
    {
        "label": "Pflanzenschutztechniker/in (Laborant/in)",
        "value": "Pflanzenschutztechniker/in (Laborant/in)"
    },
    {
        "label": "Pflasterer/Pflasterin",
        "value": "Pflasterer/Pflasterin"
    },
    {
        "label": "Steinschläger/in",
        "value": "Steinschläger/in"
    },
    {
        "label": "Pflegedienstleiter/in",
        "value": "Pflegedienstleiter/in"
    },
    {
        "label": "Pflegefachkraft",
        "value": "Pflegefachkraft"
    },
    {
        "label": "Pflegehelfer/in Krankenpflege",
        "value": "Pflegehelfer/in Krankenpflege"
    },
    {
        "label": "Pflegemutter/-vater - Kinderdorf",
        "value": "Pflegemutter/-vater - Kinderdorf"
    },
    {
        "label": "Pflegefachkraft Gesundheits- und Krankenpfleger",
        "value": "Pflegefachkraft Gesundheits- und Krankenpfleger"
    },
    {
        "label": "Pförtner/in",
        "value": "Pförtner/in"
    },
    {
        "label": "Pharmaberater/in",
        "value": "Pharmaberater/in"
    },
    {
        "label": "Pharma-Facharbeiter/in",
        "value": "Pharma-Facharbeiter/in"
    },
    {
        "label": "Pharmaingenieur/in",
        "value": "Pharmaingenieur/in"
    },
    {
        "label": "Pharmakant/in",
        "value": "Pharmakant/in"
    },
    {
        "label": "Pharmakologie-Assistent/in",
        "value": "Pharmakologie-Assistent/in"
    },
    {
        "label": "Laborant/in pharmazeutisches Labor",
        "value": "Laborant/in pharmazeutisches Labor"
    },
    {
        "label": "Pharmareferent/in",
        "value": "Pharmareferent/in"
    },
    {
        "label": "Ingenieur/in Pharmatechnik",
        "value": "Ingenieur/in Pharmatechnik"
    },
    {
        "label": "Pharmawerker/in",
        "value": "Pharmawerker/in"
    },
    {
        "label": "Pharmazeut/in",
        "value": "Pharmazeut/in"
    },
    {
        "label": "PTA (Pharmazeutisch-technische/r Assistent/in)",
        "value": "PTA (Pharmazeutisch-technische/r Assistent/in)"
    },
    {
        "label": "Pharmazeutisch-kaufmännische/r Angestellte/r",
        "value": "Pharmazeutisch-kaufmännische/r Angestellte/r"
    },
    {
        "label": "Pharmazieingenieur/in",
        "value": "Pharmazieingenieur/in"
    },
    {
        "label": "Phonotypist/in",
        "value": "Phonotypist/in"
    },
    {
        "label": "Physikalisch-technische/r Assistent/in",
        "value": "Physikalisch-technische/r Assistent/in"
    },
    {
        "label": "Ingenieur/in physikalische Technik",
        "value": "Ingenieur/in physikalische Technik"
    },
    {
        "label": "Physikingenieur/in",
        "value": "Physikingenieur/in"
    },
    {
        "label": "Physiklaborant/in",
        "value": "Physiklaborant/in"
    },
    {
        "label": "Physikotechniker/in",
        "value": "Physikotechniker/in"
    },
    {
        "label": "Physiotherapeut/in",
        "value": "Physiotherapeut/in"
    },
    {
        "label": "Pianist/in",
        "value": "Pianist/in"
    },
    {
        "label": "Pinselmacher/in",
        "value": "Pinselmacher/in"
    },
    {
        "label": "Pizzabäcker/in",
        "value": "Pizzabäcker/in"
    },
    {
        "label": "Restaurantinhaber/in",
        "value": "Restaurantinhaber/in"
    },
    {
        "label": "Plakatkleber/in",
        "value": "Plakatkleber/in"
    },
    {
        "label": "Plakatmaler/in",
        "value": "Plakatmaler/in"
    },
    {
        "label": "Planer/in",
        "value": "Planer/in"
    },
    {
        "label": "Planierraupenführer/in",
        "value": "Planierraupenführer/in"
    },
    {
        "label": "Planungsingenieur/in",
        "value": "Planungsingenieur/in"
    },
    {
        "label": "Techniker/in Produktionsplanung",
        "value": "Techniker/in Produktionsplanung"
    },
    {
        "label": "Platten- und Fliesenleger/in",
        "value": "Platten- und Fliesenleger/in"
    },
    {
        "label": "Plätter/in (Textilpflege)",
        "value": "Plätter/in (Textilpflege)"
    },
    {
        "label": "Platzanweiser/in",
        "value": "Platzanweiser/in"
    },
    {
        "label": "Platzwart/in",
        "value": "Platzwart/in"
    },
    {
        "label": "Plisseebrenner/in",
        "value": "Plisseebrenner/in"
    },
    {
        "label": "Podologe/Podologin",
        "value": "Podologe/Podologin"
    },
    {
        "label": "Polier/in Ausbau",
        "value": "Polier/in Ausbau"
    },
    {
        "label": "Polier,in",
        "value": "Polier,in"
    },
    {
        "label": "Politesse/Hilfspolizist (nicht Beamte)",
        "value": "Politesse/Hilfspolizist (nicht Beamte)"
    },
    {
        "label": "Politikwissenschaftler/in",
        "value": "Politikwissenschaftler/in"
    },
    {
        "label": "Politologe/Politologin",
        "value": "Politologe/Politologin"
    },
    {
        "label": "Polizist/in",
        "value": "Polizist/in"
    },
    {
        "label": "Kommissar/in (Polizei)",
        "value": "Kommissar/in (Polizei)"
    },
    {
        "label": "Polizeivollzugsbeamter/-beamtin (mittl. Dienst)",
        "value": "Polizeivollzugsbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Beamter/Beamtin Dokumentationsdienst (geh. Dienst)",
        "value": "Beamter/Beamtin Dokumentationsdienst (geh. Dienst)"
    },
    {
        "label": "Polizeivollzugsbeamter/-beamtin - Schutzpolizei (mittl. Dienst)",
        "value": "Polizeivollzugsbeamter/-beamtin - Schutzpolizei (mittl. Dienst)"
    },
    {
        "label": "Polster- und Dekorationsnäher/in",
        "value": "Polster- und Dekorationsnäher/in"
    },
    {
        "label": "Portalkranführer/in",
        "value": "Portalkranführer/in"
    },
    {
        "label": "Portier",
        "value": "Portier"
    },
    {
        "label": "Porträtfotograf/in",
        "value": "Porträtfotograf/in"
    },
    {
        "label": "Produktionshelfer/in Porzellanindustrie",
        "value": "Produktionshelfer/in Porzellanindustrie"
    },
    {
        "label": "Porzellanmaler/in",
        "value": "Porzellanmaler/in"
    },
    {
        "label": "Postfacharbeiter/in",
        "value": "Postfacharbeiter/in"
    },
    {
        "label": "Postbeamter/-beamtin (mittl. techn. Dienst)",
        "value": "Postbeamter/-beamtin (mittl. techn. Dienst)"
    },
    {
        "label": "Postangestellte/r",
        "value": "Postangestellte/r"
    },
    {
        "label": "Posthalter/in",
        "value": "Posthalter/in"
    },
    {
        "label": "Posthelfer/in",
        "value": "Posthelfer/in"
    },
    {
        "label": "Postverteiler/in",
        "value": "Postverteiler/in"
    },
    {
        "label": "Postzusteller/in",
        "value": "Postzusteller/in"
    },
    {
        "label": "Prägewalzengraveur/in",
        "value": "Prägewalzengraveur/in"
    },
    {
        "label": "Praktikant,in (handwerklich)",
        "value": "Praktikant,in (handwerklich)"
    },
    {
        "label": "Praktikant,in (kaufmännisch)",
        "value": "Praktikant,in (kaufmännisch)"
    },
    {
        "label": "Präparator/in",
        "value": "Präparator/in"
    },
    {
        "label": "Bundespräsident/in",
        "value": "Bundespräsident/in"
    },
    {
        "label": "Praxishelfer/in",
        "value": "Praxishelfer/in"
    },
    {
        "label": "PR-Berater/in",
        "value": "PR-Berater/in"
    },
    {
        "label": "Pressefotograf/in",
        "value": "Pressefotograf/in"
    },
    {
        "label": "Pressenführer/in",
        "value": "Pressenführer/in"
    },
    {
        "label": "Pressereferent/in",
        "value": "Pressereferent/in"
    },
    {
        "label": "Pressesprecher/in",
        "value": "Pressesprecher/in"
    },
    {
        "label": "Pressluftmeißelführer/in",
        "value": "Pressluftmeißelführer/in"
    },
    {
        "label": "Privatdetektiv/in",
        "value": "Privatdetektiv/in"
    },
    {
        "label": "Privatier",
        "value": "Privatier"
    },
    {
        "label": "Schulleiter/in Privatschulen",
        "value": "Schulleiter/in Privatschulen"
    },
    {
        "label": "Produktdesigner/in",
        "value": "Produktdesigner/in"
    },
    {
        "label": "Produktentwickler/in",
        "value": "Produktentwickler/in"
    },
    {
        "label": "Produktionsfacharbeiter/in Metall",
        "value": "Produktionsfacharbeiter/in Metall"
    },
    {
        "label": "Produktionshelfer/in Verpackung",
        "value": "Produktionshelfer/in Verpackung"
    },
    {
        "label": "Produktionsingenieur/in",
        "value": "Produktionsingenieur/in"
    },
    {
        "label": "Produktionskoordinator/in",
        "value": "Produktionskoordinator/in"
    },
    {
        "label": "Produktionsleiter/in",
        "value": "Produktionsleiter/in"
    },
    {
        "label": "Produktionsmechaniker/in",
        "value": "Produktionsmechaniker/in"
    },
    {
        "label": "Produktionsmeister/in",
        "value": "Produktionsmeister/in"
    },
    {
        "label": "Produktionsplaner/in",
        "value": "Produktionsplaner/in"
    },
    {
        "label": "Produktmanager/in",
        "value": "Produktmanager/in"
    },
    {
        "label": "Produzent/in",
        "value": "Produzent/in"
    },
    {
        "label": "Professor/in Hochschulen",
        "value": "Professor/in Hochschulen"
    },
    {
        "label": "Projektassistent/in",
        "value": "Projektassistent/in"
    },
    {
        "label": "Projektbearbeiter/in",
        "value": "Projektbearbeiter/in"
    },
    {
        "label": "Projektberater/in",
        "value": "Projektberater/in"
    },
    {
        "label": "Projektingenieur/in",
        "value": "Projektingenieur/in"
    },
    {
        "label": "Projektleiter/in",
        "value": "Projektleiter/in"
    },
    {
        "label": "EDV-Projektleiter/in",
        "value": "EDV-Projektleiter/in"
    },
    {
        "label": "Projektmanager/in",
        "value": "Projektmanager/in"
    },
    {
        "label": "Projektleiter/in - Organisation",
        "value": "Projektleiter/in - Organisation"
    },
    {
        "label": "Prokurist/in",
        "value": "Prokurist/in"
    },
    {
        "label": "Promoter/in",
        "value": "Promoter/in"
    },
    {
        "label": "Propagandist/in",
        "value": "Propagandist/in"
    },
    {
        "label": "Prostituierte/r",
        "value": "Prostituierte/r"
    },
    {
        "label": "Prozessingenieur/in",
        "value": "Prozessingenieur/in"
    },
    {
        "label": "Prozessleitelektroniker/in",
        "value": "Prozessleitelektroniker/in"
    },
    {
        "label": "Prüfer/in Qualitätskontrolle",
        "value": "Prüfer/in Qualitätskontrolle"
    },
    {
        "label": "Prüffeldelektroniker/in",
        "value": "Prüffeldelektroniker/in"
    },
    {
        "label": "Prüffeldmesstechniker/in",
        "value": "Prüffeldmesstechniker/in"
    },
    {
        "label": "Prüffeldmonteur/in",
        "value": "Prüffeldmonteur/in"
    },
    {
        "label": "Prüfingenieur/in",
        "value": "Prüfingenieur/in"
    },
    {
        "label": "TÜV-Ingenieur/in",
        "value": "TÜV-Ingenieur/in"
    },
    {
        "label": "Prüfmechaniker/in",
        "value": "Prüfmechaniker/in"
    },
    {
        "label": "Prüfplaner/in",
        "value": "Prüfplaner/in"
    },
    {
        "label": "Prüftechniker/in",
        "value": "Prüftechniker/in"
    },
    {
        "label": "Psychotherapeut/in",
        "value": "Psychotherapeut/in"
    },
    {
        "label": "Psychiater/in",
        "value": "Psychiater/in"
    },
    {
        "label": "Krankenschwester/-pfleger Psychiatrie",
        "value": "Krankenschwester/-pfleger Psychiatrie"
    },
    {
        "label": "Psychologisch-technische/r Assistent/in",
        "value": "Psychologisch-technische/r Assistent/in"
    },
    {
        "label": "Arzt/Ärztin Psychotherapie",
        "value": "Arzt/Ärztin Psychotherapie"
    },
    {
        "label": "PR-Fachmann/-frau",
        "value": "PR-Fachmann/-frau"
    },
    {
        "label": "Public-Relations-Berater/in",
        "value": "Public-Relations-Berater/in"
    },
    {
        "label": "Publizist/in",
        "value": "Publizist/in"
    },
    {
        "label": "Maschinist/in Pump- und Verdichteranlagen",
        "value": "Maschinist/in Pump- und Verdichteranlagen"
    },
    {
        "label": "Pumpenschlosser/in",
        "value": "Pumpenschlosser/in"
    },
    {
        "label": "Pumpenprüfer/in",
        "value": "Pumpenprüfer/in"
    },
    {
        "label": "Puppenmacher/in",
        "value": "Puppenmacher/in"
    },
    {
        "label": "Puppenspieler/in",
        "value": "Puppenspieler/in"
    },
    {
        "label": "Purser/Purserette (Luftfahrt)",
        "value": "Purser/Purserette (Luftfahrt)"
    },
    {
        "label": "Reinigungskraft",
        "value": "Reinigungskraft"
    },
    {
        "label": "Pyrotechniker/in",
        "value": "Pyrotechniker/in"
    },
    {
        "label": "Qualitätsingenieur/in",
        "value": "Qualitätsingenieur/in"
    },
    {
        "label": "Qualitätskontrolleur/in",
        "value": "Qualitätskontrolleur/in"
    },
    {
        "label": "Qualitätsmanager/in",
        "value": "Qualitätsmanager/in"
    },
    {
        "label": "Qualitätsprüfer/in",
        "value": "Qualitätsprüfer/in"
    },
    {
        "label": "Qualitätssicherer/in",
        "value": "Qualitätssicherer/in"
    },
    {
        "label": "Qualitätstechniker/in",
        "value": "Qualitätstechniker/in"
    },
    {
        "label": "Radarmechaniker/in",
        "value": "Radarmechaniker/in"
    },
    {
        "label": "Radialbohrer/in",
        "value": "Radialbohrer/in"
    },
    {
        "label": "Radio- und Fernsehtechniker/in",
        "value": "Radio- und Fernsehtechniker/in"
    },
    {
        "label": "Radio- und Fernsehtechnikermeister/in",
        "value": "Radio- und Fernsehtechnikermeister/in"
    },
    {
        "label": "Tontechniker/in",
        "value": "Tontechniker/in"
    },
    {
        "label": "Radiologieassistent/in",
        "value": "Radiologieassistent/in"
    },
    {
        "label": "Radiomechaniker/in",
        "value": "Radiomechaniker/in"
    },
    {
        "label": "Radiotechniker/in",
        "value": "Radiotechniker/in"
    },
    {
        "label": "Radladerfahrer/in",
        "value": "Radladerfahrer/in"
    },
    {
        "label": "Radrennfahrer/in",
        "value": "Radrennfahrer/in"
    },
    {
        "label": "Rammer/in (Pfahlrammer)",
        "value": "Rammer/in (Pfahlrammer)"
    },
    {
        "label": "Ramp-Agent/in",
        "value": "Ramp-Agent/in"
    },
    {
        "label": "Rangierer/in - Eisenbahn",
        "value": "Rangierer/in - Eisenbahn"
    },
    {
        "label": "Rangiermeister/in",
        "value": "Rangiermeister/in"
    },
    {
        "label": "Raumgestalter/in",
        "value": "Raumgestalter/in"
    },
    {
        "label": "Innenausstatter/in",
        "value": "Innenausstatter/in"
    },
    {
        "label": "Raumreiniger/in",
        "value": "Raumreiniger/in"
    },
    {
        "label": "Raumplaner/in",
        "value": "Raumplaner/in"
    },
    {
        "label": "Energieingenieur/in",
        "value": "Energieingenieur/in"
    },
    {
        "label": "Haupt- und Realschullehrer/in",
        "value": "Haupt- und Realschullehrer/in"
    },
    {
        "label": "Rebenveredler/in",
        "value": "Rebenveredler/in"
    },
    {
        "label": "Rechenzentrumsfachmann/-frau",
        "value": "Rechenzentrumsfachmann/-frau"
    },
    {
        "label": "Rechnungsprüfer/in",
        "value": "Rechnungsprüfer/in"
    },
    {
        "label": "Rechnungswesensachbearbeiter/in",
        "value": "Rechnungswesensachbearbeiter/in"
    },
    {
        "label": "Rechtsanwalt/Rechtsanwältin",
        "value": "Rechtsanwalt/Rechtsanwältin"
    },
    {
        "label": "Rechtsanwalts- und Notargehilfe/-gehilfin",
        "value": "Rechtsanwalts- und Notargehilfe/-gehilfin"
    },
    {
        "label": "Rechtsanwaltsgehilfe/-gehilfin",
        "value": "Rechtsanwaltsgehilfe/-gehilfin"
    },
    {
        "label": "Rechtsbeistand",
        "value": "Rechtsbeistand"
    },
    {
        "label": "Rechtliche/r Berater/in",
        "value": "Rechtliche/r Berater/in"
    },
    {
        "label": "Rechtsreferent/in (Kammern, Verbände)",
        "value": "Rechtsreferent/in (Kammern, Verbände)"
    },
    {
        "label": "Recycling-Fachkraft",
        "value": "Recycling-Fachkraft"
    },
    {
        "label": "Redakteur/in",
        "value": "Redakteur/in"
    },
    {
        "label": "Redaktionsassistent/in",
        "value": "Redaktionsassistent/in"
    },
    {
        "label": "Redaktionsdirektor/in",
        "value": "Redaktionsdirektor/in"
    },
    {
        "label": "Reedereikaufmann/-frau",
        "value": "Reedereikaufmann/-frau"
    },
    {
        "label": "Dachdecker/in Reetdachtechnik",
        "value": "Dachdecker/in Reetdachtechnik"
    },
    {
        "label": "REFA-Fachmann/-frau",
        "value": "REFA-Fachmann/-frau"
    },
    {
        "label": "REFA-Ingenieur/in (Industrial Engineering)",
        "value": "REFA-Ingenieur/in (Industrial Engineering)"
    },
    {
        "label": "REFA-Techniker/in",
        "value": "REFA-Techniker/in"
    },
    {
        "label": "Referatsleiter/in",
        "value": "Referatsleiter/in"
    },
    {
        "label": "Referent/in Rechtsfragen",
        "value": "Referent/in Rechtsfragen"
    },
    {
        "label": "IT-Referent/in",
        "value": "IT-Referent/in"
    },
    {
        "label": "Reformhauskaufmann/-frau",
        "value": "Reformhauskaufmann/-frau"
    },
    {
        "label": "Regeltechniker/in",
        "value": "Regeltechniker/in"
    },
    {
        "label": "Beamter/Beamtin Bund (geh. Dienst)",
        "value": "Beamter/Beamtin Bund (geh. Dienst)"
    },
    {
        "label": "Sekretär/in",
        "value": "Sekretär/in"
    },
    {
        "label": "Regionalleiter/in",
        "value": "Regionalleiter/in"
    },
    {
        "label": "Regionalplaner/in",
        "value": "Regionalplaner/in"
    },
    {
        "label": "Regisseur/in",
        "value": "Regisseur/in"
    },
    {
        "label": "Registrator/in",
        "value": "Registrator/in"
    },
    {
        "label": "Reha-Berater/in",
        "value": "Reha-Berater/in"
    },
    {
        "label": "Rehabilitationsberater/in",
        "value": "Rehabilitationsberater/in"
    },
    {
        "label": "Techniker/in Rehabilitationstechnik",
        "value": "Techniker/in Rehabilitationstechnik"
    },
    {
        "label": "Reifenhändler/in",
        "value": "Reifenhändler/in"
    },
    {
        "label": "Reifenbauer/in",
        "value": "Reifenbauer/in"
    },
    {
        "label": "Reifenmonteur/in",
        "value": "Reifenmonteur/in"
    },
    {
        "label": "Reifenvulkaniseur/in",
        "value": "Reifenvulkaniseur/in"
    },
    {
        "label": "Reinigungsfachkraft",
        "value": "Reinigungsfachkraft"
    },
    {
        "label": "Reisefachberater/in",
        "value": "Reisefachberater/in"
    },
    {
        "label": "Reisebüromitarbeiter/in",
        "value": "Reisebüromitarbeiter/in"
    },
    {
        "label": "Reisebüroinhaber/in",
        "value": "Reisebüroinhaber/in"
    },
    {
        "label": "Reisebürokaufmann/-kauffrau",
        "value": "Reisebürokaufmann/-kauffrau"
    },
    {
        "label": "Reiseverkehrskaufmann/-frau",
        "value": "Reiseverkehrskaufmann/-frau"
    },
    {
        "label": "Reiseleiter/in",
        "value": "Reiseleiter/in"
    },
    {
        "label": "Reisende/r",
        "value": "Reisende/r"
    },
    {
        "label": "Reitlehrer/in",
        "value": "Reitlehrer/in"
    },
    {
        "label": "Reitwart/in",
        "value": "Reitwart/in"
    },
    {
        "label": "Schuldirektor/in",
        "value": "Schuldirektor/in"
    },
    {
        "label": "Rennreiter/in",
        "value": "Rennreiter/in"
    },
    {
        "label": "Rennfahrer/in",
        "value": "Rennfahrer/in"
    },
    {
        "label": "Reparaturschlosser/in",
        "value": "Reparaturschlosser/in"
    },
    {
        "label": "Reporter/in",
        "value": "Reporter/in"
    },
    {
        "label": "Repräsentant/in Außendienst",
        "value": "Repräsentant/in Außendienst"
    },
    {
        "label": "Digitaldrucker/in",
        "value": "Digitaldrucker/in"
    },
    {
        "label": "Requisiteur/in",
        "value": "Requisiteur/in"
    },
    {
        "label": "Restaurantkellner/in",
        "value": "Restaurantkellner/in"
    },
    {
        "label": "Restaurantfachmann/-frau",
        "value": "Restaurantfachmann/-frau"
    },
    {
        "label": "Restaurantgeschäftsführer/in",
        "value": "Restaurantgeschäftsführer/in"
    },
    {
        "label": "Restaurantleiter/in",
        "value": "Restaurantleiter/in"
    },
    {
        "label": "Restaurantmanager/in",
        "value": "Restaurantmanager/in"
    },
    {
        "label": "Rettungsassistent/in",
        "value": "Rettungsassistent/in"
    },
    {
        "label": "Rettungssanitäter/in",
        "value": "Rettungssanitäter/in"
    },
    {
        "label": "Rettungsschwimmer/in",
        "value": "Rettungsschwimmer/in"
    },
    {
        "label": "Revierförster/in",
        "value": "Revierförster/in"
    },
    {
        "label": "Revisor/in",
        "value": "Revisor/in"
    },
    {
        "label": "Rezeptionist/in (Hotel)",
        "value": "Rezeptionist/in (Hotel)"
    },
    {
        "label": "Richter/in",
        "value": "Richter/in"
    },
    {
        "label": "Richtmeister/in",
        "value": "Richtmeister/in"
    },
    {
        "label": "Kanalwärter/in (nicht Abwasserkanal)",
        "value": "Kanalwärter/in (nicht Abwasserkanal)"
    },
    {
        "label": "Rinderzüchter/in",
        "value": "Rinderzüchter/in"
    },
    {
        "label": "Roboterführer/in",
        "value": "Roboterführer/in"
    },
    {
        "label": "Roboterschweißer/in",
        "value": "Roboterschweißer/in"
    },
    {
        "label": "Rohrflechter/in",
        "value": "Rohrflechter/in"
    },
    {
        "label": "Rohrinstallateur/in",
        "value": "Rohrinstallateur/in"
    },
    {
        "label": "Rohrisolierer/in",
        "value": "Rohrisolierer/in"
    },
    {
        "label": "Rohrleger/in",
        "value": "Rohrleger/in"
    },
    {
        "label": "Rohrleitungsbauer/in",
        "value": "Rohrleitungsbauer/in"
    },
    {
        "label": "Rohrnetzbauer/in",
        "value": "Rohrnetzbauer/in"
    },
    {
        "label": "Rohrnetzmonteur/in",
        "value": "Rohrnetzmonteur/in"
    },
    {
        "label": "Rohrreiniger/in",
        "value": "Rohrreiniger/in"
    },
    {
        "label": "Rohrschlosser/in",
        "value": "Rohrschlosser/in"
    },
    {
        "label": "Rohrzieher/in (Metall)",
        "value": "Rohrzieher/in (Metall)"
    },
    {
        "label": "Konstruktionstechniker/in Rolladen- und Jalousiebau",
        "value": "Konstruktionstechniker/in Rolladen- und Jalousiebau"
    },
    {
        "label": "Röntgenassistent/in",
        "value": "Röntgenassistent/in"
    },
    {
        "label": "Fachmann/-frau Systemgastronomie",
        "value": "Fachmann/-frau Systemgastronomie"
    },
    {
        "label": "Rundfunkmechaniker/in",
        "value": "Rundfunkmechaniker/in"
    },
    {
        "label": "Rundfunksprecher/in",
        "value": "Rundfunksprecher/in"
    },
    {
        "label": "Saaldiener/in",
        "value": "Saaldiener/in"
    },
    {
        "label": "Saatzüchter/in",
        "value": "Saatzüchter/in"
    },
    {
        "label": "Sachbearbeiter/in",
        "value": "Sachbearbeiter/in"
    },
    {
        "label": "Sachgebietsleiter/in",
        "value": "Sachgebietsleiter/in"
    },
    {
        "label": "Sachversicherungskaufmann/-frau",
        "value": "Sachversicherungskaufmann/-frau"
    },
    {
        "label": "Schadens- und Leistungssachbearbeiter/in (Versicherung)",
        "value": "Schadens- und Leistungssachbearbeiter/in (Versicherung)"
    },
    {
        "label": "Sachverständige/r Bauwirtschaft",
        "value": "Sachverständige/r Bauwirtschaft"
    },
    {
        "label": "Sägewerker/in",
        "value": "Sägewerker/in"
    },
    {
        "label": "Sägewerksarbeiter/in",
        "value": "Sägewerksarbeiter/in"
    },
    {
        "label": "Sägewerksleiter/in",
        "value": "Sägewerksleiter/in"
    },
    {
        "label": "Saisonhelfer/in",
        "value": "Saisonhelfer/in"
    },
    {
        "label": "Salzwerker/in",
        "value": "Salzwerker/in"
    },
    {
        "label": "Sandaufbereiter/in",
        "value": "Sandaufbereiter/in"
    },
    {
        "label": "Strahler/in",
        "value": "Strahler/in"
    },
    {
        "label": "Sänger/in",
        "value": "Sänger/in"
    },
    {
        "label": "Sanierer/in (Beton)",
        "value": "Sanierer/in (Beton)"
    },
    {
        "label": "Fachkraft Sanierung von Feuchtschäden und Schimmel",
        "value": "Fachkraft Sanierung von Feuchtschäden und Schimmel"
    },
    {
        "label": "Techniker/in Bausanierung",
        "value": "Techniker/in Bausanierung"
    },
    {
        "label": "Heizungs- und Sanitärinstallateur/in",
        "value": "Heizungs- und Sanitärinstallateur/in"
    },
    {
        "label": "Sanitärinstallateur/in",
        "value": "Sanitärinstallateur/in"
    },
    {
        "label": "Sanitärinstallateurmeister/in",
        "value": "Sanitärinstallateurmeister/in"
    },
    {
        "label": "Sanitärmonteur/in",
        "value": "Sanitärmonteur/in"
    },
    {
        "label": "Sanitäter/in",
        "value": "Sanitäter/in"
    },
    {
        "label": "Sattler/in",
        "value": "Sattler/in"
    },
    {
        "label": "Säuglings- und Kinderkrankenschwester/-pfleger",
        "value": "Säuglings- und Kinderkrankenschwester/-pfleger"
    },
    {
        "label": "Saunameister/in",
        "value": "Saunameister/in"
    },
    {
        "label": "Säurebaufacharbeiter/in",
        "value": "Säurebaufacharbeiter/in"
    },
    {
        "label": "Schablonenhersteller/in",
        "value": "Schablonenhersteller/in"
    },
    {
        "label": "Schachtbauer/in",
        "value": "Schachtbauer/in"
    },
    {
        "label": "Meister/in Tunnel- und Schachtbau",
        "value": "Meister/in Tunnel- und Schachtbau"
    },
    {
        "label": "Schäfer/in",
        "value": "Schäfer/in"
    },
    {
        "label": "Schienenfahrzeugführer/in",
        "value": "Schienenfahrzeugführer/in"
    },
    {
        "label": "Schaltanlagenbauer/in",
        "value": "Schaltanlagenbauer/in"
    },
    {
        "label": "Schaltanlagenmonteur/in",
        "value": "Schaltanlagenmonteur/in"
    },
    {
        "label": "Schaltanlagenwärter/in",
        "value": "Schaltanlagenwärter/in"
    },
    {
        "label": "Schalungssetzer/in (Betonbau)",
        "value": "Schalungssetzer/in (Betonbau)"
    },
    {
        "label": "Schaufensterdekorateur/in",
        "value": "Schaufensterdekorateur/in"
    },
    {
        "label": "Schauspieler/in",
        "value": "Schauspieler/in"
    },
    {
        "label": "Schausteller/in",
        "value": "Schausteller/in"
    },
    {
        "label": "Scherenmonteur/in",
        "value": "Scherenmonteur/in"
    },
    {
        "label": "Schichtmeister/in",
        "value": "Schichtmeister/in"
    },
    {
        "label": "Schichtführer/in",
        "value": "Schichtführer/in"
    },
    {
        "label": "Schichtleiter/in",
        "value": "Schichtleiter/in"
    },
    {
        "label": "Schieferdachdecker/in",
        "value": "Schieferdachdecker/in"
    },
    {
        "label": "Schienenfahrzeugschlosser/in",
        "value": "Schienenfahrzeugschlosser/in"
    },
    {
        "label": "Schiffbauer/in",
        "value": "Schiffbauer/in"
    },
    {
        "label": "Schiffbauingenieur/in",
        "value": "Schiffbauingenieur/in"
    },
    {
        "label": "Schiffbautechniker/in",
        "value": "Schiffbautechniker/in"
    },
    {
        "label": "Schiffsführer/in Binnenschifffahrt",
        "value": "Schiffsführer/in Binnenschifffahrt"
    },
    {
        "label": "Schifffahrtskaufmann/-frau",
        "value": "Schifffahrtskaufmann/-frau"
    },
    {
        "label": "Schiffswandreiniger/in",
        "value": "Schiffswandreiniger/in"
    },
    {
        "label": "Schiffsmann/-frau",
        "value": "Schiffsmann/-frau"
    },
    {
        "label": "Schiffsbetriebsmeister/in",
        "value": "Schiffsbetriebsmeister/in"
    },
    {
        "label": "Schiffsbetriebsschlosser/in",
        "value": "Schiffsbetriebsschlosser/in"
    },
    {
        "label": "Schiffsbetriebstechniker/in",
        "value": "Schiffsbetriebstechniker/in"
    },
    {
        "label": "Schiffsingenieur/in",
        "value": "Schiffsingenieur/in"
    },
    {
        "label": "Schiffseigner/in",
        "value": "Schiffseigner/in"
    },
    {
        "label": "Schiffsfunker/in",
        "value": "Schiffsfunker/in"
    },
    {
        "label": "Schiffskoch/-köchin",
        "value": "Schiffskoch/-köchin"
    },
    {
        "label": "Schiffsmakler/in",
        "value": "Schiffsmakler/in"
    },
    {
        "label": "Schiffsmaschinenwärter/in",
        "value": "Schiffsmaschinenwärter/in"
    },
    {
        "label": "Schiffsmaschinist/in",
        "value": "Schiffsmaschinist/in"
    },
    {
        "label": "Schiffsmechaniker/in",
        "value": "Schiffsmechaniker/in"
    },
    {
        "label": "Schiffsoffizier/in Nautische/r Schiffsoffizier/in",
        "value": "Schiffsoffizier/in Nautische/r Schiffsoffizier/in"
    },
    {
        "label": "Schiffssteuermann/-frau",
        "value": "Schiffssteuermann/-frau"
    },
    {
        "label": "Schiffssteward/-stewardess",
        "value": "Schiffssteward/-stewardess"
    },
    {
        "label": "Schiffszimmerer/-zimmerin",
        "value": "Schiffszimmerer/-zimmerin"
    },
    {
        "label": "Schilder- und Lichtreklamehersteller/in",
        "value": "Schilder- und Lichtreklamehersteller/in"
    },
    {
        "label": "Schilderhersteller/in",
        "value": "Schilderhersteller/in"
    },
    {
        "label": "Schildermaler/in",
        "value": "Schildermaler/in"
    },
    {
        "label": "Schirmmacher/in",
        "value": "Schirmmacher/in"
    },
    {
        "label": "Schlachter/in",
        "value": "Schlachter/in"
    },
    {
        "label": "Schlafwagenschaffner/in",
        "value": "Schlafwagenschaffner/in"
    },
    {
        "label": "Schlagzeuger/in",
        "value": "Schlagzeuger/in"
    },
    {
        "label": "Schlauchmacher/in (Gummi)",
        "value": "Schlauchmacher/in (Gummi)"
    },
    {
        "label": "Schleifer/in",
        "value": "Schleifer/in"
    },
    {
        "label": "Schleusenwart/in",
        "value": "Schleusenwart/in"
    },
    {
        "label": "Schleusenwärter/in",
        "value": "Schleusenwärter/in"
    },
    {
        "label": "Helfer/in Maschinenschlosserei",
        "value": "Helfer/in Maschinenschlosserei"
    },
    {
        "label": "Schlossermeister/in (Bauschlosserei/Metallbau)",
        "value": "Schlossermeister/in (Bauschlosserei/Metallbau)"
    },
    {
        "label": "Schmelzofenführer/in",
        "value": "Schmelzofenführer/in"
    },
    {
        "label": "Schmelzschweißer/in",
        "value": "Schmelzschweißer/in"
    },
    {
        "label": "Schmied/in",
        "value": "Schmied/in"
    },
    {
        "label": "Schmuck- und Gerätdesigner/in",
        "value": "Schmuck- und Gerätdesigner/in"
    },
    {
        "label": "Schmuckgestalter/in",
        "value": "Schmuckgestalter/in"
    },
    {
        "label": "Schmucksteinfasser/in",
        "value": "Schmucksteinfasser/in"
    },
    {
        "label": "Schmucktextilienhersteller/in",
        "value": "Schmucktextilienhersteller/in"
    },
    {
        "label": "Schmuckfachberater/in",
        "value": "Schmuckfachberater/in"
    },
    {
        "label": "Schneidermeister/in",
        "value": "Schneidermeister/in"
    },
    {
        "label": "Schnittdirektrice/-modelleur",
        "value": "Schnittdirektrice/-modelleur"
    },
    {
        "label": "Schnittwerkzeugmacher/in",
        "value": "Schnittwerkzeugmacher/in"
    },
    {
        "label": "Schnitzer/in",
        "value": "Schnitzer/in"
    },
    {
        "label": "Schokoladenwarenhersteller/in",
        "value": "Schokoladenwarenhersteller/in"
    },
    {
        "label": "Schönheitspfleger/in",
        "value": "Schönheitspfleger/in"
    },
    {
        "label": "Schornsteinbauer/in",
        "value": "Schornsteinbauer/in"
    },
    {
        "label": "Schornsteinfeger/in",
        "value": "Schornsteinfeger/in"
    },
    {
        "label": "Schornsteinfegermeister/in",
        "value": "Schornsteinfegermeister/in"
    },
    {
        "label": "Schrankenwärter/in",
        "value": "Schrankenwärter/in"
    },
    {
        "label": "Schreibkraft",
        "value": "Schreibkraft"
    },
    {
        "label": "Schreinermeister/in",
        "value": "Schreinermeister/in"
    },
    {
        "label": "Schrift- und Grafikmaler/in",
        "value": "Schrift- und Grafikmaler/in"
    },
    {
        "label": "Schriftgießer/in",
        "value": "Schriftgießer/in"
    },
    {
        "label": "Schriftsetzer/in",
        "value": "Schriftsetzer/in"
    },
    {
        "label": "Schriftsteller/in",
        "value": "Schriftsteller/in"
    },
    {
        "label": "Schrotthändler/in",
        "value": "Schrotthändler/in"
    },
    {
        "label": "Schuhfabrikarbeiter/in",
        "value": "Schuhfabrikarbeiter/in"
    },
    {
        "label": "Schuhfertiger/in",
        "value": "Schuhfertiger/in"
    },
    {
        "label": "Schuhmacher/in",
        "value": "Schuhmacher/in"
    },
    {
        "label": "Schuhverkäufer/in",
        "value": "Schuhverkäufer/in"
    },
    {
        "label": "Schuhwarenhersteller/in",
        "value": "Schuhwarenhersteller/in"
    },
    {
        "label": "Schulassistent/in (nicht Verwaltung)",
        "value": "Schulassistent/in (nicht Verwaltung)"
    },
    {
        "label": "Schulberater/in",
        "value": "Schulberater/in"
    },
    {
        "label": "Schuldnerberater/in",
        "value": "Schuldnerberater/in"
    },
    {
        "label": "Schüler/-in, Student/-in (Eintrittsalter 14-17 J.)",
        "value": "Schüler/-in, Student/-in (Eintrittsalter 14-17 J.)"
    },
    {
        "label": "Schüler/-in und Student/-in ab 18 Jahre",
        "value": "Schüler/-in und Student/-in ab 18 Jahre"
    },
    {
        "label": "Kind und Schüler/-in (Eintrittsalter 0-13 Jahre)",
        "value": "Kind und Schüler/-in (Eintrittsalter 0-13 Jahre)"
    },
    {
        "label": "Schulhausmeister/in",
        "value": "Schulhausmeister/in"
    },
    {
        "label": "Schulleiter/in allgemeinbildende Schulen",
        "value": "Schulleiter/in allgemeinbildende Schulen"
    },
    {
        "label": "Schulleiter/in Berufsschulen",
        "value": "Schulleiter/in Berufsschulen"
    },
    {
        "label": "Schulleiter/in Gymnasien",
        "value": "Schulleiter/in Gymnasien"
    },
    {
        "label": "Schulleiter/in Grund- und Hauptschulen",
        "value": "Schulleiter/in Grund- und Hauptschulen"
    },
    {
        "label": "Schulpsychologe/-psychologin",
        "value": "Schulpsychologe/-psychologin"
    },
    {
        "label": "Schulsekretär/in",
        "value": "Schulsekretär/in"
    },
    {
        "label": "Schulsozialarbeiter/in",
        "value": "Schulsozialarbeiter/in"
    },
    {
        "label": "Schulungsleiter/in",
        "value": "Schulungsleiter/in"
    },
    {
        "label": "Schulungsreferent/in",
        "value": "Schulungsreferent/in"
    },
    {
        "label": "Schuster/in",
        "value": "Schuster/in"
    },
    {
        "label": "Schwarzstraßenbauer/in",
        "value": "Schwarzstraßenbauer/in"
    },
    {
        "label": "Schweinezüchter/in",
        "value": "Schweinezüchter/in"
    },
    {
        "label": "Schweißer/in (geprüft)",
        "value": "Schweißer/in (geprüft)"
    },
    {
        "label": "Schweißermeister/in",
        "value": "Schweißermeister/in"
    },
    {
        "label": "Schweißfachingenieur/in",
        "value": "Schweißfachingenieur/in"
    },
    {
        "label": "Schweißfachmann/-frau",
        "value": "Schweißfachmann/-frau"
    },
    {
        "label": "Schwesterhelfer/in",
        "value": "Schwesterhelfer/in"
    },
    {
        "label": "Schwimmmeistergehilfe/-gehilfin",
        "value": "Schwimmmeistergehilfe/-gehilfin"
    },
    {
        "label": "Schwimmkranmaschinist/in",
        "value": "Schwimmkranmaschinist/in"
    },
    {
        "label": "Seegüterkontrolleur/in",
        "value": "Seegüterkontrolleur/in"
    },
    {
        "label": "Seelsorger/in",
        "value": "Seelsorger/in"
    },
    {
        "label": "Seemann/-frau",
        "value": "Seemann/-frau"
    },
    {
        "label": "Seevermessungsingenieur/in",
        "value": "Seevermessungsingenieur/in"
    },
    {
        "label": "Segelmacher/in",
        "value": "Segelmacher/in"
    },
    {
        "label": "Seilbahnmaschinist/in",
        "value": "Seilbahnmaschinist/in"
    },
    {
        "label": "Seiler/in",
        "value": "Seiler/in"
    },
    {
        "label": "Sekretär/in und Übersetzer/in",
        "value": "Sekretär/in und Übersetzer/in"
    },
    {
        "label": "Sekretär/in (geprüft)",
        "value": "Sekretär/in (geprüft)"
    },
    {
        "label": "Sekretär/in - Gesundheitswesen",
        "value": "Sekretär/in - Gesundheitswesen"
    },
    {
        "label": "Stenosekretär/in",
        "value": "Stenosekretär/in"
    },
    {
        "label": "Sektionsassistent/in",
        "value": "Sektionsassistent/in"
    },
    {
        "label": "Seminarleiter/in Erwachsendenbildung",
        "value": "Seminarleiter/in Erwachsendenbildung"
    },
    {
        "label": "Senator/in",
        "value": "Senator/in"
    },
    {
        "label": "Senior/-in ab 65 Jahre",
        "value": "Senior/-in ab 65 Jahre"
    },
    {
        "label": "Seniorenpfleger/in",
        "value": "Seniorenpfleger/in"
    },
    {
        "label": "Sales- und Servicefachkraft",
        "value": "Sales- und Servicefachkraft"
    },
    {
        "label": "Servicefachkraft (Restaurant)",
        "value": "Servicefachkraft (Restaurant)"
    },
    {
        "label": "Servicefahrer/in",
        "value": "Servicefahrer/in"
    },
    {
        "label": "Servicekaufmann/-frau - Luftverkehr",
        "value": "Servicekaufmann/-frau - Luftverkehr"
    },
    {
        "label": "Serviceleiter/in",
        "value": "Serviceleiter/in"
    },
    {
        "label": "Servicemonteur/in",
        "value": "Servicemonteur/in"
    },
    {
        "label": "Servicetechniker/in",
        "value": "Servicetechniker/in"
    },
    {
        "label": "Servierer/in",
        "value": "Servierer/in"
    },
    {
        "label": "Sicherheitsbeauftragte/r",
        "value": "Sicherheitsbeauftragte/r"
    },
    {
        "label": "Sicherheitsberater/in (Detektei)",
        "value": "Sicherheitsberater/in (Detektei)"
    },
    {
        "label": "Sicherheitsfachkraft",
        "value": "Sicherheitsfachkraft"
    },
    {
        "label": "Sicherheitsingenieur/in",
        "value": "Sicherheitsingenieur/in"
    },
    {
        "label": "Sicherheitskontrolleur/in",
        "value": "Sicherheitskontrolleur/in"
    },
    {
        "label": "Sicherheitstechniker/in",
        "value": "Sicherheitstechniker/in"
    },
    {
        "label": "Sicherungsposten",
        "value": "Sicherungsposten"
    },
    {
        "label": "Sichtprüfer/in",
        "value": "Sichtprüfer/in"
    },
    {
        "label": "Siebdrucker/in",
        "value": "Siebdrucker/in"
    },
    {
        "label": "Signalmechaniker/in",
        "value": "Signalmechaniker/in"
    },
    {
        "label": "Silberschmied/in",
        "value": "Silberschmied/in"
    },
    {
        "label": "Siloverwalter/in",
        "value": "Siloverwalter/in"
    },
    {
        "label": "Skilehrer/in",
        "value": "Skilehrer/in"
    },
    {
        "label": "Skitrainer/in",
        "value": "Skitrainer/in"
    },
    {
        "label": "Softwareberater/in",
        "value": "Softwareberater/in"
    },
    {
        "label": "Softwareentwickler/in",
        "value": "Softwareentwickler/in"
    },
    {
        "label": "Software-Ingenieur/in",
        "value": "Software-Ingenieur/in"
    },
    {
        "label": "Softwaretechniker/in",
        "value": "Softwaretechniker/in"
    },
    {
        "label": "Soldat/in",
        "value": "Soldat/in"
    },
    {
        "label": "Zugmaschinen-, Sonderfahrzeugführer/in",
        "value": "Zugmaschinen-, Sonderfahrzeugführer/in"
    },
    {
        "label": "Sonderpädagoge/-pädagogin",
        "value": "Sonderpädagoge/-pädagogin"
    },
    {
        "label": "Sonderschullehrer/in",
        "value": "Sonderschullehrer/in"
    },
    {
        "label": "Sonnenschutztechniker/in",
        "value": "Sonnenschutztechniker/in"
    },
    {
        "label": "Helfer/in Textilherstellung",
        "value": "Helfer/in Textilherstellung"
    },
    {
        "label": "PC-Datenverarbeiter/in",
        "value": "PC-Datenverarbeiter/in"
    },
    {
        "label": "Fachverkäufer/in Backwaren",
        "value": "Fachverkäufer/in Backwaren"
    },
    {
        "label": "Konstruktionstechniker/in Feinwerktechnik",
        "value": "Konstruktionstechniker/in Feinwerktechnik"
    },
    {
        "label": "Maschinen- und Anlagenführer/in",
        "value": "Maschinen- und Anlagenführer/in"
    },
    {
        "label": "Sortierer/in",
        "value": "Sortierer/in"
    },
    {
        "label": "Sortiermaschinenbediener/in",
        "value": "Sortiermaschinenbediener/in"
    },
    {
        "label": "Souffleur/Souffleuse",
        "value": "Souffleur/Souffleuse"
    },
    {
        "label": "Sozialassistent/in",
        "value": "Sozialassistent/in"
    },
    {
        "label": "Sozialberater/in",
        "value": "Sozialberater/in"
    },
    {
        "label": "Sozialbetreuer/in",
        "value": "Sozialbetreuer/in"
    },
    {
        "label": "Sozialmedizinische/r Assistent/in",
        "value": "Sozialmedizinische/r Assistent/in"
    },
    {
        "label": "Fachkraft Sozialarbeit",
        "value": "Fachkraft Sozialarbeit"
    },
    {
        "label": "Sozialtherapeut/in",
        "value": "Sozialtherapeut/in"
    },
    {
        "label": "Sozialversicherungsfachangestellte/r",
        "value": "Sozialversicherungsfachangestellte/r"
    },
    {
        "label": "Beamter/Beamtin Sozialversicherung (geh. Dienst)",
        "value": "Beamter/Beamtin Sozialversicherung (geh. Dienst)"
    },
    {
        "label": "Sozialversicherungskaufmann/-frau",
        "value": "Sozialversicherungskaufmann/-frau"
    },
    {
        "label": "Spachtler/in",
        "value": "Spachtler/in"
    },
    {
        "label": "Sparkassenkaufmann/-frau",
        "value": "Sparkassenkaufmann/-frau"
    },
    {
        "label": "Sparkassenfachwirt/in",
        "value": "Sparkassenfachwirt/in"
    },
    {
        "label": "Speditionsarbeiter/in",
        "value": "Speditionsarbeiter/in"
    },
    {
        "label": "Speditionskaufmann/-frau",
        "value": "Speditionskaufmann/-frau"
    },
    {
        "label": "Speiseeishersteller/in",
        "value": "Speiseeishersteller/in"
    },
    {
        "label": "Speisefetthersteller/in",
        "value": "Speisefetthersteller/in"
    },
    {
        "label": "Spengler/in",
        "value": "Spengler/in"
    },
    {
        "label": "Spenglermeister/in",
        "value": "Spenglermeister/in"
    },
    {
        "label": "Spezialbaufacharbeiter/in (Hochbau)",
        "value": "Spezialbaufacharbeiter/in (Hochbau)"
    },
    {
        "label": "Spezialdrucker/in",
        "value": "Spezialdrucker/in"
    },
    {
        "label": "Uhrenmacher/in",
        "value": "Uhrenmacher/in"
    },
    {
        "label": "Spielhallenaufseher/in",
        "value": "Spielhallenaufseher/in"
    },
    {
        "label": "Spielhallenaufsicht",
        "value": "Spielhallenaufsicht"
    },
    {
        "label": "Spielwarenmacher/in",
        "value": "Spielwarenmacher/in"
    },
    {
        "label": "Spinnereifacharbeiter/in",
        "value": "Spinnereifacharbeiter/in"
    },
    {
        "label": "Vorgarnspinner/in",
        "value": "Vorgarnspinner/in"
    },
    {
        "label": "Sport- und Fitnesskaufmann/-frau",
        "value": "Sport- und Fitnesskaufmann/-frau"
    },
    {
        "label": "Sportartikelverkäufer/in",
        "value": "Sportartikelverkäufer/in"
    },
    {
        "label": "Sportgerätebauer/in",
        "value": "Sportgerätebauer/in"
    },
    {
        "label": "Sportmanager/in",
        "value": "Sportmanager/in"
    },
    {
        "label": "Sportlehrer/in",
        "value": "Sportlehrer/in"
    },
    {
        "label": "Sportler/in",
        "value": "Sportler/in"
    },
    {
        "label": "Sporttherapeut/in",
        "value": "Sporttherapeut/in"
    },
    {
        "label": "Sporttrainer/in",
        "value": "Sporttrainer/in"
    },
    {
        "label": "Sportwissenschaftler/in",
        "value": "Sportwissenschaftler/in"
    },
    {
        "label": "Sprachlehrer/in",
        "value": "Sprachlehrer/in"
    },
    {
        "label": "Sprachtherapeut/in",
        "value": "Sprachtherapeut/in"
    },
    {
        "label": "Mediensprecher/in",
        "value": "Mediensprecher/in"
    },
    {
        "label": "Sprechstundenhilfe",
        "value": "Sprechstundenhilfe"
    },
    {
        "label": "Sprengmeister/in",
        "value": "Sprengmeister/in"
    },
    {
        "label": "Sprengmeisterhelfer/in",
        "value": "Sprengmeisterhelfer/in"
    },
    {
        "label": "Entschärfer/in (Munition)",
        "value": "Entschärfer/in (Munition)"
    },
    {
        "label": "Metallspritzgießer/in",
        "value": "Metallspritzgießer/in"
    },
    {
        "label": "Spulenwickler/in (Transformatorenbau)",
        "value": "Spulenwickler/in (Transformatorenbau)"
    },
    {
        "label": "Spuler/in",
        "value": "Spuler/in"
    },
    {
        "label": "Augenoptiker/in (staatlich geprüft)",
        "value": "Augenoptiker/in (staatlich geprüft)"
    },
    {
        "label": "Grafik- und Kommunikationsdesigner/in",
        "value": "Grafik- und Kommunikationsdesigner/in"
    },
    {
        "label": "Techniker/in Maschinenbau",
        "value": "Techniker/in Maschinenbau"
    },
    {
        "label": "Staatsanwalt/-anwältin",
        "value": "Staatsanwalt/-anwältin"
    },
    {
        "label": "Behördenbedienstete/r",
        "value": "Behördenbedienstete/r"
    },
    {
        "label": "Stadtplaner/in",
        "value": "Stadtplaner/in"
    },
    {
        "label": "Stadtrat/-rätin",
        "value": "Stadtrat/-rätin"
    },
    {
        "label": "Stahlarbeiter/in",
        "value": "Stahlarbeiter/in"
    },
    {
        "label": "Stahlbauer/in",
        "value": "Stahlbauer/in"
    },
    {
        "label": "Stahlbauingenieur/in",
        "value": "Stahlbauingenieur/in"
    },
    {
        "label": "Stahlbaumonteur/in",
        "value": "Stahlbaumonteur/in"
    },
    {
        "label": "Stahlbauschlosser/in",
        "value": "Stahlbauschlosser/in"
    },
    {
        "label": "Stahlbetonbauer/in",
        "value": "Stahlbetonbauer/in"
    },
    {
        "label": "Stahlformenbauer/in",
        "value": "Stahlformenbauer/in"
    },
    {
        "label": "Messing- und Stahlgraveur/in",
        "value": "Messing- und Stahlgraveur/in"
    },
    {
        "label": "Stahlschmied/in",
        "value": "Stahlschmied/in"
    },
    {
        "label": "Stahlstichgraveur/in",
        "value": "Stahlstichgraveur/in"
    },
    {
        "label": "Stahlwalzer/in",
        "value": "Stahlwalzer/in"
    },
    {
        "label": "Stahlwerksfacharbeiter/in",
        "value": "Stahlwerksfacharbeiter/in"
    },
    {
        "label": "Standesbeamter/-beamtin",
        "value": "Standesbeamter/-beamtin"
    },
    {
        "label": "Stangenzieher/in",
        "value": "Stangenzieher/in"
    },
    {
        "label": "Stanzer/in Metall",
        "value": "Stanzer/in Metall"
    },
    {
        "label": "Stanzwerkzeugmacher/in",
        "value": "Stanzwerkzeugmacher/in"
    },
    {
        "label": "Staplerfahrer/in",
        "value": "Staplerfahrer/in"
    },
    {
        "label": "Starkstrommonteur/in",
        "value": "Starkstrommonteur/in"
    },
    {
        "label": "Stationshilfe",
        "value": "Stationshilfe"
    },
    {
        "label": "Stationsleiter/in Krankenpflege/Altenpflege",
        "value": "Stationsleiter/in Krankenpflege/Altenpflege"
    },
    {
        "label": "Statist/in",
        "value": "Statist/in"
    },
    {
        "label": "Statistiker/in",
        "value": "Statistiker/in"
    },
    {
        "label": "Stauer/in",
        "value": "Stauer/in"
    },
    {
        "label": "Steiger/in",
        "value": "Steiger/in"
    },
    {
        "label": "Steinbearbeiter/in",
        "value": "Steinbearbeiter/in"
    },
    {
        "label": "Steinbildhauer/in",
        "value": "Steinbildhauer/in"
    },
    {
        "label": "Steinbildhauermeister/in",
        "value": "Steinbildhauermeister/in"
    },
    {
        "label": "Steinbruchbohrer/in",
        "value": "Steinbruchbohrer/in"
    },
    {
        "label": "Gesteinshauer/in",
        "value": "Gesteinshauer/in"
    },
    {
        "label": "Steinmetz/in",
        "value": "Steinmetz/in"
    },
    {
        "label": "Steinmetzhelfer/in",
        "value": "Steinmetzhelfer/in"
    },
    {
        "label": "Steinmetzmeister/in",
        "value": "Steinmetzmeister/in"
    },
    {
        "label": "Steinschleifer/in",
        "value": "Steinschleifer/in"
    },
    {
        "label": "Steinsetzer/in",
        "value": "Steinsetzer/in"
    },
    {
        "label": "Techniker/in Steintechnik",
        "value": "Techniker/in Steintechnik"
    },
    {
        "label": "Stellmacher/in",
        "value": "Stellmacher/in"
    },
    {
        "label": "Stellwerksführer/in",
        "value": "Stellwerksführer/in"
    },
    {
        "label": "Stempelmacher/in",
        "value": "Stempelmacher/in"
    },
    {
        "label": "Stenograf/in",
        "value": "Stenograf/in"
    },
    {
        "label": "Stenokontorist/in",
        "value": "Stenokontorist/in"
    },
    {
        "label": "Stenotypist/in",
        "value": "Stenotypist/in"
    },
    {
        "label": "Stepper/in Textilien",
        "value": "Stepper/in Textilien"
    },
    {
        "label": "Sterilisationsassistent/in",
        "value": "Sterilisationsassistent/in"
    },
    {
        "label": "Sterilisierer/in (Milchverarbeiter/in)",
        "value": "Sterilisierer/in (Milchverarbeiter/in)"
    },
    {
        "label": "Steuerassistent/in",
        "value": "Steuerassistent/in"
    },
    {
        "label": "Beamter/Beamtin Steuerverwaltung (geh. Dienst)",
        "value": "Beamter/Beamtin Steuerverwaltung (geh. Dienst)"
    },
    {
        "label": "Steuerberater/in",
        "value": "Steuerberater/in"
    },
    {
        "label": "Steuerbevollmächtigte/r",
        "value": "Steuerbevollmächtigte/r"
    },
    {
        "label": "Steuergehilfe/-gehilfin",
        "value": "Steuergehilfe/-gehilfin"
    },
    {
        "label": "Steuerberatungsassistent/in",
        "value": "Steuerberatungsassistent/in"
    },
    {
        "label": "Steuerfachangestellte/r",
        "value": "Steuerfachangestellte/r"
    },
    {
        "label": "Steuerfachmann/-frau",
        "value": "Steuerfachmann/-frau"
    },
    {
        "label": "Steuerfachwirt/in",
        "value": "Steuerfachwirt/in"
    },
    {
        "label": "Steuerprüfer/in",
        "value": "Steuerprüfer/in"
    },
    {
        "label": "Steuermann/-frau",
        "value": "Steuermann/-frau"
    },
    {
        "label": "Steuersachbearbeiter/in",
        "value": "Steuersachbearbeiter/in"
    },
    {
        "label": "Steuerfachgehilfe/-gehilfin",
        "value": "Steuerfachgehilfe/-gehilfin"
    },
    {
        "label": "Steuerungstechniker/in",
        "value": "Steuerungstechniker/in"
    },
    {
        "label": "Steward/Stewardess (Flugzeug)",
        "value": "Steward/Stewardess (Flugzeug)"
    },
    {
        "label": "Sticker/in",
        "value": "Sticker/in"
    },
    {
        "label": "Strahlenschutztechniker/in",
        "value": "Strahlenschutztechniker/in"
    },
    {
        "label": "Strahlentherapeut/in",
        "value": "Strahlentherapeut/in"
    },
    {
        "label": "Straßenbaufacharbeiter/in",
        "value": "Straßenbaufacharbeiter/in"
    },
    {
        "label": "Straßenbauarbeiter/in",
        "value": "Straßenbauarbeiter/in"
    },
    {
        "label": "Straßenbahnfahrer/in",
        "value": "Straßenbahnfahrer/in"
    },
    {
        "label": "Straßenbahnführer/in",
        "value": "Straßenbahnführer/in"
    },
    {
        "label": "Straßenbauhelfer/in",
        "value": "Straßenbauhelfer/in"
    },
    {
        "label": "Straßenbauingenieur/in",
        "value": "Straßenbauingenieur/in"
    },
    {
        "label": "Straßenbaumaschinenführer/in",
        "value": "Straßenbaumaschinenführer/in"
    },
    {
        "label": "Straßenbaumeister/in",
        "value": "Straßenbaumeister/in"
    },
    {
        "label": "Straßenbaupolier/in",
        "value": "Straßenbaupolier/in"
    },
    {
        "label": "Straßenmeister/in",
        "value": "Straßenmeister/in"
    },
    {
        "label": "Straßenpflasterer/-pflasterin",
        "value": "Straßenpflasterer/-pflasterin"
    },
    {
        "label": "Straßenreiniger/in",
        "value": "Straßenreiniger/in"
    },
    {
        "label": "Straßenwart/in",
        "value": "Straßenwart/in"
    },
    {
        "label": "Straßenwärter/in",
        "value": "Straßenwärter/in"
    },
    {
        "label": "Streckensicherungsarbeiter/in",
        "value": "Streckensicherungsarbeiter/in"
    },
    {
        "label": "Streckenwart/in (Straßen)",
        "value": "Streckenwart/in (Straßen)"
    },
    {
        "label": "Streicher/in (Maler/in)",
        "value": "Streicher/in (Maler/in)"
    },
    {
        "label": "Stricker/in",
        "value": "Stricker/in"
    },
    {
        "label": "Stroh- und Rohrflechter/in",
        "value": "Stroh- und Rohrflechter/in"
    },
    {
        "label": "Strohhutformer/in",
        "value": "Strohhutformer/in"
    },
    {
        "label": "Strumpfnäher/in",
        "value": "Strumpfnäher/in"
    },
    {
        "label": "Stuckateur/in",
        "value": "Stuckateur/in"
    },
    {
        "label": "Stuckateurmeister/in",
        "value": "Stuckateurmeister/in"
    },
    {
        "label": "Studienberater/in",
        "value": "Studienberater/in"
    },
    {
        "label": "Direktor/in und Professor/in",
        "value": "Direktor/in und Professor/in"
    },
    {
        "label": "Lehrer/in Gymnasien (Sekundarstufe I und II)",
        "value": "Lehrer/in Gymnasien (Sekundarstufe I und II)"
    },
    {
        "label": "Stuntman/-woman",
        "value": "Stuntman/-woman"
    },
    {
        "label": "Stützpunktleiter/in (Verkehrsbetrieb)",
        "value": "Stützpunktleiter/in (Verkehrsbetrieb)"
    },
    {
        "label": "Stylist/in (Frisuren)",
        "value": "Stylist/in (Frisuren)"
    },
    {
        "label": "Substitut/in (Warenhandel)",
        "value": "Substitut/in (Warenhandel)"
    },
    {
        "label": "Subunternehmer/in",
        "value": "Subunternehmer/in"
    },
    {
        "label": "Sudmeister/in",
        "value": "Sudmeister/in"
    },
    {
        "label": "Supervisor/in (Psychotherapie)",
        "value": "Supervisor/in (Psychotherapie)"
    },
    {
        "label": "Support-Engineer (DV-Technik)",
        "value": "Support-Engineer (DV-Technik)"
    },
    {
        "label": "Supporter/in",
        "value": "Supporter/in"
    },
    {
        "label": "Süßmoster/in",
        "value": "Süßmoster/in"
    },
    {
        "label": "Süßwarenhersteller/in",
        "value": "Süßwarenhersteller/in"
    },
    {
        "label": "Synchronsprecher/in",
        "value": "Synchronsprecher/in"
    },
    {
        "label": "Syndikus-Anwalt/-Anwältin",
        "value": "Syndikus-Anwalt/-Anwältin"
    },
    {
        "label": "Systemadministrator/in - EDV",
        "value": "Systemadministrator/in - EDV"
    },
    {
        "label": "Systemanalytiker/in",
        "value": "Systemanalytiker/in"
    },
    {
        "label": "Systemberater/in (EDV)",
        "value": "Systemberater/in (EDV)"
    },
    {
        "label": "Systembetreuer/in (EDV)",
        "value": "Systembetreuer/in (EDV)"
    },
    {
        "label": "Systemelektroniker/in",
        "value": "Systemelektroniker/in"
    },
    {
        "label": "Systementwickler/in",
        "value": "Systementwickler/in"
    },
    {
        "label": "Systemingenieur/in (EDV)",
        "value": "Systemingenieur/in (EDV)"
    },
    {
        "label": "Systemmanager/in",
        "value": "Systemmanager/in"
    },
    {
        "label": "Systemplaner/in (EDV)",
        "value": "Systemplaner/in (EDV)"
    },
    {
        "label": "Systemprogrammierer/in",
        "value": "Systemprogrammierer/in"
    },
    {
        "label": "Systemsoftwareentwickler/in",
        "value": "Systemsoftwareentwickler/in"
    },
    {
        "label": "Systemspezialist/in (EDV)",
        "value": "Systemspezialist/in (EDV)"
    },
    {
        "label": "Systemtechniker/in IT",
        "value": "Systemtechniker/in IT"
    },
    {
        "label": "Szenenbildner/in",
        "value": "Szenenbildner/in"
    },
    {
        "label": "Tabakaufbereiter/in",
        "value": "Tabakaufbereiter/in"
    },
    {
        "label": "Tabakfermentierer/in",
        "value": "Tabakfermentierer/in"
    },
    {
        "label": "Tabakwarenmacher/in",
        "value": "Tabakwarenmacher/in"
    },
    {
        "label": "Tabletteur/in",
        "value": "Tabletteur/in"
    },
    {
        "label": "Tagesmutter/-vater",
        "value": "Tagesmutter/-vater"
    },
    {
        "label": "Tagesstättenleiter/in",
        "value": "Tagesstättenleiter/in"
    },
    {
        "label": "Tankreiniger/in",
        "value": "Tankreiniger/in"
    },
    {
        "label": "Tankstellenbesitzer/in",
        "value": "Tankstellenbesitzer/in"
    },
    {
        "label": "Tankstelleninhaber/in",
        "value": "Tankstelleninhaber/in"
    },
    {
        "label": "Tankstellenkaufmann/-frau",
        "value": "Tankstellenkaufmann/-frau"
    },
    {
        "label": "Tankstellenleiter/in",
        "value": "Tankstellenleiter/in"
    },
    {
        "label": "Tankstellenpächter/in",
        "value": "Tankstellenpächter/in"
    },
    {
        "label": "Tankstellenwart/in",
        "value": "Tankstellenwart/in"
    },
    {
        "label": "Tankwagenführer/in",
        "value": "Tankwagenführer/in"
    },
    {
        "label": "Tankwart/in",
        "value": "Tankwart/in"
    },
    {
        "label": "Tänzer/in",
        "value": "Tänzer/in"
    },
    {
        "label": "Tanzlehrer/in",
        "value": "Tanzlehrer/in"
    },
    {
        "label": "Tanzpädagoge/-pädagogin",
        "value": "Tanzpädagoge/-pädagogin"
    },
    {
        "label": "Tanztrainer/in",
        "value": "Tanztrainer/in"
    },
    {
        "label": "Tapetendrucker/in",
        "value": "Tapetendrucker/in"
    },
    {
        "label": "Tapezierer/in",
        "value": "Tapezierer/in"
    },
    {
        "label": "Täschner/in",
        "value": "Täschner/in"
    },
    {
        "label": "Tätowierer/in",
        "value": "Tätowierer/in"
    },
    {
        "label": "Taucher/in",
        "value": "Taucher/in"
    },
    {
        "label": "Taxator/in",
        "value": "Taxator/in"
    },
    {
        "label": "Taxifahrer/in",
        "value": "Taxifahrer/in"
    },
    {
        "label": "Teamleiter/in",
        "value": "Teamleiter/in"
    },
    {
        "label": "Teamsekretär/in",
        "value": "Teamsekretär/in"
    },
    {
        "label": "Techniker/in Baubetrieb",
        "value": "Techniker/in Baubetrieb"
    },
    {
        "label": "Techniker/in Betriebswissenschaft",
        "value": "Techniker/in Betriebswissenschaft"
    },
    {
        "label": "Techniker/in Datenverarbeitungstechnik",
        "value": "Techniker/in Datenverarbeitungstechnik"
    },
    {
        "label": "Techniker/in Produktionsorganisation",
        "value": "Techniker/in Produktionsorganisation"
    },
    {
        "label": "Techniker/in Abfalltechnik",
        "value": "Techniker/in Abfalltechnik"
    },
    {
        "label": "Techniker/in Abwassertechnik",
        "value": "Techniker/in Abwassertechnik"
    },
    {
        "label": "Techniker/in Agrarwirtschaft",
        "value": "Techniker/in Agrarwirtschaft"
    },
    {
        "label": "Techniker/in Biotechnik",
        "value": "Techniker/in Biotechnik"
    },
    {
        "label": "Techniker/in Drucktechnik",
        "value": "Techniker/in Drucktechnik"
    },
    {
        "label": "Techniker/in Elektronik",
        "value": "Techniker/in Elektronik"
    },
    {
        "label": "Techniker/in Konstruktion",
        "value": "Techniker/in Konstruktion"
    },
    {
        "label": "Techniker/in Heizungs-, Lüftungs- und Klimatechnik",
        "value": "Techniker/in Heizungs-, Lüftungs- und Klimatechnik"
    },
    {
        "label": "Techniker/in Hüttentechnik",
        "value": "Techniker/in Hüttentechnik"
    },
    {
        "label": "Techniker/in Kraftfahrzeugtechnik",
        "value": "Techniker/in Kraftfahrzeugtechnik"
    },
    {
        "label": "Techniker/in Lebensmittelverarbeitungstechnik",
        "value": "Techniker/in Lebensmittelverarbeitungstechnik"
    },
    {
        "label": "Techniker/in Maschinentechnik",
        "value": "Techniker/in Maschinentechnik"
    },
    {
        "label": "Techniker/in Mess- und Regeltechnik",
        "value": "Techniker/in Mess- und Regeltechnik"
    },
    {
        "label": "Techniker/in Raumgestaltung und Innenausbau",
        "value": "Techniker/in Raumgestaltung und Innenausbau"
    },
    {
        "label": "Techniker/in Reinigungs- und Hygienetechnik",
        "value": "Techniker/in Reinigungs- und Hygienetechnik"
    },
    {
        "label": "Techniker/in Wasserversorgungstechnik",
        "value": "Techniker/in Wasserversorgungstechnik"
    },
    {
        "label": "Technische/r Bühnendirektor/in",
        "value": "Technische/r Bühnendirektor/in"
    },
    {
        "label": "Technische/r Sachverständige/r",
        "value": "Technische/r Sachverständige/r"
    },
    {
        "label": "Technische/r Zeichner/in",
        "value": "Technische/r Zeichner/in"
    },
    {
        "label": "Technische/r Assistent/in Konstruktions- und Fertigungstechnik",
        "value": "Technische/r Assistent/in Konstruktions- und Fertigungstechnik"
    },
    {
        "label": "Technische/r Außendienstmitarbeiter/in",
        "value": "Technische/r Außendienstmitarbeiter/in"
    },
    {
        "label": "Technische/r Autor/in",
        "value": "Technische/r Autor/in"
    },
    {
        "label": "Technische/r Berater/in",
        "value": "Technische/r Berater/in"
    },
    {
        "label": "Technische/r Betriebsleiter/in",
        "value": "Technische/r Betriebsleiter/in"
    },
    {
        "label": "Technische/r Betriebswirt/in (HS)",
        "value": "Technische/r Betriebswirt/in (HS)"
    },
    {
        "label": "Technische/r Direktor/in",
        "value": "Technische/r Direktor/in"
    },
    {
        "label": "Materialzuteiler/in",
        "value": "Materialzuteiler/in"
    },
    {
        "label": "Technische/r Einkäufer/in",
        "value": "Technische/r Einkäufer/in"
    },
    {
        "label": "Technische/r Fachwirt/in",
        "value": "Technische/r Fachwirt/in"
    },
    {
        "label": "Technische/r Kaufmann/-frau",
        "value": "Technische/r Kaufmann/-frau"
    },
    {
        "label": "Technische/r Leiter/in",
        "value": "Technische/r Leiter/in"
    },
    {
        "label": "Technische/r Mitarbeiter/in - Entwicklung (Datenverarbeitungstechnik)",
        "value": "Technische/r Mitarbeiter/in - Entwicklung (Datenverarbeitungstechnik)"
    },
    {
        "label": "Technische/r Redakteur/in",
        "value": "Technische/r Redakteur/in"
    },
    {
        "label": "Technische/r Sachbearbeiter/in",
        "value": "Technische/r Sachbearbeiter/in"
    },
    {
        "label": "Technische/r Vertriebsberater/in",
        "value": "Technische/r Vertriebsberater/in"
    },
    {
        "label": "Technische/r Schiffsoffizier/in",
        "value": "Technische/r Schiffsoffizier/in"
    },
    {
        "label": "Produktionstechnologe/-technologin",
        "value": "Produktionstechnologe/-technologin"
    },
    {
        "label": "Teebereiter/in",
        "value": "Teebereiter/in"
    },
    {
        "label": "Teichwirt/in",
        "value": "Teichwirt/in"
    },
    {
        "label": "Teiledienstleiter/in",
        "value": "Teiledienstleiter/in"
    },
    {
        "label": "Teilezurichter/in",
        "value": "Teilezurichter/in"
    },
    {
        "label": "Telefonapparatebauer/in",
        "value": "Telefonapparatebauer/in"
    },
    {
        "label": "Telefonist/in",
        "value": "Telefonist/in"
    },
    {
        "label": "Telefonverkäufer/in",
        "value": "Telefonverkäufer/in"
    },
    {
        "label": "Telegraphist/in",
        "value": "Telegraphist/in"
    },
    {
        "label": "Telekommunikationselektroniker/in",
        "value": "Telekommunikationselektroniker/in"
    },
    {
        "label": "Tennislehrer/in",
        "value": "Tennislehrer/in"
    },
    {
        "label": "Teppichknüpfer/in",
        "value": "Teppichknüpfer/in"
    },
    {
        "label": "Teppichreiniger/in",
        "value": "Teppichreiniger/in"
    },
    {
        "label": "Terminplaner/in",
        "value": "Terminplaner/in"
    },
    {
        "label": "Terrarienpfleger/in",
        "value": "Terrarienpfleger/in"
    },
    {
        "label": "Terrazzohersteller/in",
        "value": "Terrazzohersteller/in"
    },
    {
        "label": "Terrazzoleger/in",
        "value": "Terrazzoleger/in"
    },
    {
        "label": "Testfahrer/in",
        "value": "Testfahrer/in"
    },
    {
        "label": "Texter/in",
        "value": "Texter/in"
    },
    {
        "label": "Textilarbeiter/in",
        "value": "Textilarbeiter/in"
    },
    {
        "label": "Textilbetriebswirt/in (FS)",
        "value": "Textilbetriebswirt/in (FS)"
    },
    {
        "label": "Textildesigner/in (HS)",
        "value": "Textildesigner/in (HS)"
    },
    {
        "label": "Textildrucker/in",
        "value": "Textildrucker/in"
    },
    {
        "label": "Textilingenieur/in",
        "value": "Textilingenieur/in"
    },
    {
        "label": "Textilkaufmann/-frau (Einzelhandel)",
        "value": "Textilkaufmann/-frau (Einzelhandel)"
    },
    {
        "label": "Textillaborant/in",
        "value": "Textillaborant/in"
    },
    {
        "label": "Textilmaschinenführer/in",
        "value": "Textilmaschinenführer/in"
    },
    {
        "label": "Textilmaschinenführer/in Weberei",
        "value": "Textilmaschinenführer/in Weberei"
    },
    {
        "label": "Textilmechaniker/in",
        "value": "Textilmechaniker/in"
    },
    {
        "label": "Textilmustergestalter/in",
        "value": "Textilmustergestalter/in"
    },
    {
        "label": "Textilnäher/in",
        "value": "Textilnäher/in"
    },
    {
        "label": "Textilpfleger/in",
        "value": "Textilpfleger/in"
    },
    {
        "label": "Textilreiniger/in",
        "value": "Textilreiniger/in"
    },
    {
        "label": "Textilreinigungsfacharbeiter/in",
        "value": "Textilreinigungsfacharbeiter/in"
    },
    {
        "label": "Textilreinigermeister/in",
        "value": "Textilreinigermeister/in"
    },
    {
        "label": "Textilschmuckmacher/in",
        "value": "Textilschmuckmacher/in"
    },
    {
        "label": "Textilstopfer/in",
        "value": "Textilstopfer/in"
    },
    {
        "label": "Textiltechniker/in",
        "value": "Textiltechniker/in"
    },
    {
        "label": "Textilverarbeiter/in",
        "value": "Textilverarbeiter/in"
    },
    {
        "label": "Textilveredler/in",
        "value": "Textilveredler/in"
    },
    {
        "label": "Textilverflechter/in",
        "value": "Textilverflechter/in"
    },
    {
        "label": "Textilverkäufer/in",
        "value": "Textilverkäufer/in"
    },
    {
        "label": "Theaterbeleuchter/in",
        "value": "Theaterbeleuchter/in"
    },
    {
        "label": "Theaterfundusverwalter/in",
        "value": "Theaterfundusverwalter/in"
    },
    {
        "label": "Bühnen- und Studiohelfer/in (Technik)",
        "value": "Bühnen- und Studiohelfer/in (Technik)"
    },
    {
        "label": "Theaterwissenschaftler/in",
        "value": "Theaterwissenschaftler/in"
    },
    {
        "label": "Thekenkraft",
        "value": "Thekenkraft"
    },
    {
        "label": "Theologe/Theologin",
        "value": "Theologe/Theologin"
    },
    {
        "label": "Therapeut/in Psychotherapie",
        "value": "Therapeut/in Psychotherapie"
    },
    {
        "label": "Tiefbauarbeiter/in",
        "value": "Tiefbauarbeiter/in"
    },
    {
        "label": "Tiefbohrfacharbeiter/in (Erdöl-, Erdgasgewinnung)",
        "value": "Tiefbohrfacharbeiter/in (Erdöl-, Erdgasgewinnung)"
    },
    {
        "label": "Tiefbauer/in",
        "value": "Tiefbauer/in"
    },
    {
        "label": "Tiefbaufacharbeiter/in",
        "value": "Tiefbaufacharbeiter/in"
    },
    {
        "label": "Tiefbaufacharbeiter/in Brunnen- und Spezialtiefbauarbeiten",
        "value": "Tiefbaufacharbeiter/in Brunnen- und Spezialtiefbauarbeiten"
    },
    {
        "label": "Tiefbaufacharbeiter/in Gleisbauarbeiten",
        "value": "Tiefbaufacharbeiter/in Gleisbauarbeiten"
    },
    {
        "label": "Tiefbaufacharbeiter/in Rohrleitungsbauarbeiten",
        "value": "Tiefbaufacharbeiter/in Rohrleitungsbauarbeiten"
    },
    {
        "label": "Tiefbaufacharbeiter/in Straßenbauarbeiten",
        "value": "Tiefbaufacharbeiter/in Straßenbauarbeiten"
    },
    {
        "label": "Tiefbauhelfer/in",
        "value": "Tiefbauhelfer/in"
    },
    {
        "label": "Tiefbaumaschinist/in",
        "value": "Tiefbaumaschinist/in"
    },
    {
        "label": "Tiefbaupolier/in",
        "value": "Tiefbaupolier/in"
    },
    {
        "label": "Tiefbautechniker/in",
        "value": "Tiefbautechniker/in"
    },
    {
        "label": "Tiefbauvorarbeiter/in",
        "value": "Tiefbauvorarbeiter/in"
    },
    {
        "label": "Tiefdrucker/in",
        "value": "Tiefdrucker/in"
    },
    {
        "label": "Fachkraft Lagerlogistik",
        "value": "Fachkraft Lagerlogistik"
    },
    {
        "label": "Tierwirt/in",
        "value": "Tierwirt/in"
    },
    {
        "label": "Tierarzthelfer/in",
        "value": "Tierarzthelfer/in"
    },
    {
        "label": "Tierbändiger/in",
        "value": "Tierbändiger/in"
    },
    {
        "label": "Tierheilpraktiker/in",
        "value": "Tierheilpraktiker/in"
    },
    {
        "label": "Tierkörperverwerter/in",
        "value": "Tierkörperverwerter/in"
    },
    {
        "label": "Tierlehrer/in",
        "value": "Tierlehrer/in"
    },
    {
        "label": "Tierpfleger/in",
        "value": "Tierpfleger/in"
    },
    {
        "label": "Tierzuchtberater/in",
        "value": "Tierzuchtberater/in"
    },
    {
        "label": "Züchter/in (Tierzucht)",
        "value": "Züchter/in (Tierzucht)"
    },
    {
        "label": "Tischler/in",
        "value": "Tischler/in"
    },
    {
        "label": "Tischlerhelfer/in",
        "value": "Tischlerhelfer/in"
    },
    {
        "label": "Tischlermeister/in",
        "value": "Tischlermeister/in"
    },
    {
        "label": "Tonassistent/in (Tontechnik)",
        "value": "Tonassistent/in (Tontechnik)"
    },
    {
        "label": "Toningenieur/in",
        "value": "Toningenieur/in"
    },
    {
        "label": "Tonmeister/in",
        "value": "Tonmeister/in"
    },
    {
        "label": "Töpfer/in",
        "value": "Töpfer/in"
    },
    {
        "label": "Torfarbeiter/in",
        "value": "Torfarbeiter/in"
    },
    {
        "label": "Touristikfachberater/in",
        "value": "Touristikfachberater/in"
    },
    {
        "label": "Touristikfachwirt/in",
        "value": "Touristikfachwirt/in"
    },
    {
        "label": "Touristikkaufmann/-frau",
        "value": "Touristikkaufmann/-frau"
    },
    {
        "label": "Trainer/in Trabrennsport",
        "value": "Trainer/in Trabrennsport"
    },
    {
        "label": "Trainer/in Sport",
        "value": "Trainer/in Sport"
    },
    {
        "label": "EDV-Trainer/in",
        "value": "EDV-Trainer/in"
    },
    {
        "label": "Traktorist/in",
        "value": "Traktorist/in"
    },
    {
        "label": "Transformatorenbauer/in",
        "value": "Transformatorenbauer/in"
    },
    {
        "label": "Transportarbeiter/in",
        "value": "Transportarbeiter/in"
    },
    {
        "label": "Transportgeräteführer/in",
        "value": "Transportgeräteführer/in"
    },
    {
        "label": "Transportmanager/in",
        "value": "Transportmanager/in"
    },
    {
        "label": "Treppenbauer/in",
        "value": "Treppenbauer/in"
    },
    {
        "label": "Tresorschlosser/in",
        "value": "Tresorschlosser/in"
    },
    {
        "label": "Treuhänder/in",
        "value": "Treuhänder/in"
    },
    {
        "label": "Triebfahrzeugführer/in",
        "value": "Triebfahrzeugführer/in"
    },
    {
        "label": "Triebwerkmechaniker/in",
        "value": "Triebwerkmechaniker/in"
    },
    {
        "label": "Trockenausbauer/in",
        "value": "Trockenausbauer/in"
    },
    {
        "label": "Trockenbauer/in",
        "value": "Trockenbauer/in"
    },
    {
        "label": "Trockenbaumeister/in",
        "value": "Trockenbaumeister/in"
    },
    {
        "label": "Trockenbaumonteur/in",
        "value": "Trockenbaumonteur/in"
    },
    {
        "label": "Tüncher/in",
        "value": "Tüncher/in"
    },
    {
        "label": "Tunnelbauer/in",
        "value": "Tunnelbauer/in"
    },
    {
        "label": "Turbinenschlosser/in",
        "value": "Turbinenschlosser/in"
    },
    {
        "label": "Turnlehrer/in",
        "value": "Turnlehrer/in"
    },
    {
        "label": "U-Bahn-Fahrer/in",
        "value": "U-Bahn-Fahrer/in"
    },
    {
        "label": "Reparaturuhrmacher/in",
        "value": "Reparaturuhrmacher/in"
    },
    {
        "label": "Uhrmacher/in",
        "value": "Uhrmacher/in"
    },
    {
        "label": "Uhrmachermeister/in",
        "value": "Uhrmachermeister/in"
    },
    {
        "label": "Ultraschallprüfer/in",
        "value": "Ultraschallprüfer/in"
    },
    {
        "label": "Umschlagarbeiter/in",
        "value": "Umschlagarbeiter/in"
    },
    {
        "label": "Umweltberater/in",
        "value": "Umweltberater/in"
    },
    {
        "label": "Umweltsachbearbeiter/in",
        "value": "Umweltsachbearbeiter/in"
    },
    {
        "label": "Umweltschutzbeauftragte/r",
        "value": "Umweltschutzbeauftragte/r"
    },
    {
        "label": "Umweltschutzingenieur/in",
        "value": "Umweltschutzingenieur/in"
    },
    {
        "label": "Umweltschutztechniker/in",
        "value": "Umweltschutztechniker/in"
    },
    {
        "label": "Umwelttechniker/in",
        "value": "Umwelttechniker/in"
    },
    {
        "label": "Umweltwissenschaftler/in",
        "value": "Umweltwissenschaftler/in"
    },
    {
        "label": "Unfallchirurg/in",
        "value": "Unfallchirurg/in"
    },
    {
        "label": "Unfallsachverständige/r (technisch)",
        "value": "Unfallsachverständige/r (technisch)"
    },
    {
        "label": "Universalhärter/in",
        "value": "Universalhärter/in"
    },
    {
        "label": "Universalschleifer/in",
        "value": "Universalschleifer/in"
    },
    {
        "label": "Universitätsprofessor/in",
        "value": "Universitätsprofessor/in"
    },
    {
        "label": "Unternehmensberater/in",
        "value": "Unternehmensberater/in"
    },
    {
        "label": "Unternehmer/in",
        "value": "Unternehmer/in"
    },
    {
        "label": "Venerologe/Venerologin",
        "value": "Venerologe/Venerologin"
    },
    {
        "label": "Ver- und Entsorger/in",
        "value": "Ver- und Entsorger/in"
    },
    {
        "label": "Veranstaltungskaufmann/-frau",
        "value": "Veranstaltungskaufmann/-frau"
    },
    {
        "label": "Veranstaltungsmanager/in",
        "value": "Veranstaltungsmanager/in"
    },
    {
        "label": "Verbandssekretär/in",
        "value": "Verbandssekretär/in"
    },
    {
        "label": "Verbandsgeschäftsführer/in",
        "value": "Verbandsgeschäftsführer/in"
    },
    {
        "label": "Verbandsleiter/in",
        "value": "Verbandsleiter/in"
    },
    {
        "label": "Verbraucherberater/in",
        "value": "Verbraucherberater/in"
    },
    {
        "label": "Verfahrensingenieur/in",
        "value": "Verfahrensingenieur/in"
    },
    {
        "label": "Verfahrensmechaniker/in Beschichtungstechnik",
        "value": "Verfahrensmechaniker/in Beschichtungstechnik"
    },
    {
        "label": "Verfahrensmechaniker/in Hütten-/Halbzeugindustrie - Eisen-/Stahl-Metallurgie",
        "value": "Verfahrensmechaniker/in Hütten-/Halbzeugindustrie - Eisen-/Stahl-Metallurgie"
    },
    {
        "label": "Verfahrenstechniker/in",
        "value": "Verfahrenstechniker/in"
    },
    {
        "label": "Verfuger/in",
        "value": "Verfuger/in"
    },
    {
        "label": "Vergolder/in",
        "value": "Vergolder/in"
    },
    {
        "label": "Verkäufer/in Nahrungsmittelhandwerk",
        "value": "Verkäufer/in Nahrungsmittelhandwerk"
    },
    {
        "label": "Vertriebsbeauftragte/r",
        "value": "Vertriebsbeauftragte/r"
    },
    {
        "label": "Vertriebssachbearbeiter/in",
        "value": "Vertriebssachbearbeiter/in"
    },
    {
        "label": "Verkaufsassistent/in",
        "value": "Verkaufsassistent/in"
    },
    {
        "label": "Verkaufsaufsicht",
        "value": "Verkaufsaufsicht"
    },
    {
        "label": "Verkaufsberater/in",
        "value": "Verkaufsberater/in"
    },
    {
        "label": "Verkaufsfahrer/in",
        "value": "Verkaufsfahrer/in"
    },
    {
        "label": "Verkaufsförderer/-förderin",
        "value": "Verkaufsförderer/-förderin"
    },
    {
        "label": "Verkaufshilfe",
        "value": "Verkaufshilfe"
    },
    {
        "label": "Verkaufsleiter/in",
        "value": "Verkaufsleiter/in"
    },
    {
        "label": "Verkaufssachbearbeiter/in",
        "value": "Verkaufssachbearbeiter/in"
    },
    {
        "label": "Verkaufsstellenleiter/in",
        "value": "Verkaufsstellenleiter/in"
    },
    {
        "label": "Verkaufsstellenverwalter/in",
        "value": "Verkaufsstellenverwalter/in"
    },
    {
        "label": "Verkaufstrainer/in",
        "value": "Verkaufstrainer/in"
    },
    {
        "label": "Verkehrserzieher/in",
        "value": "Verkehrserzieher/in"
    },
    {
        "label": "Verkehrsfachmann/-frau (Fremdenverkehr)",
        "value": "Verkehrsfachmann/-frau (Fremdenverkehr)"
    },
    {
        "label": "Verkehrsfachwirt/in",
        "value": "Verkehrsfachwirt/in"
    },
    {
        "label": "Verkehrsflugzeugführer/in",
        "value": "Verkehrsflugzeugführer/in"
    },
    {
        "label": "Verkehrsingenieur/in",
        "value": "Verkehrsingenieur/in"
    },
    {
        "label": "Verkehrsfachwirt/in Güterverkehr",
        "value": "Verkehrsfachwirt/in Güterverkehr"
    },
    {
        "label": "Verkehrslotse/-lotsin",
        "value": "Verkehrslotse/-lotsin"
    },
    {
        "label": "Verkehrsplaner/in",
        "value": "Verkehrsplaner/in"
    },
    {
        "label": "Verlademeister/in",
        "value": "Verlademeister/in"
    },
    {
        "label": "Verlader/in",
        "value": "Verlader/in"
    },
    {
        "label": "Verlagsfachkraft",
        "value": "Verlagsfachkraft"
    },
    {
        "label": "Verlagskaufmann/-frau",
        "value": "Verlagskaufmann/-frau"
    },
    {
        "label": "Verlagsleiter/in",
        "value": "Verlagsleiter/in"
    },
    {
        "label": "Verleger/in Verlag",
        "value": "Verleger/in Verlag"
    },
    {
        "label": "Verleiher/in",
        "value": "Verleiher/in"
    },
    {
        "label": "Bergvermesser/in",
        "value": "Bergvermesser/in"
    },
    {
        "label": "Vermessungsassistent/in",
        "value": "Vermessungsassistent/in"
    },
    {
        "label": "Beamter/Beamtin Vermessungswesen (geh. techn. Dienst)",
        "value": "Beamter/Beamtin Vermessungswesen (geh. techn. Dienst)"
    },
    {
        "label": "Vermessungstechniker/in",
        "value": "Vermessungstechniker/in"
    },
    {
        "label": "Vermessungszeichner/in",
        "value": "Vermessungszeichner/in"
    },
    {
        "label": "Vermieter/in (Autovermietung)",
        "value": "Vermieter/in (Autovermietung)"
    },
    {
        "label": "Vermittler/in",
        "value": "Vermittler/in"
    },
    {
        "label": "Vermögensberater/in",
        "value": "Vermögensberater/in"
    },
    {
        "label": "Vermögensverwalter/in",
        "value": "Vermögensverwalter/in"
    },
    {
        "label": "Verpacker/in",
        "value": "Verpacker/in"
    },
    {
        "label": "Verpackungsingenieur/in",
        "value": "Verpackungsingenieur/in"
    },
    {
        "label": "Verpackungsmittelhersteller/in",
        "value": "Verpackungsmittelhersteller/in"
    },
    {
        "label": "Verpackungsmittelmaschinenführer/in",
        "value": "Verpackungsmittelmaschinenführer/in"
    },
    {
        "label": "Verpackungsmittelmechaniker/in",
        "value": "Verpackungsmittelmechaniker/in"
    },
    {
        "label": "Verpackungstechniker/in",
        "value": "Verpackungstechniker/in"
    },
    {
        "label": "Verpflegungsbetriebswirt/in",
        "value": "Verpflegungsbetriebswirt/in"
    },
    {
        "label": "Verputzer/in",
        "value": "Verputzer/in"
    },
    {
        "label": "Versandarbeiter/in",
        "value": "Versandarbeiter/in"
    },
    {
        "label": "Versandfertigmacher/in",
        "value": "Versandfertigmacher/in"
    },
    {
        "label": "Versandleiter/in",
        "value": "Versandleiter/in"
    },
    {
        "label": "Versicherungsagent/in",
        "value": "Versicherungsagent/in"
    },
    {
        "label": "Versicherungsfachangestellte/r",
        "value": "Versicherungsfachangestellte/r"
    },
    {
        "label": "Versicherungsberater/in",
        "value": "Versicherungsberater/in"
    },
    {
        "label": "Versicherungsfachwirt/in",
        "value": "Versicherungsfachwirt/in"
    },
    {
        "label": "Versicherungsmakler/in",
        "value": "Versicherungsmakler/in"
    },
    {
        "label": "Versicherungsmathematiker/in",
        "value": "Versicherungsmathematiker/in"
    },
    {
        "label": "Versicherungssachbearbeiter/in",
        "value": "Versicherungssachbearbeiter/in"
    },
    {
        "label": "Versicherungsvermittler/in",
        "value": "Versicherungsvermittler/in"
    },
    {
        "label": "Versicherungsvertreter/in",
        "value": "Versicherungsvertreter/in"
    },
    {
        "label": "Versorgungsingenieur/in",
        "value": "Versorgungsingenieur/in"
    },
    {
        "label": "Versorgungstechniker/in",
        "value": "Versorgungstechniker/in"
    },
    {
        "label": "Auktionator/in, Versteigerer/Versteigerin",
        "value": "Auktionator/in, Versteigerer/Versteigerin"
    },
    {
        "label": "Versuchsfahrer/in",
        "value": "Versuchsfahrer/in"
    },
    {
        "label": "Versuchsingenieur/in",
        "value": "Versuchsingenieur/in"
    },
    {
        "label": "Versuchstechniker/in",
        "value": "Versuchstechniker/in"
    },
    {
        "label": "Versuchstierpfleger/in",
        "value": "Versuchstierpfleger/in"
    },
    {
        "label": "Vertreter/in Handelsvertreter/in",
        "value": "Vertreter/in Handelsvertreter/in"
    },
    {
        "label": "Vertriebsmitarbeiter/in",
        "value": "Vertriebsmitarbeiter/in"
    },
    {
        "label": "Vertriebsassistent/in",
        "value": "Vertriebsassistent/in"
    },
    {
        "label": "Vertriebsberater/in",
        "value": "Vertriebsberater/in"
    },
    {
        "label": "Vertriebsdirektor/in",
        "value": "Vertriebsdirektor/in"
    },
    {
        "label": "Vertriebsingenieur/in",
        "value": "Vertriebsingenieur/in"
    },
    {
        "label": "Vertriebsinspektor/in",
        "value": "Vertriebsinspektor/in"
    },
    {
        "label": "Vertriebsleiter/in",
        "value": "Vertriebsleiter/in"
    },
    {
        "label": "Vertriebsmanager/in",
        "value": "Vertriebsmanager/in"
    },
    {
        "label": "Vertriebstechniker/in",
        "value": "Vertriebstechniker/in"
    },
    {
        "label": "Vervielfältiger/in",
        "value": "Vervielfältiger/in"
    },
    {
        "label": "Verwalter/in Lager, Material",
        "value": "Verwalter/in Lager, Material"
    },
    {
        "label": "Verwalter/in Landwirtschaft",
        "value": "Verwalter/in Landwirtschaft"
    },
    {
        "label": "Verwaltungsangestellte/r (mittl. Dienst)",
        "value": "Verwaltungsangestellte/r (mittl. Dienst)"
    },
    {
        "label": "Verwaltungsbeamter/-beamtin (mittl. Dienst)",
        "value": "Verwaltungsbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Verwaltungsleiter/in",
        "value": "Verwaltungsleiter/in"
    },
    {
        "label": "Verwaltungsfachkraft",
        "value": "Verwaltungsfachkraft"
    },
    {
        "label": "Verwaltungsjurist/in",
        "value": "Verwaltungsjurist/in"
    },
    {
        "label": "Verwaltungswirt/in",
        "value": "Verwaltungswirt/in"
    },
    {
        "label": "Verzinker/in",
        "value": "Verzinker/in"
    },
    {
        "label": "Veterinärmedizinisch-technische/r Assistent/in",
        "value": "Veterinärmedizinisch-technische/r Assistent/in"
    },
    {
        "label": "Veterinärmedizinische/r Laborant/in",
        "value": "Veterinärmedizinische/r Laborant/in"
    },
    {
        "label": "Videograf/in",
        "value": "Videograf/in"
    },
    {
        "label": "Viehhändler/in",
        "value": "Viehhändler/in"
    },
    {
        "label": "Viehhalter/in",
        "value": "Viehhalter/in"
    },
    {
        "label": "Visagist/in",
        "value": "Visagist/in"
    },
    {
        "label": "Volljurist/in",
        "value": "Volljurist/in"
    },
    {
        "label": "Vollstreckungsbeamter/-beamtin (Gerichtsvollzieher/in)",
        "value": "Vollstreckungsbeamter/-beamtin (Gerichtsvollzieher/in)"
    },
    {
        "label": "Vollziehungsbeamter/-beamtin",
        "value": "Vollziehungsbeamter/-beamtin"
    },
    {
        "label": "Redaktionsvolontär/in",
        "value": "Redaktionsvolontär/in"
    },
    {
        "label": "Vorarbeiter/in",
        "value": "Vorarbeiter/in"
    },
    {
        "label": "Werkzeugvorrichter/in",
        "value": "Werkzeugvorrichter/in"
    },
    {
        "label": "Vorstandsvorsitzende/r",
        "value": "Vorstandsvorsitzende/r"
    },
    {
        "label": "Vorstandsassistent/in",
        "value": "Vorstandsassistent/in"
    },
    {
        "label": "Vorstandsmitglied",
        "value": "Vorstandsmitglied"
    },
    {
        "label": "Vorstandssekretär/in",
        "value": "Vorstandssekretär/in"
    },
    {
        "label": "Vulkaniseur/in",
        "value": "Vulkaniseur/in"
    },
    {
        "label": "Waagenbauer/in",
        "value": "Waagenbauer/in"
    },
    {
        "label": "Aufseher/in Wachdienst",
        "value": "Aufseher/in Wachdienst"
    },
    {
        "label": "Wachschutzmann/-frau",
        "value": "Wachschutzmann/-frau"
    },
    {
        "label": "Anlagenwächter/in",
        "value": "Anlagenwächter/in"
    },
    {
        "label": "Wagenmeister/in (Schienenverkehr)",
        "value": "Wagenmeister/in (Schienenverkehr)"
    },
    {
        "label": "Wagenpfleger/in",
        "value": "Wagenpfleger/in"
    },
    {
        "label": "Wagenschmied/in",
        "value": "Wagenschmied/in"
    },
    {
        "label": "Wäger/in",
        "value": "Wäger/in"
    },
    {
        "label": "Wagner/in",
        "value": "Wagner/in"
    },
    {
        "label": "Wahrsager/in",
        "value": "Wahrsager/in"
    },
    {
        "label": "Waldarbeiter/in",
        "value": "Waldarbeiter/in"
    },
    {
        "label": "Waldfacharbeiter/in",
        "value": "Waldfacharbeiter/in"
    },
    {
        "label": "Walzenfahrer/in (Straßenbau)",
        "value": "Walzenfahrer/in (Straßenbau)"
    },
    {
        "label": "Walzenschleifer/in (Metallschleifer/in)",
        "value": "Walzenschleifer/in (Metallschleifer/in)"
    },
    {
        "label": "Walzer/in",
        "value": "Walzer/in"
    },
    {
        "label": "Walzwerker/in",
        "value": "Walzwerker/in"
    },
    {
        "label": "Walzwerksingenieur/in",
        "value": "Walzwerksingenieur/in"
    },
    {
        "label": "Auswahlprüfer/in",
        "value": "Auswahlprüfer/in"
    },
    {
        "label": "Abzähler/in",
        "value": "Abzähler/in"
    },
    {
        "label": "Warenannehmer/in",
        "value": "Warenannehmer/in"
    },
    {
        "label": "Bündler/in (Warenaufmacher/in, Versandfertigmacher/in)",
        "value": "Bündler/in (Warenaufmacher/in, Versandfertigmacher/in)"
    },
    {
        "label": "Austräger/in",
        "value": "Austräger/in"
    },
    {
        "label": "Warenbereitsteller/in",
        "value": "Warenbereitsteller/in"
    },
    {
        "label": "Warenbeschauer/in (Textil)",
        "value": "Warenbeschauer/in (Textil)"
    },
    {
        "label": "Depotverwalter/in (Lager)",
        "value": "Depotverwalter/in (Lager)"
    },
    {
        "label": "Wareneingangsleiter/in",
        "value": "Wareneingangsleiter/in"
    },
    {
        "label": "Warenhausdetektiv/in",
        "value": "Warenhausdetektiv/in"
    },
    {
        "label": "Warenkennzeichner/in",
        "value": "Warenkennzeichner/in"
    },
    {
        "label": "Warenkontrolleur/in",
        "value": "Warenkontrolleur/in"
    },
    {
        "label": "Automatenspritzer/in (Lackiererei)",
        "value": "Automatenspritzer/in (Lackiererei)"
    },
    {
        "label": "Warenprüfer/in",
        "value": "Warenprüfer/in"
    },
    {
        "label": "Warensortierer/in",
        "value": "Warensortierer/in"
    },
    {
        "label": "Wärmeisolierer/in",
        "value": "Wärmeisolierer/in"
    },
    {
        "label": "Warmwalzer/in",
        "value": "Warmwalzer/in"
    },
    {
        "label": "Wartungsingenieur/in",
        "value": "Wartungsingenieur/in"
    },
    {
        "label": "Wartungsmechaniker/in",
        "value": "Wartungsmechaniker/in"
    },
    {
        "label": "Wartungstechniker/in",
        "value": "Wartungstechniker/in"
    },
    {
        "label": "Ausbesserer/Ausbesserin",
        "value": "Ausbesserer/Ausbesserin"
    },
    {
        "label": "Wäschemangler/in",
        "value": "Wäschemangler/in"
    },
    {
        "label": "Bekleidungsnäher/in",
        "value": "Bekleidungsnäher/in"
    },
    {
        "label": "Textilreinigungsfacharbeiter/in Wäscherei",
        "value": "Textilreinigungsfacharbeiter/in Wäscherei"
    },
    {
        "label": "Wasserbauer/in",
        "value": "Wasserbauer/in"
    },
    {
        "label": "Wasserbauingenieur/in",
        "value": "Wasserbauingenieur/in"
    },
    {
        "label": "Wasserbaufacharbeiter/in",
        "value": "Wasserbaufacharbeiter/in"
    },
    {
        "label": "Wasserinstallateur/in",
        "value": "Wasserinstallateur/in"
    },
    {
        "label": "Wasserkraftwerkmaschinist/in",
        "value": "Wasserkraftwerkmaschinist/in"
    },
    {
        "label": "Wassermeister/in",
        "value": "Wassermeister/in"
    },
    {
        "label": "Bordpolizist/in (geh. Dienst)",
        "value": "Bordpolizist/in (geh. Dienst)"
    },
    {
        "label": "Wassersportlehrer/in",
        "value": "Wassersportlehrer/in"
    },
    {
        "label": "Wassersporttrainer/in",
        "value": "Wassersporttrainer/in"
    },
    {
        "label": "Wasserstraßenverkehrswart/in",
        "value": "Wasserstraßenverkehrswart/in"
    },
    {
        "label": "Wasserversorgungstechniker/in",
        "value": "Wasserversorgungstechniker/in"
    },
    {
        "label": "Kühlwasserwärter/in",
        "value": "Kühlwasserwärter/in"
    },
    {
        "label": "Wasserwerker/in",
        "value": "Wasserwerker/in"
    },
    {
        "label": "Web-Designer/in",
        "value": "Web-Designer/in"
    },
    {
        "label": "Weber/in",
        "value": "Weber/in"
    },
    {
        "label": "Webvorbereiter/in",
        "value": "Webvorbereiter/in"
    },
    {
        "label": "Weichenwärter/in",
        "value": "Weichenwärter/in"
    },
    {
        "label": "Weinbauberater/in",
        "value": "Weinbauberater/in"
    },
    {
        "label": "Weinbauer/-bäuerin",
        "value": "Weinbauer/-bäuerin"
    },
    {
        "label": "Weinbauingenieur/in",
        "value": "Weinbauingenieur/in"
    },
    {
        "label": "Weinbautechniker/in",
        "value": "Weinbautechniker/in"
    },
    {
        "label": "Weinbergsarbeiter/in",
        "value": "Weinbergsarbeiter/in"
    },
    {
        "label": "Weinhändler/in (Einzelhandel)",
        "value": "Weinhändler/in (Einzelhandel)"
    },
    {
        "label": "Weinküfer/in",
        "value": "Weinküfer/in"
    },
    {
        "label": "Weinverkäufer/in",
        "value": "Weinverkäufer/in"
    },
    {
        "label": "Werbeassistent/in",
        "value": "Werbeassistent/in"
    },
    {
        "label": "Werbeberater/in",
        "value": "Werbeberater/in"
    },
    {
        "label": "Werbedesigner/in",
        "value": "Werbedesigner/in"
    },
    {
        "label": "Werbefachmann/-fachfrau",
        "value": "Werbefachmann/-fachfrau"
    },
    {
        "label": "Werbefachwirt/in",
        "value": "Werbefachwirt/in"
    },
    {
        "label": "Werbefigurenmacher/in",
        "value": "Werbefigurenmacher/in"
    },
    {
        "label": "Werbefotograf/in",
        "value": "Werbefotograf/in"
    },
    {
        "label": "Werbegestalter/in",
        "value": "Werbegestalter/in"
    },
    {
        "label": "Werbegrafiker/in",
        "value": "Werbegrafiker/in"
    },
    {
        "label": "Werbekaufmann/-frau",
        "value": "Werbekaufmann/-frau"
    },
    {
        "label": "Werbeleiter/in",
        "value": "Werbeleiter/in"
    },
    {
        "label": "Werbemaler/in",
        "value": "Werbemaler/in"
    },
    {
        "label": "Werbemanager/in",
        "value": "Werbemanager/in"
    },
    {
        "label": "Werbetechniker/in",
        "value": "Werbetechniker/in"
    },
    {
        "label": "Werbetexter/in",
        "value": "Werbetexter/in"
    },
    {
        "label": "Werftarbeiter/in",
        "value": "Werftarbeiter/in"
    },
    {
        "label": "Detektiv/in - Werkschutz",
        "value": "Detektiv/in - Werkschutz"
    },
    {
        "label": "Schichtwerkführer/in",
        "value": "Schichtwerkführer/in"
    },
    {
        "label": "Werker/in - Feinwerktechnik (§66 BBiG/§42m HwO)",
        "value": "Werker/in - Feinwerktechnik (§66 BBiG/§42m HwO)"
    },
    {
        "label": "Werkfeuerwehrmann/-frau",
        "value": "Werkfeuerwehrmann/-frau"
    },
    {
        "label": "Werkführer/in",
        "value": "Werkführer/in"
    },
    {
        "label": "Werklehrer/in",
        "value": "Werklehrer/in"
    },
    {
        "label": "Werkleiter/in",
        "value": "Werkleiter/in"
    },
    {
        "label": "Werkmeister/in",
        "value": "Werkmeister/in"
    },
    {
        "label": "Werkpolier/in",
        "value": "Werkpolier/in"
    },
    {
        "label": "Werkschutzfachkraft",
        "value": "Werkschutzfachkraft"
    },
    {
        "label": "Werkschutzfachmann/-frau - Wach- und Streifendienst",
        "value": "Werkschutzfachmann/-frau - Wach- und Streifendienst"
    },
    {
        "label": "Betriebsfeuerwehrmann/-frau",
        "value": "Betriebsfeuerwehrmann/-frau"
    },
    {
        "label": "Produktionshelfer/in Metall",
        "value": "Produktionshelfer/in Metall"
    },
    {
        "label": "Werkstattleiter/in",
        "value": "Werkstattleiter/in"
    },
    {
        "label": "Fachlehrer/in Werkstatt",
        "value": "Fachlehrer/in Werkstatt"
    },
    {
        "label": "Werkstattmeister/in",
        "value": "Werkstattmeister/in"
    },
    {
        "label": "Werkstoffingenieur/in",
        "value": "Werkstoffingenieur/in"
    },
    {
        "label": "Werkstoffprüfer/in",
        "value": "Werkstoffprüfer/in"
    },
    {
        "label": "Werkstofftechniker/in",
        "value": "Werkstofftechniker/in"
    },
    {
        "label": "Werkzeugaufbereiter/in",
        "value": "Werkzeugaufbereiter/in"
    },
    {
        "label": "Werkzeugfräser/in",
        "value": "Werkzeugfräser/in"
    },
    {
        "label": "Werkzeuglagerist/in",
        "value": "Werkzeuglagerist/in"
    },
    {
        "label": "Werkzeugmacher/in",
        "value": "Werkzeugmacher/in"
    },
    {
        "label": "Werkzeugmachermeister/in",
        "value": "Werkzeugmachermeister/in"
    },
    {
        "label": "Werkzeugschleifer/in",
        "value": "Werkzeugschleifer/in"
    },
    {
        "label": "Wertpapierberater/in",
        "value": "Wertpapierberater/in"
    },
    {
        "label": "Ver- und Entsorger/in Recyclingbetrieben",
        "value": "Ver- und Entsorger/in Recyclingbetrieben"
    },
    {
        "label": "Wiegemeister/in (amtlich)",
        "value": "Wiegemeister/in (amtlich)"
    },
    {
        "label": "Wildheger/in",
        "value": "Wildheger/in"
    },
    {
        "label": "Großtierzüchter/in",
        "value": "Großtierzüchter/in"
    },
    {
        "label": "Winzer/in",
        "value": "Winzer/in"
    },
    {
        "label": "Winzermeister/in",
        "value": "Winzermeister/in"
    },
    {
        "label": "Wirt/in (Gastwirt/in)",
        "value": "Wirt/in (Gastwirt/in)"
    },
    {
        "label": "Wirtschafter,in",
        "value": "Wirtschafter,in"
    },
    {
        "label": "Wirtschaftler/in Außenwirtschaft",
        "value": "Wirtschaftler/in Außenwirtschaft"
    },
    {
        "label": "Wirtschaftsassistent/in",
        "value": "Wirtschaftsassistent/in"
    },
    {
        "label": "Wirtschaftsassistent/in Informatik",
        "value": "Wirtschaftsassistent/in Informatik"
    },
    {
        "label": "Wirtschaftsberater/in - Finanzdienstleistungen",
        "value": "Wirtschaftsberater/in - Finanzdienstleistungen"
    },
    {
        "label": "Wirtschaftsgehilfen",
        "value": "Wirtschaftsgehilfen"
    },
    {
        "label": "Wirtschaftshilfe",
        "value": "Wirtschaftshilfe"
    },
    {
        "label": "Wirtschaftsinformatiker/in (HS)",
        "value": "Wirtschaftsinformatiker/in (HS)"
    },
    {
        "label": "Wirtschaftsjurist/in",
        "value": "Wirtschaftsjurist/in"
    },
    {
        "label": "Wirtschaftskaufmann/-frau",
        "value": "Wirtschaftskaufmann/-frau"
    },
    {
        "label": "Wirtschaftsprüfer/in",
        "value": "Wirtschaftsprüfer/in"
    },
    {
        "label": "Wirtschaftstechniker/in",
        "value": "Wirtschaftstechniker/in"
    },
    {
        "label": "Wirtschaftswissenschaftler/in",
        "value": "Wirtschaftswissenschaftler/in"
    },
    {
        "label": "Wohnungsverwalter/in",
        "value": "Wohnungsverwalter/in"
    },
    {
        "label": "Fleisch- und Wurstwarenfachverkäufer/in",
        "value": "Fleisch- und Wurstwarenfachverkäufer/in"
    },
    {
        "label": "Yoga-Lehrer/in",
        "value": "Yoga-Lehrer/in"
    },
    {
        "label": "Zählerableser/in",
        "value": "Zählerableser/in"
    },
    {
        "label": "Zählerbauer/in",
        "value": "Zählerbauer/in"
    },
    {
        "label": "Zahlmeister/in (Schiff)",
        "value": "Zahlmeister/in (Schiff)"
    },
    {
        "label": "Zahnarzt/-ärztin",
        "value": "Zahnarzt/-ärztin"
    },
    {
        "label": "Zahnarzthelfer/in",
        "value": "Zahnarzthelfer/in"
    },
    {
        "label": "Zahnarztsekretär/in",
        "value": "Zahnarztsekretär/in"
    },
    {
        "label": "Zahnmedizinische/r Fachangestellte/r",
        "value": "Zahnmedizinische/r Fachangestellte/r"
    },
    {
        "label": "Zahnmedizinisch-technische/r Assistent/in",
        "value": "Zahnmedizinisch-technische/r Assistent/in"
    },
    {
        "label": "Zahntechnische/r Laborant/in",
        "value": "Zahntechnische/r Laborant/in"
    },
    {
        "label": "Zahnradfräser/in",
        "value": "Zahnradfräser/in"
    },
    {
        "label": "Zahntechniker/in",
        "value": "Zahntechniker/in"
    },
    {
        "label": "Zahntechnikerassistent/in",
        "value": "Zahntechnikerassistent/in"
    },
    {
        "label": "Zahntechnikermeister/in",
        "value": "Zahntechnikermeister/in"
    },
    {
        "label": "Zauberkünstler/in",
        "value": "Zauberkünstler/in"
    },
    {
        "label": "Zechenfeuerwehrmann/-frau",
        "value": "Zechenfeuerwehrmann/-frau"
    },
    {
        "label": "Zeichenlehrer/in",
        "value": "Zeichenlehrer/in"
    },
    {
        "label": "Zeichner/in (nicht technisch)",
        "value": "Zeichner/in (nicht technisch)"
    },
    {
        "label": "Kaufmann/-frau Zeitungs- und Zeitschriftenverlag",
        "value": "Kaufmann/-frau Zeitungs- und Zeitschriftenverlag"
    },
    {
        "label": "Zeitsoldat/in",
        "value": "Zeitsoldat/in"
    },
    {
        "label": "Zeitungsausträger/in",
        "value": "Zeitungsausträger/in"
    },
    {
        "label": "Zeitungskorrektor/in",
        "value": "Zeitungskorrektor/in"
    },
    {
        "label": "Zeitungsverleger/in",
        "value": "Zeitungsverleger/in"
    },
    {
        "label": "Zeitungszusteller/in",
        "value": "Zeitungszusteller/in"
    },
    {
        "label": "Zellstoffhersteller/in",
        "value": "Zellstoffhersteller/in"
    },
    {
        "label": "Zeltmacher/in",
        "value": "Zeltmacher/in"
    },
    {
        "label": "Zementwerker/in",
        "value": "Zementwerker/in"
    },
    {
        "label": "Zentralheizungsbauer/in",
        "value": "Zentralheizungsbauer/in"
    },
    {
        "label": "Zentralheizungsinstallateur/in",
        "value": "Zentralheizungsinstallateur/in"
    },
    {
        "label": "Auskunfterteiler/in",
        "value": "Auskunfterteiler/in"
    },
    {
        "label": "Zerspaner/in",
        "value": "Zerspaner/in"
    },
    {
        "label": "Zerspanungsmechaniker/in",
        "value": "Zerspanungsmechaniker/in"
    },
    {
        "label": "Zerspanungstechniker/in",
        "value": "Zerspanungstechniker/in"
    },
    {
        "label": "Ziegelbrenner/in",
        "value": "Ziegelbrenner/in"
    },
    {
        "label": "Ziegler/in",
        "value": "Ziegler/in"
    },
    {
        "label": "Zigarettenmacher/in",
        "value": "Zigarettenmacher/in"
    },
    {
        "label": "Zigarrenmacher/in",
        "value": "Zigarrenmacher/in"
    },
    {
        "label": "Zimmerer/Zimmerin",
        "value": "Zimmerer/Zimmerin"
    },
    {
        "label": "Zimmererhelfer/in",
        "value": "Zimmererhelfer/in"
    },
    {
        "label": "Zimmerermeister/in",
        "value": "Zimmerermeister/in"
    },
    {
        "label": "Zimmermädchen/Roomboy",
        "value": "Zimmermädchen/Roomboy"
    },
    {
        "label": "Zimmermann/-frau",
        "value": "Zimmermann/-frau"
    },
    {
        "label": "Meister/in Zimmererhandwerk",
        "value": "Meister/in Zimmererhandwerk"
    },
    {
        "label": "Zinngießer/in",
        "value": "Zinngießer/in"
    },
    {
        "label": "Helfer/in Metallbearbeitung",
        "value": "Helfer/in Metallbearbeitung"
    },
    {
        "label": "Zollbeamter/-beamtin (einf. Dienst)",
        "value": "Zollbeamter/-beamtin (einf. Dienst)"
    },
    {
        "label": "Zolldeklarant/in",
        "value": "Zolldeklarant/in"
    },
    {
        "label": "Zollbeamter/-beamtin (höh. Dienst)",
        "value": "Zollbeamter/-beamtin (höh. Dienst)"
    },
    {
        "label": "Zollbeamter/-beamtin (mittl. Dienst)",
        "value": "Zollbeamter/-beamtin (mittl. Dienst)"
    },
    {
        "label": "Zollsachbearbeiter/in",
        "value": "Zollsachbearbeiter/in"
    },
    {
        "label": "Zoologe/Zoologin",
        "value": "Zoologe/Zoologin"
    },
    {
        "label": "Zootierpfleger/in",
        "value": "Zootierpfleger/in"
    },
    {
        "label": "Zucker-, Süßwarenherstellerhelfer/in",
        "value": "Zucker-, Süßwarenherstellerhelfer/in"
    },
    {
        "label": "Zugabfertiger/in",
        "value": "Zugabfertiger/in"
    },
    {
        "label": "Zugbegleiter/in",
        "value": "Zugbegleiter/in"
    },
    {
        "label": "Zugführer/in",
        "value": "Zugführer/in"
    },
    {
        "label": "Zugmaschinenführer/in",
        "value": "Zugmaschinenführer/in"
    },
    {
        "label": "Zugvorbereiter/in",
        "value": "Zugvorbereiter/in"
    },
    {
        "label": "Zurichter/in Geräte-, Maschinenteile",
        "value": "Zurichter/in Geräte-, Maschinenteile"
    },
    {
        "label": "Zuschneider/in Kunststoff und Kautschuk",
        "value": "Zuschneider/in Kunststoff und Kautschuk"
    },
    {
        "label": "Zweigstellenleiter/in (Bank)",
        "value": "Zweigstellenleiter/in (Bank)"
    },
    {
        "label": "Zweiradmechaniker/in",
        "value": "Zweiradmechaniker/in"
    },
    {
        "label": "Zweiradmechanikermeister/in",
        "value": "Zweiradmechanikermeister/in"
    },
    {
        "label": "Fachverkäufer/in Zweiradhandel",
        "value": "Fachverkäufer/in Zweiradhandel"
    },
    {
        "label": "Zwirner/in",
        "value": "Zwirner/in"
    }
]
