import React, {useEffect} from "react";
import FormWrapper from "../components/forms/FormWrapper";
import {insuranceTypes} from "../../_params/variables";
import {labels} from "../../_params/form_labels";
import DateInput from "../components/formitems/DateInput";
import SelectInput from "../components/formitems/SelectInput";
import {
	bauartklasseOptions_Wohngebaeude, dachausbauOptions_Wohngebaeude,
	elementarSchaedenOptions_Wohngebaeude,
	gebaeudeartOptions_Wohngebaeude, gebaeudenutzungOptions_Wohngebaeude,
	JaNeinRadioOptions, JaNeinSwitcher, nutzungsartOptions_Wohngebaeude, selbstbehaltOptions_Wohngebaeude,
	vorschaedenOptions_Wohngebaeude,
	zahlweiseOptions
} from "../utils/optionsSelection";
import RadioGroupInput from "../components/formitems/RadioGroupInput";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import TextInput from "../components/formitems/TextInput";
import validationSchemaResidential from "../utils/validationschemas/validationSchemaResidential";
import {getQuotePrivateResidential} from "../utils/getquotes/getQuotePrivateResidential";
import {setOrderPrivateResidential} from "../utils/setOrders/setOrderPrivateResidential";
import {residentialInsuranceFormData} from "../utils/initialvalues/residentialInsuranceFormData";

const OFFER_URL = "ResidentialBuildingInsurance";
const labelsInsurance = labels.ResidentialBuildingInsurance;

const ResidentialInsurancePage = (props) => {

	const CalculationFormRender = (formik) => {
		const {values, errors, touched} = formik;

		return (
				<>
					<DateInput item={"beginn"} label={labelsInsurance.beginn}/>
					<DateInput item={"geburtsdatum_VN"} label={labels.COMMON.KUNDENDATEN.geburtsdatum_VN}/>
					<hr style={{backgroundColor: '#fff'}}/>
					<h3>Versicherte Gefahren</h3>
					<RadioGroupInput item={"feuerschutz"} label={labelsInsurance.feuerschutz}
										  options={JaNeinSwitcher} muiJaNeinSwitcher disabled/>
					{values.feuerschutz && <SelectInput item={"anzahl_vorschaeden_feuer"}
																	label={labelsInsurance.anzahl_vorschaeden_feuer}
																	options={vorschaedenOptions_Wohngebaeude}/>}
					<RadioGroupInput item={"leitungswasser"} muiJaNeinSwitcher label={labelsInsurance.leitungswasser}
										  options={JaNeinSwitcher}/>
					{values.leitungswasser && <SelectInput item={"anzahl_vorschaeden_leitungswasser"}
																		label={labelsInsurance.anzahl_vorschaeden_leitungswasser}
																		options={vorschaedenOptions_Wohngebaeude}/>}
					<RadioGroupInput item={"sturm_oder_hagel"} label={labelsInsurance.sturm_oder_hagel} muiJaNeinSwitcher
										  options={JaNeinSwitcher}/>
					{values.sturm_oder_hagel && <SelectInput item={"anzahl_vorschaeden_sturm_hagel"}
																		  label={labelsInsurance.anzahl_vorschaeden_sturm_hagel}
																		  options={vorschaedenOptions_Wohngebaeude}
																		  extraLabel={"(in den letzten 5 Jahren)"}/>}
					<RadioGroupInput item={"elementar"} label={labelsInsurance.elementar} options={JaNeinSwitcher}
										  muiJaNeinSwitcher hideError/>
					{values.elementar && <SelectInput item={"anzahl_vorschaeden_elementar"}
																 label={labelsInsurance.anzahl_vorschaeden_elementar}
																 options={elementarSchaedenOptions_Wohngebaeude}
																 extraLabel={"(in den letzten 10 Jahren)"}/>}
					{(touched.leitungswasser || touched.sturm_oder_hagel || touched.elementar) &&
					 <>
						 <Row>
							 <Col/>
							 <Col md={8}>
								 <small style={{color: 'rgb(220, 53, 69)'}}>{errors.elementar}</small>
							 </Col>
						 </Row>
						 <br/>
					 </>
					}
					<hr style={{backgroundColor: '#fff'}}/>
					<h3>Versichertes Gebäude</h3>
					<SelectInput item={"gebaeudetyp"} label={labelsInsurance.gebaeudetyp}
									 options={gebaeudeartOptions_Wohngebaeude}/>
					<TextInput item={"anzahl_stockwerke"} label={labelsInsurance.anzahl_stockwerke}/>
					<SelectInput item={"bauartklasse"} label={labelsInsurance.bauartklasse}
									 options={bauartklasseOptions_Wohngebaeude}
									 infoButton={'<b>BAK I</b><br/>Massive Bauweise, zum Beispiel aus Mauerwerk oder Beton.<br/><br/>'
													 + '<b>BAK II</b><br/>Stahl- oder Holzfachwerkonstruktion, Füllung aus nicht brennbarem Material<br/><br/>'
													 + '<b>BAK III</b><br/>Stahl- oder Holzfachwerkonstruktion, Füllung aus brennbarem Material (Lehm, Holz, etc.)'}/>
					<SelectInput item={"gebaeudenutzung"} label={labelsInsurance.gebaeudenutzung}
									 options={gebaeudenutzungOptions_Wohngebaeude}/>
					{values.gebaeudenutzung === 'auch_gewerblich' &&
					 <TextInput item={"prozentualer_anteil_gewerblich_genutzt"}
									label={labelsInsurance.prozentualer_anteil_gewerblich_genutzt}/>
					}
					<TextInput item={"baujahr"} label={labelsInsurance.baujahr}/>
					<TextInput item={"kernsanierungsjahr"} label={labelsInsurance.kernsanierungsjahr}/>
					<SelectInput item={"nutzungsart"} label={labelsInsurance.nutzungsart}
									 options={nutzungsartOptions_Wohngebaeude}/>
					<RadioGroupInput item={"gebaeude_staendig_bewohnt"} label={labelsInsurance.gebaeude_staendig_bewohnt}
										  options={JaNeinRadioOptions}/>
					<RadioGroupInput item={"denkmalschutz"} label={labelsInsurance.denkmalschutz}
										  options={JaNeinRadioOptions}/>
					<TextInput item={"wohnflaeche_einschliesslich_gewerbeflaeche"}
								  label={labelsInsurance.wohnflaeche_einschliesslich_gewerbeflaeche}
								  extraText={"(Nebengebäude bis 40 qm sind mitversichert)"} number/>
					<RadioGroupInput item={"unterkellerung"} label={labelsInsurance.unterkellerung} options={JaNeinSwitcher}
										  muiJaNeinSwitcher/>
					{values.unterkellerung && <TextInput item={"wohnflaeche_keller"}
																	 label={labelsInsurance.wohnflaeche_keller} number/>}
					<SelectInput item={"dachausbau"} label={labelsInsurance.dachausbau}
									 options={dachausbauOptions_Wohngebaeude}/>
					<RadioGroupInput item={"fussboden_oder_dachheizung"} label={labelsInsurance.fussboden_oder_dachheizung}
										  options={JaNeinSwitcher} muiJaNeinSwitcher/>
					<RadioGroupInput item={"waermepumpe_oder_solaranlage"}
										  label={labelsInsurance.waermepumpe_oder_solaranlage} options={JaNeinSwitcher}
										  muiJaNeinSwitcher/>
					<RadioGroupInput item={"garage_oder_carport"} label={labelsInsurance.garage_oder_carport}
										  options={JaNeinSwitcher} muiJaNeinSwitcher/>
					{values.garage_oder_carport && <TextInput item={"anzahl_garage_oder_carport"}
																			label={labelsInsurance.anzahl_garage_oder_carport} number/>}
					<Row>
						<Col xs={12} md={4}>
							Bausteine
						</Col>
						<Col xs={12} md={8}>
							<Row>
								<Col xs={12} lg={6}>
									<RadioGroupInput switcher item={"glas"} label={labelsInsurance.glas}
														  infoButton={'Wenn in Ihrem Wohngebäude viel Glas verbaut ist, kann die Gefahr eines Glasschadens hoch sein. Sichern Sie sich ab gegen Bruchschäden an Fenstern, Türen und sonstigen fest montierten Glaselementen. Dieser Schutz zählt nicht für Gewächshäuser.'}
														  checkbox/>
									<RadioGroupInput switcher item={"unbenannte_gefahren"}
														  label={labelsInsurance.unbenannte_gefahren}
														  infoButton={'Für alle Fälle: Sichern Sie Ihr Wohngebäude gegen unvorhergesehene Beschädigungen und unbenannte Gefahren jeglicher Art ab – dazu gehören z.B. Schäden durch Wind, Nutztiere oder Flüssigkeiten. Hier gilt eine <strong>feste Selbstbeteiligungvon 150 €</strong>.'}
														  checkbox/>
									<RadioGroupInput switcher item={"haustechnik"} label={labelsInsurance.haustechnik}
														  infoButton={'Schützen Sie Ihre Smart-Home-Geräte, Alarmanlagen und elektrische Rollläden gegen Schäden durch Flüssigkeiten, Kurzschlüsse, Bedienungsfehler und Vandalismus. Hier gilt eine <strong>feste Selbstbeteiligungvon 150 €</strong>.'}
														  checkbox/>
								</Col>
								<Col xs={12} lg={6} className={'rightCol'}>
									<RadioGroupInput switcher item={"ableitungsrohre"} label={labelsInsurance.ableitungsrohre}
														  infoButton={'Schützen Sie sich gegen Frostschäden und sonstige Schäden an Ableitungsrohren – egal, ob sie sich auf oder außerhalb des Grundstücks befinden.'}
														  checkbox/>
									<RadioGroupInput switcher item={"photovoltaikanlage"}
														  label={labelsInsurance.photovoltaikanlage}
														  infoButton={'Schützen Sie Ihre Photovoltaikanlage gegen Brand-, Blitzschlag-, Sturm- und Hagel-Schäden. Darüber hinaus auch bei technischen Mängeln oder einem Ertragsausfall. Hier gilt eine <strong>feste Selbstbeteiligungvon 150 €</strong>.'}
														  checkbox/>
									<RadioGroupInput switcher item={"assistance"} label={labelsInsurance.assistance}
														  infoButton={'Mit dem Schutzbrief erhalten Sie einen Rund-um-die-Uhr-Notfallschutz – z.B. übernehmen wir den Schlüsseldienst, Rohrreinigungs-Service, Notfall-Heizungen, Schädlingsbekämpfung sowie die Entfernung von Wespen-, Hornissen und Bienennestern.'}
														  checkbox/>
								</Col>
							</Row>
						</Col>
					</Row>
					<hr style={{backgroundColor: '#fff'}}/>
					<h3>Adressdaten & Zahlweise</h3>
					<TextInput item={"versichertes_objekt.strasse_vo"} label={labelsInsurance.strasse_vo}/>
					<TextInput item={"versichertes_objekt.hausnr_vo"} label={labelsInsurance.hausnr_vo}/>
					<TextInput item={"versichertes_objekt.postleitzahl_vo"} label={labelsInsurance.postleitzahl_vo}/>
					<TextInput item={"versichertes_objekt.ort_vo"} label={labelsInsurance.ort_vo}/>

					<SelectInput item={"selbstbehalt"} label={labelsInsurance.selbstbehalt}
									 options={selbstbehaltOptions_Wohngebaeude}/>
					<SelectInput item={"zahlungsweise"} label={labelsInsurance.zahlungsweise} options={zahlweiseOptions}/>
				</>
		)
	}

	return (<>
				<FormWrapper
						validationSchemaCalculation={validationSchemaResidential}
						OFFER_URL={OFFER_URL}
						CalculationForm={CalculationFormRender}
						calcList={['L', 'XL', 'XXL']}
						getQuoteHandler={getQuotePrivateResidential}
						setOrder={setOrderPrivateResidential}
						initValuesForm={residentialInsuranceFormData}
						tariffName={insuranceTypes.RESIDENTIAL}/>

			</>

	)
}

export default ResidentialInsurancePage;
