import React, {createContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {numberToInsuranceType} from "../../_params/variables";
import {INSURANCES_ROUTES} from "../utils/formHelper";

export const QueryParamsContext = createContext();

const vmNrInputRegex = new RegExp("\\d{4}-\\d{4}-\\d");

export const QueryParamsProvider = ({children}) => {
    const history = useHistory();
    const location = useLocation();

    const [, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [selectedTarifs, setSelectedTarifs] = useState([]);
    const [loginError, setLoginError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!currentUser) {
            const loggedIn = sessionStorage.getItem("vermittlernummer");
            const vmNr = new URLSearchParams(window.location.search).get("nutzer");
            const tarifs = new URLSearchParams(window.location.search).get("tarif");
            const externeID = new URLSearchParams(window.location.search).get("externeIdent");

            sessionStorage.setItem("externeIdent", externeID);
            if (loggedIn) {
                filterTarifs(tarifs)
                login(loggedIn)
            } else {
                if (vmNr) {
                    filterTarifs(tarifs)
                    login(vmNr)
                } else {
                    filterTarifs(tarifs)
                    history.push("/login")
                }
            }
        }
    }, []);

    useEffect(() => {
        const pathname = location.pathname.substring(1)

        if (Object.values(INSURANCES_ROUTES).includes(pathname)) {
            sessionStorage.setItem("currentTariff", pathname)
        } else {
            const currentTarif = INSURANCES_ROUTES[selectedTarifs[0]] ?? INSURANCES_ROUTES.PRIVATE_LIABILITY
            sessionStorage.setItem("currentTariff", currentTarif)
        }

    }, [selectedTarifs])

    function filterTarifs(searchParamsTarifs) {
        if (searchParamsTarifs) {
            let tarifNames = searchParamsTarifs.split("");
            tarifNames = tarifNames.map(nr => {
                return numberToInsuranceType(nr)
            }).filter(el => el !== undefined)
            setSelectedTarifs(tarifNames)
        }
    }

    function login(loginInput) {
        setLoading(true);
        setLoginError(null);
        setTimeout(() => {
            if (vmNrInputRegex.test(loginInput))  {
                setCurrentUser(loginInput);
                setIsAuthenticated(true);
                setLoading(false);
                sessionStorage.setItem("vermittlernummer", loginInput);
                history.push(`/${sessionStorage.getItem("currentTariff")}`)
            }
            else {
                setCurrentUser(null);
                setIsAuthenticated(false);
                setLoginError("Falsche Vermittlernummer!");
                history.push("/login");
                setLoading(false);
            }
        }, 1000);
    }

    function logout() {
        setLoading(true);
        setTimeout(() => {
            setCurrentUser(null);
            setIsAuthenticated(false);
            setLoading(false);
            setSelectedTarifs([]);
            sessionStorage.clear();
            window.location.href="/login"
        }, 1000);
    }

    return (
        <QueryParamsContext.Provider value={{
            currentUser, selectedTarifs,
            login, logout, loginError,
            loading, setLoading,
        }}>
            {children}
        </QueryParamsContext.Provider>
    )
}
