import {CommonFormData} from "./commonFormData";

export const householdFormdata = {
  "beginn": null,
  "versicherungsdauer": "1",
  "tarif": "",
  "wohnflaeche": "",
  "versicherungssumme": "",
  "berufsgruppe": "freie_wirtschaft",
  "vorschaeden": "",
  "selbstbehalt": "",
  "zahlungsweise": "",

  "fahrrad": false,
  "fahrrad_versicherungssumme": null,
  "glas": false,
  "elementar": false,
  "vorschaeden_elementar": null,
  "gebaeudeart": null,

  "versichertes_objekt": {
    "strasse_vo": "",
    "hausnr_vo": "",
    "postleitzahl_vo": "",
    "ort_vo": "",
  },

  ...CommonFormData
}
