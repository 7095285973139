import {dateConverter, translateHundeliste, translateZahlungsweise} from "../translaterForAPI";

export const getQuotePetLiability = (values, deckung) => {

	return {
		"salesPartnerNumber": sessionStorage.getItem("vermittlernummer"),
		"externalIdent": sessionStorage.getItem("externeIdent"),
		"policyholder": {
			"birthday": dateConverter(values.geburtsdatum_VN),
			"civilService": (values.berufsgruppe === 'oeffentlicher_dienst'),
			"address": {
				"street": values.strasse,
				"streetNumber": values.hausnummer,
				"postalCode": values.postleitzahl,
				"city": values.ort,
				"countryCode": "D"
			},
			"phoneNumber": values.telefon?.replace(/\s/g, ''),
			"emailAddress": values.email
		},
		"insuranceData": {
			"countOfPreviousDamages": values.vorschaeden,
			"paymentInterval": translateZahlungsweise(values.zahlungsweise),
			"insuranceBegin": dateConverter(values.beginn),
			"contractTermInYears": parseInt(values.versicherungsdauer),
			"tariffSelection": deckung,
			"deductible": parseInt(values.selbstbehalt),
			"dogs": translateHundeliste(values.hundeliste)
		}
	};
};
