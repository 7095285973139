import React, {useContext} from "react";
import {Spinner} from "react-bootstrap";
import {QueryParamsContext} from "../contexts/QueryParamsContext";
import UploadDataBox from "./UploadDataBox";

const TopBar = (props) => {
	const {currentUser, logout, loading} = useContext(QueryParamsContext);

	return (
			<div style={{width: '100%',height:'40px', lineHeight: '40px'}}>
				<img src="https://www.adam-riese.de/assets/img/ar_logo.svg" alt="Adam Riese Logo" style={{width: '120px', float:'left'}}/>
				<div className="loginData">

				<UploadDataBox
						offer={props.offer}
						disabled={props.disabled}
				/>
				<span className="loginDataNr">
					{` VM-Nr.: ${currentUser} `}
					{loading && <Spinner animation="border" size="sm"/>}
				</span>
				<span className="cursor-link"
						style={{fontWeight: "bold", marginLeft: '10px'}}
						onClick={logout}
						title={`Abmelden`}
				>Abmelden</span>
			</div>
			</div>

	)
}

export default TopBar;
