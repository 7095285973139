import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {berufsgruppeRadioOptions, selbstbehaltOptions, zahlweiseOptions} from "../optionsSelection";

const errors_PetLiabilityInsurance = errors.PetLiabilityInsurance;

const today = new Date();
let minBirthdate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

export default Yup.object().shape({
	beginn: Yup.date().min(new Date(), errors_PetLiabilityInsurance.beginn).required(errors_PetLiabilityInsurance.beginn).nullable(),
	geburtsdatum_VN: Yup.date()
			.max(minBirthdate, errors_PetLiabilityInsurance.geburtsdatum_min18)
			.required(errors_PetLiabilityInsurance.geburtsdatum).nullable(),
	hundeliste: Yup.array().typeError(errors_PetLiabilityInsurance.hundeliste)
			.required(errors_PetLiabilityInsurance.hundeliste)
			.nullable()
			.min(1, errors_PetLiabilityInsurance.hundeliste),
	berufsgruppe: Yup.string().required(errors_PetLiabilityInsurance.berufsgruppe).oneOf(berufsgruppeRadioOptions.map(item => {return item.value}), errors_PetLiabilityInsurance.berufsgruppe).nullable(),
	vorschaeden: Yup.string().matches(/^[0-2]$/, errors_PetLiabilityInsurance.vorschaeden)
			.required(errors_PetLiabilityInsurance.vorschaeden).nullable(),
	selbstbehalt: Yup.string().required(errors_PetLiabilityInsurance.selbstbehalt).oneOf(selbstbehaltOptions.map(item => {return item.value.toString()}), errors_PetLiabilityInsurance.selbstbehalt).nullable(),
	zahlungsweise: Yup.string().required(errors_PetLiabilityInsurance.zahlungsweise).oneOf(zahlweiseOptions.map(item => {return item.value}), errors_PetLiabilityInsurance.zahlungsweise).nullable(),

});
