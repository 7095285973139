import React from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import BerechnenResult from "../BerechnenResult";

const CalculationWrapper_RSD = (props) => {
	const {values, resultGUT, resultBESSER, resultRIESIG, calcLoading, goNextStep, config} = props;

	const allResultsHaveLoaded = resultGUT && resultBESSER && resultRIESIG;

	return (
			<div>
				<Row className={'calculationWrapperRow'}>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "L" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>Gut</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper'}>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Nachlass für Neubauten & Sanierungen bis zu 46 %</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Grobe Fahrlässigkeit bis <b>10.000 €</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Aufräum- und Abbruchkosten bis <b>25.000 €</b></li>
							</ul>
							<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
								<li><span className={'ar-icon ar-icon-cross'}/>Graffitischäden</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Beseitigung von Einbruchschäden</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Mehrkosten für Modernisierungen</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Mehrkosten für Umbaumaßnahmen</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Diebstahlkosten</li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.L ?? <BerechnenResult error={resultGUT.calculationError}
																		  result={resultGUT.calculationResult.grossPremium}
																		  messages={resultGUT.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultGUT || resultGUT.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('L', resultGUT.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XL" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>Besser</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper'}>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Nachlass für Neubauten & Sanierungen bis zu 46 %</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Grobe Fahrlässigkeit <b>(unbegrenzt)</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Aufräum- und Abbruchkosten <b>(unbegrenzt)</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Graffitischäden bis <b>2.500 €</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Beseitigung von Einbruchschäden bis <b>5.000 €</b></li>
							</ul>
							<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
								<li><span className={'ar-icon ar-icon-cross'}/>Mehrkosten für Modernisierungen</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Mehrkosten für Umbaumaßnahmen</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Diebstahlkosten</li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.XL ?? <BerechnenResult error={resultBESSER.calculationError}
																		  result={resultBESSER.calculationResult.grossPremium}
																		  messages={resultBESSER.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultBESSER || resultBESSER.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('XL', resultBESSER.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XXL" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>Riesig</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper flexGrow'}>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Nachlass für Neubauten & Sanierungen bis zu 46 %</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Grobe Fahrlässigkeit <b>(unbegrenzt)</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Aufräum- und Abbruchkosten <b>(unbegrenzt)</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Graffitischäden <b>(unbegrenzt)</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Beseitigung von Einbruchschäden bis <b>5.000 €</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Mehrkosten für Modernisierungen bis <b>10.000 €</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Mehrkosten für Umbaumaßnahmen bis <b>10.000 €</b></li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Diebstahlkosten bis <b>5.000 €</b></li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.XXL ?? <BerechnenResult error={resultRIESIG.calculationError}
																		  result={resultRIESIG.calculationResult.grossPremium}
																		  messages={resultRIESIG.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultRIESIG || resultRIESIG.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('XXL', resultRIESIG.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
				</Row>
			</div>
	)
}

export default CalculationWrapper_RSD;
