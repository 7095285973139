export const translateZahlungsweise = (zahlungsweise) => {
    switch (zahlungsweise) {
        case "jaehrlich":
            return "YEARLY"

        case "halbjaehrlich":
            return "HALF_YEARLY"

        case "vierteljaehrlich":
            return "QUARTERLY"

        case "monatlich":
            return "MONTHLY"

        default:
            break;
    }
};

export const translateFamilienstand = (familienstand) => {
    switch (familienstand) {
        case "single":
            return "SINGLE"

        case "paar":
            return "COUPLES"

        case "alleinerziehend":
            return "SINGLE_PARENT"

        case "familie":
            return "FAMILY"
    }
}

export const translateBerufsgruppe = (berufsgruppe) => {
    switch (berufsgruppe) {
        case "arbeitnehmer":
            return "WORKER"

        case "oeffentlicher_dienst":
            return "CIVIL_SERVICE"

        case "rentner":
            return "PENSIONER"

        case "student":
            return "STUDENT"

        case "ohne_berufliche_taetigkeit":
            return "WITHOUT_PROFESSIONAL_ACTIVITY"

        case "selbststaendig":
            return "SELF_EMPLOYED"
    }
}

export const translateHundeliste = (hundeliste) => {
    return hundeliste.map((dog) => {
        return {
            nameOfDog: dog.hundename,
            dogBreeds: dog.hunderasse
        }
    })
}

export const translateDachausbau = (dachausbau) => {
    switch (dachausbau) {
        case "ausgebaut":
            return "EXPANDED"

        case "nicht_ausgebaut":
            return "NOT_EXPANDED"

        case "flachdach":
            return "FLAT_ROOF"
    }
}

export const translateNutzungsart = (nutzungsart) => {
    switch (nutzungsart) {
        case "eigennutzung":
            return "BY_POLICYHOLDER"

        case "vermietet":
            return "RENTED"
    }
}

export const translateGebaeudenutzung = (gebaeudenutzung) => {
    switch (gebaeudenutzung) {
        case "ausschliesslich_privat":
            return "ONLY_PRIVATE"

        case "auch_gewerblich":
            return "ALSO_COMMERCIALLY"

        case "ferienhaus":
            return "HOLIDAY_HOME"
    }
}

export const translateGebaeudeart_Wohngebaeudeversicherung = (gebaeudeart) => {
    switch (gebaeudeart) {
        case "einfamilienhaus":
            return "DETACHED_HOUSE"

        case "zweifamilienhaus":
            return "TWO_FAMILY_HOUSE"

        case "mehrfamilienhaus":
            return "APARTMENT_BUILDING"

        case "doppelhaushälfte":
            return "SEMI_DETACHED_HOUSE"

        case "doppelhaus":
            return "TWIN_HOUSE"

        case "reihenhaus":
            return "TOWN_HOUSE"
    }
}

export const translateGebaeudeart_Hausratversicherung = (gebaeudeart) => {
    switch (gebaeudeart) {
        case "einfamilienhaus":
            return "SINGEL_FAMILIY_HOUSE_WITHOUT_SEPARATE_APARTMENT"

        case "zweifamilienhaus":
            return "SINGEL_FAMILIY_HOUSE_WITHOUT_SEPARATE_APARTMENT"

        case "mehrfamilienhaus":
            return "APARTMENT_BUILDING"

        case "reihenhaus":
            return "SINGEL_FAMILIY_HOUSE_WITHOUT_SEPARATE_APARTMENT"

    }
}

export const dateConverter = (date) => {
    if (date) {
        if (typeof date === "string") {
            date = new Date(date)
        }
        return [date.getFullYear(),
                date.getMonth() + 1,
                date.getDate()]
    }

    return null
}