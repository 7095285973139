import React from "react";
import {Col, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {Datepicker} from "react-formik-ui";
import MaskedTextInput from "react-text-mask";
import {useField} from "formik";
import {de} from "date-fns/locale";

const dateInputMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

const DateInput = (props) => {

	const {item, label, disabled, infoButton} = props;

	const [field, meta, helper] = useField(item);

	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	let leastBirthDate = new Date();
	if (!item.includes("kind") && !item.includes("kaufdatum")) {
		leastBirthDate.setFullYear(leastBirthDate.getFullYear() - (18));
	}

	return (
			<React.Fragment>

				<Form.Group as={Row} md="2" controlId={"formField-" + item}>
					<Form.Label column md={4}>
						{label}
						{infoButton && <OverlayTrigger
								placement='right'
								overlay={
									<Tooltip id={'tooltip-gibsgeld'}>
										<div dangerouslySetInnerHTML={{__html: infoButton}}></div>
									</Tooltip>
								}
						>
							<span className='ar-btn ar-icon-info'/>
						</OverlayTrigger>}
					</Form.Label>
					<Col md={8}>
						<Datepicker
							disabled={disabled}
							locale={de}
							name={item}
							placeholder={'TT.MM.JJJJ'}
							dateFormat="dd.MM.yyyy"
							onChange={field.onChange}
							onBlur={() => helper.setTouched(true)}
							className="form-control"
							minDate={(item === 'beginn') ? tomorrow : false}
							maxDate={(item.includes('geburtsdatum') || item.includes('kaufdatum')) ? leastBirthDate : false }
							todayButton={'Heute'}
							customInput={<MaskedTextInput
									mask={dateInputMask}
									style={(meta.error && meta.touched)?{borderBottomColor:"#dc3545"}:{}}/>}
						/>
					</Col>
				</Form.Group>
			</React.Fragment>

	)
};

export default DateInput;
