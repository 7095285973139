import {Button, Col, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import React, {useMemo} from "react";
import Row from "react-bootstrap/Row";
import RadioGroupInput from "../formitems/RadioGroupInput";
import BerechnenResult from "../BerechnenResult";
import {tariffOptions} from "../../utils/optionsSelection";

const CalculationWrapperExtensions_LC = (props) => {
	const {values, resultRIESIG, resultBESSER, resultGUT, goNextStep, calcLoading} = props

	const bausteinePrivat = {
		L: <Col sm={12} md={4} className={`calculationWrapperCell`}>
			<div className={'calculationWrapperBorder'}>
				<span className={'calculationWrapperHead'}>Privat Gut</span>
				<div className={'calculationWrapperBody'}>
					<ul className={'calculationWrapper'}>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Beratungs-Rechtsschutz im Familien-,
							Lebenspartnerschafts- und Erbrecht je Versicherungsfall <b> bis 250 €</b></li>
					</ul>
					<ul className={'calculationWrapper calculationWrapperDisabled'}>
						<li><span className={'ar-icon ar-icon-cross'}/>Beratungs-Rechtsschutz bei privaten
							Urheberrechtsverstößen im Internet je Kalenderjahr <b> bis 500 €</b></li>
						<li><span className={'ar-icon ar-icon-cross'}/>Aktiver Cyber-Strafrechtsschutz</li>
					</ul>
				</div>
			</div>
		</Col>,
		XL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
			<div className={'calculationWrapperBorder'}>
				<span className={'calculationWrapperHead'}>Privat Besser</span>
				<div className={'calculationWrapperBody'}>
					<ul className={'calculationWrapper'}>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Beratungs-Rechtsschutz im Familien-,
							Lebenspartnerschafts- und Erbrecht je Versicherungsfall <b> bis 500 €</b></li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Beratungs-Rechtsschutz bei privaten
							Urheberrechtsverstößen im Internet je Kalenderjahr <b> bis 500 €</b></li>
					</ul>
					<ul className={'calculationWrapper calculationWrapperDisabled'}>
						<li><span className={'ar-icon ar-icon-cross'}/>Aktiver Cyber-Strafrechtsschutz</li>
					</ul>
				</div>
			</div>
		</Col>,
		XXL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
			<div className={'calculationWrapperBorder'}>
				<span className={'calculationWrapperHead'}>Privat Riesig</span>
				<div className={'calculationWrapperBody'}>
					<ul className={'calculationWrapper'}>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Beratungs-Rechtsschutz im Familien-,
							Lebenspartnerschafts- und Erbrecht je Versicherungsfall <b> bis 500 €</b></li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Beratungs-Rechtsschutz bei privaten
							Urheberrechtsverstößen im Internet je Kalenderjahr <b> bis 1.000 €</b></li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Aktiver Cyber-Strafrechtsschutz</li>
					</ul>
				</div>
			</div>
		</Col>
	}

	const bausteineVerkehr = {
		L: <Col sm={12} md={4} className={`calculationWrapperCell`}>
			<div className={'calculationWrapperBorder'}>
				<span className={'calculationWrapperHead'}>Verkehr Gut</span>
				<div className={'calculationWrapperBody'}>
					<ul className={'calculationWrapper'}>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Vertragsrecht (z.B. Kaufvertrag, Reperatur eines PKW)</li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Verkehrsrechtliche Ordnungswidrigkeiten <b> sofern ein Fahrverbot droht</b></li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Sozialbehörden, Sozial-RS <b> vor Gerichten</b></li>
					</ul>
				</div>
			</div>
		</Col>,
		XL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
			<div className={'calculationWrapperBorder'}>
				<span className={'calculationWrapperHead'}>Verkehr Besser</span>
				<div className={'calculationWrapperBody'}>
					<ul className={'calculationWrapper'}>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Vertragsrecht (z.B. Kaufvertrag, Reperatur eines PKW)</li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Verkehrsrechtliche Ordnungswidrigkeiten <b> sofern Punkte drohen</b></li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Sozialbehörden, Sozial-RS <b> ab dem vorgeschalteten Widerspruchsverfahren</b></li>
					</ul>
				</div>
			</div>
		</Col>,
		XXL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
			<div className={'calculationWrapperBorder'}>
				<span className={'calculationWrapperHead'}>Verkehr Riesig</span>
				<div className={'calculationWrapperBody'}>
					<ul className={'calculationWrapper'}>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Vertragsrecht (z.B. Kaufvertrag, Reperatur eines PKW)</li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Verkehrsrechtliche Ordnungswidrigkeiten</li>
						<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Sozialbehörden, Sozial-RS <b> vor Gerichten</b></li>
					</ul>
				</div>
			</div>
		</Col>,
	}

	const bausteineBeruf = {
		L: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Beruf Gut</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit dem Arbeitgeber: Abmahnung/Kündigung</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Abfindungsanspruch</li>
						</ul>
						<ul className={'calculationWrapper calculationWrapperDisabled'}>
							<li><span className={'ar-icon ar-icon-cross'}/>Manager-Rechtsschutz ab dem gerichtlichen Verfahren, bis Streitwert <b>50.000 €</b></li>
						</ul>
					</div>
				</div>
			</Col>,
		XL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Beruf Besser</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit dem Arbeitgeber: Abmahnung/Kündigung</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Abfindungsanspruch</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Manager-Rechtsschutz ab dem gerichtlichen Verfahren, bis Streitwert <b>50.000 €</b></li>
						</ul>
					</div>
				</div>
			</Col>,
		XXL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Beruf Riesig</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit dem Arbeitgeber: Abmahnung/Kündigung</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Abfindungsanspruch</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Manager-Rechtsschutz ab dem gerichtlichen Verfahren, bis Streitwert <b>50.000 €</b></li>
						</ul>
					</div>
				</div>
			</Col>
	}

	const bausteineVermietung = {
		L: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Vermietung Gut</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Mietern</li>
						</ul>
						<ul className={'calculationWrapper calculationWrapperDisabled'}>
							<li><span className={'ar-icon ar-icon-cross'}/>Bonitätsprüfung potentieller Mieter, 3 Prüfungen im Jahr pro versichertem Objekt</li>
							<li><span className={'ar-icon ar-icon-cross'}/>Forderungsmanagement</li>
						</ul>
					</div>
				</div>
			</Col>,
		XL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Vermietung Besser</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Mietern</li>
						</ul>
						<ul className={'calculationWrapper calculationWrapperDisabled'}>
							<li><span className={'ar-icon ar-icon-cross'}/>Bonitätsprüfung potentieller Mieter, 3 Prüfungen im Jahr pro versichertem Objekt</li>
							<li><span className={'ar-icon ar-icon-cross'}/>Forderungsmanagement</li>
						</ul>
					</div>
				</div>
			</Col>,
		XXL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Vermietung Riesig</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Mietern</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Bonitätsprüfung potentieller Mieter, 3 Prüfungen im Jahr pro versichertem Objekt</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Forderungsmanagement</li>
						</ul>
					</div>
				</div>
			</Col>
	}

	const bausteineWohnen = {
		L: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Wohnen Gut</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit dem Vermieter</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Finanzbehörden, Steuer-RS <b>vor Gericht</b></li>
						</ul>
						<ul className={'calculationWrapper calculationWrapperDisabled'}>
							<li><span className={'ar-icon ar-icon-cross'}/>Erschließungs- und Anliegerabgaben ab dem Gerichtsverfahren</li>
						</ul>
					</div>
				</div>
			</Col>,
		XL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Wohnen Besser</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit dem Vermieter</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Finanzbehörden, Steuer-RS <b>ab den vorgeschalteten Einspruchverfahren</b></li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Erschließungs- und Anliegerabgaben <b>ab dem Gerichtsverfahren</b></li>
						</ul>
					</div>
				</div>
			</Col>,
		XXL: <Col sm={12} md={4} className={`calculationWrapperCell`}>
				<div className={'calculationWrapperBorder'}>
					<span className={'calculationWrapperHead'}>Wohnen Riesig</span>
					<div className={'calculationWrapperBody'}>
						<ul className={'calculationWrapper'}>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit dem Vermieter</li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Streitigkeiten mit Finanzbehörden, Steuer-RS <b>ab den vorgeschalteten Einspruchverfahren</b></li>
							<li><span className={'ar-icon ar-icon-checkmark'}/>Erschließungs- und Anliegerabgaben <b>ab dem Gerichtsverfahren bis 25.000 € je Versicherungsfall</b></li>
						</ul>
					</div>
				</div>
			</Col>
	}

	const result = useMemo(() => {
		switch (values.tarif) {
			case "L": return resultGUT
			case "XL": return resultBESSER
			case "XXL": return resultRIESIG
		}
	}, [values.tarif, resultGUT, resultBESSER, resultRIESIG])

	return (
			<div>
				{values.produkt && <>
					<hr style={{backgroundColor: '#fff', marginTop: '30px'}}/>
					<h3 style={{textAlign: 'center', marginBottom: '30px'}}>Gewählte Bausteine:</h3>
					<Row className={'calculationWrapperRow'}>
						{values.produkt.includes("P") && bausteinePrivat[values.tarif]}
						{values.produkt.includes("V") && bausteineVerkehr[values.tarif]}
						{values.produkt.includes("B") && bausteineBeruf[values.tarif]}
						{values.produkt.includes("Vermiet") && bausteineVermietung[values.tarif]}
						{values.produkt.includes("Wohn") && bausteineWohnen[values.tarif]}
					</Row>
				</>}
				<hr style={{backgroundColor: '#fff', marginTop: '30px'}}/>
				<h3 style={{textAlign: 'center', marginBottom: '30px'}}>Weitere Zusatzbausteine:</h3>
				<div className={'zusaetzlicheBausteine'}>
					<Row>
						<Col sm={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
							{
								values.tarif === "XXL" ?
										<div style={{fontSize: "14px", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", backgroundColor: "#F0FAFC", width: "100%", top: "-21px", left: "-5px", height: "178px"}}>
											<span>Im Tarif</span>
											<span>Riesig</span>
											<span>inklusive</span>
										</div>
										: <RadioGroupInput switcher item={'zusatzbausteinErweiterterStraftrechtsschutz'} overwriteDefaultStyle={{border: "none", marginRight: "-50px", marginTop: "25px"}}/>
							}
						</Col>
						<Col sm={10}>
							<h5><b>Erweiterter Strafrechtsschutz</b></h5>
							Der erweiterte Strafrechtsschutz bietet Ihrer Kundschaft eine Absicherung, die über den allgemeinen Strafrechtsschutz hinausgeht.
							Ihre Kunden können sich zusätzlich mit dem erweiterten Strafrechtsschutz absichern,
							falls ihnen ein fahrlässiges oder vorsätzliches Vergehen vorgeworfen wird und sie sich verteidigen müssen.
							Verbrechen sind generell nicht abgedeckt.
						</Col>
						<Col sm={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
						</Col>
					</Row>
				</div>
				<div className={'zusaetzlicheBausteine'}>
					<Row>
						<Col sm={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
							<RadioGroupInput disabled={values.tarif !== "XXL"} switcher item={"zusatzbausteinPflegeberatung"} overwriteDefaultStyle={{border: "none", marginRight: "-50px", marginTop: "25px"}}/>
						</Col>
						<Col sm={10}>
							<h5><b>Pflegeberatung</b></h5>
							Der Zusatzbaustein Pflegeberatung bietet Ihrer Kundschaft juristische Hilfe bei der Beantragung von Leistungen aus der gesetzlichen Pflegeversicherung zur Erteilung eines Pflegegrads an.
							Außerdem erhalten Ihre Kunden rechtliche Unterstützung, wenn sie Zuschüsse zur Finanzierung von wohnumfeldverbessernden Maßnahmen nach dem Sozialgesetzbuch XI. beantragen möchten.
						</Col>
						<Col sm={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
							<OverlayTrigger
									overlay={
										<Tooltip id={'tooltip-gibsgeld'}>
											<div>
												Detailinformation zur Pflegeberatung: Wenn eine versicherte Person oder die Eltern einer versicherten Person pflegebedürftig werden und
												<ul>
													<li>bei der Beantragung von Leistungen aus der gesetzlichen Pflegeversicherung (Erteilung eines Pflegegrads) oder</li>
													<li>bei der Beantragung von Zuschüssen zur Finanzierung von wohnumfeldverbessernden Maßnahmen nach dem Sozialgesetzbuch XI</li>
												</ul>
												juristische Hilfe benötigen, vermitteln wir einen Rechtsanwalt und übernehmen dessen Kosten.
												Alle versicherten Personen sowie die Eltern aller mitversicherten Personen können diese Leistung jeweils einmal je Kalenderjahr in Anspruch nehmen.
												<br/>
												Der Zusatzbaustein Pflegeberatung ist nur im Tarif Riesig abschließbar.
											</div>
										</Tooltip>
									}
							>
								<span className='ar-btn ar-icon-info'/>
							</OverlayTrigger>
						</Col>
					</Row>
				</div>
				<div style={{margin: "30px", display: "flex", justifyContent: "center", alignItems: "center"}}>
					{calcLoading ?
						<Spinner animation="border" variant="info"/> :
						<>
							{
									result && <BerechnenResult error={result.calculationError}
																		result={result.calculationResult.grossPremium}
																		messages={result.calculationMessages}
																		zahlungsweise={values.zahlungsweise}
											 />
							}
							<Button
									style={{marginLeft: "20px"}}
									disabled={!result || result.calculationResult.grossPremium === 'NaN'}
									onClick={() => goNextStep(values.tarif, result.calculationResult)}
									className="btn btn-weiter btn-weiter-values.tarif"
							>
								Weiter mit Tarif {tariffOptions.find(el => el.value === values.tarif)?.label}
							</Button>
						</>
					}
				</div>
			</div>
	)
}

export default CalculationWrapperExtensions_LC
