import React from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import BerechnenResult from "../BerechnenResult";

const CalculationWrapper_PET = (props) => {
	const {values, resultBESSER, resultRIESIG, calcLoading, goNextStep, config} = props;

	const allResultsHaveLoaded = resultBESSER && resultRIESIG;

	return (
			<div>
				<Row className={'calculationWrapperRow'}>
					<Col sm={12} md={6} className={`calculationWrapperCell ${config && (config?.markBox === "XL" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>XL</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper'}>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Wenn aus Bellen Beißen wird</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Hüten durch Freunde
								</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Schäden an der Mietwohnung</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
							</ul>
							<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
								<li><span className={'ar-icon ar-icon-cross'}/>Tierpension-Aufenthalt im Notfall</li>
								<li><span className={'ar-icon ar-icon-cross'}/>{'Neupreis für Sachen < 24 Monate'}</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Nebenberufliche Tätigkeit bis 22.000 € Umsatz p.a.</li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.XL ?? <BerechnenResult error={resultBESSER.calculationError}
																		  result={resultBESSER.calculationResult.grossPremium}
																		  messages={resultBESSER.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultBESSER || resultBESSER.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('XL', resultBESSER.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
					<Col sm={12} md={6} className={`calculationWrapperCell ${config && (config?.markBox === "XXL" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>XXL</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper flexGrow'}>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Wenn aus Bellen Beißen wird</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Hüten durch Freunde
								</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Schäden an der Mietwohnung</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Tierpension-Aufenthalt im Notfall</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>{'Neupreis für Sachen < 24 Monate'}</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Nebenberufliche Tätigkeit bis 22.000 € Umsatz p.a.</li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.XXL ?? <BerechnenResult error={resultRIESIG.calculationError}
																		  result={resultRIESIG.calculationResult.grossPremium}
																		  messages={resultRIESIG.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultRIESIG || resultRIESIG.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('XXL', resultRIESIG.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
				</Row>
			</div>
	)
}

export default CalculationWrapper_PET;
