import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {
	berufsgruppeRadioOptions,
	gebaeudeartOptions_Hausrat,
	selbstbehaltOptions,
	zahlweiseOptions
} from "../optionsSelection";
import {versichertes_objektSchema} from "./validationSchemaLegalCost";

const errors_HouseholdInsurance = errors.HouseholdInsurance;
const errors_common = errors.COMMON;

const today = new Date();
let minBirthdate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

export default Yup.object().shape({
	beginn: Yup.date().min(new Date(), errors_HouseholdInsurance.beginn)
			.required(errors_HouseholdInsurance.beginn).nullable(),
	geburtsdatum_VN: Yup.date()
			.max(minBirthdate, errors_HouseholdInsurance.geburtsdatum_min18)
			.required(errors_HouseholdInsurance.geburtsdatum).nullable(),
	wohnflaeche: Yup.number().typeError(errors_common.typeErrorNumber)
			.min(0).required(errors_HouseholdInsurance.wohnflaeche).nullable(),
	versicherungssumme: Yup.number()
			.when("wohnflaeche", {
				is: value => value && value,
				then: schema => schema
						.min(0, errors_HouseholdInsurance.versicherungssumme)
						.required(errors_HouseholdInsurance.versicherungssumme)
						.typeError(errors_common.typeErrorNumber)
			}),
	berufsgruppe: Yup.string().required(errors_HouseholdInsurance.berufsgruppe)
			.oneOf(berufsgruppeRadioOptions.map(item => {
				return item.value
			}), errors_HouseholdInsurance.berufsgruppe).nullable(),
	//	postleitzahl: Yup.string().matches(/^[0-9]{5}$/, errors_common.postleitzahl)
	//			.required().nullable(),
	vorschaeden: Yup.string().matches(/^[0-2]$/, errors_HouseholdInsurance.vorschaeden)
			.required(errors_HouseholdInsurance.vorschaeden).nullable(),
	selbstbehalt: Yup.string().required(errors_HouseholdInsurance.selbstbehalt).oneOf(selbstbehaltOptions.map(item => {
		return item.value.toString()
	}), errors_HouseholdInsurance.selbstbehalt).nullable(),
	zahlungsweise: Yup.string().required(errors_HouseholdInsurance.zahlungsweise).oneOf(zahlweiseOptions.map(item => {
		return item.value
	}), errors_HouseholdInsurance.zahlungsweise).nullable(),

	fahrrad: Yup.boolean().required(errors_HouseholdInsurance.fahrrad),
	fahrrad_versicherungssumme: Yup.number()
			.min(0, errors_HouseholdInsurance.fahrrad_versicherungssumme)
			.typeError(errors_common.typeErrorNumber)
			.when("fahrrad", {
				is: value => value && value,
				then: schema => schema
						.min(0, errors_HouseholdInsurance.fahrrad_versicherungssumme_min)
						.required(errors_HouseholdInsurance.fahrrad_versicherungssumme_min)
						.nullable(),
				otherwise: schema => schema
						.min(0, errors_HouseholdInsurance.fahrrad_versicherungssumme_min).nullable()
			}),
	glas: Yup.boolean().required(errors_HouseholdInsurance.glas),
	gebaeudeart: Yup.string()
			.when("glas", {
				is: value => value && value,
				then: schema =>  schema
						.oneOf(gebaeudeartOptions_Hausrat.map(item => {
							return item.value
						}), errors_HouseholdInsurance.gebaeudeart).nullable(),
				otherwise: schema => schema.nullable()
			}),
	elementar: Yup.boolean().required(errors_HouseholdInsurance.elementar),
	vorschaeden_elementar: Yup.number()
			.min(0, errors_HouseholdInsurance.vorschaeden_elementar_min)
			.max(2, errors_HouseholdInsurance.vorschaeden_elementar_min)
			.typeError(errors_common.typeErrorNumber)
			.when("elementar", {
				is: value => value && value,
				then: schema => schema
						.min(0, errors_HouseholdInsurance.vorschaeden_elementar_min)
						.required(errors_HouseholdInsurance.vorschaeden_elementar_min)
						.nullable(),
				otherwise: schema => schema
						.min(0, errors_HouseholdInsurance.vorschaeden_elementar_min).nullable()
			}),

	versichertes_objekt: versichertes_objektSchema
});
