export const insuranceTypes = {
    PRIVATE_LIABILITY: "PRIVATE_LIABILITY",
    PET_LIABILITY: "PET_LIABILITY",
    HOUSE_HOLD: "HOUSE_HOLD",
    LEGAL_COSTS: "LEGAL_COSTS",
    ACCIDENT: "ACCIDENT",
    RESIDENTIAL: "RESIDENTIAL",
    BICYCLE: "BICYCLE"
}

export const numberToInsuranceType = (number) => {
    switch (number) {
        case "1":
            return insuranceTypes.PRIVATE_LIABILITY

        case "2":
            return insuranceTypes.LEGAL_COSTS

        case "3":
            return insuranceTypes.HOUSE_HOLD

        case "4":
            return insuranceTypes.PET_LIABILITY

        case "5":
            return insuranceTypes.ACCIDENT

        case "6":
            return insuranceTypes.RESIDENTIAL
        case "7":
            return insuranceTypes.BICYCLE

        default:
            return undefined
    }
}
