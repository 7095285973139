import {getQuotePrivateLiability} from "../getquotes/getQuotePrivateLiability";

export const setOrderPrivateLiability = (values) => {

	let birthDate;
	if (values.geburtsdatum) {
		if (typeof values.geburtsdatum === "string") {
			values.geburtsdatum = new Date(values.geburtsdatum)
		}
		birthDate = [values.geburtsdatum.getFullYear(),
						 values.geburtsdatum.getMonth() + 1,
						 values.geburtsdatum.getDate()]
	}

	let result = {
		"orderData": {
			"grossPremium": values.berechneter_preis,
			"contributor": {
				"policyholderIsContributor": true,
				"bankAccount": {"directDebit": true, "iban": values.iban, "bic": ""},
				"contributor": {
					"firstName": values.vorname,
					"lastName": values.nachname,
					"salutation": values.anrede,
					"birthday": birthDate,
					"gender": "MALE",
					"title": "Dr.",
					"civilService": (values.berufsgruppe === 'oeffentlicher_dienst'),
					"address": {
						"street": values.strasse,
						"streetNumber": values.hausnummer,
						"postalCode": values.postleitzahl,
						"city": values.ort,
						"countryCode": "D"
					},
				}
			},
			"preInsurer": {
				"name": values.gesellschaftsname,
				"policyNumber": values.vorversicherungsnummer,
				"reason4Departure": "TERMINATION_BY_POLICIYHOLDER_TO_END"
			},
		},
		"tariffingData": getQuotePrivateLiability(values, values.tarif)
	};

	result.tariffingData.policyholder['firstName'] = values.vorname;
	result.tariffingData.policyholder['lastName'] = values.nachname;

	if (values.anrede === 'Herr') {
		result.orderData.contributor.contributor.gender = 'MALE';
		result.tariffingData.policyholder['gender'] = 'MALE';
		result.tariffingData.policyholder['salutation'] = 'Herr';
	}
	else {
		result.orderData.contributor.contributor.gender = 'FEMALE';
		result.tariffingData.policyholder['gender'] = 'FEMALE';
		result.tariffingData.policyholder['salutation'] = 'Frau';
	}

	return result;
};
