import React from "react";
import FormWrapper from "../components/forms/FormWrapper";
import {insuranceTypes} from "../../_params/variables";
import {labels} from "../../_params/form_labels";
import DateInput from "../components/formitems/DateInput";
import SelectInput from "../components/formitems/SelectInput";
import {
	accidentHouseholdOptions, beginn_unfall_tagegeld_options,
	berufsgruppeRadioOptions,
	dailyHospitalBenefitSumInsuredOptions,
	deathSumInsuredChildOptions,
	deathSumInsuredOptions,
	invalidityProgressionOptions, invaliditySumInsuredOptions,
	JaNeinRadioOptions,
	pensionSumInsuredChildOptions,
	pensionSumInsuredOptions, plasterMoneyBlockInsuredChildOptions, plasterMoneyBlockInsuredOptions,
	sicknessDailyAllowanceSumInsuredOptions,
	transitionalBenefitSumInsuredOptions, versicherungsDauerOptions,
	zahlweiseOptions
} from "../utils/optionsSelection";
import RadioGroupInput from "../components/formitems/RadioGroupInput";
import {getQuoteAccidentInsurance} from "../utils/getquotes/getQuoteAccidentInsurance";
import {setOrderAccidentInsurance} from "../utils/setOrders/setOrderAccidentInsurance";
import validationSchemaAccidentInsurance from "../utils/validationschemas/validationSchemaAccidentInsurance";
import {accidentInsuranceFormData} from "../utils/initialvalues/accidentInsuranceFormData";
import Row from "react-bootstrap/Row";
import {Col, Form as Formm} from "react-bootstrap";
import berufData from "../utils/berufData";
import TextInput from "../components/formitems/TextInput";
import _ from "lodash";
import SelectAutocomplete from "../components/formitems/SelectAutocomplete";

const OFFER_URL = "AccidentInsurance";
const labelsInsurance = labels.AccidentInsurance;

const convertValueInvaliditySumInsured = (invaliditaet) => {
	if (invaliditaet === 0) {
		return {label: 'Nein'}
	}
	else if (invaliditaet > 0) {
		return {label: `${invaliditaet.toLocaleString('DE')}€`}
	}
	else {
		return null;
	}
}


const AccidentInsurancePage = (props) => {
	const CalculationFormRender = (formik) => {
		const {values} = formik;


		return (
				<>
					<DateInput item={"beginn"} label={labelsInsurance.beginn}/>
					<Formm.Group as={Row} md="2" controlId={"formField-" + "job"}>
						<Formm.Label column md={4}>{labelsInsurance.versicherungsdauer}</Formm.Label>
						<Col md={8} style={{marginTop:'10px'}} >
							1 Jahr
						</Col>
					</Formm.Group>
					<SelectInput item={"haushalt"} label={labelsInsurance.haushalt}
									 options={accidentHouseholdOptions}/>

					{values.haushalt && values.haushalt !== 'kind(er)' &&
					 <>
						 <DateInput item={"mich.geburtsdatum"} label={labelsInsurance.geburtsdatum_Mich}/>
						 <Formm.Group as={Row} md="2" controlId={"formField-" + "job"}>
							 <Formm.Label column md={4}>{labelsInsurance.beruf}</Formm.Label>
							 <Col md={8}>
								 <SelectAutocomplete item="mich.beruf" options={berufData} convertValueFunc={value => ({"label": value})} />
							 </Col>
						 </Formm.Group>
						 <RadioGroupInput item={"mich.berufsgruppe"} label={labelsInsurance.berufsgruppe}
												options={berufsgruppeRadioOptions}/>
						 <Formm.Group as={Row} md="2" controlId={"formField-" + "invaliditySumInsured"}>
							 <Formm.Label column
											  md={4}>{labelsInsurance.invaliditaet}</Formm.Label>
							 <Col md={8}>
								 <SelectAutocomplete item="mich.invaliditaet" options={invaliditySumInsuredOptions} convertValueFunc={convertValueInvaliditySumInsured}/>
							 </Col>
						 </Formm.Group>
						 <SelectInput item={"mich.progression"} label={labelsInsurance.progression}
										  options={invalidityProgressionOptions}/>
						 <SelectInput item={"mich.unfallrente"} label={labelsInsurance.unfallrente}
										  options={pensionSumInsuredOptions}/>
						 <SelectInput item={"mich.krankenhaustagegeld"}
										  label={labelsInsurance.krankenhaustagegeld}
										  options={dailyHospitalBenefitSumInsuredOptions}/>
						 <SelectInput item={"mich.todesfallleistung"} label={labelsInsurance.todesfallleistung}
										  options={deathSumInsuredOptions}/>
						 <SelectInput item={`mich.gipsgeld`}
										  label={labelsInsurance.gipsgeld}
										  options={plasterMoneyBlockInsuredOptions}/>
						 <RadioGroupInput item={"mich.notfall_assistent"} label={labelsInsurance.notfall_assistent}
												options={JaNeinRadioOptions}/>
						 <SelectInput item={"mich.uebergangsleistung"}
										  label={labelsInsurance.uebergangsleistung}
										  options={transitionalBenefitSumInsuredOptions}/>
						 <SelectInput item={"mich.unfall_tagegeld"}
										  label={labelsInsurance.unfall_tagegeld}
										  options={sicknessDailyAllowanceSumInsuredOptions}/>
						 {
								 (Number(values.mich.unfall_tagegeld) > 0) && <SelectInput
																								 item={"mich.beginn_unfall_tagegeld"}
																								 label={labelsInsurance.beginn_unfall_tagegeld}
																								 options={beginn_unfall_tagegeld_options}/>
						 }
						 <RadioGroupInput item={"mich.dynamik"} label={labelsInsurance.dynamik} options={JaNeinRadioOptions}
												infoButton={'Wenn „Ja“ ausgewählt wird, erhöht sich die Versicherungssumme jährlich um 5%. Auch der Beitrag steigt jährlich an.'}/>
					 </>
					}
					{values.haushalt && values.haushalt !== 'mich' &&
					 <>
						 <hr/>
						 {['mich_partner_und_kind(er)', 'mich_und_partner'].includes(values.haushalt) &&
						  <>
							  <h4>Partner</h4>
							  <DateInput item={"partner.geburtsdatum"} label={labelsInsurance.geburtsdatum}/>
							  <Formm.Group as={Row} md="2" controlId={"formField-" + "job"}>
								  <Formm.Label column md={4}>{labelsInsurance.beruf}</Formm.Label>
								  <Col md={8}>
									  <SelectAutocomplete item="partner.beruf" options={berufData} convertValueFunc={value => ({"label": value})}/>
								  </Col>
							  </Formm.Group>
							  <RadioGroupInput item={"partner.berufsgruppe"} label={labelsInsurance.berufsgruppe}
													 options={berufsgruppeRadioOptions}/>
							  <Formm.Group as={Row} md="2"
												controlId={"formField-person-" + "invaliditySumInsured"}>
								  <Formm.Label column
													md={4}>{labelsInsurance.invaliditaet}</Formm.Label>
								  <Col md={8}>
									  <SelectAutocomplete item="partner.invaliditaet" options={invaliditySumInsuredOptions} convertValueFunc={convertValueInvaliditySumInsured}/>
								  </Col>
							  </Formm.Group>
							  <SelectInput item={"partner.progression"}
												label={labelsInsurance.progression}
												options={invalidityProgressionOptions}/>
							  <SelectInput item={"partner.unfallrente"} label={labelsInsurance.unfallrente}
												options={pensionSumInsuredOptions}/>
							  <SelectInput item={"partner.krankenhaustagegeld"}
												label={labelsInsurance.krankenhaustagegeld}
												options={dailyHospitalBenefitSumInsuredOptions}/>
							  <SelectInput item={"partner.todesfallleistung"} label={labelsInsurance.todesfallleistung}
												options={deathSumInsuredOptions}/>
							  <SelectInput item={`partner.gipsgeld`}
												label={labelsInsurance.gipsgeld}
												options={plasterMoneyBlockInsuredOptions}/>
							  <RadioGroupInput item={"partner.notfall_assistent"} label={labelsInsurance.notfall_assistent}
													 options={JaNeinRadioOptions}/>
							  <SelectInput item={"partner.uebergangsleistung"}
												label={labelsInsurance.uebergangsleistung}
												options={transitionalBenefitSumInsuredOptions}/>
							  <SelectInput item={"partner.unfall_tagegeld"}
												label={labelsInsurance.unfall_tagegeld}
												options={sicknessDailyAllowanceSumInsuredOptions}/>
							  {
									  (Number(values.partner?.unfall_tagegeld) > 0) && <SelectInput
																											item={"partner.beginn_unfall_tagegeld"}
																											label={labelsInsurance.beginn_unfall_tagegeld}
																											options={[{value: 1, label: '1. Tag'}, {value: 43, label: '43. Tag'}]}/>
							  }
							  <RadioGroupInput item={"partner.dynamik"} label={labelsInsurance.dynamik}
													 options={JaNeinRadioOptions}
													 infoButton={'Wenn „Ja“ ausgewählt wird, erhöht sich die Versicherungssumme jährlich um 5%. Auch der Beitrag steigt jährlich an.'}/>
							  <hr/>
						  </>
						 }

						 {['mich_partner_und_kind(er)', 'mich_und_kind(er)', 'kind(er)'].includes(values.haushalt) &&
						  <>
							  <h4>Kind(er)</h4>
							  <TextInput item={"anzahl_kinder"} label={labelsInsurance.anzahl_kinder}/>
							  {["1", "2", "3"].includes(values.anzahl_kinder) &&
								_.range(1, Number(values.anzahl_kinder) + 1, 1).map(child => (
										<>
											<hr/>
											<strong style={{backgroundColor: '#37afc9'}}>{`Kind ${child} :`}</strong>
											<br/><br/>
											<DateInput item={`kind${child}.geburtsdatum`} label={labelsInsurance.geburtsdatum}/>
											<Formm.Group controlId={"formField-child" + child + "-invaliditySumInsured"}
															 as={Row} md="2">
												<Formm.Label column md={4}>
													{labelsInsurance.invaliditaet}
												</Formm.Label>
												<Col md={8}>
													<SelectAutocomplete item={`kind${child}.invaliditaet`} options={invaliditySumInsuredOptions} convertValueFunc={convertValueInvaliditySumInsured}/>
												</Col>
											</Formm.Group>
											<SelectInput item={`kind${child}.progression`}
															 label={labelsInsurance.progression}
															 options={invalidityProgressionOptions}/>
											<SelectInput item={`kind${child}.unfallrente`}
															 label={labelsInsurance.unfallrente}
															 options={pensionSumInsuredChildOptions}/>
											<SelectInput item={`kind${child}.krankenhaustagegeld`}
															 label={labelsInsurance.krankenhaustagegeld}
															 options={dailyHospitalBenefitSumInsuredOptions}/>
											<SelectInput item={`kind${child}.todesfallleistung`}
															 label={labelsInsurance.todesfallleistung}
															 options={deathSumInsuredChildOptions}/>
											<SelectInput item={`kind${child}.gipsgeld`}
															 label={labelsInsurance.gipsgeld}
															 options={plasterMoneyBlockInsuredChildOptions}/>
											<SelectInput item={`kind${child}.uebergangsleistung`}
															 label={labelsInsurance.uebergangsleistung}
															 options={transitionalBenefitSumInsuredOptions}/>
											<RadioGroupInput item={`kind${child}.dynamik`} label={labelsInsurance.dynamik}
																  options={JaNeinRadioOptions}
																  infoButton={'Wenn „Ja“ ausgewählt wird, erhöht sich die Versicherungssumme jährlich um 5%. Auch der Beitrag steigt jährlich an.'}/>
										</>
								))}
						  </>
						 }

					 </>
					}
					<SelectInput item={"zahlungsweise"} label={labelsInsurance.zahlungsweise}
									 options={zahlweiseOptions}/>
				</>
		)
	}



	return (<>
				<FormWrapper
						validationSchemaCalculation={validationSchemaAccidentInsurance}
						OFFER_URL={OFFER_URL}
						CalculationForm={CalculationFormRender}
						calcList={['L','XL', 'XXL']}
						getQuoteHandler={getQuoteAccidentInsurance}
						setOrder={setOrderAccidentInsurance}
						initValuesForm={accidentInsuranceFormData}
						tariffName={insuranceTypes.ACCIDENT}/>
			</>
	)
}

export default AccidentInsurancePage;
