import {
	dateConverter,
	translateDachausbau, translateGebaeudeart_Wohngebaeudeversicherung,
	translateGebaeudenutzung,
	translateNutzungsart,
	translateZahlungsweise
} from "../translaterForAPI";

export const getQuotePrivateResidential = (values, deckung) => {

	let result = {
		"salesPartnerNumber": sessionStorage.getItem("vermittlernummer"),
		"externalIdent": sessionStorage.getItem("externeIdent"),
		"policyholder": {
			"birthday": dateConverter(values.geburtsdatum_VN),
			"civilService": true,
			"address": {
				"street": values.versichertes_objekt.strasse_vo || "strasse",
				"streetNumber": values.versichertes_objekt.hausnr_vo || "40",
				"postalCode": values.versichertes_objekt.postleitzahl_vo || "03046",
				"city": values.versichertes_objekt.ort_vo || "city",
				"countryCode": "D"
			},
			"phoneNumber": values.telefon?.replace(/\s/g, ''),
			"emailAddress": values.email
		},
		"insuranceData": {
			"countOfPreviousDamages": Number(values.vorschaeden) || 0,
			"paymentInterval": translateZahlungsweise(values.zahlungsweise),
			"insuranceBegin": dateConverter(values.beginn),
			"contractTermInYears": parseInt(values.versicherungsdauer),
			"tariffSelection": deckung,
			"deductible": Number(values.selbstbehalt),
			"combinedBUICoverage": {},
			"waterDrainagePipes": values.ableitungsrohre === true,
			"photovoltaisSolarSystem": values.photovoltaikanlage === true,
			"fireShell": false,
			"assistance": values.assistance === true,
			"insuredBuilding": {
				"permanentlyInhabited": values.gebaeude_staendig_bewohnt === "ja",
				"buildingType": translateGebaeudeart_Wohngebaeudeversicherung(values.gebaeudetyp),
				"buildingUse": translateGebaeudenutzung(values.gebaeudenutzung),
				"typeOfUse": translateNutzungsart(values.nutzungsart),
				"roofExtension": translateDachausbau(values.dachausbau),
				"livingSpaceAboveGround": values.wohnflaeche_einschliesslich_gewerbeflaeche,
				"shareOfCommercialUse": values.prozentualer_anteil_gewerblich_genutzt,
				"constructionTypeClass": values.bauartklasse,
				"constructionYear": values.baujahr,
				"coreRenovationYear": values.kernsanierungsjahr,
				"monumentProtection": values.denkmalschutz === "ja",
				"countOfFloors": Number(values.anzahl_stockwerke),
				"withCellar": values.unterkellerung,
				"constructionWork": {
					"solarSystem": values.waermepumpe_oder_solaranlage,
					"heatPump": values.waermepumpe_oder_solaranlage,
					"underfloorHeating": values.fussboden_oder_dachheizung,
					"roofHeating": values.fussboden_oder_dachheizung
				},
				"objectAddress": {
					"street": values.versichertes_objekt.strasse_vo,
					"streetNumber": values.versichertes_objekt.hausnr_vo,
					"postalCode": values.versichertes_objekt.postleitzahl_vo,
					"city": values.versichertes_objekt.ort_vo,
					"countryCode": "D"
				}
			}
		}
	}

	let combinedBUICoverage = {};
	combinedBUICoverage = {...combinedBUICoverage, fire: {countOfPreviousDamages: values.anzahl_vorschaeden_feuer}};

	if (values.glas) {
		combinedBUICoverage = {...combinedBUICoverage, glass: {countOfPreviousDamages: 1}};
	}
	if (values.leitungswasser) {
		combinedBUICoverage =
				{...combinedBUICoverage, tapWater: {countOfPreviousDamages: Number(values.anzahl_vorschaeden_leitungswasser)}};
	}
	if (values.sturm_oder_hagel) {
		combinedBUICoverage = {...combinedBUICoverage, storm: {countOfPreviousDamages: Number(values.anzahl_vorschaeden_sturm_hagel)}};
	}
	if (values.elementar) {
		combinedBUICoverage =
				{...combinedBUICoverage, elementary: {countOfPreviousDamages: Number(values.anzahl_vorschaeden_elementar)}};
	}
	if (values.unbenannte_gefahren) {
		combinedBUICoverage =
				{...combinedBUICoverage, unnamendDanger: {countOfPreviousDamages: 1}};
	}
	if (values.haustechnik) {
		combinedBUICoverage =
				{...combinedBUICoverage, houseTechnic: {countOfPreviousDamages: 1}};
	}

	if (values.unterkellerung) {
		result.insuranceData.insuredBuilding["livingSpaceUnderground"] = Number(values.wohnflaeche_keller);
	}
	else {
		result.insuranceData.insuredBuilding["livingSpaceUnderground"] = 0;
	}

	if (values.garage_oder_carport) {
		result.insuranceData.insuredBuilding["countOfGarages"] = Number(values.anzahl_garage_oder_carport);
	}
	else {
		result.insuranceData.insuredBuilding["countOfGarages"] = 0;
	}

	result.insuranceData.combinedBUICoverage = combinedBUICoverage;

	return result;
};
