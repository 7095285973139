import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {zahlweiseOptions} from "../optionsSelection";

const errors_BicycleInsurance = errors.BicycleInsurance;
const errors_common = errors.COMMON;

const today = new Date();
let minBirthdate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

export const versichertesfahrrad_Schema = Yup.object({
	originalrechnung: Yup.boolean().required(errors_BicycleInsurance.originalrechnung).oneOf([true], errors_BicycleInsurance.originalrechnung_false).nullable(),
	carbon: Yup.boolean().required(errors_BicycleInsurance.carbon),
	leasing: Yup.boolean().required(errors_BicycleInsurance.leasing),
	rahmennummer: Yup.string().required(errors_BicycleInsurance.rahmennummer).nullable(),
	condition: Yup.string().required(errors_BicycleInsurance.condition).nullable(),
	typ: Yup.string().required(errors_BicycleInsurance.typ).nullable(),
	fahrradtyp: Yup.string().required(errors_BicycleInsurance.fahrradtyp).nullable(),
	fahrradhersteller: Yup.string().required(errors_BicycleInsurance.fahrradhersteller).nullable(),
	kaufdatum: Yup.date().max(new Date(), errors_BicycleInsurance.kaufdatum).required(errors_BicycleInsurance.kaufdatum).nullable(),
	nutzung: Yup.string().oneOf(['PRIVAT'], errors_BicycleInsurance.nutzung_privat).required(errors_BicycleInsurance.nutzung).nullable(),
	kaufsumme: Yup.string().required(errors_BicycleInsurance.kaufsumme).nullable(),

})

export default Yup.object().shape({
	beginn: Yup.date().min(new Date(), errors_BicycleInsurance.beginn).required(errors_BicycleInsurance.beginn)
			.nullable(),
	geburtsdatum_VN: Yup.date()
			.max(minBirthdate, errors_BicycleInsurance.geburtsdatum_min18)
			.required(errors_BicycleInsurance.geburtsdatum).nullable(),
	postleitzahl: Yup.string().matches(/^[0-9]{5}$/, errors_common.postleitzahl).required(errors_common.postleitzahl)
			.nullable(),
	vorschaeden: Yup.number().required(errors_BicycleInsurance.vorschaeden)
			.max(2, errors_BicycleInsurance.vorschaeden_max2).nullable(),
	zahlungsweise: Yup.string().required(errors_BicycleInsurance.zahlungsweise).oneOf(zahlweiseOptions.map(item => {
		return item.value
	}), errors_BicycleInsurance.zahlungsweise).nullable(),
	versichertesfahrrad: versichertesfahrrad_Schema

});
