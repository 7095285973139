import React, {useEffect, useState} from "react";
import {Col, Modal, Row, Spinner} from "react-bootstrap";
import {Formik, useFormikContext} from "formik";
import {DropZone, Form} from "react-formik-ui";
import Button from "react-bootstrap/Button";
import {offerNameChecker} from "../utils/formHelper";
import * as Yup from "yup";

const UploadDataBox = (props) => {

    const {disabled, offer} = props;

    const formik = useFormikContext();

    useEffect(() => {

        if (uploadFormFinished) {
            let obj = {}

            Object.keys(formik.errors).map(key => {
                obj[key] = true
            })

            formik.setTouched(obj, true)
        }

    }, [formik.errors])

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadFormFinished, setUploadFormFinished] = useState(false);

    function uploadFile(file) {
        const fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onloadend = event => {
            let uploadedValues;
            // remove all linebreaks from input text file
            let content = event.target.result.replaceAll("\n", "");
            content = content.charAt(0) === '{' ? content.slice(1,-1) : content;
            try {
                uploadedValues = JSON.parse(`{${content}}`);
                let keysFormik = Object.keys(formik.values)
                Object.keys(uploadedValues).forEach((field) => {
                    if (keysFormik.includes(field)) {
                        formik.setFieldValue(field, uploadedValues[field])
                        formik.setFieldTouched(field, true, true)
                    }
                })
                setUploadFormFinished(true)
            } catch (err) {
                setLoading(false);
                alert('Hochladen ist fehlgeschlagen!\nBitte stellen Sie sicher, dass Sie eine gültige Datei verwenden.')
            }
        };
    }

    async function submitUpload(data) {
        let file = data.fileInput[0];
        if (!file.type.match('application/json') && !file.type.match('text/plain')) {
            alert('File not supported!');
            return;
        }
        if (file && !offerNameChecker(offer, file.path)) {
            alert(file.path + ' ist ein falscher Tarif!');
            return;
        }

        setLoading(true);
        setTimeout(() => {
            uploadFile(file);
            setLoading(false);
            setIsDialogOpen(false);
        }, 1500);
    }

    if (disabled) {
        return (
            <span className={'uploadLink'} style={{cursor: "not-allowed", color: "grey"}}>
					<span className={'ar-btn ar-icon-upload'}/> <span className={'uploadText'}>Tarifberechnung hochladen</span>
				</span>
        )
    }
    return (
        <>
				<span className={'uploadLink'} onClick={() => setIsDialogOpen(!isDialogOpen)}
                      title={'Tarifberechnung hochladen'}>
					<span className={'ar-btn ar-icon-upload'}/> <span className={'uploadText'}>Tarifberechnung hochladen</span>
				</span>

            <Modal
                show={isDialogOpen}
                onHide={() => setIsDialogOpen(false)}
                size={"sm"} centered
            >
                <Modal.Header style={{height: '30px'}}>
                    <h3 className={'dialogHead'}>Tarifberechnung hochladen</h3>
                    <span className={'ar-btn-big ar-icon-cross cursorHand'} onClick={() => setIsDialogOpen(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{fileInput: []}}
                        onSubmit={values => submitUpload(values)}
                        validationSchema={Yup.object().shape({
                            fileInput: Yup.array().required('Verpflichtend').min(1, 'Mindestens eine Datei hochladen.')
                                .max(1, 'Höchstens eine Datei hochladen.')
                        })}
                    >
                        {({values}) =>
                            <Form mode='themed' style={{padding: '0 50px 0 50px'}}>
                                <fieldset>
                                    <div className={'modalUpload'}>
                                        <DropZone
                                            name='fileInput'
                                            placeholder='Datei auswählen oder hier ablegen.'
                                            required
                                            zoneActiveText={'Datei hier ablegen'}
                                            multiple={false}
                                            maxFiles={1}
                                        />
                                        <Button
                                          variant="info"
                                          className={'btn-weiter'}
                                          type="submit"
                                    >
                                        {'Tarif auswählen '}
                                        {loading && <Spinner animation="border" size="sm"/>}
                                    </Button>
                                    </div>
                                </fieldset>
                            </Form>
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UploadDataBox;
