import {CommonFormData} from "./commonFormData";

export const fahrradFormdata = {
  "beginn": null,
  "versicherungsdauer": "1",
  "tarif": "",
  "geburtsdatum_VN": null,
  "postleitzahl": "",
  "vorschaeden": "",
  "zahlungsweise": "",
  "zusatzbausteinDiebstahl": false,
  "zusatzbausteinReparatur": false,
  "zusatzbausteinPannenhilfe": false,
  "versichertesfahrrad": {
    "typ": "",
    "fahrradtyp": "",
    "fahrradhersteller": null,
    "condition": "",
    "leasing": null,
    "carbon": null,
    "originalrechnung": null,
    "kaufdatum": null,
    "rahmennummer": "",
  },

  ...CommonFormData
};
