import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import CalculationWrapper_PL from "../calculationwrappers/CalculationWrapper_PL";
import APIService from "../../services/API.service";
import {insuranceTypes} from "../../../_params/variables";
import CalculationWrapper_LC from "../calculationwrappers/CalculationWrapper_LC";
import CalculationWrapper_HH from "../calculationwrappers/CalculationWrapper_HH";
import CalculationWrapper_ACC from "../calculationwrappers/CalculationWrapper_ACC";
import CalculationWrapper_PET from "../calculationwrappers/CalculationWrapper_PET";
import CalculationWrapper_RSD from "../calculationwrappers/CalculationWrapper_RSD";
import CalculationWrapper_FV from "../calculationwrappers/CalculationWrapper_FV";
import DownloadPdfForm from "../DownloadPdfForm";
import {accidentInsuranceFormData} from "../../utils/initialvalues/accidentInsuranceFormData";
import {offerNameRender} from "../../utils/formHelper";
import {requiring_address_products} from "../../pages/LegalCostsPage";
import _ from "lodash";

const CalculationDataForm = ({
	activeStep,
	calcList,
	children,
	getQuoteHandler,
	handleNext,
	offer,
	offerURL,
	setTariffHighlights
}) => {

	const formik = useFormikContext();
	const {values, dirty, setValues, setFieldValue} = formik;

	const [resultGUT, setResultGUT] = useState(null);
	const [resultBESSER, setResultBESSER] = useState(null);
	const [resultRIESIG, setResultRIESIG] = useState(null);
	const [calcLoading, setCalcLoading] = useState(false);



	useEffect(() => {
		let cancelHelper = {canceled: false};

		formik.validateForm(values)
				.then((err) => {
					if (activeStep === 1 && dirty) {
						if (Object.keys(err).length === 0) {
							if (offer === insuranceTypes.LEGAL_COSTS) {
								values.tarif && handleCalculationLegalCost(cancelHelper, values.tarif)
							} else {
								handleCalculation(cancelHelper)
							}
						}
						else {
							setResultGUT(null);
							setResultBESSER(null);
							setResultRIESIG(null);
						}
					}
				})

		return () => cancelHelper.canceled = true
	}, [values]);

	useEffect(() => {
		let v = null

		if (offer === insuranceTypes.ACCIDENT) {

			if (!values.haushalt?.includes("mich")) {
				v = {...v, mich: accidentInsuranceFormData.mich}
			}

			if (!values.haushalt?.includes("partner")) {
				v = {...v, partner: accidentInsuranceFormData.partner}
			}

			if (!values.haushalt?.includes("kind")) {
				v = {
					...v,
					kind1: accidentInsuranceFormData.kind1,
					kind2: accidentInsuranceFormData.kind2,
					kind3: accidentInsuranceFormData.kind3,
				}
			}

			v && setValues((formikValues) => ({...formikValues, ...v}))
		}
	}, [values.haushalt])

	useEffect(() => {
		if (offer === insuranceTypes.LEGAL_COSTS &&  !requiring_address_products.includes(values.produkt)) {
			values.anzahl_wohnungen && setFieldValue("anzahl_wohnungen", "")
			values.versicherte_objekte.forEach((obj, nr) => {
				obj.strasse_vo && setFieldValue(`versicherte_objekte[${nr}].strasse_vo`, "")
				obj.hausnr_vo && setFieldValue(`versicherte_objekte[${nr}].hausnr_vo`, "")
				obj.postleitzahl_vo && setFieldValue(`versicherte_objekte[${nr}].postleitzahl_vo`, "")
				obj.ort_vo && setFieldValue(`versicherte_objekte[${nr}].ort_vo`, "")
			})
		}
	}, [values.produkt])

	useEffect(() => {
		if (offer === insuranceTypes.LEGAL_COSTS) {
			if (values.tarif === "XXL") {
				setFieldValue('zusatzbausteinPflegeberatung', false)
				setFieldValue('zusatzbausteinErweiterterStraftrechtsschutz', true)
			}
			else {
				setFieldValue('zusatzbausteinPflegeberatung', false)
				setFieldValue('zusatzbausteinErweiterterStraftrechtsschutz', false)
			}
		}
	}, [values.tarif])

	useEffect(() => {
		if (offer === insuranceTypes.LEGAL_COSTS && values.anzahl_wohnungen && values.anzahl_wohnungen < values.versicherte_objekte.length) {
			setFieldValue('versicherte_objekte', values.versicherte_objekte.slice(0, values.anzahl_wohnungen))
		}
	}, [values.anzahl_wohnungen, values.versicherte_objekte])

	useEffect(() => {
		if (offer === insuranceTypes.LEGAL_COSTS && values.addresse_gleich_bei_versicherten_objekten && values.versicherte_objekte[0]) {
			_.range(1, values.anzahl_wohnungen,1).map(nr => {
				setFieldValue(`versicherte_objekte[${nr}].strasse_vo`, values.versicherte_objekte[0].strasse_vo)
				setFieldValue(`versicherte_objekte[${nr}].hausnr_vo`, values.versicherte_objekte[0].hausnr_vo)
				setFieldValue(`versicherte_objekte[${nr}].postleitzahl_vo`, values.versicherte_objekte[0].postleitzahl_vo)
				setFieldValue(`versicherte_objekte[${nr}].ort_vo`, values.versicherte_objekte[0].ort_vo)
			})
		}
	}, [values.versicherte_objekte, values.addresse_gleich_bei_versicherten_objekten, values.anzahl_wohnungen])

	useEffect(() => {
		if (offer === insuranceTypes.LEGAL_COSTS && !values.addresse_gleich_bei_versicherten_objekten) {
			_.range(1, values.anzahl_wohnungen,1).map(nr => {
				setFieldValue(`versicherte_objekte[${nr}].strasse_vo`, '')
				setFieldValue(`versicherte_objekte[${nr}].hausnr_vo`, '')
				setFieldValue(`versicherte_objekte[${nr}].postleitzahl_vo`, '')
				setFieldValue(`versicherte_objekte[${nr}].ort_vo`, '')
			})
		}
	}, [values.addresse_gleich_bei_versicherten_objekten, values.anzahl_wohnungen])

	useEffect(() => {
		if (values.wohnflaeche_einschliesslich_gewerbeflaeche < 0) {
			setFieldValue("wohnflaeche_einschliesslich_gewerbeflaeche", 0)
		}
	}, [values.wohnflaeche_einschliesslich_gewerbeflaeche])


	useEffect(() => {
		if (offer === insuranceTypes.ACCIDENT) {
			if (values.mich?.geburtsdatum) {
				setFieldValue("geburtsdatum_VN", values.mich?.geburtsdatum)
			}
			else {
				setFieldValue("geburtsdatum_VN", null)
			}
		}
	}, [values.mich?.geburtsdatum])

	useEffect(() => {
		let v = null

		if (offer === insuranceTypes.ACCIDENT) {
			if (values.anzahl_kinder < 3) {
				v = {...v, kind3: accidentInsuranceFormData.kind3}
			}

			if (values.anzahl_kinder < 2) {
				v = {...v, kind2: accidentInsuranceFormData.kind2}
			}

			if (values.anzahl_kinder < 1) {
				v = {...v, kind1: accidentInsuranceFormData.kind1}
			}

			v && setValues((formikValues) => ({...formikValues, ...v}))
		}
	}, [values.anzahl_kinder])

	const goNextStep = (deckung, calcResult) => {
		if (resultGUT || resultBESSER || resultRIESIG) {
			formik.setFieldValue('tarif', deckung);
			formik.setFieldValue('berechneter_preis', calcResult.grossPremium);
			formik.setFieldValue('berechneter_preis_produkte', calcResult.productsGrossPremium);
			handleNext();
		}
	}

	const calculationWrapperRender = (config, media) => {
		switch (offer) {
			case insuranceTypes.PRIVATE_LIABILITY:
				return <CalculationWrapper_PL
						resultGUT={resultGUT}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						values={values}
						goNextStep={goNextStep}
						config={config}
				/>;
			case insuranceTypes.LEGAL_COSTS:
				return <CalculationWrapper_LC
						resultGUT={resultGUT}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						values={values}
						goNextStep={goNextStep}
						config={config}
						setFieldValue={setFieldValue}
				/>;
			case insuranceTypes.BICYCLE:
				return <CalculationWrapper_FV
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						values={values}
						goNextStep={goNextStep}
						config={config}
						setFieldValue={setFieldValue}
				/>;
			case insuranceTypes.HOUSE_HOLD:
				return <CalculationWrapper_HH
						resultGUT={resultGUT}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						values={values}
						goNextStep={goNextStep}
						config={config}
				/>;
			case insuranceTypes.PET_LIABILITY:
				return <CalculationWrapper_PET
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						values={values}
						goNextStep={goNextStep}
						config={config}
				/>;
			case insuranceTypes.ACCIDENT:
				return <CalculationWrapper_ACC
						resultGUT={resultGUT}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						values={values}
						goNextStep={goNextStep}
						config={config}
						setFieldValue={setFieldValue}
						media={media}
				/>;
			case insuranceTypes.RESIDENTIAL:
				return <CalculationWrapper_RSD
						resultGUT={resultGUT}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						values={values}
						goNextStep={goNextStep}
						config={config}
				/>;

			default:
				return null;


		}
	}

	const buildResponseObject = (cancelHelper, res, setResultState) => {

		if (!cancelHelper.canceled && res && res !== '{}') {
			if (res.status === 406 || res.status === 500) {
				setResultState({
					calculationResult: {
						grossPremium: 'NaN'
					},
					calculationMessages: res.errorText ?? String(res),
					calculationError: true,
				});
			}
			else {
				setResultState({
					calculationResult: {
						grossPremium: parseFloat(res.grossPremium).toFixed(2),
						productsGrossPremium: res.productsGrossPremium && Object.keys(res.productsGrossPremium)
								.reduce((prev, cur) => ({
									...prev, [cur]: parseFloat(res.productsGrossPremium[cur]).toFixed(2)
								}), {})
					},
					calculationMessages: res.serverMessages.join(" "),
				});
			}
		}

	}

	const handleCalculationLegalCost = (cancelHelper, tarif) => {
		setCalcLoading(true)
		APIService.getQuote(offerURL, getQuoteHandler(values, tarif))
				.then((res) => {
					switch (tarif) {
						case "L": return buildResponseObject(cancelHelper, res, setResultGUT)
						case "XL": return buildResponseObject(cancelHelper, res, setResultBESSER)
						case "XXL": return buildResponseObject(cancelHelper, res, setResultRIESIG)
					}
				})
				.catch(error => {
					console.error('Error: ' + error);
				})
				.finally(() => setCalcLoading(false))
	}

	const handleCalculation = (cancelHelper) => {
		//make calculation
		setCalcLoading(true);
		let fetchPromises = [];
		if (calcList.includes('L')) {
			fetchPromises.push(APIService.getQuote(offerURL, getQuoteHandler(values, 'L'))
					.then((res) => {
						buildResponseObject(cancelHelper, res, setResultGUT);
					})
					.catch(error => {
						console.error('Error: ' + error);
					}));
		}

		if (calcList.includes('XL')) {
			fetchPromises.push(APIService.getQuote(offerURL, getQuoteHandler(values, 'XL'))
					.then((res) => {
						buildResponseObject(cancelHelper, res, setResultBESSER);
					})
					.catch(error => {
						console.error('Error: ' + error);
					}));
		}

		if (calcList.includes('XXL')) {
			fetchPromises.push(APIService.getQuote(offerURL, getQuoteHandler(values, 'XXL'))
					.then((res) => {
						buildResponseObject(cancelHelper, res, setResultRIESIG);
					})
					.catch(error => {
						console.error('Error: ' + error);
					}));
		}
		return Promise.all(fetchPromises).finally(() => setCalcLoading(false));
	}

	useEffect(() => {
		if (allResultsFetched()) {
			setTariffHighlights(() => calculationWrapperRender)
		}
	}, [resultGUT, resultBESSER, resultRIESIG, calcLoading])

	const allResultsFetched = () => {
		let list = []

		if (calcLoading) {
			return false;
		}

		if (offer === insuranceTypes.LEGAL_COSTS || offer === insuranceTypes.ACCIDENT || offer === insuranceTypes.HOUSE_HOLD) {
			return Boolean(resultGUT || resultBESSER || resultRIESIG)
		}

		if (calcList.includes('L')) {
			list.push(resultGUT ?? false)
		}

		if (calcList.includes('XL')) {
			list.push(resultBESSER ?? false)
		}

		if (calcList.includes('XXL')) {
			list.push(resultRIESIG ?? false)
		}

		return !list.includes(false)
	}

	return (
			<>
				<h3>{offerNameRender(offer).replace('ae', 'ä')} berechnen</h3>
				{children}

				<div>
					<hr style={{backgroundColor: '#fff', marginTop: '30px'}}/>
					<h2 style={{textAlign: 'center', marginBottom: '30px'}}> Tarifauswahl </h2>
					{calculationWrapperRender()}
				</div>
				{allResultsFetched() && <DownloadPdfForm
						offer={offer}
						values={values}
						hideCustomerData={true}
						tariffPrices={calculationWrapperRender}
				/>}

				{/*<code>
					<pre>{JSON.stringify(values, null, 2)}</pre>
					<pre>{JSON.stringify(errors, null, 2)}</pre>
					<pre>{JSON.stringify(touched, null, 2)}</pre>
				</code>*/}
			</>
	)
}

export default CalculationDataForm;
