import React, {useContext, useEffect} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import PrivateLiabilityPage from "./pages/PrivateLiabilityPage";
import SignInPage from "./pages/SignInPage";
import {Container} from "react-bootstrap";
import {ToastContainer} from "react-toastify";
import LegalCostsPage from "./pages/LegalCostsPage";
import HouseholdPage from "./pages/HouseholdPage";
import PetLiabilityPage from "./pages/PetLiabilityPage";
import AccidentInsurancePage from "./pages/AccidentInsurancePage";
import ResidentialInsurancePage from "./pages/ResidentialInsurancePage";
import {INSURANCES_ROUTES} from "./utils/formHelper";
import {QueryParamsContext} from "./contexts/QueryParamsContext";
import BicycleInsurancePage from "./pages/BicycleInsurancePage";

const AppWrapper = (props) => {
	const history = useHistory();
	const location = useLocation();

	const {currentUser} = useContext(QueryParamsContext);

	useEffect(() => {
		if (currentUser) {
			if (location.pathname.split('/').length > 2 && !location.pathname.includes('login')) {
				history.push(location.pathname);
			}
		}
	}, [currentUser]);

	return (
			<div className={'site-container'}>
				<div className="App">
					<Container>
						<ToastContainer autoClose={5000} hideProgressBar limit={3} newestOnTop/>
						<Switch>
							<Route path={'/login'}>
								<SignInPage/>
							</Route>
							<Route path={`/${INSURANCES_ROUTES.PRIVATE_LIABILITY}`}>
								<PrivateLiabilityPage/>
							</Route>
							<Route path={`/${INSURANCES_ROUTES.LEGAL_COSTS}`}>
								<LegalCostsPage/>
							</Route>
							<Route path={`/${INSURANCES_ROUTES.HOUSE_HOLD}`}>
								<HouseholdPage/>
							</Route>
							<Route path={`/${INSURANCES_ROUTES.PET_LIABILITY}`}>
								<PetLiabilityPage/>
							</Route>
							<Route path={`/${INSURANCES_ROUTES.ACCIDENT}`}>
								<AccidentInsurancePage/>
							</Route>
							<Route path={`/${INSURANCES_ROUTES.RESIDENTIAL}`}>
								<ResidentialInsurancePage/>
							</Route>
							<Route path={`/${INSURANCES_ROUTES.BICYCLE}`}>
								<BicycleInsurancePage/>
							</Route>
						</Switch>
					</Container>
				</div>
				<div className={'site-footer'}>

					<div style={{margin:"auto", display:"inline-block"}}>
					<a href={'https://www.adam-riese.de/impressum'} target={'_blank'} style={{marginRight:15}}>Impressum</a> |
					<a href={'https://www.adam-riese.de/datenschutz'} target={'_blank'} style={{marginRight:15,marginLeft:15}}>Datenschutz</a> |
					<a href={'https://www.adam-riese.de/nutzungsbedingungen'} target={'_blank'} style={{marginLeft:15}}>Nutzungsbedingungen</a>
					</div>
				</div>
			</div>
	)
}

export default AppWrapper;
