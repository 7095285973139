import {CommonFormData} from "./commonFormData";

export const rechtsschutzFormdata = {
  "beginn": null,
  "versicherungsdauer": "1",
  "tarif": "",
  "familienstand": "",
  "berufsgruppeVN": "",
  "produkt": "",
  "anzahl_wohnungen": "",
  "vorschaeden": "",
  "schadenfreieJahre" : "",
  "selbstbehalt": "",
  "zahlungsweise": "",
  "versicherte_objekte": [{
    "strasse_vo": "",
    "hausnr_vo": "",
    "postleitzahl_vo": "",
    "ort_vo": "",
  }],
  "addresse_gleich_bei_versicherten_objekten": true,
  "zusatzbausteinErweiterterStraftrechtsschutz": false,
  "zusatzbausteinPflegeberatung": false,

  ...CommonFormData
};
