import React, {useEffect, useState} from "react";
import FormWrapper from "../components/forms/FormWrapper";
import {insuranceTypes} from "../../_params/variables";
import {labels} from "../../_params/form_labels";
import DateInput from "../components/formitems/DateInput";
import SelectInput from "../components/formitems/SelectInput";
import {
	berufsgruppeRadioOptions,
	selbstbehaltOptions,
	vorschaedenOptions,
	zahlweiseOptions
} from "../utils/optionsSelection";
import validationSchemaPetLiability from "../utils/validationschemas/validationSchemaPetLiability";
import {getQuotePetLiability} from "../utils/getquotes/getQuotePetLiability";
import {setOrderPetLiability} from "../utils/setOrders/setOrderPetLiability";
import {petLiabilityFormdata} from "../utils/initialvalues/petLiabilityFormdata";
import RadioGroupInput from "../components/formitems/RadioGroupInput";
import Formm from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {Col, ListGroup} from "react-bootstrap";
import {FieldArray} from "formik";
import Select from "react-select";
import hunderasseData from "../utils/hunderasseData";
import {stylingFunc} from "../components/formitems/SelectAutocomplete";

const OFFER_URL = "PetLiabilityInsurance";
const labelsInsurance = labels.PetLiabilityInsurance;

const PetLiabilityPage = (props) => {
	const [hunderasseOptionsList, setHunderasseOptionsList] = useState([]);
	const [inputedHundename, setHundnameInput] = useState('');
	const [selectedHundrasse, setHundrasseSelected] = useState([]);

	useEffect(() => {
		const options = [];
		hunderasseData.forEach((element, index) => {
			options.push({value: element, label: element});
		});
		setHunderasseOptionsList(options)
	}, []);

	const CalculationFormRender = (formik) => {

		const {values, errors, touched} = formik;

		const handleHunderasseList = () => {
			let stringList = [];
			if (![null, [], undefined].includes(selectedHundrasse)) {
				selectedHundrasse.forEach((e, i) => {
					stringList.push(e.value);
				})
			}
			return stringList;
		};

		return (
				<>
					<DateInput item={"beginn"} label={labelsInsurance.beginn}/>

					{/*TODO*/}
					<Formm.Group as={Row} md="2">
						<Formm.Label column md={4}>{labelsInsurance.hundeliste}</Formm.Label>
						<Col md={8}>
							<FieldArray name='hundeliste' render={({push, remove}) => (
									<div>
										<Row>
											<Col md={5}>
												<Formm.Control
														value={inputedHundename}
														onChange={e => setHundnameInput(e.target.value)}
														placeholder='Hundename'
														isInvalid={errors.hundeliste && touched.hundeliste && true}
												/>
											</Col>
											<Col md={5}>
												<Select isMulti
														  options={hunderasseOptionsList}
														  value={selectedHundrasse}
														  onChange={v => {
															  if (selectedHundrasse?.length < 2 || selectedHundrasse === null) {
																  setHundrasseSelected(v)
															  }
														  }}
														  closeMenuOnSelect={false}
														  placeholder={'Hunderasse'}
														  styles={{
															  ...stylingFunc(errors.dogs, touched.dogs),
															  multiValue: (styles, {data}) => {
																  return {
																	  ...styles,
																	  backgroundColor: 'rgb(0, 184, 217)',
																  };
															  }
														  }}
												/>
											</Col>
											<Col md={2} className={'spezielleHunderasseSpalteWithBtn'}>
												<button type="button"
														  className={'btn btn-plus'}
														  onClick={() => {
															  if (values.hundeliste.length < 5) {
																  push({
																	  hundename: inputedHundename,
																	  hunderasse: handleHunderasseList()
																  });
																  setHundnameInput('');
																  setHundrasseSelected([]);
															  }
														  }}
														  disabled={values.hundeliste && (
																  values.hundeliste.length >= 5
																  || inputedHundename.length < 3
																  || selectedHundrasse?.length === 0
																  || selectedHundrasse === null
														  )
														  }
												> {'√'}
												</button>
											</Col>
										</Row>
										<Row>
											<small style={{
												paddingLeft: '20px',
												paddingTop: '5px',
												paddingBottom: '5px',
												color: '#37afc9'
											}}>
												<small>
													Bitte Eingaben zum Hund mit
													<span className={'btn btn-plus mini-btn'}
															style={{cursor: 'default'}}>{'√'}</span>
													hinzufügen. (Max. 2 Rassen)
												</small>
											</small>
										</Row>

										{errors.hundeliste && touched.hundeliste &&
										 <Row>
											 <small style={{
												 paddingLeft: '20px',
												 paddingTop: '5px',
												 paddingBottom: '5px',
												 color: 'rgb(220, 53, 69)'
											 }}>
												 {errors.hundeliste}
											 </small>
										 </Row>
										}

										<ListGroup>
											{values.hundeliste && values.hundeliste.map((dog, index) => (
													<React.Fragment>
														<ListGroup.Item key={index}>
															<Row>
																<Col md={1}
																	  className={'spezielleHunderasseSpalte'}><span
																		style={{fontWeight: "bold"}}>{'' + parseInt(
																												index + 1)}</span></Col>
																<Col md={4}
																	  style={{textAlign: 'left'}}>{dog.hundename}</Col>
																<Col md={5}
																	  className={'spezielleHunderasseSpalte'}>{dog.hunderasse ?
																		dog.hunderasse.toString().replace(/,/, ', ') :
																		<small
																				style={{color: 'rgb(220, 53, 69)'}}>Fehler!</small>
																}</Col>
																<Col md={2}
																	  className={'spezielleHunderasseSpalteWithBtn'}>
																	<button type="button"
																			  className={'btn btn-plus'}
																			  onClick={() => remove(index)}>-
																	</button>
																</Col>
															</Row>
															<hr/>
														</ListGroup.Item>
													</React.Fragment>
											))}
										</ListGroup>
									</div>
							)}/>
						</Col>
					</Formm.Group>

					<RadioGroupInput item={"berufsgruppe"} label={labelsInsurance.berufsgruppe}
										  options={berufsgruppeRadioOptions}/>
					<DateInput item={"geburtsdatum_VN"} label={labels.COMMON.KUNDENDATEN.geburtsdatum_VN}/>
					<SelectInput item={"vorschaeden"} label={labelsInsurance.vorschaeden}
									 options={vorschaedenOptions}/>
					<SelectInput item={"selbstbehalt"} label={labelsInsurance.selbstbehalt}
									 options={selbstbehaltOptions}/>
					<SelectInput item={"zahlungsweise"} label={labelsInsurance.zahlungsweise}
									 options={zahlweiseOptions}/>
				</>
		)
	}

	return (<>
				<FormWrapper
					validationSchemaCalculation={validationSchemaPetLiability}
					OFFER_URL={OFFER_URL}
					CalculationForm={CalculationFormRender}
					calcList={['XL', 'XXL']}
					getQuoteHandler={getQuotePetLiability}
					setOrder={setOrderPetLiability}
					initValuesForm={petLiabilityFormdata}
					tariffName={insuranceTypes.PET_LIABILITY} />
			</>
	)
}

export default PetLiabilityPage;
