import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {anredeOptions} from "../optionsSelection";

const errors_common = errors.COMMON;

const accidentCustomerDataSchema = () => Yup.object({
    vorname: Yup.string().min(3, errors_common.vorname).required(errors_common.vorname).nullable(),
    nachname: Yup.string().min(3, errors_common.nachname).required(errors_common.nachname).nullable(),
});

export default Yup.object({
    anrede: Yup.string().required(errors_common.anrede).oneOf(anredeOptions.map(item => {return item.value}),errors_common.anrede).nullable(),
    vorname: Yup.string().min(3, errors_common.vorname).required(errors_common.vorname).nullable(),
    nachname: Yup.string().min(3, errors_common.nachname).required(errors_common.nachname).nullable(),
    geburtsdatum_VN: Yup.date().required(errors_common.geburtsdatum_VN).nullable(),
    strasse: Yup.string().min(2, errors_common.strasse).required(errors_common.strasse).nullable(),
    hausnummer: Yup.string().typeError(errors_common.hausnummer).required(errors_common.hausnummer).nullable(),
    postleitzahl: Yup.string().matches(/^[0-9]{5}$/, errors_common.postleitzahl)
        .required(errors_common.postleitzahl).nullable(),
    ort: Yup.string().min(3, errors_common.ort).required(errors_common.ort).nullable(),
    telefon: Yup.string()
        .matches('\\+49[ ()]?(\\d){3,4}[ ()]?(\\d){6,7}', 'Bitte geben Sie die Telefonnummer in folgendem Format ein: +49 Vorwahl Telefonnummer \n(z.B. +49711998877)')
        .notRequired().nullable(),
    email: Yup.string().email(errors_common.email).required(errors_common.email).nullable(),

    kontoinhaber: Yup.string().min(3, errors_common.kontoinhaber).required(errors_common.kontoinhaber).nullable(),
    iban: Yup.string().min(22, errors_common.iban).max(22, errors_common.iban)
        .matches('DE[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?', 'Falcher IBAN  \n(f.e. DE01234567890123456789)')
        .required(errors_common.iban).nullable(),
//	bic: Yup.string().nullable(),
    vorversicherung: Yup.boolean().required(errors_common.vorversicherung).nullable(),
    gesellschaftsname: Yup.string()
        .when("vorversicherung", {
            is: true,
            then: schema => schema.required(errors_common.gesellschaftsname).nullable(),
            otherwise: schema => schema.nullable()
        }),
    vorversicherungsnummer: Yup.string()
        .when("vorversicherung", {
            is: true,
            then: schema => schema.required(errors_common.vorversicherungsnummer).nullable(),
            otherwise: schema => schema.nullable()
        }),
    gekuendigt_durch: Yup.string()
        .when("vorversicherung", {
            is: true,
            then: schema => schema.required(errors_common.gesellschaftsname).oneOf([
                  "versicherungsnehmer"
            ],
                  errors_common.gekuendigt_durchVvr).nullable(),
            otherwise: schema => schema.nullable()
        }),

    partner: Yup.object().when('haushalt', {
        is: value => value && ['mich_und_partner', 'mich_partner_und_kind(er)'].includes(value),
        then: accidentCustomerDataSchema
    }),

    kind1: Yup.object().when(['haushalt', 'anzahl_kinder'], {
        is: (val_1, val_2) => val_2 && val_2 > 0 && val_1 && ['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(val_1),
        then: accidentCustomerDataSchema
    }),

    kind2: Yup.object().when(['haushalt', 'anzahl_kinder'], {
        is: (val_1, val_2) => val_2 && val_2 > 1 && val_1 && ['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(val_1),
        then: accidentCustomerDataSchema
    }),

    kind3: Yup.object().when(['haushalt', 'anzahl_kinder'], {
        is: (val_1, val_2) => val_2 && val_2 > 2 && val_1 && ['mich_und_kind(er)', 'mich_partner_und_kind(er)', 'kind(er)'].includes(val_1),
        then: accidentCustomerDataSchema
    }),
});
