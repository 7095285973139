import * as Yup from "yup";
import {errors} from "../../../_params/form_errors";
import {
	dachausbauOptions_Wohngebaeude,
	gebaeudeartOptions_Wohngebaeude,
	gebaeudenutzungOptions_Wohngebaeude, nutzungsartOptions_Wohngebaeude,
	selbstbehaltOptions_Wohngebaeude
} from "../optionsSelection";

const errors_ResidentialInsurance = errors.ResidentialBuildingInsurance;
const errors_common = errors.COMMON;

const today = new Date();
let minBirthdate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

export default Yup.object().shape({
	beginn: Yup.date()
			.min(new Date(), errors_ResidentialInsurance.beginn)
			.required(errors_ResidentialInsurance.beginn).nullable(),
	geburtsdatum_VN: Yup.date()
			.max(minBirthdate, errors_ResidentialInsurance.geburtsdatum_min18)
			.required(errors_ResidentialInsurance.geburtsdatum)
			.nullable(),
	selbstbehalt: Yup.string().required(errors_ResidentialInsurance.selbstbehalt).oneOf(selbstbehaltOptions_Wohngebaeude.map(item => {return item.value.toString()}), errors_ResidentialInsurance.selbstbehalt).nullable(),
	zahlungsweise: Yup.string().required(errors_ResidentialInsurance.zahlungsweise).nullable(),

	gebaeudetyp: Yup.string().required(errors_ResidentialInsurance.gebaeudetyp).oneOf(gebaeudeartOptions_Wohngebaeude.map(item => {return item.value}), errors_ResidentialInsurance.gebaeudetyp).nullable(),
	bauartklasse: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			.min(0, errors_ResidentialInsurance.bauartklasse_min)
			.required(errors_ResidentialInsurance.bauartklasse).nullable(),
	gebaeudenutzung: Yup.string().required(errors_ResidentialInsurance.gebaeudenutzung).oneOf(gebaeudenutzungOptions_Wohngebaeude.map(item => {return item.value}), errors_ResidentialInsurance.gebaeudenutzung).nullable(),
	prozentualer_anteil_gewerblich_genutzt: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			.when('gebaeudenutzung', {
				is: value => value && value === 'auch_gewerblich',
				then: schema => schema.typeError(errors_ResidentialInsurance.prozentualer_anteil_gewerblich_genutzt)
						.min(0, errors_ResidentialInsurance.prozentualer_anteil_gewerblich_genutzt_min_max)
						.max(50, errors_ResidentialInsurance.prozentualer_anteil_gewerblich_genutzt_min_max)
						.required(errors_ResidentialInsurance.prozentualer_anteil_gewerblich_genutzt).nullable(),
				otherwise: schema => schema.notRequired().nullable()
			}),

	baujahr: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			.min(0, errors_ResidentialInsurance.baujahr_min)
			.required(errors_ResidentialInsurance.baujahr).nullable(),
	kernsanierungsjahr: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			.min(0, errors_ResidentialInsurance.kernsanierungsjahr_min)
			//			.required(errors_ResidentialInsurance.coreRenovationYear)
			.nullable(),
	nutzungsart: Yup.string().required(errors_ResidentialInsurance.nutzungsart).oneOf(nutzungsartOptions_Wohngebaeude.map(item => {return item.value}), errors_ResidentialInsurance.nutzungsart).nullable(),

	gebaeude_staendig_bewohnt: Yup.string()
			.required(errors_ResidentialInsurance.gebaeude_staendig_bewohnt).nullable()
			.oneOf(['ja'], errors_ResidentialInsurance.gebaeude_staendig_bewohnt),
	denkmalschutz: Yup.string(errors_ResidentialInsurance.denkmalschutz)
			.required(errors_ResidentialInsurance.denkmalschutz).nullable()
			.oneOf(['nein'], errors_ResidentialInsurance.denkmalschutz),

	anzahl_garage_oder_carport: Yup.number()
			.when('garage_oder_carport', {
				is: true,
				then: schema => schema.typeError(errors_ResidentialInsurance.anzahl_garagen)
						.min(1, errors_ResidentialInsurance.anzahl_garagen_min)
						.required(errors_ResidentialInsurance.anzahl_garagen).nullable(),
				otherwise: schema => schema.notRequired().nullable()
			}),
	dachausbau: Yup.string().required(errors_ResidentialInsurance.dachausbau).oneOf(dachausbauOptions_Wohngebaeude.map(item => {return item.value}), errors_ResidentialInsurance.dachausbau).nullable(),

	wohnflaeche_einschliesslich_gewerbeflaeche: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			.min(0, errors_ResidentialInsurance.wohnflaeche_oberhalb_boden_min)
			.required(errors_ResidentialInsurance.wohnflaeche_oberhalb_boden).nullable(),
	anzahl_stockwerke: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			.min(1, errors_ResidentialInsurance.anzahl_stockwerke_min_max)
			.max(10, errors_ResidentialInsurance.anzahl_stockwerke_min_max)
			.required(errors_ResidentialInsurance.anzahl_stockwerke).nullable(),
	wohnflaeche_keller: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			.when('unterkellerung', {
				is: true,
				then: schema => schema.required(errors_ResidentialInsurance.wohnflaeche_keller)
						.min(0, errors_ResidentialInsurance.wohnflaeche_keller_min)
						.nullable(),
				otherwise: schema => schema.notRequired().nullable()
			}),

	anzahl_vorschaeden_feuer: Yup.number()
			.typeError(errors_common.typeErrorNumber)
			//			.min(0, errors_ResidentialInsurance.fire_minZero)
			.oneOf([0, 1, 2], errors_ResidentialInsurance.vorschaeden_max2)
			.required(errors_ResidentialInsurance.feuer).nullable(),

	elementar: Yup.boolean()
			.when(['sturm_oder_hagel', 'leitungswasser'], {
				is: (isStorm, isTapWater) => !isStorm && !isTapWater,
				then: schema => schema.required(errors_ResidentialInsurance.weitere_gefahr_min).nullable()
						.oneOf([true], errors_ResidentialInsurance.weitere_gefahr_min),
				otherwise: schema => schema.notRequired()
			}),

	anzahl_vorschaeden_leitungswasser: Yup.number()
			.when('leitungswasser', {
				is: true,
				then: schema => schema.typeError(errors_common.typeErrorNumber)
						.oneOf([0, 1, 2], errors_ResidentialInsurance.vorschaeden_max2)
						.required(errors_ResidentialInsurance.leitungswasser).nullable(),
				otherwise: schema => schema.notRequired().nullable()
			}),

	anzahl_vorschaeden_sturm_hagel: Yup.number()
			.when('sturm_oder_hagel', {
				is: true,
				then: schema => schema.typeError(errors_common.typeErrorNumber)
						.oneOf([0, 1, 2], errors_ResidentialInsurance.vorschaeden_max2)
						.required(errors_ResidentialInsurance.sturm).nullable(),
				otherwise: schema => schema.notRequired().nullable()
			}),

	anzahl_vorschaeden_elementar: Yup.number()
			.when('elementar', {
				is: true,
				then: schema => schema.typeError(errors_common.typeErrorNumber)
						.oneOf([0], errors_ResidentialInsurance.vorschaeden_max0)
						.required(errors_ResidentialInsurance.elementar).nullable(),
				otherwise: schema => schema.notRequired().nullable()
			}),

	versichertes_objekt: Yup.object().shape({
		strasse_vo: Yup.string().min(2, errors_common.strasse).required(errors_common.strasse).nullable(),
		hausnr_vo: Yup.string().typeError(errors_common.hausnummer).required(errors_common.hausnummer)
				.nullable(),
		postleitzahl_vo: Yup.string().matches(/^[0-9]{5}$/, errors_common.postleitzahl)
				.required(errors_common.postleitzahl).nullable(),
		ort_vo: Yup.string().min(3, errors_common.ort).required(errors_common.ort).nullable(),
	})

});
