import {
	dateConverter,
	translateGebaeudeart_Hausratversicherung,
	translateZahlungsweise
} from "../translaterForAPI";

export const getQuoteHousehold = (values, deckung) => {

	let returnObject = {
		"salesPartnerNumber": sessionStorage.getItem("vermittlernummer"),
		"externalIdent": sessionStorage.getItem("externeIdent"),
		"policyholder": {
			"birthday": dateConverter(values.geburtsdatum_VN),
			"civilService": (values.berufsgruppe === 'oeffentlicher_dienst'),
			"address":
					{
						"street": values.strasse,
						"streetNumber": values.hausnummer,
						"postalCode": values.postleitzahl,
						"city": values.ort,
						"countryCode": "D"
					},
			"phoneNumber": values.telefon?.replace(/\s/g, ''),
			"emailAddress": values.email
		},
		"insuranceData": {
			"countOfPreviousDamages": values.vorschaeden,
			"paymentInterval": translateZahlungsweise(values.zahlungsweise),
			"insuranceBegin": dateConverter(values.beginn),
			"contractTermInYears": parseInt(values.versicherungsdauer),
			"tariffSelection": deckung,
			"deductible": values.selbstbehalt,
			"sumInsured": parseInt(values.versicherungssumme),
			"livingSpace": parseInt(values.wohnflaeche),
			"riskLocation": {
				"street": values.versichertes_objekt.strasse_vo,
				"streetNumber": values.versichertes_objekt.hausnr_vo,
				"postalCode": values.versichertes_objekt.postleitzahl_vo,
				"city": values.versichertes_objekt.ort_vo,
				"countryCode": "D"
			},
		}
	};

	if (values.fahrrad) {
		returnObject.insuranceData['bicycleInclusion'] = {'priceOfBike': values.fahrrad_versicherungssumme};
	}
	if (values.glas) {
		returnObject.insuranceData['glassInclusion'] = {'buildingType': translateGebaeudeart_Hausratversicherung(values.gebaeudeart)};
	}
	if (values.elementar) {
		returnObject.insuranceData['elementaryInclusion'] = {'countOfPreviousDamages': values.vorschaeden_elementar};
	}

	return returnObject;
};
