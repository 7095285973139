import {useFormikContext} from "formik";
import React, {useEffect, useState} from "react";
import {Button, Col, ListGroup, Spinner} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import FileSaver from 'file-saver';
import RadioGroupInput from "../formitems/RadioGroupInput";
import customerInformationDoc from "../../../adamriese_docs/Adam Riese Kundeninformation.pdf";
import AdamRiese_Vorvertragliche_Anzeigepflichten
	from "../../../adamriese_docs/Adam Riese Vorvertragliche Anzeigepflichten.pdf";
import AdamRiese_Datenschutzhinweise from "../../../adamriese_docs/Adam Riese Datenschutzhinweise.pdf";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import {docsListRender, offerNameRender} from "../../utils/formHelper";

const ZIP_FILE_NAME = "alleDokumente";

const ConfirmTermsForm = (props) => {

	const {handlePrev, tariffName, loading, tariffHighlights, offer} = props;


	const {values} = useFormikContext();

	const [documentsList, setDocumentsList] = useState([]);

	useEffect(() => {
		setDocumentsList(docsListRender(tariffName));
	}, []);

	const downloadAllFiles = async () => {
		let today = new Date().getFullYear() + '-' + parseInt(new Date().getMonth() + 1) + '-' + new Date().getDate();
		let count = 0;

		let zip = new JSZip();
		let zipFileName = offerNameRender(tariffName) + '_' + ZIP_FILE_NAME + '_' + today;

		documentsList.push(customerInformationDoc);
		documentsList.push(AdamRiese_Vorvertragliche_Anzeigepflichten);
		documentsList.push(AdamRiese_Datenschutzhinweise)
		documentsList.forEach(async (file, index) => {
			JSZipUtils.getBinaryContent(file, function (err, data) {
				if (err) {
					console.error(err)
					throw err;
				}
				let fileName = file.toString().substring(0, file.length - 13).replace('/static/media/', '').trim();
				zip.file(fileName + '.pdf', data, {binary: true});
				count++;

				if (count === documentsList.length) {
					zip.generateAsync({type: 'blob'})
							.then(function (content) {
								FileSaver.saveAs(content, zipFileName + ".zip");
							})
				}
			});
		});
	}

	return (
			<>
				<h3>Downloads</h3>
				<ListGroup>
					{
						documentsList?.map((element, i) =>
								<ListGroup.Item key={i}>
									<a href={element} className={'coloredLink'}
										download
									>
										{' ' + element.toString()
												.substring(0, element.length - 13)
												.replace('/static/media/', '')
												.trim()
										}
									</a>
								</ListGroup.Item>
						)
					}
					<ListGroup.Item>
						<a href={customerInformationDoc} style={{cursor: "pointer"}} className={'coloredLink'} download>
							<i className={'fa fa-file-pdf-o'}/>
							{' ' + customerInformationDoc.toString()
									.substring(0, customerInformationDoc.length - 13)
									.replace('/static/media/', '')
									.trim()}
						</a>
					</ListGroup.Item>
					<ListGroup.Item>
						<a href={AdamRiese_Vorvertragliche_Anzeigepflichten}  className={'coloredLink'}download>
							<i className={'fa fa-file-pdf-o'}/>
							{' ' + AdamRiese_Vorvertragliche_Anzeigepflichten.toString()
									.substring(0, AdamRiese_Vorvertragliche_Anzeigepflichten.length - 13)
									.replace('/static/media/', '')
									.trim()}
						</a>
					</ListGroup.Item>
					<ListGroup.Item>
						<a href={AdamRiese_Datenschutzhinweise} className={'coloredLink'} download>

							{' ' + AdamRiese_Datenschutzhinweise.toString()
									.substring(0, AdamRiese_Datenschutzhinweise.length - 13)
									.replace('/static/media/', '')
									.trim()}
						</a>
					</ListGroup.Item>
					<ListGroup.Item>
						<br />
						<span style={{cursor: "pointer", marginLeft:'-15px'}} onClick={downloadAllFiles}  className={'coloredLink'}>
							<span className={'ar-btn ar-icon-download'} style={{fontSize:'150%'}}/> Alle als ZIP-Datei herunterladen
						</span>
						<br />
						<br />
					</ListGroup.Item>
				</ListGroup>

				<div className={'dottedBox'}>
					<b>Bitte bestätigen Sie um abzuschließen: </b><br /><br />
					<RadioGroupInput
							checkbox
							item={"bedingungen"}
							label="Der Antragsteller hat alle vertragsrelevanten Dokumente erhalten und/oder gespeichert. Der Antragsteller möchte verbindlich und ohne Unterschrift diese Versicherung beantragen."
					/>
				</div>

				<Row>
					<Col md={4}/>
					<Col>
						<Button className="btn btn-primary btn-info"
								  onClick={handlePrev}
								  style={{width:'40px', padding: '0 26px 0 12px'}}
						>
							<div className={'ar-btn ar-btn-reward ar-icon-forward'}/>
						</Button>
						<Button
								disabled={!values.bedingungen}
								className={"btn btn-success"}
								type="submit"
						>
							Abschließen {loading && <Spinner animation="border" size="sm"/>}
						</Button>
					</Col>
				</Row>
			</>
	)
}

export default ConfirmTermsForm;
