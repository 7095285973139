import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import CalculationWrapperExtensions_ACC from "./CalculationWrapperExtensions_ACC";

const CalculationWrapper_ACC = (props) => {
	const {values, resultGUT, resultBESSER, resultRIESIG, calcLoading, goNextStep, config, setFieldValue, media} = props;

	const chooseTarif = (deckung) => {
		setFieldValue('tarif', deckung);
		setFieldValue('gesundheitsfragen', "");
	}


	return (
			<div>
				<Row className={'calculationWrapperRow'}>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "L" ? "markBox" : "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "L" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'} style={values.tarif === "L" ? {color: "#000", backgroundColor: "#4fd4e7"} : {}}>Gut</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>5 % Familienrabatt</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Suche, Rettung & Bergung bis  <b>50.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Kosmetische Operationen bis  <b>15.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>1 Jahr Vorsorgeschutz für neu hinzukommende
										Kinder & Ehepartner</li>

									<li><span className={'ar-icon ar-icon-checkmark'}/>Infektionen durch Tierbisse, z. B. von Zecken</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Zahnbehandlungen & Zahnersatz bis <b>15.000 €</b></li>
								</ul>

								<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>

									<li><span className={'ar-icon ar-icon-cross'}/>Barrierefreies Wohnen & Hilfsmittel</li>
									<li><span className={'ar-icon ar-icon-cross'}/>Kinderzusatzleistungen teilweise mitversichert</li>
									<li><span className={'ar-icon ar-icon-cross'}/>Besserstellungs-Garantie gegenüber Vorvertrag (Besitzstandgarantie)</li>
								</ul>

								<Button
										style={{width: '100%'}}
										onClick={() => chooseTarif("L")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XL" ? "markBox" : "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "XL" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'} style={values.tarif === "XL" ? {color: "#000", backgroundColor: "#4fd4e7"} : {}}>Besser</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>5 % Familienrabatt</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Suche, Rettung & Bergung bis  <b>50.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Kosmetische Operationen bis  <b>30.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>1 Jahr Vorsorgeschutz für neu hinzukommende
										Kinder & Ehepartner</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Infektionen durch Tierbisse, z. B. von Zecken</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Zahnbehandlungen & Zahnersatz bis <b>30.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Barrierefreies Wohnen & Hilfsmittel bis <b>10.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Kinderzusatzleistungen teilweise mitversichert</li>
								</ul>

								<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
									<li><span className={'ar-icon ar-icon-cross'}/>Besserstellungs-Garantie gegenüber Vorvertrag (Besitzstandgarantie)</li>
								</ul>
								<Button
										style={{width: '100%'}}
										onClick={() => chooseTarif("XL")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XXL" ? "markBox" : "markDisabled")}`}>
						<div className={`calculationWrapperBorder ${values.tarif === "XXL" ? "markAsChoosen" : ""}`}>
							<span className={'calculationWrapperHead'} style={values.tarif === "XXL" ? {color: "#000", backgroundColor: "#4fd4e7"} : {}}>Riesig</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>5 % Familienrabatt</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Suche, Rettung & Bergung bis  <b>1 Mio. €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Kosmetische Operationen bis  <b>1 Mio. €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>1 Jahr Vorsorgeschutz für neu hinzukommende
										Kinder & Ehepartner</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Infektionen durch Tierbisse, z. B. von Zecken</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Zahnbehandlungen & Zahnersatz bis <b>50.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Barrierefreies Wohnen & Hilfsmittel bis <b>50.000 €</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Kinderzusatzleistungen teilweise mitversichert</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Besserstellungs-Garantie gegenüber Vorvertrag (Besitzstandgarantie)</li>
								</ul>
								<Button
										style={{width: '100%', marginTop: "auto"}}
										onClick={() => chooseTarif("XXL")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
				</Row>
				{values.tarif && <CalculationWrapperExtensions_ACC
						values={values}
						resultGUT={resultGUT}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						calcLoading={calcLoading}
						goNextStep={goNextStep}
						media={media}
				/>}
			</div>
	)
}

export default CalculationWrapper_ACC;
