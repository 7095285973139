import React from "react";
import FormWrapper from "../components/forms/FormWrapper";
import {insuranceTypes} from "../../_params/variables";
import {labels} from "../../_params/form_labels";
import DateInput from "../components/formitems/DateInput";
import SelectInput from "../components/formitems/SelectInput";
import {
	bikeHerstellerOptions_Fahrradversicherung, bikeTypenOptions_Fahrradversicherung,
	conditionOptions_Fahrradversicherung,
	JaNeinSwitcher, nutzungOptions_Fahrradversicherung, typenOptions_Fahrradversicherung,
	vorschaedenOptions, zahlweiseOptions,
	zahlweiseOptions_Rechtsschutz
} from "../utils/optionsSelection";
import TextInput from "../components/formitems/TextInput";
import RadioGroupInput from "../components/formitems/RadioGroupInput";
import validationSchemaBicycleInsurance from "../utils/validationschemas/validationSchemaBicycleInsurance";
import {getQuoteBicycleInsurance} from "../utils/getquotes/getQuoteBicycleInsurance";
import {setOrderBicycleInsurance} from "../utils/setOrders/setOrderBicycleInsurance";
import {fahrradFormdata} from "../utils/initialvalues/fahrradFormdata";
import SelectAutocomplete from "../components/formitems/SelectAutocomplete";
import {Col, Form as Formm} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import berufData from "../utils/berufData";

const OFFER_URL = "BicycleInsurance";
const labelsInsurance = labels.BicycleInsurance;
const BicycleInsurancePage = () => {

	const CalculationFormRender = (formik) => {
		const {values} = formik;
		return (
				<>
					<DateInput item={"beginn"} label={labelsInsurance.beginn}/>
					<DateInput item={"geburtsdatum_VN"} label={labelsInsurance.geburtsdatum_VN}/>
					<TextInput item={"postleitzahl"} label={labelsInsurance.postleitzahl_VN}/>
					<SelectInput item={"versichertesfahrrad.typ"} label={labelsInsurance.typ}
									 options={typenOptions_Fahrradversicherung}/>
					<SelectInput item={"versichertesfahrrad.fahrradtyp"} label={labelsInsurance.fahrradtyp}
									 options={bikeTypenOptions_Fahrradversicherung}/>
					<SelectInput item={"versichertesfahrrad.nutzung"} label={labelsInsurance.nutzung}
									 options={nutzungOptions_Fahrradversicherung}/>
					<Formm.Group as={Row} md="2" controlId={"formField-" + "hersteller"}>
						<Formm.Label column md={4}>{labelsInsurance.fahrradhersteller}</Formm.Label>
						<Col md={8}>
							<SelectAutocomplete item="versichertesfahrrad.fahrradhersteller"
													  options={bikeHerstellerOptions_Fahrradversicherung}
													  convertValueFunc={value => ({"label": value})}/>
						</Col>
					</Formm.Group>

					<SelectInput item={"versichertesfahrrad.condition"} label={labelsInsurance.condition}
									 options={conditionOptions_Fahrradversicherung}/>
					<RadioGroupInput item={"versichertesfahrrad.originalrechnung"}
										  label={labelsInsurance.originalrechnung}
										  options={JaNeinSwitcher}
										  muiJaNeinSwitcher/>
					<DateInput item={"versichertesfahrrad.kaufdatum"} label={labelsInsurance.kaufdatum}/>
					<TextInput item={"versichertesfahrrad.kaufsumme"} label={labelsInsurance.kaufsumme}/>
					<RadioGroupInput item={"versichertesfahrrad.carbon"}
										  label={labelsInsurance.carbon}
										  options={JaNeinSwitcher}
										  muiJaNeinSwitcher/>
					<RadioGroupInput item={"versichertesfahrrad.leasing"}
										  label={labelsInsurance.leasing}
										  options={JaNeinSwitcher}
										  muiJaNeinSwitcher/>
					<TextInput item={"versichertesfahrrad.rahmennummer"} label={labelsInsurance.rahmenummer}/>
					<SelectInput item={"vorschaeden"} label={labelsInsurance.vorschaeden}
									 options={vorschaedenOptions}/>
					<SelectInput item={"zahlungsweise"} label={labelsInsurance.zahlungsweise}
									 options={zahlweiseOptions}/>
				</>
		)
	}

	return (<>
				<FormWrapper
						validationSchemaCalculation={validationSchemaBicycleInsurance}
						OFFER_URL={OFFER_URL}
						CalculationForm={CalculationFormRender}
						calcList={['XL', 'XXL']}
						getQuoteHandler={getQuoteBicycleInsurance}
						setOrder={setOrderBicycleInsurance}
						initValuesForm={fahrradFormdata}
						tariffName={insuranceTypes.BICYCLE}/>
			</>
	)
}

export default BicycleInsurancePage;
