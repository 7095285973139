import React, {useEffect, useRef, useState} from "react";
import {offerNameRender, offerNameRenderForPdf} from "../utils/formHelper";
import {labels} from "../../_params/form_labels";
import {
	accidentHouseholdOptions,
	anredeOptions,
	bauartklasseOptions_Wohngebaeude,
	beginn_unfall_tagegeld_options,
	berufsgruppeOptions_Rechtsschutz,
	berufsgruppeRadioOptions, bikeTypenOptions_Fahrradversicherung, conditionOptions_Fahrradversicherung,
	dachausbauOptions_Wohngebaeude,
	dailyHospitalBenefitSumInsuredOptions,
	deathSumInsuredOptions,
	familienstandOptions,
	gebaeudeartOptions_Wohngebaeude,
	gebaeudenutzungOptions_Wohngebaeude,
	gekundigtDurchRadioOptions,
	invalidityProgressionOptions,
	invaliditySumInsuredOptions, nutzungOptions_Fahrradversicherung,
	nutzungsartOptions_Wohngebaeude,
	pensionSumInsuredOptions,
	produktOptions_Rechtsschutz,
	selbstbehaltOptions_Wohngebaeude,
	sicknessDailyAllowanceSumInsuredOptions,
	transitionalBenefitSumInsuredOptions, typenOptions_Fahrradversicherung,
	versicherungsDauerOptions,
	zahlweiseOptions
} from "../utils/optionsSelection";
import {insuranceTypes} from "../../_params/variables";
import {useReactToPrint} from "react-to-print";
import {Spinner} from "react-bootstrap";

import leistungsuebersichtPHV from "../../adamriese_docs/PHV/Adam Riese Leistungsübersicht PHV.pdf";
import leistungsuebersichtRS from "../../adamriese_docs/RS/Adam Riese Leistungsübersicht RS.pdf";
import leistungsuebersichtFV from "../../adamriese_docs/FV/Adam Riese Leistungsübersicht FV.pdf";
import leistungsuebersichtHR from "../../adamriese_docs/HR/Adam Riese Leistungsübersicht HR.pdf";
import leistungsuebersichtHHV from "../../adamriese_docs/HHV/Adam Riese Leistungsübersicht HHV.pdf";
import leistungsuebersichtUV from "../../adamriese_docs/UV/Adam Riese Leistungsübersicht Unfall.pdf";
import leistungsuebersichtWOG from "../../adamriese_docs/WOG/Adam Riese Leistungsübersicht WOG.pdf";
import DownloadDataBox from "./DownloadDataBox";


const DownloadPdfForm = (props) => {

	const {offer, values, tariffPrices, hideCustomerData, offerSuccessful, resultData = false} = props;

	const [tariffLabels, setTariffLabels] = useState({});
	const [customerLabels, setCustomerLabels] = useState({});

	const [performanceOverviewDocs, setPerformanceOverviewDocs] = useState([]);

	const [loading, setLoading] = useState(false);

	const vm_nr = sessionStorage.getItem("vermittlernummer");

	let myWindow;

	const getConfig = () => {
		if (values.berechneter_preis && values.tarif) {
			return {

				markBox: values.tarif,
			}
		}
		else {
			return undefined
		}
	}

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onBeforeGetContent: () => {
			setLoading(true)
			// static id from react-to-print library
			let frame = document.getElementById("printWindow");
			if (frame) {
				frame.parentNode.removeChild(frame)
			}
		},
		print: target => {
			let documentTitle = `Berechnungsdaten_${offerNameRender(offer)}_${new Date().toISOString()
					.split("T")[0]}`;
			let documentTitleBefore = [window.document.title].slice()[0];

			// Print filename in Chrome
			target.ownerDocument.title = documentTitle;

			// Print filename in Firefox, Safari
			if (target.contentDocument) {
				target.contentDocument.title = documentTitle;
			}

			let htmlString = `<!DOCTYPE html><html>${target.contentDocument.head.outerHTML}${target.contentDocument.body.outerHTML}</html>`;
			myWindow.document.write(htmlString);
			myWindow.document.close();
			myWindow.focus();
			setTimeout(() => myWindow.print(), 500);

			myWindow.onafterprint = window.close;

			// Restore the page's original title information
			if (documentTitleBefore) {
				target.ownerDocument.title = documentTitleBefore;

				if (target.contentDocument) {
					target.contentDocument.title = documentTitleBefore;
				}
			}

			setLoading(false)
			return Promise.resolve();
		},

	});

	useEffect(() => {
		setTariffLabels(findCalculationDataLabels())
		setCustomerLabels(findCustomerDataLabels())
	}, [])

	const findCalculationDataLabels = () => {
		switch (offer) {
			case insuranceTypes.PRIVATE_LIABILITY:
				setPerformanceOverviewDocs([leistungsuebersichtPHV])
				return labels.LiabilityInsurance;

			case insuranceTypes.LEGAL_COSTS:
				setPerformanceOverviewDocs([leistungsuebersichtRS])
				return labels.LegalCostsInsurance;

			case insuranceTypes.BICYCLE:
				setPerformanceOverviewDocs([leistungsuebersichtFV])
				return labels.BicycleInsurance;

			case insuranceTypes.HOUSE_HOLD:
				setPerformanceOverviewDocs([leistungsuebersichtHR])
				return labels.HouseholdInsurance;

			case insuranceTypes.PET_LIABILITY:
				setPerformanceOverviewDocs([leistungsuebersichtHHV])
				return labels.PetLiabilityInsurance;

			case insuranceTypes.ACCIDENT:
				setPerformanceOverviewDocs([leistungsuebersichtUV])
				return labels.AccidentInsurance;

			case insuranceTypes.RESIDENTIAL:
				setPerformanceOverviewDocs([leistungsuebersichtWOG])
				return labels.ResidentialBuildingInsurance;

			default:
				setPerformanceOverviewDocs([])
				return {}
		}
	}

	const findCustomerDataLabels = () => {
		return {
			...labels.COMMON.KUNDENDATEN,
			...labels.COMMON.KONTODATEN,
			...labels.COMMON.VORVERSICHERUNGSDATEN
		}
	}

	const findParsedOption = (optionsList, value) => {
		return optionsList.filter(el => el.value === value)[0]?.label;
	}

	const PrintDocComponent = React.forwardRef((props, ref) => {

		const parseValue = (key, value) => {
			if (value instanceof Date) {
				return value.toLocaleDateString("ger", {day: "2-digit", month: "2-digit", year: "numeric"});
			}
			else if (value === true || value === "ja") {
				return "Ja";
			}
			else if (value === false || value === "nein") {
				return "Nein";
			}
			else {
				switch (key) {
					case "familienstand":
						return findParsedOption(familienstandOptions, value);

					case "selbstbehalt":
						return findParsedOption(selbstbehaltOptions_Wohngebaeude, parseInt(value));

					case "zahlungsweise":
						return findParsedOption(zahlweiseOptions, value);

					case "berufsgruppe":
						return findParsedOption(berufsgruppeRadioOptions, value);

					case "berufsgruppeVN":
						return findParsedOption(berufsgruppeOptions_Rechtsschutz, value);

					case "produkt":
						return findParsedOption(produktOptions_Rechtsschutz, value);

					case "gebaeudeart":
						return findParsedOption(gebaeudeartOptions_Wohngebaeude, value);

					case "gebaeudenutzung":
						return findParsedOption(gebaeudenutzungOptions_Wohngebaeude, value);

					case "gebaeudetyp":
						return findParsedOption(gebaeudeartOptions_Wohngebaeude, value);

					case "nutzungsart":
						return findParsedOption(nutzungsartOptions_Wohngebaeude, value);

					case "dachausbau":
						return findParsedOption(dachausbauOptions_Wohngebaeude, value);

					case "bauartklasse":
						return findParsedOption(bauartklasseOptions_Wohngebaeude, parseInt(value));

					case "haushalt":
						return findParsedOption(accidentHouseholdOptions, value)

					case "invaliditaet":
						return findParsedOption(invaliditySumInsuredOptions, parseInt(value))

					case "progression":
						return findParsedOption(invalidityProgressionOptions, parseInt(value))

					case "beginn_unfall_tagegeld":
						return findParsedOption(beginn_unfall_tagegeld_options, parseInt(value))

					case "unfall_tagegeld":
						return findParsedOption(sicknessDailyAllowanceSumInsuredOptions, parseInt(value))

					case "uebergangsleistung":
						return findParsedOption(transitionalBenefitSumInsuredOptions, parseInt(value))

					case "todesfallleistung":
						return findParsedOption(deathSumInsuredOptions, parseInt(value))

					case "krankenhaustagegeld":
						return findParsedOption(dailyHospitalBenefitSumInsuredOptions, parseInt(value));

					case "unfallrente":
						return findParsedOption(pensionSumInsuredOptions, parseInt(value))

					case "hunderasse":
						return value.join(', ')

					case "anrede": {
						return findParsedOption(anredeOptions, value)
					}

					case "versicherungsdauer": {
						return findParsedOption(versicherungsDauerOptions, value)
					}

					case "gekuendigt_durch": {
						return findParsedOption(gekundigtDurchRadioOptions, value)
					}

					case "geburtsdatum":
					case "geburtsdatum_VN":
					case "beginn": {
						return new Date(value).toLocaleDateString("ger", {day: "2-digit", month: "2-digit", year: "numeric"});
					}
					case "kaufdatum": {
						return new Date(value).toLocaleDateString("ger", {day: "2-digit", month: "2-digit", year: "numeric"});
					}

					case "nutzung": {
						return findParsedOption(nutzungOptions_Fahrradversicherung, value)
					}
					case "fahrradtyp": {
						return findParsedOption(bikeTypenOptions_Fahrradversicherung, value)
					}
					case "typ": {
						return findParsedOption(typenOptions_Fahrradversicherung, value)
					}
					case "condition": {
						return findParsedOption(conditionOptions_Fahrradversicherung, value)
					}

					default:
						return value;
				}
			}
		}

		const letterEndTable = <div className={"letterEnd"}>
			<table id="center">
				<tbody>
				<tr>
					<td>Dein Ansprechpartner:</td>
					<td>Dein Versicherer:</td>
					<td>Sitz der Gesellschaft: Kornwestheim</td>
					<td>Bankverbindung:</td>
				</tr>
				<tr>
					<td>Adam Riese GmbH</td>
					<td>Württembergische</td>
					<td>Registergericht:</td>
					<td>Landesbank Baden-Württemberg</td>
				</tr>
				<tr>
					<td>70801 Kornwestheim</td>
					<td>Versicherung AG</td>
					<td>AG Stuttgart HR B14327</td>
					<td>IBAN: DE61600501010008682107</td>
				</tr>
				<tr>
					<td></td>
					<td/>
					<td>Vers.-Steuernummer: 801/V90801006186</td>
					<td>BIC: SOLADEST600</td>
				</tr>
				<tr>
					<td/>
					<td/>
					<td>UST-ID: DE811128268</td>
					<td/>
				</tr>
				</tbody>
			</table>
		</div>

		const getListOfObjects = (values) => {
			let keyValue = [];

			for (const [key, value] of Object.entries(values)) {
				value !== null && value !== '' && (value !== false || key.includes("zusatzbaustein")) && keyValue.push({
					key: {...tariffLabels, ...customerLabels}[key], value: parseValue(key, value)
				})
			}
			return keyValue;
		}

		const formatValues = (table, listOfValues, objectName) => {
			getListOfObjects(listOfValues).forEach((obj, nr) => {
				if (obj.value instanceof Object) {
					if (formatValues([], obj.value).length > 0) {
						formatValues(table, obj.value, obj.key ?? objectName)
					}
				}
				else {
					table.push({
						objectName: objectName, key: obj.key, value: obj.value
					})
				}
			})
			return table;
		}

		const insertValuesIntoTable = (printed, tempPrint, tempTable, divKey, countRows) => {
			const singleRowKeys = ["Anrede", "Geburtsdatum Versicherungsnehmer"]

			for (let i = 0; i < tempTable.length; i++) {
				if (i < tempTable.length - 1 && !singleRowKeys.includes(tempTable[i].key)) {
					tempPrint.push(<tr key={i}>
						<td><b>{tempTable[i].key}</b></td>
						<td>{tempTable[i].value}</td>
						<td><b>{tempTable[i + 1].key}</b></td>
						<td>{tempTable[i + 1].value}</td>
					</tr>)
					i++;
				}
				else {
					// only left
					tempPrint.push(<tr key={i}>
						<td><b>{tempTable[i].key}</b></td>
						<td>{tempTable[i].value}</td>
						<td/>
						<td/>
					</tr>)
				}
			}
			countRows += 1 + tempPrint.length

			let helperClass = ""

			if (countRows > 30) {
				countRows = 0;
				helperClass = "pageBreakInTable"
			}

			printed.push(<div key={divKey} className={`${helperClass}`}>
				<div className="pageMarginTop"/>
				<table>
					<tbody>
					{tempPrint}
					</tbody>
				</table>
				<hr/>
			</div>)

			return countRows
		}

		const fillTableParts = (printed, dataTable, key, countRows) => {
			let tableCount = Array.from(new Set(dataTable.map(el => el.objectName)))

			tableCount.forEach(name => {
				let tempPrint = [];
				let tempTable = dataTable.filter(obj => obj.objectName === name);
				if (tempTable.length && name !== "normal") {
					tempPrint.push(<tr key={name}>
						<td className="headInTable"><b>{name}</b></td>
						<td/>
						<td/>
						<td/>
					</tr>)
				}
				countRows = insertValuesIntoTable(printed, tempPrint, tempTable, key + name, countRows)
			})

			return countRows
		}

		const printValues = () => {
			let table = formatValues([], values, "normal");
			const customerDataLabels = Object.values(customerLabels)
			const calculationDataLabels = Object.values(tariffLabels)
			let pageTables = []
			let countRows = 1;
			let accidentInsuranceTable = []
			table.forEach((t) => {
				if (customerDataLabels.includes(t.key) && t.objectName === "normal") {
					pageTables.push({page: 2, ...t});
					if (t.key === "E-Mail") {
						pageTables = pageTables.concat(accidentInsuranceTable)
					}
				}
				else if (["Vorname", "Nachname"].includes(t.key) && t.objectName !== "normal") {
					accidentInsuranceTable.push({page: 2, ...t})
				}
				if (calculationDataLabels.includes(t.key)) {
					pageTables.push({page: 1, ...t})
				}
			})
			const calculationDataTable = pageTables.filter((p) => p.page === 1)
			let printed = [];

			countRows = fillTableParts(printed, calculationDataTable, "", countRows)

			if (!hideCustomerData) {
				// sonderlocke für unfallversicherung
				let page2Table = pageTables.filter((p, i) => p.page === 2)
				if (values.haushalt?.includes("partner") || values.haushalt?.includes("kind")) {
					const split = page2Table.map(x => x.key).indexOf("E-Mail")
					countRows = fillTableParts(printed, page2Table.slice(0, split + 1), "customerAccident_", countRows)
					page2Table = page2Table.slice(split + 1, page2Table.length)
				}
				fillTableParts(printed, page2Table, "customer_", countRows)
			}

			return printed;
		}

		return <div className="printComponent" ref={ref}>
			<div className="printComponentMediaScreen">
				<div style={{width: "11%", margin: "auto"}}>
					<img src="https://www.adam-riese.de/assets/img/ar_logo.svg" alt="Adam Riese Logo"/>
				</div>
				<span className={'marker'} style={{
					fontSize: "20px",
					fontWeight: "700",
					marginTop: "20px",
					paddingRight: "5px",
					paddingLeft: "5px"
				}}>
					... Laden der Druckansicht
					<Spinner animation={"border"} size={"sm"} style={{padding: "8px", marginLeft: "12px"}}/>
					<span className="marker-background"
							style={{height: '13px', top: '10px'}}>
							<span className="bkg-left" style={{width: "15px"}}/>
							<span className="bkg-right" style={{width: "15px"}}/>
					</span>
				</span>
			</div>
			<div className="printComponentMediaPrint">
				<div className={"ar-logo-header"}>
					<img src="https://www.adam-riese.de/assets/img/ar_logo.svg" alt="Adam Riese Logo"/>
				</div>
				<p style={{fontSize: "20px", float: "right"}}>{`Vermittlernummer: ${vm_nr}`}</p>
				<div className="pdfCalculationData">
					<div className="headerOffer">
						<p className={'top'}>Berechnungsdaten zu Ihrer</p>
						<span className={'marker'}>
						{offerNameRenderForPdf(offer)}
							<span className="marker-background"
									style={{height: '25px', top: '10px'}}>
							<span className="bkg-left" style={{width: "30px"}}/>
							<span className="bkg-right" style={{width: "25px"}}/>
							</span>
						</span>
					</div>
					<hr/>
					{printValues()}
					<div className="dontBrake tariffHighlights">
						<div className="pageMarginTop"/>
						<div className="headerTariffs">
							{hideCustomerData ?
									<>
										<span>Unsere </span>
										<span>
												<span className={'marker'}>
													Tarifhighlights
													<span className="marker-background"
															style={{height: '15px', top: '9px'}}>
														<span className="bkg-left" style={{width: "30px"}}/>
														<span className="bkg-right" style={{width: "25px"}}/>
													</span>
												</span>
											</span>
										<span> im Überblick</span>
									</> :
									<>
										<span>Ihr </span>
										<span>
													<span className={'marker'}>
														gewählter Tarif
														<span className="marker-background"
																style={{height: '15px', top: '9px'}}>
															<span className="bkg-left" style={{width: "30px"}}/>
															<span className="bkg-right" style={{width: "25px"}}/>
														</span>
													</span>
												</span>
									</>
							}
						</div>
						{tariffPrices && tariffPrices(getConfig(), 'print')}
						<div className="tariffPricesInformationBox">
							<p>{`Es handelt sich um eine tagesaktuelle Berechnung vom ${new Date().toLocaleString("de-DE", { hour12: false })} Uhr mit einer Gültigkeitsdauer
										von 14 Tagen.`}</p>
							<p>Nach Ablauf der 14 Tage bitten wir Sie, eine Neuberechnung durchzuführen.</p>
							{offerSuccessful && <p><b>Ihr Antrag wurde am {new Date().toLocaleString("de-DE",
									{hour12: false})} Uhr erfolgreich versendet!</b></p>}
						</div>
					</div>
				</div>
				{letterEndTable}
			</div>
		</div>
	})

	return <>
		<div style={{
			marginBottom: "50px"
		}}>
			{!resultData && <div style={{marginBottom: "15px"}}>
				<DownloadDataBox
						offer={offer}
						values={values}
				/>
			</div>}

			<div style={resultData?{marginBottom: "15px", textAlign:'center'}:{marginBottom: "15px"}} className={'downloadLink'}>
				<span className={'ar-btn ar-icon-download'}/>
				{loading ?
						<span><Spinner animation="border" size="sm"/></span>
						: <span style={{marginLeft: 0}} onClick={() => {
							setTimeout(() => myWindow = window.open(), 450)
							handlePrint()
						}} title={'Berechnungsdaten als PDF downloadenN'}>
							<span>{resultData ? 'Antragsdaten als PDF downloaden' :'Berechnungsdaten als PDF downloaden'}</span>
						</span>
				}
			</div>
			{hideCustomerData && <>
				<div>
					{
						performanceOverviewDocs.map((element, i) =>
								<div style={{marginBottom: "15px"}} key={i}>
									<a href={element} download className="downloadLink" key={i}>
										<i className={'ar-btn ar-icon-download'}/>
										{'' + element.toString()
												.substring(0, element.length - 13)
												.replace('/static/media/', '')
												.trim()
										}
									</a>
								</div>
						)
					}
				</div>
			</>
			}
			<div style={{display: "none"}}>
				<PrintDocComponent ref={componentRef}/>
			</div>
		</div>
	</>
}

export default DownloadPdfForm;
