import React from "react";
import {translateZahlungsweiseToNoun} from "../utils/formHelper";

export const resultHandler = (r) => {
	if (r) {
		return parseFloat(r)?.toLocaleString('DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
	}
	return r;
};

const BerechnenResult = ({error, messages, result, zahlungsweise}) => {
	return (
			<React.Fragment>
				<div style={{paddingTop: '20px',color: (error) ? 'red' : ''}}>
					<React.Fragment>
						{(result && result.toString() !== 'NaN') &&
							<span className={'marker'}>
								<span className={'praemieSum'}>{resultHandler(result) + ' €'}</span>
									<span className="marker-background"
											style={{height: "30px", top: "10px"}}>
																<span className="bkg-left" style={{width: "34.7px"}}/>
																<span className="bkg-right" style={{width: "30.4px"}}/>
															</span>

							</span>
						}
						{(result && !error) ?
								  <span className={'praemieZahlweise'}> / {translateZahlungsweiseToNoun(zahlungsweise)}</span> :
								  <span className={'praemieZahlweise'}>keine Berechnung möglich</span>
						}
					</React.Fragment>
				</div>
			</React.Fragment>
	)
};

export default BerechnenResult;
