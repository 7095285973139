import {CommonFormData} from "./commonFormData";

export const residentialInsuranceFormData = {
  "beginn": null,
  "versicherungsdauer": "1",
  "tarif": "",
  // "vorschaeden": "",
  // "anzahl_vorschaeden_elementar": null,
  "selbstbehalt": "0",
  "zahlungsweise": "",

  "garage_oder_carport": false,
  "anzahl_garage_oder_carport": "",
  "gebaeude_staendig_bewohnt": 'ja',
  "gebaeudetyp": null,
  "gebaeudenutzung": null,
  "nutzungsart": null,
  "dachausbau": null,
  // "wohnflaeche_dachgeschoss": "",
  "anzahl_stockwerke": "",
  "wohnflaeche_keller": "",
  "prozentualer_anteil_gewerblich_genutzt": "",
  "bauartklasse": "1",
  "baujahr": "",
  "kernsanierungsjahr": "",
  "denkmalschutz": 'nein',
  // "anzahl_geschaefte": "
  "wohnflaeche_einschliesslich_gewerbeflaeche": "",
  "unterkellerung": false,
  "fussboden_oder_dachheizung": false,
  "waermepumpe_oder_solaranlage": false,

  "feuerschutz": true,
  "anzahl_vorschaeden_feuer": '0',
  "leitungswasser": true,
  "anzahl_vorschaeden_leitungswasser": '0',
  "sturm_oder_hagel": true,
  "anzahl_vorschaeden_sturm_hagel": '0',
  "elementar": false,
  "anzahl_vorschaeden_elementar": '0',

  "glas": false,
  "unbenannte_gefahren": false,
  "haustechnik": false,
  "ableitungsrohre": false,
  "photovoltaikanlage": false,
  "assistance": false,

  "versichertes_objekt": {
    "strasse_vo": "",
    "hausnr_vo": "",
    "postleitzahl_vo": "",
    "ort_vo": "",
  },

  ...CommonFormData
}
