export const CommonFormData = {
	"anrede": "",
	"vorname": "",
	"nachname": "",
	"geburtsdatum_VN": null,
	"strasse": "",
	"hausnummer": "",
	"postleitzahl": "",
	"ort": "",
	"telefon": "",
	"email": "",

	"kontoinhaber": "",
	"iban": "",
	// "bic": "",
	"vorversicherung": false,
	"gekuendigt_durch": null,
	"gesellschaftsname": null,
	"vorversicherungsnummer": null,

	"bedingungen": ""
}