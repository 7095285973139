export const errors = {

	LiabilityInsurance: {
		test: '(error test message from env)',

		beginn: 'Bitte  geben Sie den gewünschten Beginn ein. Der früheste Beginn ist morgen.',
		tarif: 'Bitte wählen Sie den gewünschten Tarif aus.',
		versicherungssumme: 'Bitte geben Sie die Versicherungssumme ein.',
		familienstand: 'Bitte wählen Sie den Familienstand des Versicherungsnehmers aus.',
		geburtsdatum: 'Bitte geben Sie Ihr Geburtsdatum ein.',
		geburtsdatum_min18: 'Bitte geben Sie Ihr Geburtsdatum (min. 18 Jahre alt) an.',
		//geburtsdatum_min18: 'Sie müssen mindestens 18 Jahre alt sein.',
		alterVN: 'Bitte geben Sie Ihr Alter in Jahren ein.',
		berufsgruppe: 'Bitte geben Sie an, ob der Versicherungsnehmer im öffentlichen Dienst tätig ist.',
		deutschePLZ: 'Bitte geben Sie eine gültige Postleitzahl ein.',
		vorschaeden: 'Bitte wählen Sie die Anzahl der Vorschäden aus. (0, 1 oder 2)',
		selbstbehalt: 'Bitte wählen Sie einen Selbstbehalt aus.',
		zahlungsweise: 'Bitte wählen Sie eine Zahlweise gemäß der Vorgabe aus.',

	},

	PetLiabilityInsurance: {
		test: '(error test message from env)',

		beginn: 'Bitte geben Sie den gewünschten Beginn ein. Der Beginn kann nicht in der Vergangenheit liegen.',
		geburtsdatum: 'Bitte geben Sie Ihr Geburtsdatum ein.',
		geburtsdatum_min18: 'Bitte geben Sie die Angabe zu Geburtsdatum (min. 18 Jahre alt) an.',
		tarif: 'Bitte wählen Sie den gewünschten Tarif aus.',
		hundeliste: 'Bitte geben Sie mind. 1 Hund mit Name und Rasse ein.',
		versicherungssumme: 'Bitte geben Sie die Versicherungssumme ein.',
		hundename: 'Bitte geben Sie die Hundename ein.',
		hunderasse: 'Bitte geben Sie die Hunderasse ein.',
		alterVN: 'Das Alter muss in Jahren angegeben sein.',
		berufsgruppe: 'Bitte wählen Sie aus, ob der Versicherungsnehmer im öffentlichen Dienst tätig ist.',
		deutschePLZ: 'Bitte geben Sie eine gültige Postleitzahl ein.',
		vorschaeden: 'Bitte wählen Sie die Anzahl der Vorschäden aus. (0, 1 oder 2)',
		selbstbehalt: 'Bitte wählen Sie einen Selbstbehalt aus.',
		zahlungsweise: 'Bitte wählen Sie eine Zahlweise gemäß Vorgabe aus.',

	},

	HouseholdInsurance: {
		test: '(error test message from env)',

		beginn: 'Bitte geben Sie den gewünschten Versicherungsbeginn ein.',
		geburtsdatum: 'Bitte geben Sie das Geburtsdatum ein.',
		geburtsdatum_min18: 'Bitte geben Sie die Angabe zu Geburtsdatum (min. 18 Jahre alt) an.',
		tarif: 'Bitte wählen Sie den gewünschten Tarif aus',
		wohnflaeche: 'Bitte geben Sie die Wohnfläche des zu versichernden Haushalts ein.',
		versicherungssumme: 'Bitte geben Sie die Versicherungssumme ein.',
		versicherungssummeMin: 'Ihr angegebener Wert liegt unter der empfohlenen Mindestversicherungssumme von 650 € pro qm. Somit kann kein Unterversicherungsverzicht vereinbart werden.',
		berufsgruppe: 'Bitte wählen Sie aus, ob der Versicherungsnehmer im öffentlichen Dienst tätig ist.',
		deutschePLZ: 'Bitte geben Sie eine gültige Postleitzahl ein.',
		vorschaeden: 'Bitte wählen Sie die Anzahl der Vorschäden aus. (0, 1 oder 2)',
		selbstbehalt: 'Bitte wählen Sie den gewünschten Selbstbehalt aus.',
		zahlungsweise: 'Bitte wählen Sie eine Zahlweise gemäß Vorgabe aus.',

		fahrrad: 'Fehler: Fahrradauswahl',
		fahrrad_versicherungssumme: 'Bitte geben Sie die Versicherungssumme des Fahrrads ein.',
		fahrrad_versicherungssumme_min: 'Bitte geben Sie die Versicherungssumme ein.',
		glas: 'Fehler: Glas',
		elementar: 'Fehler: Elementar',
		vorschaeden_elementar: 'Bitte geben Sie die Anzahl der Vorschäden an.',
		vorschaeden_elementar_min: 'Bitte geben Sie die Anzahl der Vorschäden an. (0, 1 oder 2).',
		gebaeudeart: 'Bitte wählen Sie die zu versichernde Gebäudeart aus.',

	},

	LegalCostsInsurance: {
		test: '(error test message from env)',
		beginn: 'Bitte  geben Sie den gewünschten Beginn an. Der Beginn kann nicht in der Vergangenheit liegen.',
		tarif: 'Bitte wählen Sie den gewünschten Tarif aus.',
		geburtsdatum: 'Bitte geben Sie das Geburtsdatum an.',
		geburtsdatum_min18: 'Bitte geben Sie die Angabe zu Geburtsdatum (min. 18 Jahre alt) an.',
		familienstand: 'Bitte geben Sie den Familienstand des Versicherungsnehmers an.',
		berufsgruppeVN: 'Bitte geben Sie die Berufsgruppe des Versicherungsnehmers an.',
		berufsgruppe_partner: 'Bitte geben Sie an, ob der Partner im öffentlichen Dienst tätig ist.',
		produkt: 'Bitte wählen Sie das gewünschte Produkt aus.',
		anzahl_wohnungen: 'Bitte geben Sie die Anzahl der Wohnungen an. (1, 2 oder 3)',
		vorschaeden: 'Bitte geben Sie die Anzahl der Vorschäden an. (0, 1 oder 2)',
		vorschaeden_max2: 'Der Antrag kann aufgrund der Anzahl der Vorschäden nicht angenommen werden.',
		schadenfreieJahre: 'Bitte geben Sie die Anzahl der schadensfreien Jahre an.',
		selbstbehalt: 'Bitte wählen Sie einen Selbstbehalt aus.',
		zahlungsweise: 'Bitte wählen Sie eine Zahlweise gemäß Vorgabe aus.',

	},

	BicycleInsurance: {
		test: '(error test message from env)',
		beginn: 'Bitte  geben Sie den gewünschten Versicherungsbeginn an. Der Versicherungsbeginn kann nicht in der Vergangenheit liegen.',
		nutzung: 'Bitte geben Sie die Nutzung an.',
		nutzung_privat: 'Es kann nur "privat" versichert werden.',
		tarif: 'Bitte wählen Sie den gewünschten Tarif aus.',
		geburtsdatum: 'Bitte geben Sie das Geburtsdatum an.',
		geburtsdatum_min18: 'Bitte geben Sie die Angabe zu Geburtsdatum (min. 18 Jahre alt) an.',
		familienstand: 'Bitte geben Sie den Familienstand des Versicherungsnehmers an.',
		berufsgruppe: 'Bitte wählen Sie aus, ob der Versicherungsnehmer im öffentlichen Dienst tätig ist.',
		deutschePLZ: 'Bitte geben Sie eine gültige Postleitzahl ein.',
		vorschaeden: 'Bitte geben Sie die Anzahl der Vorschäden an. (0, 1 oder 2)',
		vorschaeden_max2: 'Der Antrag kann aufgrund der Anzahl der Vorschäden nicht angenommen werden.',
		zahlungsweise: 'Bitte wählen Sie eine Zahlweise gemäß Vorgabe aus.',
		kaufsumme: 'Bitte geben Sie einen Kaufpreis ein.',
		originalrechnung: 'Bitte geben Sie an, ob Sie die Originalrechnung noch haben.',
		originalrechnung_false: 'Abschluss ist ohne Originalrechnung nicht möglich.',
		leasing: 'Bitte geben Sie an, ob das E-Bike geleased ist.',
		carbon: 'Bitte geben Sie an, ob der Fahrradrahmen aus Karbon ist.',
		condition: 'Bitte geben Sie den Zustand Ihres E-Bikes an.',
		typ: 'Bitte geben Sie den Typ Ihres E-Bikes an.',
		fahrradtyp: 'Bitte geben Sie den Fahrradtyp Ihres E-Bikes an.',
		fahrradhersteller: 'Bitte geben Sie den Fahrradhersteller E-Bikes an.',
		kaufdatum: 'Bitte  geben Sie das Kaufdatum an. Das Datum darf nur in der vergangenheit liegen.',
		rahmennummer: 'Bitte  geben Sie die Rahmennummer an.',

	},

	AccidentInsurance: {
		beginn: 'Bitte  geben Sie den gewünschten Beginn an. Der Beginn kann nicht in der Vergangenheit liegen.',
		tarif: 'Bitte wählen Sie den gewünschten Tarif aus.',
		zahlungsweise: 'Bitte wählen Sie eine Zahlweise gemäß Vorgabe aus.',
		geburtsdatum: 'Bitte geben Sie das Geburtsdatum an.',
		geburtsdatum_min18: 'Bitte geben Sie die Angabe zu Geburtsdatum (min. 18 Jahre alt) an.',
		beruf: 'Bitte geben Sie den Beruf an.',
		berufsgruppe: 'Bitte geben Sie die Berufsgruppe an.',
		invaliditaet: "Bitte wählen Sie eine gültige Versicherungssumme aus.",
		progression: "Bitte wählen Sie eine gültige Progression aus.",
		unfallrente: "Bitte wählen Sie eine gültige Unfallrente aus.",
		krankenhaustagegeld: "Bitte wählen Sie eine gültige Summe für das Krankenhaustagegeld aus.",
		todesfallleistung: "Bitte wählen Sie eine gültige Summe für die Todesfallleistung aus.",
		gipsgeld: "Bitte wählen Sie eine Option für Gipsgeld.",
		notfall_assistent: "Bitte wählen Sie eine Option für Notfall-Assistent.",
		haushalt: 'Bitte wählen Sie die Personen, die Sie versichern möchten.',
		gesundheitsfragen: 'Bitte bestätigen Sie die Antwort auf die Frage.',
		uebergangsleistung: "Bitte wählen Sie eine gültige Summe für den Baustein Übergangsleistung aus.",
		unfall_tagegeld: "Bitte wählen Sie eine gültige Summe für den Baustein Unfalltagegeld aus.",
		beginn_unfall_tagegeld: "Bitte wählen Sie, ab wann die Leistung getragen werden soll.",
		anzahl_kinder: "Die Anzahl der Kinder muss 1, 2 oder 3 sein",
		dynamik: "Bitte wählen Sie eine Option"
	},

	ResidentialBuildingInsurance: {

		beginn: 'Bitte geben Sie die Angabe zum gewünschten Beginn an. Der Beginn kann nicht in der Vergangenheit liegen.',
		tarif: 'Bitte geben Sie die Angabe zum gewünschten Tarif an.',
		zahlungsweise: 'Bitte geben Sie die Angabe zur Zahlweise gemäß Vorgabe an.',
		geburtsdatum: 'Bitte geben Sie die Angabe zum Geburtsdatum an.',
		geburtsdatum_min18: 'Bitte geben Sie die Angabe zum Geburtsdatum (min. 18 Jahre alt) an.',
		vorschaeden: 'Bitte geben Sie die Angabe zur Anzahl der Vorschäden an. (0, 1 oder 2)',
		anzahl_vorschaeden_elementar: 'Bitte geben Sie die Angabe zur Anzahl der Elementarschäden an.',
		anzahl_vorschaeden_elementar_zu_hoch: 'Ab 1 Vorschaden kann Elementar nicht mehr versichert werden.',
		selbstbehalt: 'Bitte geben Sie die Angabe zum Selbstbehalt an.',

		ableitungsrohre: 'Bitte geben Sie die Angabe zu Ableitungsrohre an.',
		photovoltaikanlage: 'Bitte geben Sie die Angabe zur Photovoltaikanlage an.',
		feuerschale: 'Bitte geben Sie die Angabe zur Feuerschale an.',
		hilfeleistungen: 'Bitte geben Sie die Angabe zur Hilfeleistung an.',

		gebaeudetyp: 'Bitte geben Sie die Angabe zum Gebäudetyp an.',
		gebaeudenutzung: 'Bitte geben Sie die Angabe zur Gebäudenutzung an.',
		bauartklasse: 'Bitte geben Sie die Angabe zur Bauartklasse an.',
		anzahl_garagen: 'Bitte geben Sie die Angabe zur Anzahl der Garagen an.',
		anzahl_garagen_min: 'Bitte geben Sie die Angabe zur Anzahl der Garagen (min. 1) an.',
		gebaeude_staendig_bewohnt: 'Es können in diesem Tarif nur ständig bewohnte Gebäude versichert werden.',
		denkmalschutz: 'Es können in diesem Tarif keine denkmalgeschützten Gebäude versichert werden.',
		nutzungsart: 'Bitte geben Sie die Angabe zur Art der Nutzung an.',
		dachausbau: 'Bitte geben Sie die Angabe zum Dachausbau an.',
		wohnflaeche_dachgeschoss: 'Bitte geben Sie die Angabe zur Wohnfläche des Dachgeschosses (in qm) an.',
		wohnflaeche_oberhalb_boden: 'Bitte geben Sie die Angabe zur Wohnfläche oberhalb des Bodens an.',
		anzahl_stockwerke: 'Bitte geben Sie die Angabe zur Anzahl der Stockwerke an.',
		anzahl_stockwerke_min_max: 'Bitte geben Sie die Angabe zur Anzahl der Stockwerke (min. 1 / max. 10) an.',
		wohnflaeche_oberhalb_boden_min: 'Bitte geben Sie die Angabe zur Wohnfläche oberhalb Boden an.',
		wohnflaeche_keller: 'Bitte geben Sie die Angabe zur Wohnfläche des Kellers an.',
		wohnflaeche_keller_min: 'Bitte geben Sie die Angabe zur Wohnfläche des Kellers an.',
		prozentualer_anteil_gewerblich_genutzt: 'Bitte geben Sie die Angabe zu Anteil gewerblich genutzt an.',
		prozentualer_anteil_gewerblich_genutzt_min_max: 'Bitte geben Sie die Angabe zu Anteil gewerblich genutzt an. (min. 0% / max. 50%)',
		bauartklasse_min: 'Bitte geben Sie die Angabe zur Bauartklasse an.',
		baujahr: 'Bitte geben Sie die Angabe zum Baujahr an.',
		baujahr_min: 'Bitte geben Sie die Angabe zum Baujahr an.',
		kernsanierungsjahr: 'Bitte geben Sie die Angabe zum Jahr der Kernsanierung an.',
		kernsanierungsjahr_min: 'Bitte geben Sie die Angabe zum Jahr der Kernsanierung an.',
		anzahl_geschaefte: 'Bitte geben Sie die Angabe zur Anzahl der Geschäfte an.',
		anzahl_geschaefte_min: 'Bitte geben Sie die Angabe zur Anzahl der Geschäfte an.',
		unterkellerung: 'Bitte geben Sie die Angabe zur Unterkellerung an.',
		dachform: 'Bitte geben Sie die Angabe zur Dachform an.',
		solaranlage: 'Bitte geben Sie die Angabe zur Solaranlage an.',
		waermepumpe: 'Bitte geben Sie die Angabe zur Wärmepumpe an.',
		fussbodenheizung: 'Bitte geben Sie die Angabe zur Fußbodenheizung an.',
		dachheizung: 'Bitte geben Sie die Angabe zur Dachheizung an.',

		weitere_gefahr_min: 'Mindestens 1 weitere versicherte Gefahr muss gewählt werden.',

		vorschaeden_max2: 'Bei 3 oder mehr Vorschäden ist eine Berechnung nicht möglich.',
		vorschaeden_max0: 'Bei 1 oder mehr Vorschäden ist eine Berechnung nicht möglich.',
		feuer: 'Bitte geben Sie die Angabe zu Feuer an.',
		feuer_min: 'Bitte geben Sie die Angabe zu Feuer an.',
		glas: 'Bitte geben Sie die Angabe zu Glas an.',
		glas_min: 'Bitte geben Sie die Angabe zu Glas an.',
		leitungswasser: 'Bitte geben Sie die Angabe zu Leitungswasser an.',
		leitungswasser_min: 'Bitte geben Sie die Angabe zu Leitungswasser an.',
		sturm: 'Bitte geben Sie die Angabe zu Sturm an.',
		sturm_min: 'Bitte geben Sie die Angabe zu Sturm an.',
		elementar: 'Bitte geben Sie die Angabe zu Elementar an.',
		elementar_min: 'Bitte geben Sie die Angabe zu Elementar an.',
		unbenannte_gefahren: 'Bitte geben Sie die Angabe zu unbenannte Gefahren an.',
		unbenannte_gefahren_min: 'Bitte geben Sie die Angabe zu unbenannte Gefahren an.',
		haustechnik: 'Bitte geben Sie die Angabe zu Haustechnik an.',
		haustechnik_min: 'Bitte geben Sie die Angabe zu Haustechnik an.',
	},

	COMMON: {
		anrede: 'Bitte geben Sie die Anrede des Versicherungsnehmers an.',
		vorname: 'Bitte geben Sie den Vornamen an. Er muss mind. aus 3 Zeichen bestehen.',
		nachname: 'Bitte geben Sie den Namen an. Er muss mind. aus 3 Zeichen bestehen.',
		geburtsdatum_VN: 'Bitte geben Sie das Geburtsdatum des Versicherungsnehmers ein.',
		strasse: 'Bitte geben Sie die Straße an. Sie muss mind. aus 2 Zeichen bestehen.',
		hausnummer: 'Bitte geben Sie die Hausnummer an.',
		postleitzahl: 'Bitte geben Sie eine Postleitzahl an.',
		ort: 'Bitte geben Sie den Ort an. Er muss mind. aus 3 Zeichen bestehen.',
		telefon: 'Fehler: Telefonnummer',
		email: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',

		kontoinhaber: 'Bitte geben Sie den Kontoinhaber an. Es sind mind. 3 Zeichen notwendig.',
		iban: 'Bitte geben Sie eine gültige deutsche IBAN an.',
		bic: 'Bitte geben Sie eine gültige zugehörige BIC zu der eingegebenen IBAN an.',
		vorversicherung: 'Fehler: Vorversicherung',
		gesellschaftsname: 'Bitte geben Sie den Namen der Gesellschaft an.',
		vorversicherungsname: 'Bitte geben Sie den Namen der Vorversicherung an. Es sind mind. 3 Zeichen notwendig.',
		vorversicherungsnummer: 'Bitte geben Sie die Versicherungsnummer der Vorversicherung an.',
		gekuendigt_durch: 'Bitte geben Sie an, durch wen die Vorversicherung gekündigt worden ist.',
		gekuendigt_durchVvr: 'Der Antrag kann aufgrund der Kündigung durch den Vorversicherer nicht angenommen werden.',

		bedingungen: 'Check Bitte!',

		typeErrorNumber: 'Bitte geben Sie eine Nummer an.'
	}

};
