import React from "react";
import FormWrapper from "../components/forms/FormWrapper";
import validationSchemaLegalCost from "../utils/validationschemas/validationSchemaLegalCost";
import {insuranceTypes} from "../../_params/variables";
import {labels} from "../../_params/form_labels";
import DateInput from "../components/formitems/DateInput";
import SelectInput from "../components/formitems/SelectInput";
import {
	berufsgruppeOptions_Rechtsschutz,
	familienstandOptions,
	produktOptions_Rechtsschutz,
	schadenfreieJahre_Rechtsschutz,
	selbstbehaltOptions_Rechtsschutz,
	vorschaedenOptions_Rechtsschutz,
	zahlweiseOptions_Rechtsschutz
} from "../utils/optionsSelection";
import TextInput from "../components/formitems/TextInput";
import {rechtsschutzFormdata} from "../utils/initialvalues/rechtsschutzFormdata";
import {getQuoteLegalCosts} from "../utils/getquotes/getQuoteLegalCosts";
import {setOrderLegalCosts} from "../utils/setOrders/setOrderLegalCosts";
import RadioGroupInput from "../components/formitems/RadioGroupInput";
import _ from "lodash";

const OFFER_URL = "LegalCostsInsurance";
const labelsInsurance = labels.LegalCostsInsurance;

export const requiring_address_products = ['PBV+Wohn+Vermiet', 'PBV+Vermiet'];

const LegalCostsPage = () => {
	const CalculationFormRender = (formik) => {
		const {values} = formik;

		const addressRequired = requiring_address_products.includes(values.produkt)

		return (
				<>
					<DateInput item={"beginn"} label={labelsInsurance.beginn}/>
					<SelectInput item={"familienstand"} label={labelsInsurance.familienstand}
									 options={familienstandOptions}/>
					<DateInput item={"geburtsdatum_VN"} label={labels.COMMON.KUNDENDATEN.geburtsdatum_VN}/>
					<TextInput item={"postleitzahl"} label={labels.COMMON.KUNDENDATEN.postleitzahl_VN}/>
					<SelectInput item={"berufsgruppeVN"} label={labelsInsurance.berufsgruppeVN}
									 options={berufsgruppeOptions_Rechtsschutz}/>
					<SelectInput item={"produkt"} label={labelsInsurance.produkt}
									 options={produktOptions_Rechtsschutz}/>
					{addressRequired &&
					 <TextInput item={"anzahl_wohnungen"} label={labelsInsurance.anzahl_wohnungen}/>
					}
					<SelectInput item={"vorschaeden"} label={labelsInsurance.vorschaeden}
									 options={vorschaedenOptions_Rechtsschutz}/>
					<SelectInput item={"schadenfreieJahre"} label={labelsInsurance.schadenfreieJahre}
									 options={schadenfreieJahre_Rechtsschutz}/>
					<SelectInput item={"selbstbehalt"} label={labelsInsurance.selbstbehalt}
									 options={selbstbehaltOptions_Rechtsschutz}/>
					<SelectInput item={"zahlungsweise"} label={labelsInsurance.zahlungsweise}
									 options={zahlweiseOptions_Rechtsschutz}/>
					{addressRequired && values.anzahl_wohnungen > 0 && <>
						<hr style={{backgroundColor: '#fff'}}/>
						<h3>Über deine vermieteten Wohneinheiten</h3>
						{_.range(0, Number(values.anzahl_wohnungen), 1).map(obj => <React.Fragment key={obj}>
							<h4>{labelsInsurance.versicherte_objekte} {obj+1}</h4>
							<TextInput disabled={obj > 0 && values.addresse_gleich_bei_versicherten_objekten} item={`versicherte_objekte[${obj}].strasse_vo`} label={labelsInsurance.strasse_vo}/>
							<TextInput disabled={obj > 0 && values.addresse_gleich_bei_versicherten_objekten} item={`versicherte_objekte[${obj}].hausnr_vo`} label={labelsInsurance.hausnr_vo}/>
							<TextInput disabled={obj > 0 && values.addresse_gleich_bei_versicherten_objekten} item={`versicherte_objekte[${obj}].postleitzahl_vo`} label={labelsInsurance.postleitzahl_vo}/>
							<TextInput disabled={obj > 0 && values.addresse_gleich_bei_versicherten_objekten} item={`versicherte_objekte[${obj}].ort_vo`} label={labelsInsurance.ort_vo}/>
							{obj === 0 && <RadioGroupInput
									checkbox
									item={"addresse_gleich_bei_versicherten_objekten"}
									label={<h6>Diese angegebene Adresse gilt für alle vermieteten Wohneinheiten.</h6>}
							/>}
						</React.Fragment>)}
					</>}
				</>
		)
	}

	return (<>
				<FormWrapper
					validationSchemaCalculation={validationSchemaLegalCost}
					OFFER_URL={OFFER_URL}
					CalculationForm={CalculationFormRender}
					calcList={['L', 'XL', 'XXL']}
					getQuoteHandler={getQuoteLegalCosts}
					setOrder={setOrderLegalCosts}
					initValuesForm={rechtsschutzFormdata}
					tariffName={insuranceTypes.LEGAL_COSTS}/>
				</>
	)
}

export default LegalCostsPage;
