import React from "react";
import {Button, Col, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import BerechnenResult from "../BerechnenResult";

const CalculationWrapper_HH = (props) => {
	const {values, resultGUT, resultBESSER, resultRIESIG, calcLoading, goNextStep, config} = props;
	const placement = 'top'

	const allResultsHaveLoaded = resultGUT && resultBESSER && resultRIESIG;
	return (
			<div>
				<Row className={'calculationWrapperRow'}>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "L" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>Gut</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper'}>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Höhe des Schutzes frei wählbar</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Diebstahl aus Hotelzimmer weltweit
								</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
							</ul>
							<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
								<li><span className={'ar-icon ar-icon-cross'}/>Phishing, Pharming und Skimming beim
									Online-Banking <b>bis 5000 €</b>
								</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Taschen- und Täuschungsdiebstahl
								</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Besitzstands-Garantie</li>
								<li><span className={'ar-icon ar-icon-cross'}/>

									<OverlayTrigger
											placement={placement}
											overlay={
												<Tooltip id={'tooltip-gibsgeld'}>
													Sollte der Schaden nicht in unserem Tarif
													versichert sein, zahlen wir trotzdem - wenn ein anderer Versicherungsanbieter am
													deutschen Markt diesen Schaden standardmäßig versichert.
												</Tooltip>
											}
									>
										<span className={'calculationTooltip'}>Best-Leistungs-Garantie</span>
									</OverlayTrigger>

								</li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.L ?? <BerechnenResult error={resultGUT.calculationError}
																		  result={resultGUT.calculationResult.grossPremium}
																		  messages={resultGUT.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultGUT || resultGUT.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('L', resultGUT.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XL" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>Besser</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper'}>

								<li><span className={'ar-icon ar-icon-checkmark'}/>Höhe des Schutzes frei wählbar</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Diebstahl aus Hotelzimmer weltweit
								</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Phishing, Pharming und Skimming beim
									Online-Banking <b>bis 5000 €</b>
								</li>

							</ul>
							<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>

								<li><span className={'ar-icon ar-icon-cross'}/>Taschen- und Täuschungsdiebstahl
								</li>
								<li><span className={'ar-icon ar-icon-cross'}/>Besitzstands-Garantie</li>
								<li><span className={'ar-icon ar-icon-cross'}/>

									<OverlayTrigger
											placement={placement}
											overlay={
												<Tooltip id={'tooltip-gibsgeld'}>
													Sollte der Schaden nicht in unserem Tarif
													versichert sein, zahlen wir trotzdem - wenn ein anderer Versicherungsanbieter am
													deutschen Markt diesen Schaden standardmäßig versichert.
												</Tooltip>
											}
									>
										<span className={'calculationTooltip'}>Best-Leistungs-Garantie</span>
									</OverlayTrigger>

								</li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.XL ?? <BerechnenResult error={resultBESSER.calculationError}
																		  result={resultBESSER.calculationResult.grossPremium}
																		  messages={resultBESSER.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultBESSER || resultBESSER.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('XL', resultBESSER.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
					<Col sm={12} md={4} className={`calculationWrapperCell ${config && (config?.markBox === "XXL" ? "markBox" : "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
						<span className={'calculationWrapperHead'}>Riesig</span>
						<div className={'calculationWrapperBody'}>
							<ul className={'calculationWrapper'}>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Höhe des Schutzes frei wählbar</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Diebstahl aus Hotelzimmer weltweit
								</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Phishing, Pharming und Skimming beim
									Online-Banking
								</li>

								<li><span className={'ar-icon ar-icon-checkmark'}/>Taschen- und Täuschungsdiebstahl
								</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>Besitzstands-Garantie</li>
								<li><span className={'ar-icon ar-icon-checkmark'}/>

									<OverlayTrigger
											placement={placement}
											overlay={
												<Tooltip id={'tooltip-gibsgeld'}>
													Sollte der Schaden nicht in unserem Tarif
													versichert sein, zahlen wir trotzdem - wenn ein anderer Versicherungsanbieter am
													deutschen Markt diesen Schaden standardmäßig versichert.
												</Tooltip>
											}
									>
										<span className={'calculationTooltip'}>Best-Leistungs-Garantie</span>
									</OverlayTrigger>

								</li>
							</ul>
							{
								calcLoading ?
										<Spinner animation="border" variant="info"/>
										:
										<div>
											{
												allResultsHaveLoaded &&
												<>
												{
													config?.XXL ?? <BerechnenResult error={resultRIESIG.calculationError}
																		  result={resultRIESIG.calculationResult.grossPremium}
																		  messages={resultRIESIG.calculationMessages}
																		  zahlungsweise={values.zahlungsweise}
													/>
												}
													<Button
															style={{width: '100%'}}
															disabled={!resultRIESIG || resultRIESIG.calculationResult.grossPremium === 'NaN'}
															onClick={() => goNextStep('XXL', resultRIESIG.calculationResult)}
															className="btn btn-weiter btn-weiter-tarif"
													>
														Tarif auswählen
													</Button>
												</>
											}
										</div>
							}
						</div>
						</div>
					</Col>
				</Row>
			</div>
	)
}

export default CalculationWrapper_HH;
