import {dateConverter, translateFamilienstand, translateZahlungsweise} from "../translaterForAPI";

export const getQuotePrivateLiability = (values, deckung) => {

	return {
		"salesPartnerNumber": sessionStorage.getItem("vermittlernummer"),
		"externalIdent": sessionStorage.getItem("externeIdent"),
		"policyholder": {
			"birthday": dateConverter(values.geburtsdatum_VN),
			"address": {
				"street": values.strasse,
				"streetNumber": values.hausnummer,
				"postalCode": values.postleitzahl,
				"city": values.ort,
				"countryCode": "D"
			},
			"phoneNumber": values.telefon?.replace(/\s/g, ''),
			"emailAddress": values.email
		},
		"insuranceData": {
			"countOfPreviousDamages": values.vorschaeden,
			"paymentInterval": translateZahlungsweise(values.zahlungsweise),
			"insuranceBegin": dateConverter(values.beginn),
			"contractTermInYears": parseInt(values.versicherungsdauer),
			"tariffSelection": deckung,
			"deductible": values.selbstbehalt,
			"houseHoldKind": translateFamilienstand(values.familienstand),
		}
	}
};
